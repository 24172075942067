import React, {  } from 'react';
import { useTranslation } from 'react-i18next';
import { Preferences as PreferencesModel } from "../model";
import { PreferencesNotification } from './PreferenceNotification';

type PreferencesNotificationsProps = {
  preferences: PreferencesModel
  onUpdate: (preferences: PreferencesModel) => void
}

export const PreferencesNotifications: React.FC<PreferencesNotificationsProps> = (props) => {

  const { t } = useTranslation();

  return (
    <div className="d-f fb-d-c w-100% max-w-25 max-w-40.5-M max-w-45-L m-b-2">
      <div className="d-f fb-d-c m-t-1 m-t-2-M">
        <div className="d-f h-5 a-i-c b-sb-s b-c-lightestGray b-wb-0.1 f-w-b t-c-b">
          <div className="d-f fb-a p-l-1 p-l-1.5-M">{t("Monitors")}</div>
          <div className="d-f w-4 w-6-M j-c-e p-r-0.5">{t("Email")}</div>
        </div>

        <PreferencesNotification
          field="mailMonitorCreated"
          title={t("Create Monitor")}
          selected={props.preferences.mailMonitorCreated}
          onUpdate={(selected) => props.onUpdate({...props.preferences, mailMonitorCreated: selected})} />

        <PreferencesNotification
          field="mailMonitorDeleted"
          title={t("Delete Monitor")}
          selected={props.preferences.mailMonitorDeleted}
          onUpdate={(selected) => props.onUpdate({...props.preferences, mailMonitorDeleted: selected})} />

        <div className="d-f h-5 a-i-c b-sb-s b-c-lightestGray b-wb-0.1 f-w-b t-c-b m-t-3">
          <div className="d-f fb-a p-l-1 p-l-1.5-M">{t("Invitations")}</div>
          <div className="d-f w-4 w-6-M j-c-e p-r-0.5">{t("Email")}</div>
        </div>

        <PreferencesNotification
          field="mailInvitationAccepted"
          title={t("Invitation Accepted")}
          selected={props.preferences.mailInvitationAccepted}
          onUpdate={(selected) => props.onUpdate({...props.preferences, mailInvitationAccepted: selected})} />

        <PreferencesNotification
          field="mailInvitationDeclined"
          title={t("Invitation Declined")}
          selected={props.preferences.mailInvitationDeclined}
          onUpdate={(selected) => props.onUpdate({...props.preferences, mailInvitationDeclined: selected})} />

        <div className="d-f h-5 a-i-c b-sb-s b-c-lightestGray b-wb-0.1 f-w-b t-c-b m-t-3">
          <div className="d-f fb-a p-l-1 p-l-1.5-M">{t("Webhooks")}</div>
          <div className="d-f w-4 w-6-M j-c-e p-r-0.5">{t("Email")}</div>
        </div>

        <PreferencesNotification
          field="mailWebhookFailing"
          title={t("Webhook Failing")}
          selected={props.preferences.mailWebhookFailing}
          onUpdate={(selected) => props.onUpdate({...props.preferences, mailWebhookFailing: selected})} />

      </div>
    </div>
  );
}

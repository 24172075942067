import React, { useState, useCallback, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import { ReactSortable } from "react-sortablejs";
import { Group, Monitor, Page, PageStatus, ValidatePageCustomDomainReq, ValidatePageSlugReq } from '../model';
import { TextInput, BaseInputOnChangeEvent, RadioButton, Button, ComboBox, BaseInputOnSelectEvent, ComboBoxItem, ComboBoxItemType, Modal, FileInput, Spinner } from '../ui';
import { Validations, useValidator } from '../validator';
import { isFieldStringEmpty, isFieldDomainNotMatching, isStringEmpty, isFieldSlugNotMatching } from '../Validations';
import { Reorder, X } from "../images/";
import { useDebounce } from '../hooks/UseDebounce';
import axios, { CancelTokenSource } from 'axios';
import { apiBaseUrl, cancelTokenMessage, statBaseUrl } from "../Consts";
import { Errors } from '../validator/Validator';
import { GroupNew } from '../group-new';
import { MonitorNew } from '../monitor-new';
import { usePrevious } from '../hooks/UsePrevious';
import Resizer from 'react-image-file-resizer';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { productsState, teamState } from '../atoms';
import { useHandleRequestError } from '../hooks';
import { productsNonFreeEnabledState } from '../atoms/productsNonFreeEnabledState';

const v = new Validations(new Page());
v.addField("name", (v: any) => isFieldStringEmpty(v));
v.addField("customDomain", (v: any) => isStringEmpty(v as string) ? undefined : isFieldDomainNotMatching(v));
v.addField("slug", (v: any) => isFieldStringEmpty(v) || isFieldSlugNotMatching(v));

type PageFormProps = {
  page: Page
  // eslint-disable-next-line
  onSave: (page: Page, logoFile?: File, removeLogo?: boolean, faviconFile?: File, removeFavicon?: boolean) => void
  onCancel: () => void
  errorsOnServer?: Errors<Page>
  isSaving?: boolean
}

class PageStatusMonitor extends PageStatus {
  intervalId!: number
}

const MonitorNewItem = new ComboBoxItem("New monitor ...", ComboBoxItemType.Add);
const GroupNewItem = new ComboBoxItem("New group ...", ComboBoxItemType.Add);

const pageSizeStatusesItems = 10;

export const PageForm: React.FC<PageFormProps> = (props) => {

  const { t } = useTranslation();
  const team = useRecoilValue(teamState);
  const { handleRequestError } = useHandleRequestError();

  const [ statusItems, setStatusItems ] = useState<ComboBoxItem[]>();
  const [ statusInputValue, setStatusInputValue ] = useState<string>();
  const debouncedStatusInputValue = useDebounce(statusInputValue, 500);
  const previousDebouncedStatusInputValue = usePrevious(debouncedStatusInputValue);
  const [ page, setPage ] = useState(props.page);
  const { errors, validateField, validateAll, resetErrorField, resetErrorAllFromServer } = useValidator(v);
  const scrollStatusesToBottom = useRef(false);
  const cancelToken = useRef<CancelTokenSource>();
  const [ isAddingMonitor, setIsAddingMonitor ] = useState(false);
  const [ isAddingGroup, setIsAddingGroup ] = useState(false);

  const [ slugTerm, setSlugTerm ] = useState<string>();
  const debouncedSlugTerm = useDebounce(slugTerm, 500);

  const [ customDomainTerm, setCustomDomainTerm ] = useState<string>();
  const debouncedCustomDomainTerm = useDebounce(customDomainTerm, 500);

  const [ logoFile, setLogoFile ] = useState<File>();
  const [ logoFileProcessed, setLogoFileProcessed ] = useState<File>();
  const [ removeLogo, setRemoveLogo ] = useState(false);
  const [ faviconFile, setFaviconFile ] = useState<File>();
  const [ faviconFileProcessed, setFaviconFileProcessed ] = useState<File>();
  const [ removeFavicon, setRemoveFavicon ] = useState(false);

  const [ statusDefaultFocusedIndex, setStatusDefaultFocusedIndex ] = useState<number>(-1);

  const [ isLoadingItems, setIsLoadingItems ] = useState(false);
  const [ itemsCount, setItemsCount ] = useState(0);

  const allProducts = useRecoilValue(productsState);
  const products = useMemo(() =>
    allProducts?.filter(p => p.productType === "monitor"), [allProducts])
  const productsNonFreeEnabled = useRecoilValue(productsNonFreeEnabledState);
  const [ hasNonFreeInterval, setHasNonFreeInterval ] = useState<boolean>(false);

  const refStatuses = useRef<HTMLDivElement>(null);

  const onChangeField = useCallback(
    (fieldName: keyof Page, event: BaseInputOnChangeEvent) => {
      const value = event.value;
      if (event.dirtyAndTouched) {
        validateField(fieldName, event.value);

        if (fieldName === "slug") {
          setSlugTerm(value as string);
        }

        if (fieldName === "customDomain") {
          setCustomDomainTerm(value as string);
        }
      }
      const _page = { ...page } as Page;
      _page[fieldName] = value as never;

      if (fieldName === "useGroups") {
        _page.statuses = [];
      }
      setPage(_page);

    }, [page, validateField])


  const onBlurField = useCallback(
    (fieldName: keyof Page, event: BaseInputOnChangeEvent) => {
      // Validating slug or customDomain only with remote validation avoids clear the slug
      // error message when the error is because the slug or customDomain already exists, since this
      // validation only is validated on the server, validating on the client removes this error
      if (fieldName === "slug") {
        setSlugTerm(event.value as string);
      } else if (fieldName === "customDomain") {
        setCustomDomainTerm(event.value as string);
      } else {
        validateField(fieldName, event.value);
      }
    }, [validateField]);

  const save = useCallback(() => {
    if (validateAll(page)) {
      props.onSave(page, logoFileProcessed, removeLogo, faviconFileProcessed, removeFavicon);
    }
  }, [validateAll, page, props, logoFileProcessed, removeLogo, faviconFileProcessed, removeFavicon]);


  const removeStatus = useCallback((status: PageStatus) => {
    if (page) {
      const _selectedStatuses = [...page.statuses];
      _selectedStatuses.splice(_selectedStatuses.indexOf(status), 1);
      setPage(page => {
        return {...page, statuses: _selectedStatuses};
      });
    }
  }, [page]);

  const cancel = useCallback(() => {
    props.onCancel();
  }, [props])

  const fetchStatusList = useCallback((text: string, isLoadingNextPage: boolean = false) => {
    if (team) {
      setIsLoadingItems(true);
      if (cancelToken.current !== undefined) {
        cancelToken.current.cancel(cancelTokenMessage);
      }

      cancelToken.current = axios.CancelToken.source();

      if (page.useGroups) {
        axios.get(`${apiBaseUrl}/${team.uuid}/groups`, {
            params: {
              short: true,
              text: text,
              pageSize: pageSizeStatusesItems,
              // length of statusesItems without the new item entry (statusItems.length - 1)
              recordOffset: isLoadingNextPage && statusItems ? statusItems.length - 1 : 0
            },
            cancelToken: cancelToken.current.token
          })
          .then(response => {
            let items = response.data.groups.map((g: Group) => {
              const pageStatus = convertGroupToPageStatus(g);

              const alreadyAdded = page.statuses?.some(s => s.uuid === g.uuid);
              return new ComboBoxItem(
                pageStatus.name,
                alreadyAdded ? ComboBoxItemType.Selected : ComboBoxItemType.Normal,
                pageStatus,
                alreadyAdded);
            });
            if (isLoadingNextPage && statusItems) {
              items = statusItems.concat(items);
            } else {
              items = [GroupNewItem, ...items];
            }
            setItemsCount(response.data.paginator.recordsCount);
            setStatusItems(items);
          })
          .catch(error => handleRequestError(error))
          .finally(() => setIsLoadingItems(false));
      } else {
        axios.get(`${apiBaseUrl}/${team.uuid}/monitors`, {
            params: {
              short: true,
              text: text,
              pageSize: pageSizeStatusesItems,
              recordOffset: isLoadingNextPage && statusItems ? statusItems.length : 0
            },
            cancelToken: cancelToken.current.token
          })
          .then(response => {
            let items = response.data.monitors.map((m: Monitor) => {
              const pageStatus = convertMonitorToPageStatus(m);

              const alreadyAdded = page.statuses?.some(s => s.uuid === m.uuid);
              return new ComboBoxItem(
                pageStatus.name,
                alreadyAdded ? ComboBoxItemType.Selected : ComboBoxItemType.Normal,
                pageStatus,
                alreadyAdded);
            });
            if (isLoadingNextPage && statusItems) {
              items = statusItems.concat(items);
            } else {
              items = [MonitorNewItem, ...items];
            }
            setItemsCount(response.data.paginator.recordsCount);
            setStatusItems(items);
          })
          .catch(error => handleRequestError(error))
          .finally(() => setIsLoadingItems(false));
      }
    }
  }, [page.statuses, page.useGroups, statusItems, team, handleRequestError]);

  const onChangeStatusInputValue = useCallback((e: BaseInputOnChangeEvent) => {
    setStatusInputValue(e.value as string);
    setStatusDefaultFocusedIndex(0);
  }, []);

  const onSelectStatus = useCallback((event: BaseInputOnSelectEvent) => {
    if (event.index === 0) {
      if (page.useGroups) {
        setIsAddingGroup(true);
      } else {
        setIsAddingMonitor(true);
      }
    } else if (statusItems) {
      const selectedStatus = page.useGroups ?
        statusItems[event.index].data as PageStatus :
        statusItems[event.index].data as PageStatusMonitor;
      setPage(page => {
        return {...page, statuses: [...page.statuses || [], selectedStatus]};
      });
      statusItems[event.index].type = ComboBoxItemType.Selected;
      statusItems[event.index].disabled = true;
      scrollStatusesToBottom.current = true;
    }
    setStatusInputValue(undefined);
    setStatusDefaultFocusedIndex(-1);
  }, [page.useGroups, statusItems]);

  const onBlurStatuses = useCallback(() => {
    setStatusDefaultFocusedIndex(-1);
  }, []);

  const onFocusStatuses = useCallback(() => {
    fetchStatusList(statusInputValue as string);
  }, [fetchStatusList, statusInputValue]);

  const onNewGroup = useCallback((newGroup: Group) => {
    setPage(page => {
      return {...page, statuses: [...(page.statuses || []), convertGroupToPageStatus(newGroup)]};
    });
    setIsAddingGroup(false);
    scrollStatusesToBottom.current = true;
  }, []);

  const onNewMonitor = useCallback((newMonitor: Monitor) => {
    setPage(page => {
      return {...page, statuses: [...(page.statuses || []), convertMonitorToPageStatus(newMonitor)]};
    });
    setIsAddingMonitor(false);
    scrollStatusesToBottom.current = true;
  }, []);

  const onChangeLogoFile = useCallback((file?: File) => {
    if (file) {
      Resizer.imageFileResizer(file,
        400, 400, 'PNG', 100, 0,
        uri => { setLogoFileProcessed(uri as File) },
        'blob'
      );
    } else {
      setLogoFileProcessed(undefined)
    }
    setRemoveLogo(false);
    setLogoFile(file);
  }, []);

  const onChangeFaviconFile = useCallback((file?: File) => {
    if (file) {
      Resizer.imageFileResizer(file,
        400, 400, 'PNG', 100, 0,
        uri => { setFaviconFileProcessed(uri as File) },
        'blob'
      );
    } else {
      setFaviconFileProcessed(undefined)
    }
    setRemoveFavicon(false);
    setFaviconFile(file);
  }, []);

  const onScrollItems = useCallback((percent: number) => {
    // itemsCount is the total of records that could be fetched, if this value is not greater
    // than the current statusItems fetched, that means the total of records already has been
    // fetched
    if (percent >= 90 && !isLoadingItems && (!statusItems || itemsCount > statusItems.length)) {
      scrollStatusesToBottom.current = false
      fetchStatusList(statusInputValue as string, true);
    }
  }, [fetchStatusList, isLoadingItems, itemsCount, statusInputValue, statusItems]);

  useEffect(() => {
    if (previousDebouncedStatusInputValue !== debouncedStatusInputValue) {
      fetchStatusList(debouncedStatusInputValue as string);
    }
  }, [debouncedStatusInputValue, fetchStatusList, previousDebouncedStatusInputValue]);

  useLayoutEffect(() => {
    if (refStatuses.current && scrollStatusesToBottom.current) {
      refStatuses.current.scrollTop = refStatuses.current?.scrollHeight;
      scrollStatusesToBottom.current = false;
    }
  }, [page.statuses]);

  useEffect(() => {
    if (props.errorsOnServer) {
      resetErrorAllFromServer(props.errorsOnServer);
    }
  }, [props.errorsOnServer, resetErrorAllFromServer])

  useEffect(
    () => {
      if (debouncedSlugTerm && team) {
        const req = new ValidatePageSlugReq();
        req.slug = debouncedSlugTerm;
        const url = `${apiBaseUrl}/${team.uuid}/pages/${page.uuid ? page.uuid + '/' : ''}validate-slug`
        axios.get(url, { params: req })
          .then(() => resetErrorField("slug"))
          .catch(err => resetErrorField("slug", err.response.data.slug))
      }
    },
    [debouncedSlugTerm, page.uuid, resetErrorField, team] // Only call effect if debounced search term changes
  );

  useEffect(
    () => {
      if (debouncedCustomDomainTerm && team) {
        const req = new ValidatePageCustomDomainReq();
        req.customDomain = debouncedCustomDomainTerm;
        const url = `${apiBaseUrl}/${team.uuid}/pages/${page.uuid ? page.uuid + '/' : ''}validate-custom-domain`
        axios.get(url, { params: req })
          .then(() => resetErrorField("customDomain"))
          .catch(err => resetErrorField("customDomain", err.response.data.customDomain))
      }
    },
    [debouncedCustomDomainTerm, page.uuid, resetErrorField, team] // Only call effect if debounced search term changes
  );

  useEffect(() => {
      setHasNonFreeInterval(
        !!products &&
        !page.useGroups &&
        page.statuses?.some((status) => {
          const monitor = status as PageStatusMonitor;
          return monitor &&
            monitor.intervalId &&
            products.some(p =>
              p.monitorInterval.id === monitor.intervalId && (p.prices && p.prices.length > 0))
        }));
  }, [page, products])

  return (

    <>
      <form onSubmit={e => { e.preventDefault(); save(); }} className="d-f fb-d-c m-h-a w-100% max-w-44.5-M max-w-49-L t-c-nb">
        <div className="d-f fb-d-c">
          <TextInput
            autoFocus={true}
            label={t("Name")}
            value={page.name}
            placeholder={t("Enter Name")}
            onChange={e => onChangeField("name", e)}
            onBlur={e => onBlurField("name", e)}
            error={errors.fields.name}
            maxLength={50}
            type="text" />
        </div>

        <div className="d-f fb-d-c m-t-1 m-t-2-M">
          <TextInput
            label={t("Title (optional)")}
            value={page.title}
            placeholder={t("Enter Title")}
            onChange={e => onChangeField("title", e)}
            onBlur={e => onBlurField("title", e)}
            error={errors.fields.title}
            maxLength={50}
            helpTip={
              <div>
                When filled out, it appears as the title<br />on the Status Pages.
              </div>
            }
            type="text" />
        </div>

        <div className="d-f fb-d-c m-t-1 m-t-2-M">
          <TextInput
            label={t("Slug")}
            value={page.slug}
            placeholder={t("Enter Slug")}
            prefix={<div className="p-h-0.5 bg-c-nw t-c-nb h-100% d-f a-i-c ws-n">{statBaseUrl}/</div>}
            onChange={e => onChangeField("slug", e)}
            onBlur={e => onBlurField("slug", e)}
            error={errors.fields.slug}
            maxLength={50}
            type="text" />
        </div>

        <div className="d-f fb-d-c m-t-1 m-t-2-M">
          <TextInput
            label={t("Custom Domain (optional)")}
            value={page.customDomain}
            placeholder={t("Enter Custom Domain")}
            onChange={e => onChangeField("customDomain", e)}
            onBlur={e => onBlurField("customDomain", e)}
            error={errors.fields.customDomain}
            helpTip={
              <div>
                The custom domain for your status Page.<br /><br />
                DNS configuration:<br />
                <b>Type:</b> CNAME<br />
                <b>Target:</b> page.statsignal.dev<br /><br />
                <a className="d-f p-t-0.5" href="https://www.statsignal.dev/doc/console/custom-domain" target="_blank" rel="noreferrer">More Information</a>
              </div>
            }
            maxLength={253}
            type="text" />
        </div>

        <div className="d-f fb-d-c m-t-1 m-t-2-M">
          <div className="f-s-1 f-s-1.25-M">{t("Items")}</div>
          <div className="d-f fb-d-c m-t-0.5 w-100% b-s-s-M b-w-1px-M b-c-lightestGray-M p-h-2-M p-v-1.5-M">
            <div className="d-f fb-w-w m-b-1">
              <div className="m-r-2">
                <RadioButton
                  onChange={() => onChangeField("useGroups", new BaseInputOnChangeEvent(false, true, true))}
                  checked={!page.useGroups}
                  label={t("Monitors")} />
              </div>
              <div className="m-r-2">
                <RadioButton
                  onChange={() => onChangeField("useGroups", new BaseInputOnChangeEvent(true, true, true))}
                  checked={page.useGroups}
                  label={t("Groups")} />
              </div>
            </div>

            <div ref={refStatuses} className="max-h-12 max-h-16-M of-a">

              <ReactSortable
                list={page.statuses as any[] || []}
                setList={(newList) => setPage({ ...page, statuses: newList as PageStatus[] || [] })}>
                {page.statuses?.map( (ss, i) =>
                  <div className="w-100% m-b-1 c-g" key={i}>
                    <div className="h-3 bg-c-nw p-h-1 p-v-0.5 d-f a-i-c">
                      <div className="fb-n d-f a-i-c">
                        <Reorder />
                      </div>
                      <div className="fb-a ws-n of-h t-o-e m-h-0.5">
                        { ss.name }
                      </div>
                      <div className="fb-n c-p" onClick={() => removeStatus(ss)}>
                        <X />
                      </div>
                    </div>
                  </div>
                )}
              </ReactSortable>

            </div>

            <ComboBox
              value={statusInputValue}
              defaultFocusedIndex={statusDefaultFocusedIndex}
              onInputValueChange={onChangeStatusInputValue}
              onFocus={onFocusStatuses}
              onSelect={onSelectStatus}
              onBlur={onBlurStatuses}
              placeholder={`${t("Select or add a")} ${page.useGroups ? t('Group') : t('Monitor')}...`}
              options={ statusItems }
              showSpinner={ isLoadingItems }
              pageSize={ pageSizeStatusesItems }
              onScroll={ onScrollItems } />

          </div>
        </div>

        <div className="d-f fb-d-c m-t-1 m-t-2-M">
          <TextInput
            label={t("Description (optional)")}
            value={page.description}
            placeholder={t("Enter Description")}
            onChange={e => onChangeField("description", e)}
            maxLength={250}
            type="text" />
        </div>

        <div className="d-f fb-d-c m-t-1 m-t-2-M">          
          <div className="f-s-1 f-s-1.25-M">
            {t("Images")}
          </div>
          <div className="d-f fb-d-c j-c-sb-M m-t-0.5 w-100% b-s-s-M b-w-1px-M b-c-lightestGray-M p-h-2-M p-v-1.5-M">
            <div className="d-f fb-d-c fb-d-r-M w-100%">
              <div className="d-f w-50%-M a-i-c m-t-0.5 p-r-0.5-M">
                <div className="fb-n">
                  <div className="d-f j-c-c a-i-c b-w-1px b-s-s b-c-lightestGray t-c-gray f-w-700 w-8 h-8 p-0.5">
                    { !removeLogo && (logoFileProcessed || page.logoUrl) ?
                      <img className="max-w-100% max-h-100%" alt="Logo" src={logoFileProcessed ? URL.createObjectURL(logoFileProcessed) : page.logoUrl} />
                    : t("Logo")
                    }
                  </div>
                  {page.logoUrl && !logoFileProcessed && !removeLogo ?
                    <div onClick={() => setRemoveLogo(true)} className="t-dl-u c-p t-a-c">
                      {t("Remove")}
                    </div>
                  : page.logoUrl && removeLogo ?
                    <div onClick={() => onChangeLogoFile(undefined)} className="t-dl-u c-p t-a-c">
                      {t("Restore")}
                    </div>
                  : null
                  }
                </div>
                <div className="fb-a m-l-1">
                  <FileInput
                    value={logoFile}
                    accept="image/jpeg, image/jpg, image/png"
                    placeholder={!removeLogo && (logoFileProcessed || page.logoUrl) ? t("Change") : t("Upload")}
                    onChange={e => onChangeLogoFile(e.value as (File | undefined))} />
                </div>
              </div>
              <div className="d-f w-50%-M a-i-c m-t-1.5 m-t-0.5-M p-l-0.5-M">
                <div className="fb-n">
                  <div className="d-f j-c-c a-i-c b-w-1px b-s-s b-c-lightestGray t-c-gray f-w-700 w-8 h-8 p-0.5">
                    { !removeFavicon && (faviconFileProcessed || page.faviconUrl) ?
                      <img className="max-w-100% max-h-100%" alt="Logo" src={faviconFileProcessed ? URL.createObjectURL(faviconFileProcessed) : page.faviconUrl} />
                    : t("Favicon")
                    }
                  </div>
                  {page.faviconUrl && !faviconFileProcessed && !removeFavicon ?
                    <div onClick={() => setRemoveFavicon(true)} className="t-dl-u c-p t-a-c">
                      {t("Remove")}
                    </div>
                  : page.faviconUrl && removeFavicon ?
                    <div onClick={() => onChangeFaviconFile(undefined)} className="t-dl-u c-p t-a-c">
                      {t("Restore")}
                    </div>
                  : null
                  }
                </div>
                <div className="fb-a m-l-1">
                  <FileInput
                    value={faviconFile}
                    accept="image/jpeg, image/jpg, image/png"
                    placeholder={!removeFavicon && (faviconFileProcessed || page.faviconUrl) ? t("Change") : t("Upload")}
                    onChange={e => onChangeFaviconFile(e.value as (File | undefined))} />
                </div>
              </div>
            </div>
            <div className="m-t-1 f-s-0.9 t-c-gray">
              {t("File size can´t exceed 50k / Min 32x32 pixels and max 400x400 pixels.")}
            </div>
          </div>
        </div>
        <div className="d-f fb-d-c m-t-1 m-t-2-M">
          <TextInput
            label={t("Google Analytics Id (optional)")}
            value={page.googleAnalyticsId}
            placeholder={t("Enter Google Analytics Id")}
            onChange={e => onChangeField("googleAnalyticsId", e)}
            maxLength={100}
            type="text" />
        </div>

        {!productsNonFreeEnabled && hasNonFreeInterval ?
          <div className="d-f m-t-2 j-c-e t-c-gray">
            {page.uuid ?
              t("By clicking on the Save button, you will be redirected to add a Payment Method.")
            :
              t("By clicking on the Add Monitor button, you will be redirected to add a Payment Method.")
            }
          </div>
        : null}

        <div className="d-f fb-d-cr fb-d-r-M m-t-1 m-b-1 m-b-2-M j-c-e a-i-c w-100%">
          {props.isSaving ?
            <Spinner type={"primary"} />
          :
            <>
              <div onClick={cancel} className="d-f t-c-g f-s-1.25 c-p p-t-1 p-t-0-M j-c-e-M">
                {t("Cancel")}
              </div>
              <div className="m-l-1-M w-100% w-auto-M">
                <Button type="primary" submit={true} fullWidth="mobile">
                  { page.uuid ? t("Save") : t("Add Page") }
                </Button>
              </div>
            </>
          }
        </div>
      </form>

      <Modal
        open={isAddingGroup}
        onClose={ () => setIsAddingGroup(false) }
        title={t("New Group")}>
        <GroupNew
          onSave={onNewGroup}
          onCancel={ () => setIsAddingGroup(false) }
          disallowSetMonitors={true}
          />
      </Modal>

      <Modal
        open={isAddingMonitor}
        onClose={ () => setIsAddingMonitor(false) }
        title={t("New Monitor")}>
        <MonitorNew
          onSave={onNewMonitor}
          onCancel={ () => setIsAddingMonitor(false) }
          noRedirectToCheckout={true}
          disallowSetGroups={true}
          />
      </Modal>
    </>

  );
}

function convertGroupToPageStatus(group: Group): PageStatus {
  const pageStatus = new PageStatus();
  pageStatus.name = group.name;
  pageStatus.type = 'group';
  pageStatus.uuid = group.uuid;

  return pageStatus;
}

function convertMonitorToPageStatus(monitor: Monitor): PageStatusMonitor {
  const pageStatus = new PageStatusMonitor();
  pageStatus.name = monitor.name;
  pageStatus.type = 'monitor';
  pageStatus.uuid = monitor.uuid;
  pageStatus.intervalId = monitor.intervalId;

  return pageStatus;
}
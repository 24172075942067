export const teamsPath = "teams";

export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const statBaseUrl = process.env.REACT_APP_STAT_BASE_URL;
export const authBaseUrl = process.env.REACT_APP_AUTH_BASE_URL;
export const webBaseUrl = process.env.REACT_APP_WEB_BASE_URL;
export const reCaptchaKeyV2 = process.env.REACT_APP_RECAPTCHA_KEY_V2;
export const reCaptchaKeyV3 = process.env.REACT_APP_RECAPTCHA_KEY_V3;
export const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;
export const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const sessionCookieName = "session_token";
export const cancelTokenMessage = "cancelToken";

export enum Breakpoints {
  Large = 1024,
  Medium = 768
}

export enum SidebarOptionColors {
  Enabled = "#168D13",
  Disabled = "#4a4a4a"
}

export const DefaultColor = "#168D13"
export const DefaultPageSize = 10
export const ColorTextRed = "#CB2C2C"

export enum StatusColors {
  Starting = "#AEE5AE",
  Up = "#168D13",
  Degraded = "#B0BF53",
  Disrupted = "#FFD978",
  Down = "#CB2C2C",
  Maintenance = "#5779C0",
  Paused = "#4A4A4A",
  Activity = "#40E0D0"
}

export enum MonitorStatusType {
  Starting = "starting",
  Up = "up",
  Degraded = "degraded",
  Disrupted = "disrupted",
  Down = "down",
  Maintenance = "maintenance",
  Paused = "paused",
  Archived = "archived",
  Deleted = "deleted",
  RequiresPaymentMethod = "requiresPaymentMethod"
}

export enum SelectorStatusType {
  Pausing = "pausing",
  Maintaining = "maintaining",
  Deleting  = "deleting"
}

export type StatusType = MonitorStatusType | SelectorStatusType;

export enum MonitorHttpMethods {
  get = "get",
  post = "post",
  put = "put",
  patch = "patch",
  head = "head",
  delete = "delete",
  options = "options"
}

export enum GroupColors {
  DeepPink = "#FF1493",
  Plum = "#DDA0DD",
  Magenta = "#FF00FF",
  DarkOrchid = "#9932CC",
  Purple = "#800080",
  Indigo = "#4B0082",
  Navy = "#000080",
  DodgerBlue = "#1E90FF",
  SteelBlue = "#4682B4",
  DeepSkyBlue = "#00BFFF",
  DarkTurquoise = "#00CED1",
  Turquoise = "#40E0D0",
  LightSeaGreen = "#20B2AA",
  Teal = "#008080",
  DarkSlateGray = "#2F4F4F",
  DarkGreen = "#006400",
  Green = "#008000",
  Olive = "#808000",
  YellowGreen = "#9ACD32",
  SpringGreen = "#00FF7F",
  Yellow = "#FFFF00",
  Gold = "#FFD700",
  Orange = "#FFA500",
  DarkOrange = "#FF8C00",
  Coral = "#FF7F50",
  Red = "#FF0000",
  DarkRed = "#8b0000",
  SaddleBrown = "#8B4513",
  Chocolate = "#D2691E",
  DarkGoldenRod = "#B8860B",
  GoldenRod = "#DAA520",
  Tan = "#D2B48C"
}

export const responseCodes: Record<string, string> = {
  // Informational
  "100": "Continue",
  "101": "Switching Protocols",
  "102": "Processing",
  
  // Success
  "200": "Ok",
  "201": "Created",
  "202": "Accepted",
  "203": "Non-authoritative Information",
  "204": "No Content",
  "205": "Reset Content",
  "206": "Partial Content",
  "207": "Multi-Status",
  "208": "Already Reported",
  "226": "IM Used",

  // Redirection
  "300": "Multiple Choices",
  "301": "Moved Permanently",
  "302": "Found",
  "303": "See Other",
  "304": "Not Modified",
  "305": "Use Proxy",
  "307": "Temporary Redirect",
  "308": "Permanent Redirect",

  // Client Error
  "400": "Bad Request",
  "401": "Unauthorized",
  "402": "Payment Required",
  "403": "Forbidden",
  "404": "Not Found",
  "405": "Method Not Allowed",
  "406": "Not Acceptable",
  "407": "Proxy Authentication Required",
  "408": "Request Timeout",
  "409": "Conflict",
  "410": "Gone",
  "411": "Length Required",
  "412": "Precondition Failed",
  "413": "Payload Too Large",
  "414": "Request-URI Too Long",
  "415": "Unsupported Media Type",
  "416": "Requested Range Not Satisfiable",
  "417": "Expectation Failed",
  "418": "I'm a teapot",
  "421": "Misdirected Request",
  "422": "Unprocessable Entity",
  "423": "Locked",
  "424": "Failed Dependency",
  "426": "Upgrade Required",
  "428": "Precondition Required",
  "429": "Too Many Requests",
  "431": "Request Header Fields Too Large",
  "444": "Connection Closed Without Response",
  "451": "Unavailable For Legal Reasons",
  "499": "Client Closed Request",

  // Server Error
  "500": "Internal Server Error",
  "501": "Not Implemented",
  "502": "Bad Gateway",
  "503": "Service Unavailable",
  "504": "Gateway Timeout",
  "505": "HTTP Version Not Supported",
  "506": "Variant Also Negotiates",
  "507": "Insufficient Storage",
  "508": "Loop Detected",
  "510": "Not Extended",
  "511": "Network Authentication Required",
  "599": "Network Connect Timeout Error",

  // Not HTTP responses
	"1200": "Ok",
  "1405": "Could not resolve host",
	"1410": "Status does not match",
	"1411": "Status match",
	"1412": "Keyword header does not exist",
	"1413": "Keyword header exists",
	"1414": "Keyword body does not exist",
	"1415": "Keyword body exists",
  "1416": "Keyword response does not exist",
  "1417": "Keyword response exists",
	"1500": "Connection refused",
  "1510": "Request timeout",
	"1512": "Max redirects reached",
	"1513": "Body too large",
	"1514": "Body error",
  "1515": "Response too large"
};
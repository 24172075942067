import React from "react";

type ChevronRightProps = {
  active?: boolean
}
export const ChevronRight: React.FC<ChevronRightProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={props.active ? (props.active === true ? "currentColor" : "#E6E6E6") : "currentColor" }
        d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"
      ></path>
    </svg>
  );
}
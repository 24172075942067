import { MonitorStatusType } from "../Consts"

export class MonitorsFilterModel {
  public title?: string
  public groupUuid?: string
  public groupColor?: string
  public pageUuid?: string
  public alertUuid?: string
  public pageLogoUrl?: string
  public pageFaviconUrl?: string
  public status?: MonitorStatusType[]
}
import React from 'react';
import { ProfileMenu } from './ProfileMenu';
import { NewMenu } from './NewMenu';
import { useRecoilValue } from 'recoil';
import { isOwnerOrMemberState, teamState } from '../atoms';
import { formatAmount } from '../Helper';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const TeamHeader: React.FC = () => {

  const { t } = useTranslation();
  const isOwnerOrMember = useRecoilValue(isOwnerOrMemberState);
  const team = useRecoilValue(teamState);

  return (
    <>
      <div className="z-9 pos-l-14-L pos-l-3.5 d-f pos-f pos-t-0 pos-r-0 h-4 b-sb-s b-c-lightestGray bg-c-w b-w-1px">
        <div className="fb-a">
        </div>
        <div className="fb-n d-f a-i-c m-r-0.5">
          {team ?
            <Link to={`/${team.slug}/billing`} className="m-l-1.5 d-f fb-d-c a-i-c c-p">
              <div className="f-s-0.8 t-c-gray">
                {t("Balance")}
              </div>
              <div className="f-s-1 f-w-600 t-c-nb">
                {formatAmount(team.balance, 2)}
              </div>
            </Link>
          : null}
          <div className="m-h-1.5">
            {isOwnerOrMember ?
              <NewMenu />
            : null
            }
          </div>
          <div>
            <ProfileMenu />
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { TextInput, BaseInputOnChangeEvent, Spinner, Button } from '../ui';
import { ChangeSessionUserPasswordReq } from '../model';
import { useValidator, Validations } from '../validator';
import { isFieldStringEmpty } from '../Validations';
import { apiBaseUrl } from '../Consts';
import { useTranslation } from 'react-i18next';
import { useHandleRequestError } from '../hooks';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { messageState, userState } from '../atoms';

const v = new Validations(new ChangeSessionUserPasswordReq());
v.addField("password", (v: any) => isFieldStringEmpty(v));
v.addField("passwordConfirmation", (v: any) => isFieldStringEmpty(v));

export const ChangePassword: React.FC = () => {

  const { t } = useTranslation();
  const { handleRequestErrorAvoidingPopup } = useHandleRequestError();
  const user = useRecoilValue(userState);
  const [ model, setModel ] = useState(new ChangeSessionUserPasswordReq());
  const { errors, validateField, validateAll, resetErrorAll, resetErrorAllFromServer } = useValidator(v);
  const [ isSaving, setIsSaving ] = useState(false);
  const [ dirty, setDirty ] = useState(false);
  const setMessage = useSetRecoilState(messageState);

  const onChangeField = useCallback(
    (fieldName: keyof ChangeSessionUserPasswordReq, event: BaseInputOnChangeEvent) => {
      const value = event.value;
      if (event.dirtyAndTouched) {
        validateField(fieldName, event.value);
      }
      const _model = { ...model } as ChangeSessionUserPasswordReq;
      _model[fieldName] = value as never;

      setModel(_model);
      setDirty(dirty || event.dirty);

    }, [dirty, model, validateField])

  const onBlurField = useCallback(
    (fieldName: keyof ChangeSessionUserPasswordReq, event: BaseInputOnChangeEvent) => {
      if (event.dirty) {
        validateField(fieldName, event.value);
      }
    }, [validateField])

  const save = useCallback(() => {
    if (validateAll(model)) {
      setIsSaving(true);
      axios.put(`${apiBaseUrl}/session/user/password`, model)
        .then(() => {
          setModel(new ChangeSessionUserPasswordReq());
          resetErrorAll();
          setDirty(false);
          setMessage({
            type: "success",
            text: t("Your password has been successfully changed.")
          });
        })
        .catch(err => {
          if (err.message) {
            resetErrorAllFromServer(err.response.data)
          } else {
          handleRequestErrorAvoidingPopup(err)
          }
        })
        .finally(() => setIsSaving(false));
    }
  }, [validateAll, model, resetErrorAll, setMessage, t, resetErrorAllFromServer, handleRequestErrorAvoidingPopup])

  const discard = useCallback(() => {
    setModel(new ChangeSessionUserPasswordReq());
    resetErrorAll();
    setDirty(false);
  }, [resetErrorAll]);

  return (
    <form onSubmit={(e) => { e.preventDefault(); save(); }}>
      {user?.registeredWithEmail ?
        <>
          <div className="t-c-b f-w-b f-s-1.25 m-t-3">
            {t("Change Password")}
          </div>
          <div className="d-f fb-d-c m-t-1 m-t-2-M">
            <TextInput
              label={t("Current Password")}
              value={model.currentPassword}
              placeholder={t("Enter Current Password")}
              onChange={e => onChangeField("currentPassword", e)}
              onBlur={e => onBlurField("currentPassword", e)}
              error={errors.fields.currentPassword}
              maxLength={64}
              type="password" />
          </div>
        </>
      :
        <div className="t-c-b f-w-b f-s-1.25 m-t-3">
          {t("Set Password")}
        </div>
      }
      <div className="d-f fb-d-c m-t-1 m-t-2-M">
        <TextInput
          label={t("New Password")}
          value={model.password}
          placeholder={t("New Password")}
          onChange={e => onChangeField("password", e)}
          onBlur={e => onBlurField("password", e)}
          error={errors.fields.password}
          maxLength={64}
          type="password" />
      </div>
      <div className="d-f fb-d-c m-t-1 m-t-2-M">
        <TextInput
          label={t("Confirm Password")}
          value={model.passwordConfirmation}
          placeholder={t("Confirm Password")}
          onChange={e => onChangeField("passwordConfirmation", e)}
          onBlur={e => onBlurField("passwordConfirmation", e)}
          error={errors.fields.passwordConfirmation}
          maxLength={64}
          type="password" />
      </div>
      {dirty ?
        <div className="d-f j-c-e p-v-1 p-v-2-M b-sb-s b-c-lightestGray b-w-1px">
          {isSaving ?
            <Spinner type={"primary"} />
          :
            <div className="d-f fb-d-cr fb-d-r-M w-100% m-v-1 j-c-e a-i-c">
              <div onClick={discard} className="t-c-g f-s-1.25 c-p p-t-1 p-t-0-M">
                {t("Cancel")}
              </div>
              <div className="m-l-1-M w-100% w-auto-M">
                <Button type="primary" submit={true} fullWidth="mobile">
                  {t("Change Password")}
                </Button>
              </div>
            </div>
          }
        </div>
      : null }
    </form>
  )
}
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { ApiToken, UpdateApiTokenReq } from '../model';
import axios from 'axios';
import { Loading, Modal } from '../ui';
import { apiBaseUrl } from "../Consts";
import { SettingsApiTokenForm } from './SettingsApiTokenForm';
import { Errors } from '../validator/Validator';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { teamState } from '../atoms';
import { useHandleRequestError } from '../hooks';

type SettingsApiTokenEditProps = {
  apiTokenUuid?: string,
  onUpdate: (apiToken: ApiToken) => void
  onCancel: () => void
}

export const SettingsApiTokenEdit: React.FC<SettingsApiTokenEditProps> = (props) => {

  const { t } = useTranslation();
  const team = useRecoilValue(teamState);
  const { handleRequestError } = useHandleRequestError();
  const [ isSaving, setIsSaving ] = useState(false);

  const [ apiToken, setApiToken ] = useState<ApiToken>();
  const apiTokenUuid = useMemo(() => props.apiTokenUuid, [props.apiTokenUuid]);
  const [ errorsOnServer, setErrorsOnServer ] = useState<Errors<ApiToken>>();

  const fetch = useCallback(() => {
    if (team && apiTokenUuid) {
      axios.get(`${apiBaseUrl}/${team.uuid}/api-tokens/${apiTokenUuid}`)
        .then(response => setApiToken(response.data.apiToken as ApiToken))
        .catch(err => {
          props.onCancel();
          handleRequestError(err)
        });
    }
  }, [team, apiTokenUuid, handleRequestError, props]);


  const onSave = useCallback((apiToken: ApiToken) => {
    if (team) {
      const req = new UpdateApiTokenReq();
      req.name = apiToken.name;
      req.allowWrite = apiToken.allowWrite;
      setIsSaving(true)

      axios.patch(`${apiBaseUrl}/${team.uuid}/api-tokens/${apiToken.uuid}`, req)
        .then(response => {
          props.onUpdate(response.data.apiToken as ApiToken);
        })
        .catch(err => {
          handleRequestError(err);
          setErrorsOnServer(err.response.data);
        })
      .finally(() => setIsSaving(false));
    }
  }, [team, props, handleRequestError]);

  const onCancel = useCallback(() => {
    props.onCancel();
  }, [props])

  useEffect(() => fetch(), [fetch])

  if (apiTokenUuid !== undefined) {
    return (
      <Modal
        open={true}
        onClose={ () => props.onCancel() }
        title={t("Edit API Token")}
        description={ apiToken?.name }
        noPadding={true}>
        <div className="p-h-1 p-h-2-M">
          { !apiToken ?
            <Loading />
          :
            <SettingsApiTokenForm
              errorsOnServer={errorsOnServer}
              onSave={onSave}
              onCancel={onCancel}
              apiToken={apiToken}
              isSaving={isSaving} />
          }
        </div>
      </Modal>
    );
  } else {
    return null;
  }
}


import React, { useState, useCallback } from 'react';
import { TagsInput, TagsInputItem, BaseInputOnAddEvent, BaseInputOnChangeEvent, BaseInputOnRemoveEvent, Spinner, Button } from '../ui';
import { isFieldEmailNotMatching } from '../Validations';
import axios from 'axios';
import { apiBaseUrl } from '../Consts';
import { InviteMembersReq, Member, Paginator } from '../model';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { teamState } from '../atoms';
import { useHandleRequestError } from '../hooks';

type InviteMembersProps = {
  onCancel: () => void
  onInvitationsSent: (members: Member[], paginator: Paginator) => void
}

export const InviteMembers: React.FC<InviteMembersProps> = (props) => {

  const { t } = useTranslation();
  const team = useRecoilValue(teamState);
  const { handleRequestError } = useHandleRequestError();

  const [ emails, setEmails ] = useState<TagsInputItem[]>([]);
  const [ emailInputValue, setEmailInputValue ] = useState("");
  const [ emailInputError, setEmailInputError ] = useState<string>();
  const [ isSendingInvitations, setIsSendingInvitations ] = useState<boolean>();

  const onChangeEmailInputValue = useCallback((event: BaseInputOnChangeEvent) => {
    setEmailInputError(undefined);
    setEmailInputValue(event.value as string);
  }, []);

  const onAddEmail = useCallback((event: BaseInputOnAddEvent) => {
    const error = isFieldEmailNotMatching(event.value as string, "Email");
    if (error) {
      setEmailInputError(error);
    } else {
      setEmailInputError(undefined);
      setEmails([
        ...(emails || []),
        new TagsInputItem(event.value as string)
      ]);
      setEmailInputValue("");
    }
  }, [emails]);

  const onRemoveEmail = useCallback((event: BaseInputOnRemoveEvent) => {
    const _emails = [...emails];
    _emails.splice(event.index, 1);
    setEmails(_emails);
    setEmailInputValue("");
  }, [emails]);

  const onCancel = useCallback(() => {
    props.onCancel();
  }, [props])

  const onSendInvitations = useCallback(() => {
    setIsSendingInvitations(true);
    if (team) {
      const req = new InviteMembersReq()
      req.emails = emails.map(e => e.text);
      req.teamUuid = team.uuid;
      axios.post(`${apiBaseUrl}/${team.uuid}/members`, req)
        .then(response => {
          props.onInvitationsSent(response.data.members, response.data.paginator);
        })
        .catch(err => {
          handleRequestError(err)
          setIsSendingInvitations(false);
        })
    }
  }, [emails, props, team, handleRequestError])

  return (
    <React.Fragment>
      <TagsInput
        autoFocus={true}
        error={emailInputError}
        tags={emails}
        label={t("Email Addresses")}
        onAdd={onAddEmail}
        onRemove={onRemoveEmail}
        inputValue={emailInputValue}
        onInputValueChange={onChangeEmailInputValue}
        charSeparators=" ,;"
        placeholder={t("Enter email addresses")}
      />

      <div className="d-f fb-d-cr fb-d-r-M m-v-1 m-v-2-M j-c-e a-i-c w-100%">
        {isSendingInvitations ?
          <Spinner type={"primary"} />
        :
        <React.Fragment>
          <div onClick={onCancel} className="d-f t-c-g f-s-1.25 c-p p-t-1 p-t-0-M j-c-e-M">
            {t("Cancel")}
          </div>
          <div className="m-l-1-M w-100% w-auto-M">
            <Button type="primary" onClick={onSendInvitations} fullWidth="mobile">
              {t("Send Invitations")}
            </Button>
          </div>
        </React.Fragment>
        }
      </div>
    </React.Fragment>
  );
}
import React from "react";
import { sizeFlag } from "../Helper";

type FlagAUProps = {
  size?: string | "small"
}

export const FlagAU: React.FC<FlagAUProps> = (props) => {
  const size = sizeFlag(props.size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size.width}
      height={size.height}
      fill="none"
      viewBox="0 0 24 18"
    >
      <path fill="#006" d="M0 2h24v16H0V2z"></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M11.242 14.973l-1.638.128.225 1.459L8.7 15.494 7.571 16.56l.225-1.459-1.639-.128 1.414-.75-.911-1.227 1.537.521.503-1.401.506 1.402 1.538-.522-.912 1.227 1.41.75zm7.005 1.345l-.397-.531.667.225.218-.608.218.608.667-.225-.394.531.615.326-.712.057.097.636-.487-.468-.495.468.097-.636-.712-.054.618-.329zm-.003-9.484l-.394-.531.667.225.218-.608.218.608.667-.225-.394.531.615.33-.712.053.097.636-.487-.464-.495.46.097-.632-.712-.053.615-.33zm-3.964 3.853l-.394-.531.668.225.217-.609.221.609.668-.225-.398.53.615.327-.712.057.101.636-.495-.468-.487.468.093-.636-.712-.054.615-.33zm7.496-.948l-.401-.531.668.225.217-.609.221.609.668-.225-.394.53.611.327-.712.057.097.632-.487-.464-.495.464.097-.632-.712-.057.622-.326zm-1.451 2.797l-.386.218.108-.4-.348-.262.453-.034.173-.376.176.38.454.03-.349.262.109.4"
        clipRule="evenodd"
      ></path>
      <path fill="#006" d="M0 2h12v8H0V2z"></path>
      <path
        fill="#fff"
        d="M1.406 2l4.575 3.017L10.537 2H12v1.033L7.5 6.017 12 8.983V10h-1.5L6 7.017 1.519 10H0V9l4.481-2.967L0 3.067V2h1.406z"
      ></path>
      <path
        fill="#C8102E"
        d="M7.95 6.683L12 9.333V10L6.919 6.683H7.95zm-3.45.334l.112.583-3.6 2.4H0l4.5-2.983zM12 2v.05L7.331 5.183l.038-.733L11.063 2H12zM0 2l4.481 2.933H3.356L0 2.7V2z"
      ></path>
      <path
        fill="#fff"
        d="M4.519 2v8.069h3V2h-3zM0 4.69v2.689h12v-2.69H0z"
      ></path>
      <path
        fill="#C8102E"
        d="M0 5.244v1.614h12V5.244H0zM5.119 2v8.069h1.8V2h-1.8z"
      ></path>
    </svg>
  )
}
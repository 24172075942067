import React from 'react';
import { MonitorStatusType } from '../Consts';
import { getCheckBoxZise, statusToColor } from '../Helper';
import { GroupIcon, PageIcon } from '../images';

type StatusProps = {
  status: MonitorStatusType
  type?: string
  size?: string
}

export const Status: React.FC<StatusProps> = (props) => {

  const size = getCheckBoxZise(props.size ? props.size : "small");
  const color = statusToColor(props.status);

  return (
    <div className="d-f j-c-s a-i-c">
      <div className={`d-f a-i-c j-c-c ${props.type === "monitor" ? "b-r-50% b-w-0" : "b-r-20%"}`}
        style={ props.type === "monitor" ? { backgroundColor: color, height: size, width: size } : { borderColor: color } }>
          {props.type !== "monitor" ?
            props.type === "group" ?
              <GroupIcon color={color} size={props.size} />
            :
            <PageIcon color={color} size={props.size} />
          : null
          }
      </div>
    </div>
  );

}
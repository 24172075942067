import React, {  } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { GeneralHeader } from "./header";
import { teamsPath } from "./Consts";
import { WorkspaceInitialize } from "./workspace-initialize";
import { Teams } from "./teams";
import { TeamNew } from "./team-new";
import { Profile } from "./profile";
import { EmailConfirmation } from "./header/EmailConfirmation";
import { CompleteCheckout } from "./complete-checkout";


export const GeneralScope: React.FC = () => {

  const location = useLocation();

  return (
    <React.Fragment>
      <GeneralHeader />
      <div className="p-t-4">
        {!location.pathname.startsWith("/checkout/") ?
          <EmailConfirmation />
        : null
        }
        <Switch>
          <Route path="/" exact={true} component={Teams} />
          <Route path="/profile" exact={true} component={Profile} />
          <Route path="/checkout/:checkoutKey" exact={true} component={CompleteCheckout} />
          <Route path={`/workspace/initialize`} exact={true} component={WorkspaceInitialize} />
          <Route path={`/${teamsPath}/new`} exact={true} component={TeamNew} />
          <Route path={`/${teamsPath}`} exact={true} component={() => <Redirect to="/" />} />
        </Switch>
      </div>
    </React.Fragment>
  )
}

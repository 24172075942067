import React, { useCallback, useState, useRef, useLayoutEffect, useMemo } from 'react';
import { ChevronLeft } from '../images/ChevronLeft';
import { ChevronRight } from '../images/ChevronRight';
import { renderErrors } from "../Helper";
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { productsState } from '../atoms/productsState';
import { useWindowSize } from '../hooks';
import { teamState } from '../atoms';

type IntervalsProps = {
  intervalId?: number
  onChange: (intervalId: number) => void
  error?: string | string[]
}

export const Intervals: React.FC<IntervalsProps> = (props) => {

  const { t } = useTranslation();

  const windowSize = useWindowSize();

  const team = useRecoilValue(teamState);
  const container = useRef<HTMLDivElement>(null);
  const allProducts = useRecoilValue(productsState);
  const products = useMemo(() =>
    allProducts?.filter(p => p.productType === "monitor"), [allProducts])
  const [ showLeftArrow, setShowLeftArrow ] = useState(false);
  const [ showRightArrow, setShowRightArrow ] = useState(true);

  const scrollToRight = useCallback(() => {
    if (container.current) {
      const ref = container.current;
      ref.scrollLeft = ref.scrollLeft + (ref.getBoundingClientRect().width * 0.8);
    }
  }, []);

  const scrollToLeft = useCallback(() => {
    if (container.current) {
      const ref = container.current;
      ref.scrollLeft = ref.scrollLeft - (ref.getBoundingClientRect().width * 0.8);
    }
  }, []);

  const onScroll = useCallback((e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = e.currentTarget;
    setShowLeftArrow(target.scrollLeft > 0);
    setShowRightArrow(target.scrollLeft + target.getBoundingClientRect().width < e.currentTarget.scrollWidth);
  }, []);

  useLayoutEffect(() => {
    if (container.current) {
      setShowRightArrow(container.current.scrollWidth > container.current.clientWidth);
    }
  }, [windowSize]);

  if (!team) {
    return null
  }

  return (
    <div className="d-f fb-d-c m-t-1 m-t-2-M">
      <div className="f-s-1 f-s-1.25-M">{t("Choose Interval")}</div>
      <div className="d-f m-t-1">
        <div className="fb-n d-f w-1.5 a-i-c p-b-1.5">
          { showLeftArrow ?
            <div onClick={scrollToLeft} className="h-100% c-p d-f a-i-c">
              <ChevronLeft />
            </div>
          : null
          }
        </div>
        <div
          onScroll={onScroll}
          className="fb-a d-f of-x-a p-b-1.5"
          ref={container}
          style={{scrollBehavior: 'smooth'}}>
            { products?.map((p, index) => {
              let time;
              let interval;
              const i = p.monitorInterval;
              if (i.seconds >= 60 && i.seconds < 3600) {
                interval = i.seconds/60
                time = t("min")
              } else if (i.seconds === 3600) {
                interval = i.seconds/3600
                time = t("hr")
              } else {
                interval = i.seconds
                time = t("sec")
              }
              const isFree = !p.prices || p.prices.length === 0;
              const availableInterval = !isFree || team.isWorkspace;
              return (
                <div
                  key={i.id}
                  onClick={availableInterval ? () => props.onChange(i.id) : () => ("")}
                  className={`${props.intervalId === i.id ? "b-c-g" : "b-c-gray"} d-f pos-re fb-n fb-d-c w-8.25 w-9-L h-12 b-s-s b-w-1px ${index > 0 ? "m-l-1.5" : ""} ${availableInterval ? "c-p" : ""}`}>
                  <div className={`${props.intervalId === i.id ? "b-c-g bg-c-lg" : "b-c-gray bg-c-lightestGray"} d-f a-i-c j-c-c f-s-1.5 b-sb-s b-w-1px p-v-1`}>
                    { interval } {time}
                  </div>
                  <div className="d-f fb-a fb-d-c j-c-c a-i-c f-w-400 f-s-1">
                    {isFree ?
                      t("FREE")
                    :
                      <>
                        {p.prices.map((pp, i) =>
                          <div key={i} className="f-s-0.85 d-f w-100% p-h-0.5">
                            <div className="w-4 fb-n p-l-0.25">
                              { pp.name }
                            </div>
                            <div className="fb-a p-h-0.25 p-t-0.15 t-a-r f-w-600">
                              { pp.monthlyPrice } $
                            </div>
                          </div>
                        )}
                        <div className="f-s-0.85 p-t-0.35">
                          {t("Monthy in US$")}
                        </div>
                      </>
                    }
                  </div>
                  { !availableInterval ?
                    <>
                      <div className="pos-a pos-l-0 pos-r-0 pos-t-0 pos-b-0 bg-c-w o-0.6">
                      </div>
                      <div className="pos-a pos-t-4 p-0.5 t-c-nb t-a-c bg-c-w">
                        {t("Not available with Teams")}
                      </div>
                    </>
                  : null
                  }
                </div>
              )}
            )}
        </div>
        <div className="fb-n d-f a-i-c j-c-e w-1.5 p-b-1.5">
          { showRightArrow ?
            <div onClick={scrollToRight} className="h-100% c-p d-f a-i-c">
              <ChevronRight />
            </div>
          : null
          }
        </div>
      </div>

      { renderErrors(props.error) }
    </div>
  )
}

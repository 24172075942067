import React from "react";

export const CheckMark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="currentColor"
        d="M6 10L2 6 0 8l6 6L16 4l-2-2-8 8z"
      ></path>
    </svg>
  );
}
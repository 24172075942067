import React from "react";
import { sizeFlag } from "../Helper";

type FlagINProps = {
  size?: string
}

export const FlagIN: React.FC<FlagINProps> = (props) => {
  const size = sizeFlag(props.size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size.width}
      height={size.height}
      fill="none"
      viewBox="0 0 24 16"
    >
      <path fill="#F93" d="M0 0h24v5.333H0V0z"></path>
      <path fill="#fff" d="M0 5.333h24v5.334H0V5.333z"></path>
      <path fill="#128807" d="M0 10.667h24V16H0v-5.333z"></path>
      <path
        fill="#008"
        d="M12 10.133c1.325 0 2.4-.955 2.4-2.133S13.325 5.867 12 5.867 9.6 6.822 9.6 8s1.075 2.133 2.4 2.133z"
      ></path>
      <path
        fill="#fff"
        d="M12 9.867c1.16 0 2.1-.836 2.1-1.867 0-1.031-.94-1.867-2.1-1.867-1.16 0-2.1.836-2.1 1.867 0 1.03.94 1.867 2.1 1.867z"
      ></path>
      <path
        fill="#008"
        d="M12 8.373c.232 0 .42-.167.42-.373s-.188-.373-.42-.373c-.232 0-.42.167-.42.373s.188.373.42.373zM14.068 8.34c.059.006.113-.03.121-.083.008-.053-.034-.1-.093-.108-.059-.007-.113.03-.121.083-.008.052.034.1.093.107z"
      ></path>
      <path
        fill="#008"
        d="M12 9.867l.072-1.12L12 8.213l-.072.534.072 1.12z"
      ></path>
    </svg>
  )
}
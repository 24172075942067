import React, { useEffect, useState, useCallback, useMemo } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { CreateTeamReq, ValidateTeamSlugReq, Team } from '../model';
import { TextInput, Button, BaseInputOnChangeEvent, Modal, Spinner, Checkbox } from '../ui';
import { Validations, useValidator } from '../validator';
import { isFieldStringEmpty, isFieldSlugNotMatching } from '../Validations';
import { useDebounce } from '../hooks/UseDebounce';
import { apiBaseUrl } from "../Consts";
import { useTranslation } from 'react-i18next';
import { useHandleRequestError } from '../hooks';
import { useRecoilValue } from 'recoil';
import { hasWorkspaceState } from '../atoms/hasWorkspaceState';

const v = new Validations(new CreateTeamReq());
v.addField("name", (v: any) => isFieldStringEmpty(v));
v.addField("slug", (v: any) => isFieldStringEmpty(v) || isFieldSlugNotMatching(v));

export const TeamNew: React.FC = () => {

  const { t } = useTranslation();
  const { handleRequestErrorAvoidingPopup } = useHandleRequestError();
  const history = useHistory();
  const [ isEdited, setIsEdited ] = useState(false);
  const hasWorkSpace = useRecoilValue(hasWorkspaceState);
  const [ canceling, setCanceling ] = useState(false);
  const [ dirtySlug, setDirtySlug ] = useState(false);
  const [ team, setTeam ] = useState(() => {
    const t = new CreateTeamReq();
    return t;
  });
  const [ slugTerm, setSlugTerm ] = useState<string>();
  const debouncedSlugTerm = useDebounce(slugTerm, 500);
  const { errors, validateField, validateAll, resetErrorField, resetErrorAllFromServer } = useValidator(v);
  const [ isSaving, setIsSaving ] = useState(false);

  const slugPrefix = useMemo(() => `${window.location.protocol}//${window.location.host}/`, [])

  const onChangeField = useCallback(
    (fieldName: keyof CreateTeamReq, event: BaseInputOnChangeEvent) => {
      let value = event.value;
      const _team = { ...team } as CreateTeamReq;

      if (fieldName === "slug") {
        value = (value as string).toLowerCase();
      }
      if (fieldName === "name" && !dirtySlug) {
        _team["slug"] = (value as string)
          .toLowerCase()
          .split(" ")
          .join("-")
          .replace(/[^\sa-z\d-]/g, "")
          .replace(/^-+/g, "")
          .replace(/-+$/g, "");
      }
      if (event.dirtyAndTouched) {
        validateField(fieldName, event.value);

        if (fieldName === "slug") {
          setSlugTerm(value as string);
        }
      }
      _team[fieldName] = value as never;

      setTeam(_team);
      setIsEdited(true);

    }, [team, validateField, dirtySlug])

  const onBlurField = useCallback(
    (fieldName: keyof CreateTeamReq, event: BaseInputOnChangeEvent) => {
      if (event.dirty) {
        // Validating slug only with remote validation avoids clear the slug error message
        // when the error is because the slug already exists, since this validation only
        // is validated on the server, validating on the client removes this error
        if (fieldName === "slug") {
          setDirtySlug(true);
          setSlugTerm(event.value as string);
        } else {
          validateField(fieldName, event.value);
        }
      }
    }, [validateField])

  const save = useCallback(() => {
    if (validateAll(team)) {
      setIsSaving(true);
      axios.post(`${apiBaseUrl}/teams/new`, team)
        .then(response => {
          const team = response.data.team as Team;

          window.location.href = `/${team.slug}`
        })
        .catch(err => {
          setIsSaving(false);
          handleRequestErrorAvoidingPopup(err);
          resetErrorAllFromServer(err.response.data);
        })
    }
  }, [validateAll, team, resetErrorAllFromServer, handleRequestErrorAvoidingPopup]);

  const discard = useCallback(() => {
    if (isEdited) {
      setCanceling(true)
    } else { 
      history.push("/")
    }
  }, [history, setCanceling, isEdited]);

  useEffect(
    () => {
      if (debouncedSlugTerm) {
        const req = new ValidateTeamSlugReq();
        req.slug = debouncedSlugTerm;
        axios.get(`${apiBaseUrl}/teams/validate-slug`, { params: req })
          .then(() => resetErrorField("slug"))
          .catch(err => resetErrorField("slug", err.response.data.slug))
      }
    },
    [debouncedSlugTerm, resetErrorField] // Only call effect if debounced search term changes
  );

  return (
    <React.Fragment>
      <form
        onSubmit={(e) => { e.preventDefault(); save(); }}
        className="d-f fb-d-c m-h-a w-100% max-w-44.5-M max-w-49-L p-h-1 p-h-2-M t-c-nb">
        <div className="f-w-300 f-s-1.5 f-s-2-M m-t-1.5">{t("New Team")}</div>

        <div className="d-f fb-d-c m-t-1 m-t-2-M">
          <TextInput
            autoFocus
            label={t("Name")}
            value={team.name}
            placeholder={t("Enter Team Name")}
            onChange={e => onChangeField("name", e)}
            onBlur={e => onBlurField("name", e)}
            error={errors.fields.name}
            maxLength={50}
            type="text" />
        </div>
        <div className="d-f fb-d-c m-t-1 m-t-2-M">
          <TextInput
            label={t("Slug")}
            value={team.slug}
            placeholder={t("Enter Slug")}
            prefix={<div className="d-n d-f-M p-h-1 bg-c-nw t-c-nb h-100% a-i-c ws-n">{slugPrefix}</div>}
            onChange={e => onChangeField("slug", e)}
            onBlur={e => onBlurField("slug", e)}
            error={errors.fields.slug}
            maxLength={50}
            type="text" />
          <div className="d-f d-n-M m-t-1 t-c-nb fb-n a-i-c">
            {slugPrefix}{team.slug}
          </div>
        </div>
        {!hasWorkSpace ?
          <div className="d-f m-t-1 m-t-2-M">
            <Checkbox
              large={true}
              label={t("Create as workspace ")}
              onChange={(e) => onChangeField("isWorkspace", e)}
              checked={team.isWorkspace} />
          </div>
        : null}
        <div className="d-f j-c-e m-t-1 m-t-2-M">
          <React.Fragment>
            <div className="d-f fb-d-cr fb-d-r-M a-i-c j-c-e w-100%">
              { isSaving ?
                <Spinner type={"primary"} />
              :
                <>
                  <div onClick={discard} className="d-f t-c-g f-s-1.25 c-p p-t-1 p-t-0-M j-c-e-M">
                    { t("Discard")}
                  </div>
                  <div className="m-l-1-M w-100% w-auto-M">
                    <Button type="primary" submit={true} fullWidth="mobile">{t("Save")}</Button>
                  </div>
                </>
              }
            </div>
          </React.Fragment>
        </div>
      </form>

      <Modal
        open={canceling}
        onClose={ () => setCanceling(false) }
        secondaryActions={ [ { title: t("No"), onAction: () => setCanceling(false) } ] }
        primaryAction={ { title: t("Discard"), onAction: () => (history.push("/")), type: "destructive" } }>
        <div className="f-w-300 f-s-2">{t("Discard New Team?")}</div>
      </Modal>

    </React.Fragment>
  )
}
import { MonitorEvent, MonitorRegionEvent } from ".";
import { DateTime } from "luxon";
import { duration } from "../Helper";

export class MonitorEventWrap {

  constructor(
    public event: MonitorEvent | MonitorRegionEvent,
    public nextEventTime?: DateTime) {
  }

  public get description(): string {
    const event = this.event;
    const createdAt = DateTime.fromISO(event.createdAt);
  
    let durationDescription = `${event.status.charAt(0).toUpperCase() + event.status.slice(1)} at ${createdAt.toFormat("MMM d HH:mm")}`;
  
    const nextEventTime = this.nextEventTime ?? DateTime.now();

    const _duration = duration(createdAt, nextEventTime);

    if (_duration) {
      durationDescription = `${durationDescription} for ${_duration}`;
    }

    return durationDescription;
  }

  public static wrapMonitorEvents(monitorEvents: MonitorEvent[] | MonitorRegionEvent[], nextEventTime: DateTime | undefined = undefined): MonitorEventWrap[] {
    const eventWraps: MonitorEventWrap[] = [];
    for (let i = 0; i < monitorEvents.length; i++) {
      const monitorEvent = monitorEvents[i];
      let _nextEventTime: DateTime | undefined;
  
      if (i === 0) {
        if (nextEventTime) {
          _nextEventTime = nextEventTime;
        }
      } else {
        _nextEventTime = DateTime.fromISO(monitorEvents[i - 1].createdAt);
      }
  
      const eventWrap = new MonitorEventWrap(monitorEvent, _nextEventTime);
      eventWraps.push(eventWrap);
    }
    return eventWraps;
  }

}

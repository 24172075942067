import React from "react";
import { getCheckBoxZise } from "../Helper";

type SettingsIconProps = {
  color?: string
  size?: string
}

export const SettingsIcon: React.FC<SettingsIconProps> = (props) => {
  const color = props.color
  const size = getCheckBoxZise(props.size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M21.1754 13.175C21.2268 12.8 21.2525 12.4125 21.2525 12C21.2525 11.6 21.2268 11.2 21.1626 10.825L23.7713 8.85C24.0026 8.675 24.0668 8.3375 23.9255 8.0875L21.4581 3.9375C21.3039 3.6625 20.9827 3.575 20.6999 3.6625L17.6286 4.8625C16.9861 4.3875 16.305 3.9875 15.5468 3.6875L15.0842 0.5125C15.0328 0.2125 14.7758 0 14.4673 0H9.53266C9.22425 0 8.98008 0.2125 8.92868 0.5125L8.46605 3.6875C7.70786 3.9875 7.01392 4.4 6.38424 4.8625L3.31292 3.6625C3.0302 3.5625 2.70893 3.6625 2.55472 3.9375L0.100236 8.0875C-0.0539726 8.35 -0.00256984 8.675 0.254445 8.85L2.86314 10.825C2.79889 11.2 2.74748 11.6125 2.74748 12C2.74748 12.3875 2.77319 12.8 2.83744 13.175L0.228743 15.15C-0.00256982 15.325 -0.0668233 15.6625 0.0745346 15.9125L2.54187 20.0625C2.69608 20.3375 3.01735 20.425 3.30006 20.3375L6.37139 19.1375C7.01392 19.6125 7.69501 20.0125 8.4532 20.3125L8.91583 23.4875C8.98008 23.7875 9.22425 24 9.53266 24H14.4673C14.7758 24 15.0328 23.7875 15.0713 23.4875L15.5339 20.3125C16.2921 20.0125 16.9861 19.6125 17.6158 19.1375L20.6871 20.3375C20.9698 20.4375 21.2911 20.3375 21.4453 20.0625L23.9126 15.9125C24.0668 15.6375 24.0026 15.325 23.7584 15.15L21.1754 13.175ZM12 16.5C9.45556 16.5 7.37374 14.475 7.37374 12C7.37374 9.525 9.45556 7.5 12 7.5C14.5444 7.5 16.6263 9.525 16.6263 12C16.6263 14.475 14.5444 16.5 12 16.5Z"
      />
    </svg>
  );
}
import { MonitorStatusType } from "../Consts";
import { Group, Monitor } from "../model";

// Immutable class
export class GroupSelector {

  constructor(
    public readonly group: Group,
    public readonly expanded: boolean = false,
    public readonly selected: boolean = false,
    public readonly deleting: boolean = false) {
  }

  delete(): GroupSelector {
    return new GroupSelector(this.group, this.expanded, this.selected, true);
  }

  select(): GroupSelector {
    return new GroupSelector(this.group, this.expanded, !this.deleting ? true : this.selected, this.deleting);
  }
  
  unselect(): GroupSelector {
    return new GroupSelector(this.group, this.expanded, !this.deleting ? false : this.selected, this.deleting);
  }

  toggle(): GroupSelector {
    return new GroupSelector(this.group, this.expanded, !this.deleting ? !this.selected : this.selected, this.deleting);
  }

  expand(): GroupSelector {
    return new GroupSelector(this.group, true, this.selected, this.deleting);
  }

  updateFromRemote(group: Group): GroupSelector {
    return new GroupSelector(group, this.expanded);
  }

  updateMonitorsFromRemote(monitors: Monitor | Monitor[]): GroupSelector {
    if (!this.group.monitors || this.group.monitors.length === 0) {
      return this;
    }

    if (!(monitors instanceof Array)) {
      monitors = [monitors];
    }
    
    const newMonitors = [...this.group.monitors] as Monitor[];
    let found = false;

    for (const monitor of monitors) {
      const index = newMonitors.findIndex(m => m.uuid === monitor.uuid);

      if (index > -1) {
        found = true;

        const currentMonitor = newMonitors[index];
        const monitorNew: Monitor = {
          ...monitor,
          groups: currentMonitor.groups,
          interval: currentMonitor.interval,
          regions: currentMonitor.regions
        };
    
        switch (monitorNew.status) {
          case MonitorStatusType.Deleted:
          case MonitorStatusType.Archived:
            newMonitors.splice(index, 1);
            break;
          default:
            newMonitors.splice(index, 1, monitorNew);
        }
      }
    }

    return found ?
      new GroupSelector({...this.group, monitors: newMonitors}, this.expanded, this.selected, this.deleting) :
      this;
  }

  public get blocked(): boolean {
    return this.deleting;
  }

  public get canDelete(): boolean {
    return !this.blocked;
  }
}
import React from "react";
import { getCheckBoxZise } from "../Helper";


type PreferencesIconProps = {
  color?: string
  size?: string
}

export const PreferencesIcon: React.FC<PreferencesIconProps> = (props) => {
  const color = props.color ? props.color : "#4A4A4A"
  const size = getCheckBoxZise(props.size ? props.size : "small");

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z"
      ></path>
    </svg>
  );
}
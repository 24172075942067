import { useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Paginator } from "../model";
import { useQuery } from "./UseQuery";

export const useAdjustPage = (baseUrl: string | undefined) => {
  const shouldFetch = useRef(true);

  const query = useQuery();
  const history = useHistory();

  const shouldFetchAndReset = useCallback((): boolean => {
    const should = shouldFetch.current;
    if (!should) {
      shouldFetch.current = true;
    }
    return should;
  }, []);

  const adjustPage = useCallback((paginator: Paginator) => {
    if (paginator.recordOffsetChanged && baseUrl) {
      shouldFetch.current = false;
      const _query = new URLSearchParams(query);
      _query.set(
        "page",
        Math.ceil(( paginator.recordOffset + 1) / paginator.pageSize).toString())
      history.replace(`${baseUrl}?${_query.toString()}`);
    }
  }, [baseUrl, history, query]);

  return { adjustPage, shouldFetchAndReset };
}
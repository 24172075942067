import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { Spinner, Modal, TextInput, BaseInputOnChangeEvent } from '../ui';
import { apiBaseUrl } from "../Consts";
import { useTranslation } from 'react-i18next';
import { useHandleRequestError } from '../hooks';
import { useRecoilValue } from 'recoil';
import { userState } from '../atoms';

export const DeleteAccount: React.FC = () => {

  const { t } = useTranslation();
  const { handle422Error } = useHandleRequestError();
  const user = useRecoilValue(userState);

  const [ isRemoving, setIsRemoving ] = useState(false);
  const [ confirmRemove, setConfirmRemove ] = useState(false);

  const [ emailConfirmation, setEmailConfirmation ] = useState<string>();
  const [ deleteConfirmationError, setDeleteConfirmationError ] = useState<string>();

  const [ error, setError ] = useState<string>();

  const cancelDelete = useCallback(() => {
    setConfirmRemove(false);
    setDeleteConfirmationError(undefined);
    setEmailConfirmation(undefined);
  }, []);

  const execRemove = useCallback(() => {
    if (user) {
      if (emailConfirmation === user.email) {
        cancelDelete();
        setIsRemoving(true);
        axios
          .delete(`${apiBaseUrl}/account`)
          .then(() => window.location.href = "/")
          .catch(err => {
            const error = handle422Error(err);
            if (error) {
              setError(error);
            }
          })
          .finally(() => setIsRemoving(false));
      } else {
        setDeleteConfirmationError(t("Invalid email address."));
      }
    }
  }, [cancelDelete, emailConfirmation, handle422Error, t, user]);

  const onChangeEmailConfirmation = useCallback((event: BaseInputOnChangeEvent) => {
    setEmailConfirmation(event.value as string);
  }, [])

  if (!user) {
    return null;
  }

  return (
    <>
      <div className="d-f fb-d-c m-t-2 m-t-5-M">
        <div className="h-2 h-3-M f-s-1 f-s-1.25-M t-c-r">
          {t("Danger Zone")}
        </div>
        <div className="b-s-s b-w-1px b-c-red bg-c-nw p-1 f-s-1">
          <div className="f-w-700 m-b-0.5">
            {t("Delete Account")}
          </div>
          <div className="d-f fb-d-c fb-d-r-M a-i-e">
            <div className="fb-a p-r-2 d-f fb-d-c">
              {error ?
                <div className="t-c-r m-t-0.5">{t("Error")}: {error}</div>
              :
                <div>
                  {t("If you are owner of a team, you need to remove yourself as the owner or remove the team.")}
                </div>
              }
            </div>
            <div className="d-f fb-n j-c-e p-t-1 p-t-0-M">
              {isRemoving ?
                <Spinner type={"destructive"} />            
              :
                <div
                  onClick={() => { setError(undefined); setConfirmRemove(true); }}
                  tabIndex={0}
                  className="t-c-r c-p">
                  {t("Delete")}
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      { confirmRemove ?
        <Modal
          open={confirmRemove}
          onClose={ cancelDelete }
          title={t("Delete Account")}
          secondaryActions={ [ {onAction: cancelDelete } ] }
          primaryAction={ { title: t("Delete Account"), onAction: execRemove, type: "destructive" } }>
          <div className="p-2">
            <div className="f-s-1.5 f-w-300 t-c-b">
              {t("You can't reverse this action!")}
            </div>
            <div className="f-s-1 f-w-300 m-t-0.5">
              {t("This action will delete your account in Statsignal.")}
            </div>
            <div className="f-s-1 f-w-300 m-t-0.5">
              <div className="m-b-0.5 m-t-1 f-w-700">
                {t("Write your email address '{{email}}' to confirm this action", {email: user?.email})}:
              </div>
              <TextInput
                autoFocus={true}
                placeholder="Email Address"
                value={emailConfirmation}
                error={deleteConfirmationError}
                onChange={onChangeEmailConfirmation} />
            </div>
          </div>
        </Modal>
        : null
      }
    </>
  );
}

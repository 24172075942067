import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import { apiBaseUrl } from '../Consts';
import { useHandleRequestError } from '../hooks';
import { Loading } from '../ui';


export const AccountConfirmation: React.FC = () => {

  const { t } = useTranslation();
  const history = useHistory();
  const { handleRequestError } = useHandleRequestError();
  const { email, confirmationCode } = (useParams() as any);
  const [ confirmed, setConfirmed ] = useState(false);
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    axios.post(`${apiBaseUrl}/confirm-account/${email}/${confirmationCode}`)
      .then(() => {
        setConfirmed(true);
        timeout = setTimeout(() => history.replace("/"), 10000);
      })
      .catch((err) => handleRequestError(err))
      .finally(() => setLoading(false));
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, [confirmationCode, email, handleRequestError, history])

  if (loading) {
    return <Loading />
  }

  return (
    confirmed ?
      <div className="d-f fb-d-c a-i-c bg-c-nw h-100vh">
        <div className="t-a-c m-t-2.5 m-t-3.5-M f-w-b f-s-5-L f-s-3-M f-s-2">
          <Trans>
            Great!<br />
            Your email address was<br />
            confirmed!
          </Trans>
        </div>
        <div className="t-a-c m-t-2.5 m-t-3-M f-s-3-L f-s-2-M f-s-1.5">
          {t("You are being redirected...")}
        </div>
        <div className="t-a-c m-v-2.5 m-v-3-M f-s-1.5-M f-s-1">
          <Trans>
            If you are not redirected&nbsp;
            <Link to="/" className="t-c-g">click here</Link>&nbsp;
            to continue.
          </Trans>
        </div>
      </div>
    :
      <div className="d-f fb-d-c a-i-c bg-c-nw h-100vh">
        <div className="t-a-c m-t-2.5 m-t-3.5-M f-w-b f-s-2.5 f-s-3.5-M f-s-5-L">
          <Trans>
            Looks like this link<br/>
            has expired!
          </Trans>
        </div>
        <div className="t-a-c f-w-400 f-s-1 f-s-1.5-M m-v-1 m-v-3-M t-c-gray">
          {t("Need help? Ask at")}&nbsp;
          <a href="mailto:support@statsignal.dev" target="_blank" rel="noreferrer" className="t-c-g">support@statsignal.dev</a>.
        </div>
        <div className="t-a-c m-v-1.5 m-v-2-M f-s-1.5 f-s-2-M f-s-3-L f">
          <Trans>
            Do you need start monitoring<br />
            your internet application?
          </Trans>
          <br /><br />
          <a href="https://www.statsignal.dev" className="t-c-g">{t("Start exploring")}</a>
        </div>
      </div>
  )
}
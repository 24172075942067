import React from "react";

type MaintenanceButtonProps = {
  size?: number
}

export const MaintenanceButton: React.FC<MaintenanceButtonProps> = (props) => {
  const size = props.size || 24

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#000"
        d="M20.777 17.895l-7.02-7.064c.694-1.785.308-3.881-1.158-5.356-1.543-1.552-3.858-1.863-5.71-1.01l3.318 3.339-2.315 2.329-3.395-3.338c-.926 1.863-.54 4.191 1.003 5.744 1.466 1.475 3.55 1.863 5.324 1.164l7.021 7.064c.309.31.772.31 1.08 0l1.775-1.785c.386-.31.386-.854.077-1.087z"
      ></path>
    </svg>
  )
}

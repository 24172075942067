import { Alert } from "../model";
import { MultiSelectState } from "../ui";
import { AlertSelector } from "./AlertSelector";

// Immutable class
export class AlertSelectors {

  private readonly selectors: AlertSelector[];
  public readonly multiSelectState: MultiSelectState;

  public readonly hasSelectedForDelete: boolean;

  constructor(alerts: Alert[] | AlertSelector[]) {
    if (alerts.length > 0) {
      if ((alerts[0] as Alert).uuid) {
        this.selectors = (alerts as Alert[]).map(g => new AlertSelector(g));
      } else {
        this.selectors = (alerts as AlertSelector[]);
      }
    } else {
      this.selectors = [];
    }

    let hasSelected = false;
    let hasUnSelected = false;

    let hasSelectedForDelete = false;
    
    this.selectors.forEach(ms => {
      hasSelected = hasSelected || ms.selected;
      hasUnSelected = hasUnSelected || !ms.selected;

      hasSelectedForDelete = hasSelectedForDelete || (ms.canDelete);
    });

    this.multiSelectState =
      hasUnSelected && hasSelected ?
        MultiSelectState.Partially :
      !hasUnSelected && hasSelected ? 
        MultiSelectState.All :
        MultiSelectState.None;

    this.hasSelectedForDelete = hasSelectedForDelete;
  }

  get length(): number {
    return this.selectors ? this.selectors.length : 0;
  }

  get list(): AlertSelector[] {
    return this.selectors;
  }

  delete(alertSelector: AlertSelector): AlertSelectors {
    return new AlertSelectors(this.selectors.map(
      ms => ms.alert.uuid === alertSelector.alert.uuid ? ms.delete() : ms));
  }

  remove(uuids: string | string[]): AlertSelectors {
    if (!(uuids instanceof Array)) {
      uuids = [uuids];
    }
    return new AlertSelectors(this.selectors.filter(ms => !uuids.includes(ms.alert.uuid)));
  }

  select(alertSelector: AlertSelector): AlertSelectors {
    return new AlertSelectors(this.selectors.map(
      ms => ms.alert.uuid === alertSelector.alert.uuid && !ms.blocked ? ms.select() : ms));
  }
  
  unselect(alertSelector: AlertSelector): AlertSelectors {
    return new AlertSelectors(this.selectors.map(
      ms => ms.alert.uuid === alertSelector.alert.uuid ? ms.unselect() : ms));
  }

  toggle(alertSelector: AlertSelector): AlertSelectors {
    return alertSelector.selected ? this.unselect(alertSelector) : this.select(alertSelector);
  }

  expand(alertSelector: AlertSelector): AlertSelectors {
    return new AlertSelectors(this.selectors.map(
      ms => ms.alert.uuid === alertSelector.alert.uuid ? ms.expand() : ms));
  }

  showRecipients(alertSelector: AlertSelector): AlertSelectors {
    return new AlertSelectors(this.selectors.map(
      ms => ms.alert.uuid === alertSelector.alert.uuid ? ms.showRecipients() : ms));
  }

  hideRecipients(alertSelector: AlertSelector): AlertSelectors {
    return new AlertSelectors(this.selectors.map(
      ms => ms.alert.uuid === alertSelector.alert.uuid ? ms.hideRecipients() : ms));
  }

  changeMultiSelectState(): AlertSelectors {
    const state = this.multiSelectState !== MultiSelectState.None ?
      MultiSelectState.None :
      MultiSelectState.All;

    return state === MultiSelectState.All ? this.selectAll() : this.unselectAll();
  }

  unselectAll(): AlertSelectors {
    return new AlertSelectors(this.selectors.map(ms => ms.selected ? ms.unselect() : ms));
  }

  selectAll(): AlertSelectors {
    return new AlertSelectors(this.selectors.map(ms => !ms.selected && !ms.blocked ? ms.select() : ms));
  }

  updateFromRemote(alerts: Alert | Alert[]): AlertSelectors {
    if (!(alerts instanceof Array)) {
      alerts = [alerts];
    }
    return new AlertSelectors(this.selectors.map(
      as => {
        const a = (alerts as Alert[]).find(a => a.uuid === as.alert.uuid);
        return a ? as.updateFromRemote(a) : as;
      }));
  }
}
import React from 'react';
import { NewTabIcon } from '../images/NewTabIcon';
import { Monitor } from '../model';

type StatusProps = {
  monitor: Monitor
  size?: "small" | "large"
  noLink?: boolean
}

export const MonitorUrl: React.FC<StatusProps> = (props) => {

  const url = `${ props.monitor.type }://${ props.monitor.urn }`;
  const title = props.monitor.type + "://" + props.monitor.urn

  return (
    !props.noLink && (props.monitor.type === "http" || props.monitor.type === "https") ?
      <a className="d-f max-w-100% a-i-c" href={url} rel="noreferrer" target="_blank">
        <div className="d-f of-h">
          <span className={`ws-n t-o-e of-h t-c-nb f-w-300 ${props.size === "small" ? 'f-s-0.87' : 'f-s-1'}`}>
            {url}
          </span>
        </div>
        <div className="d-f p-l-0.25 a-i-c"><NewTabIcon /></div>
      </a>
    :
      <span className={`t-c-nb f-w-300 ${props.size === "small" ? 'f-s-0.87' : 'f-s-1'}`} title={title}>
        {url}
      </span>
  )

}
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Alert, UpdateAlertReq } from '../model';
import axios from 'axios';
import { Loading, Modal } from '../ui';
import { apiBaseUrl } from "../Consts";
import { AlertForm } from '../alert-form';
import { Errors } from '../validator/Validator';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { teamState } from '../atoms';
import { useHandleRequestError } from '../hooks';

type AlertEditProps = {
  alertUuid?: string,
  onUpdate: (alert: Alert) => void
  onCancel: () => void
  onFetch?: (alert: Alert) => void
}

export const AlertEdit: React.FC<AlertEditProps> = (props) => {

  const { t } = useTranslation();
  const team = useRecoilValue(teamState);
  const { handleRequestError, handleRequestErrorAvoidingPopup } = useHandleRequestError()

  const [ alert, setAlert ] = useState<Alert>();
  const alertUuid = useMemo(() => props.alertUuid, [props.alertUuid])
  const onFetch = useMemo(() => props.onFetch, [props.onFetch])
  const onParentCancel = useMemo(() => props.onCancel, [props.onCancel])
  const [ errorsOnServer, setErrorsOnServer ] = useState<Errors<Alert>>()
  const [ isSaving, setIsSaving ] = useState(false);

  const fetch = useCallback(() => {
    if (team && alertUuid) {
      axios.get(`${apiBaseUrl}/${team.uuid}/alerts/${alertUuid}`)
        .then(response => {
          const alert = response.data.alert as Alert;
          setAlert(alert);
          if (onFetch) {
            onFetch(alert);
          }
        })
        .catch(err => {
          onParentCancel();
          handleRequestError(err);
        });
    }
  }, [team, alertUuid, onFetch, handleRequestError, onParentCancel]);


  const onSave = useCallback((alert: Alert) => {
    if (team) {
      const req = new UpdateAlertReq();
      req.name = alert.name;
      req.up = alert.up;
      req.degraded = alert.degraded;
      req.disrupted = alert.disrupted;
      req.down = alert.down;
      req.maintenance = alert.maintenance;
      req.recipients = alert.recipients;
      setIsSaving(true);

      axios.patch(`${apiBaseUrl}/${team.uuid}/alerts/${alert.uuid}`, req)
        .then(response => {
          props.onUpdate(response.data.alert as Alert);
        })
        .catch(err => {
          handleRequestErrorAvoidingPopup(err);
          setErrorsOnServer(err.response.data);
        })
        .finally(() => setIsSaving(false));
    }
  }, [team, props, handleRequestErrorAvoidingPopup]);

  const onCancel = useCallback(() => {
    props.onCancel();
  }, [props])

  useEffect(() => fetch(), [fetch])

  if (alertUuid !== undefined) {
    return (
      <Modal
        open={true}
        onClose={ () => props.onCancel() }
        title={t("Edit Alert")}
        description={ alert?.name }
        noPadding={true}
        isSaving={isSaving} >
        <div className="p-h-1 p-h-2-M m-v-1 m-v-1.5-M">
          { !alert ?
            <Loading />
          :
            <AlertForm
              errorsOnServer={errorsOnServer}
              onSave={onSave}
              onCancel={onCancel}
              alert={alert}
              isSaving={isSaving} />
          }
        </div>
      </Modal>
    );
  } else {
    return null;
  }
}

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Loading, Tabs, TabsOption } from '../ui';
import { Route, useRouteMatch, useHistory, Switch, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PreferencesNotifications } from './PreferencesNotifications';
import { PreferencesAppearance } from './PreferencesAppearance';
import { Preferences as PreferencesModel } from  "../model";
import axios from 'axios';
import { apiBaseUrl } from '../Consts';
import { useRecoilValue } from 'recoil';
import { teamState } from '../atoms';
import { useHandleRequestError } from '../hooks';

export const Preferences: React.FC = () => {

  const match = useRouteMatch();

  const team = useRecoilValue(teamState);
  const { t } = useTranslation();
  const tabsOptions = useMemo<TabsOption[]>(() =>
    [
      {
        id: "notifications",
        title: t("Notifications"),
        link: `/${team?.slug}/preferences/notifications`
      },
      {
        id: "appearance",
        title: t("Appearance"),
        link: `/${team?.slug}/preferences/appearance`
      }
    ]
  , [t, team?.slug]);

  const { handleRequestError } = useHandleRequestError();
  const history = useHistory();
  const [selectedTabOptionIndex, setSelectedTabOptionIndex] = useState(-1);
  const [preferences, setPreferences] = useState<PreferencesModel>();

  const fetch = useCallback(() => {
    if (team) {
      axios.get(`${apiBaseUrl}/${team.uuid}/preferences`)
        .then(response => setPreferences(response.data.preferences))
        .catch(err => handleRequestError(err));
    }
  }, [handleRequestError, team])

  useEffect(() => {
    const pathParts = history.location.pathname.split('/');
    let tabId = pathParts[pathParts.length - 1];
    if (tabId.length === 0) {
      tabId = pathParts[pathParts.length - 2];
    }
    const index = tabsOptions.findIndex(t => t.id === tabId);
    setSelectedTabOptionIndex(index);
  }, [history.location, tabsOptions]);

  useEffect(() => fetch(), [fetch]);

  if (!team || !preferences) {
    return <Loading />
  } else {
    return (
      <div className="d-f w-100% max-w-66 fb-d-c m-h-a p-h-1 p-h-2-M t-c-nb p-b-1 p-b-2-M">
        <div className="f-w-300 f-s-1.5 f-s-2-M m-t-1.5">
          {t("Preferences")}
        </div>
        <div>
          <Tabs
            options={ tabsOptions }
            selected={ selectedTabOptionIndex } />
        </div>
        <div className="d-f j-c-c m-h-a w-100%">
          <Switch>
            <Route path={`${match.path}`} exact><Redirect to={`${match.path}/notifications`} /></Route>
            <Route path={`${match.path}/notifications`} exact component={() =>
              <PreferencesNotifications
                preferences={preferences}
                onUpdate={(preferences) => setPreferences(preferences)}
              />
            }/>

            <Route path={`${match.path}/appearance`} exact component={PreferencesAppearance} />
          </Switch>
        </div>
      </div>
    );
  }
}

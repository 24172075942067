import React from "react";
import { getCheckBoxZise } from "../Helper";


type CustomeIconProps = {
  size?: string
  background?: string
}

export const CustomeIcon: React.FC<CustomeIconProps> = (props) => {
  const size = getCheckBoxZise(props.size);

  return (
    <div
      className="d-f b-r-20%"
      style={{
        backgroundImage: `url(${props.background})`,
        backgroundSize: `cover`,
        backgroundPosition: `center`,
        height: (size),
        width: (size),
      }}>
    </div>
  );
}
import React, { useCallback, useState } from 'react';
import { AlertIcon, GroupIcon, PageIcon } from '../../images';
import { BaseInputOnChangeEvent, BaseInputOnBlurEvent } from '../event/BaseInputEvent';
import { CheckMark } from '../images/'

type CheckboxWithImageProps = {
  color?: string
  checked?: boolean
  label?: string
  disabled?: boolean
  size?: string
  round?: boolean
  type: "group" | "page" | "alert"
  onChange?: (event: BaseInputOnChangeEvent) => void
  onBlur?: (event: BaseInputOnBlurEvent) => void
  background?: string
}

export const CheckboxWithImage: React.FC<CheckboxWithImageProps> = (props) => {

  const [ dirty, setDirty ] = useState(false);
  const [ touched, setTouched ] = useState(false);

  const onChange = useCallback(() => {
    const checked = !props.checked;
    const dirty = true;
    setDirty(dirty);
    if (props.onChange) {
      props.onChange(new BaseInputOnChangeEvent(checked, dirty, touched))
    }
  }, [props, touched]);

  const onBlur = useCallback(() => {
    setTouched(true);
    if (props.onBlur) {
      props.onBlur(new BaseInputOnBlurEvent(props.checked || false, dirty, true));
    }
  }, [props, dirty]);

  const color = 
    props.disabled ? 
      undefined
    :
      props.color;

  let backgroundImage

  switch (props.type) {
    case "group" :
      backgroundImage = (
        <GroupIcon color={color} size={props.size}/>
      )
      break;
    case "page" :
      backgroundImage = (
        <PageIcon color={color} size={props.size}/>
      )
      break;
    case "alert" :
      backgroundImage = (
        <AlertIcon color={color} size={props.size}/>
      )
      break;
      default:
      backgroundImage = (null)
  }

  const style =
    props.disabled ?
      undefined
    :
      props.checked ?
        props.color ?
          {backgroundColor: color, borderColor: color}
        :
         undefined
      :
        props.background ?
          {backgroundImage: `url(${props.background}) `, backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center"}
        :
          props.color ?
            {borderColor: color}
          :
            undefined

  return (
    <div
      tabIndex={0}
      onClick={!props.disabled ? onChange : undefined}
      onBlur={onBlur}
      className={`d-f a-i-c ${!props.disabled ? "c-p" : undefined}`}>
      <div className={
          `d-f t-c-w a-i-c j-c-c b-r-20% b-s-s 
          ${props.checked ? "b-w-1px" : "b-w-0px"}
          ${props.size === "large" ? "h-2 w-2" : "w-1.5 h-1.5"}
          ${color ? "" : props.checked? "b-c-g bg-c-g" : "b-c-g"}
          ${props.round ? "b-r-50%" : ""}
        `}
        style={style} >

        { props.checked ?
          <CheckMark />
        :
          (!props.disabled && !props.background) || props.disabled ?
            backgroundImage
          :
            undefined
        }
      </div>
      {props.label ?
        <div className="m-l-0.5">
          {props.label}
        </div>
      : null
      }
    </div>
  );
}

import React, { useEffect, useCallback, useState, useMemo } from 'react';
import axios from 'axios';
import { GroupNameWithColor } from "../ui/groupNameWithColor";
import { Monitor, AverageRt, MonitorRegionEventStatusByDate, MonitorEventWrap, MonitorRegionEvent } from '../model';
import { Month, Status } from '../ui';
import { Loading } from '../ui/loading/Loading';
import { useParams } from 'react-router-dom';
import { apiBaseUrl, MonitorStatusType } from "../Consts";
import { MonitorEventShow } from "../monitor-show/MonitorEventShow";
import { ResponseTime } from './ResponseTime';
import { ResponseTimeHour } from '../ui/response-time-hour/ResponseTimeHour';
import { ResponseTimeLegend } from '../ui/legend/ResponseTimeLegend';
import { Day, MonthProps } from '../ui/month/Month';
import { Breadcrumbs } from '../ui/breadcrumbs/Breadcrumbs'
import { calculateApdex, dasherizedToCamelCase } from '../Helper';
import { Flag } from '../images';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { regionsState, teamState, userTimeZoneState } from '../atoms';
import { Apdex } from '../ui/apdex/apdex';
import { useHandleRequestError } from '../hooks';
import { DateTime } from 'luxon';
import { MonitorUrl } from '../monitor-url';

export const MonitorShowRegionDay: React.FC = () => {

  const { t } = useTranslation();
  const userTimeZone = useRecoilValue(userTimeZoneState);
  const regions = useRecoilValue(regionsState);
  const team = useRecoilValue(teamState);
  const { handleRequestError } = useHandleRequestError();

  const { monitorUuid, day, regionSlug } = useParams<any>();
  const [ date, setDate ] = useState<DateTime>();
  const [ now, setNow ] = useState<DateTime>();

  const [ monitor, setMonitor ] = useState<Monitor>();
  const [ events, setEvents ] = useState<MonitorRegionEvent[]>();
  const [ eventWraps, setEventWraps ] = useState<MonitorEventWrap[]>();
  const [ monitorRegionAverageRt, setMonitorRegionAverageRt ] = useState<AverageRt[]>();
  const [ monitorRegionEventsStatusByDate, setMonitorRegionEventsStatusByDate ] = useState<MonitorRegionEventStatusByDate[]>();
  const [ apdexIndex, setApdexIndex ] = useState<number>()
  const [ status, setStatus ] = useState<string[]>();

  const [ months, setMonths ] = useState<MonthProps[]>([]);

  const region = useMemo(() => {
    const regionSystemName = dasherizedToCamelCase(regionSlug);
    return regions?.find(r => r.systemName === regionSystemName);
  }, [regionSlug, regions]);


  const fetch = useCallback(() => {
    if (team && day && region) {
      axios.get(`${apiBaseUrl}/${team.uuid}/monitors/${monitorUuid}/${region.id}/history/${day}`)
        .then(response => {
          setMonitor(response.data.monitor);
          setMonitorRegionAverageRt(response.data.averageRt);
          setMonitorRegionEventsStatusByDate(response.data.eventsStatusByDate);
          setApdexIndex(calculateApdex(response.data.averageRt));
          setEvents(response.data.events);
        })
        .catch(error => handleRequestError(error));
    }
  }, [team, monitorUuid, day, region, handleRequestError])

  useEffect(() => {
    if (events && events.length > 0 && monitor && date) {
      setEventWraps(MonitorEventWrap.wrapMonitorEvents(events));
      const _events = [...events].reverse();
      const status = [_events[0].status];
      
      for (let i = 1; i < events.length; i++) {
        const createdAt = DateTime.fromISO(_events[i].createdAt).startOf("day");

        if (createdAt < date) {
          status[0] = _events[i].status;
        } else if (createdAt.hasSame(date, "day")) {
          const existingIndex = status.findIndex(s => s === _events[i].status);
          if (existingIndex >= 0) {
            status.splice(existingIndex, 1);
          }
          status.push(_events[i].status);
        } else {
          break;
        }
      }
      setStatus(status);
    }
  }, [events, monitor, date])

  useEffect(() =>  {
    fetch()
  }, [team, monitorUuid, day, region, fetch]);

  useEffect(() => {
    if (day && now) {
      const date = (day === "today") ? now.startOf('day') : DateTime.fromISO(day);
      setDate(date);
    }
  }, [day, now])

  useEffect(() => {
    if (userTimeZone) setNow(DateTime.now());
  }, [userTimeZone])

  useEffect(() => {
    if (date && now && monitorRegionEventsStatusByDate && monitorRegionEventsStatusByDate.length > 0) {
      const numberOfDays = Math.floor(Math.abs(now.startOf("day").diff(DateTime.fromISO(monitorRegionEventsStatusByDate[0].date)).as("days"))) + 1;
      const months: MonthProps[] = [];

      let indexEvent = 0;
      let previousDate: DateTime | undefined = undefined;
      let lastStatus: MonitorStatusType | undefined = undefined;

      [...Array(numberOfDays)].forEach((_, i) => {
        const _date = DateTime.fromISO(monitorRegionEventsStatusByDate[0].date).startOf("day").plus({days: i});

        if (!previousDate || previousDate.startOf("month") < _date.startOf("month")) {
          months.push({
            monitor: monitor,
            regionSlug: regionSlug,
            year: _date.year,
            month: _date.month,
            days: []
          } as MonthProps)
        }

        let day: Day | undefined = undefined;

        const eventDate = monitorRegionEventsStatusByDate.length - 1 >= indexEvent ?
          DateTime.fromISO(monitorRegionEventsStatusByDate[indexEvent].date).startOf("day")
          : undefined;

        if (eventDate && _date.hasSame(eventDate, "day")) {
          let statusArray: MonitorStatusType[] = [];
          do {
            statusArray = statusArray.concat(monitorRegionEventsStatusByDate[indexEvent].status as MonitorStatusType[]);
            indexEvent++;
          } while (
            indexEvent < monitorRegionEventsStatusByDate.length &&
            _date.hasSame(DateTime.fromISO(monitorRegionEventsStatusByDate[indexEvent].date), "day")
          );
          day = new Day(_date.hasSame(date, "day"), _date, statusArray.filter((v,i) => statusArray.indexOf(v) === i));
          lastStatus = statusArray[statusArray.length - 1];
        } else {
          day = new Day(_date.hasSame(date, "day"), _date, [lastStatus as MonitorStatusType]);
        }
        months[months.length - 1].days.push(day);

        previousDate = _date;
      })

      setMonths(months);
    }
  }, [now, monitorRegionEventsStatusByDate, monitor, date, regionSlug]);

  if (team && monitor && events && date && months && region) {
    const _date = day === "today" ? "Today" : date.toFormat("MMM d yyyy");

    return (
      <div className="d-f fb-d-c m-h-a p-h-0.25 p-h-2-M w-100% max-w-66-L t-c-nb m-b-4">
        <div className="d-f">
          <div className="p-l-0.5 m-t-1.5">
            <Breadcrumbs
              items={[
                { title: t("Monitors"), link: `/${team.slug}/monitors`},
                { title: monitor.name, link: `/${team.slug}/monitors/${monitor.uuid}`},
                { title: _date, link: `/${team.slug}/monitors/${monitor.uuid}/${day}`},
                { title: region.name },
              ]}
            />
          </div>
        </div>
        <div className="d-f fb-d-c fb-d-r-M fb-a j-c-c p-l-0.5 m-t-1 m-t-1.5-M">
          <div className="fb-a p-r-1.5">
            <div className="d-f a-i-c">
              <div className="fb-a f-w-300">
                <div className="fb-n f-s-1.5 f-w-300">
                  { monitor.name} - {_date}
                </div>
                <div className="d-f p-t-0.5 m-r-1">
                  <MonitorUrl monitor={monitor} />
                </div>
              </div>
                <div className="fb-n d-f fb-d-c a-i-c">
                  <div className="d-n d-f-M">
                    <Flag country={region.country.code} size="big" />
                  </div>
                  <div className="d-f d-n-M">
                    <Flag country={region.country.code} size="small" />
                  </div>
                  <div className="f-s-0.75 m-t-0.25">
                    {region.name}
                  </div>
                </div>
            </div>
            {monitor.groups ?
              <div className="fb-w-w m-t-0.5 d-f">
                {monitor.groups.map((g, i) =>
                  <div key={i} className="max-w-10">
                    <GroupNameWithColor group={g} textSize={"small"} margin={true} link={`/${team.slug}/monitors?groupUuid=${g.uuid}`} />
                  </div>
                )}
              </div>
            : null}
            {status && monitorRegionAverageRt ?
              <>
                <div className="">
                  <div className="d-f m-t-4 j-c-c">
                    {status.map((s, i ) =>
                      <div key={i} style={{marginLeft: "-0.5rem"}}>
                        <Status animate={false} status={s as MonitorStatusType} size={48} />
                      </div>
                    )}
                  </div>
                  {apdexIndex ?
                    <div className="d-f j-c-c p-t-2">
                      <Apdex apdexIndex={apdexIndex}></Apdex>
                    </div>
                  : null}
                  <div className="m-t-0.5">
                    <ResponseTime
                      monitor={monitor}
                      monitorRegionAverageRt={monitorRegionAverageRt} />
                  </div>
                  <div className="d-f m-t-1.5 a-i-c">
                    <ResponseTimeLegend monitorType={monitor.type} />
                  </div>
                  <div className="m-t-4">
                    <ResponseTimeHour averageRt={monitorRegionAverageRt} events={events} date={date} />
                  </div>
                </div>
                <div className="m-t-4">
                  <div className="m-b-1">
                    {t("Events")}
                  </div>
                  <div>
                    {eventWraps?.map((eventWrap, i) =>
                      DateTime.fromISO(eventWrap.event.createdAt).hasSame(date, "day") ?
                        <MonitorEventShow key={i} eventWrap={eventWrap} />
                      :
                      null
                    )}
                  </div>
                </div>
              </>
            :
              <div className="m-t-1 f-s-1.5">
                {t("No events this day")}
              </div>
            }
          </div>
          <div className="d-f fb-n fb-d-c a-i-e-M b-sl-s-M b-w-1px-M b-c-lightestGray-M w-100% w-11.5-M f-s-1">
            <div className="d-f fb-w-w fb-nw-M fb-d-r fb-d-c-M a-i-e">
              {months.map((m, i) =>
                <Month key={i} {...m} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Loading />
  }
}
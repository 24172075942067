import React, { useState, useCallback, useEffect } from 'react';
import { CreateMonitorReq, Monitor } from '../model';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs, Loading } from '../ui';
import { apiBaseUrl } from "../Consts";
import { MonitorForm } from '../monitor-form';
import { Errors } from '../validator/Validator';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { regionsState, monitorTypesState, productsState, teamState, messageState } from '../atoms';
import { useHandleRequestError } from '../hooks';
import { isMonitorIntervalFree } from '../Helper';

type MonitorNewProps = {
  onCancel?: () => void
  onSave?: (monitor: Monitor) => void
  disallowSetGroups?: boolean
  noRedirectToCheckout?: boolean
}

export const MonitorNew: React.FC<MonitorNewProps> = (props) => {

  const { t } = useTranslation();
  const history = useHistory();
  const team = useRecoilValue(teamState);
  const { handleRequestErrorAvoidingPopup } = useHandleRequestError();
  const setMessage = useSetRecoilState(messageState);

  const regions = useRecoilValue(regionsState);
  const products = useRecoilValue(productsState);
  const monitorTypes = useRecoilValue(monitorTypesState);
  const [ monitor, setMonitor ] = useState<Monitor>();
  const [ errorsOnServer, setErrorsOnServer ] = useState<Errors<Monitor>>();
  const [ isSaving, setIsSaving ] = useState(false);

  const save = useCallback((monitor: Monitor) => {
    if (team && products) {
      const req = new CreateMonitorReq();
      req.groups = (monitor.groups || []).map(g => g.uuid);
      req.alerts = (monitor.alerts || []).map(a => a.uuid);
      req.regions = monitor.regions.map(r => r.id);
      req.type = monitor.type;
      req.urn = monitor.urn;
      req.intervalId = monitor.intervalId;
      req.name = monitor.name;
      req.title = monitor.title;
      req.description = monitor.description;
      req.noRedirectToCheckout = props.noRedirectToCheckout ?? false;
      req.degradedThreshold = typeof monitor.degradedThreshold === "string" ? 0 : monitor.degradedThreshold;
      req.apdexThreshold = typeof monitor.apdexThreshold === "string" ? 0 : monitor.apdexThreshold;
      req.timeout = monitor.timeout;
      req.httpFollowRedirects = monitor.httpFollowRedirects;
      req.httpRequest = monitor.httpRequest;
      req.httpResponse = monitor.httpResponse;
      req.tcpOptions = monitor.tcpOptions;

      if ((req.type !== "http" && req.type !== "https") || isMonitorIntervalFree(products, monitor.intervalId)) {
        req.httpRequest = undefined;
        req.httpResponse = undefined;
      }

      if (req.type !== "tcp" || isMonitorIntervalFree(products, monitor.intervalId)) {
        req.tcpOptions = undefined;
      }

      if (req.httpRequest && req.httpRequest.body?.length > 0 && req.httpRequest.method === "HEAD") {
        req.httpRequest.body = "";
      }
      if (req.httpResponse) {
        if (req.httpResponse.statusMatches === undefined || req.httpResponse.statusMatches === null) {
          req.httpResponse.statusMatches = false;
        }
        if (req.httpResponse.keywordBodyExists === undefined || req.httpResponse.keywordBodyExists === null) {
          req.httpResponse.keywordBodyExists = false;
        }
        if (req.httpResponse.keywordHeaderExists === undefined || req.httpResponse.keywordHeaderExists === null) {
          req.httpResponse.keywordHeaderExists = false;
        }
      }
      if (req.tcpOptions) {
        if (req.tcpOptions.keywordResponseExists === undefined || req.tcpOptions.keywordResponseExists === null) {
          req.tcpOptions.keywordResponseExists = false;
        }
      }

      setIsSaving(true);
      axios.post(`${apiBaseUrl}/${team.uuid}/monitors`, req)
        .then((response) => {
          if (response.data.checkoutUrl) {
            window.location.href = response.data.checkoutUrl;
            return
          } else if (props.onSave) {
            props.onSave(response.data.monitor as Monitor);
          } else {
            history.push(`/${team.slug}/monitors/${response.data.monitor.uuid}`);
          }
          if (response.data.checkoutError) {
            setMessage({
              type: "error",
              text: response.data.checkoutError
            })
          }
        })
        .catch(err => {
          handleRequestErrorAvoidingPopup(err);
          setErrorsOnServer(err.response.data)
        })
        .finally(() => setIsSaving(false));
    }
  }, [team, products, props, history, handleRequestErrorAvoidingPopup]);

  const cancel = useCallback(() => {
    if (props.onCancel) {
      props.onCancel();
    } else if (history.length > 2) {
      history.goBack();
    } else {
      history.push(`/${team?.slug}/monitors`)
    }
  }, [history, props, team]);

  const componentForm = useCallback(() =>
    monitor ?
      <MonitorForm
        onSave={save}
        isSaving={isSaving}
        onCancel={cancel}
        monitor={monitor}
        disallowSetGroups={props.disallowSetGroups}
        noRedirectToCheckout={props.noRedirectToCheckout}
        errorsOnServer={errorsOnServer} />
    : null
  , [cancel, errorsOnServer, isSaving, monitor, props.disallowSetGroups, save])

  useEffect(() => {
    if (team && regions && products && monitorTypes) {
      const monitor = new Monitor();
      monitor.intervalId = products[0].monitorInterval.id;
      monitor.type = monitorTypes[1];
      monitor.regions = regions;
      monitor.httpFollowRedirects = true;
      monitor.timeout = 10;
      setMonitor(monitor);
    }
  }, [regions, products, team, monitorTypes]);


  if (!monitorTypes || !monitor || !team) {
    return <Loading />
  }
  return (
    !props.onSave ? // without Modal
      <div className="d-f fb-d-c m-h-a w-100% max-w-44.5-M max-w-49-L p-h-1 p-h-2-M t-c-nb">
        <div className="m-t-1.5">
          <Breadcrumbs
            items={[
              { title: t("Monitors"), link: `/${team.slug}/monitors`},
              { title: t("New") },
            ]}
          />
        </div>
        <div className="f-w-300 f-s-1.5 f-s-2-M m-v-1 m-v-1.5-M">{t("New Monitor")}</div>
        {componentForm()}
      </div>
    : // With Modal
      componentForm()
  );
}

import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { messageState } from "../atoms";
import { cancelTokenMessage } from "../Consts";
import { Message } from "../model";

export const useHandleRequestError = () => {

  const { t } = useTranslation();
  const setMessage = useSetRecoilState(messageState);

  const handleError = useCallback((error: any, return422Error: boolean = false, avoidPopup = false): string | undefined => {
    const message: Message = {
      type: "error",
      text: ""
    }

    if (error.response) {
      switch (error.response.status as number) {
        case 422:
          if (typeof error.response.data === "object") {
            if (error.response.data._base) {
              message.text = error.response.data._base;
            } else if (!avoidPopup || Object.keys(error.response.data).length === 0) {
              message.text = t("Invalid request");
            } else { // If avoidPopup is true, then validation errors on fields should not display a popup
              return;
            }
          } else {
            message.text = error.response.data;
          }
          if (return422Error) {
            return message.text as string | undefined;
          }
          break;
        case 401:
          if (![ "confirm-account",
            "forgot-password",
            "invitation",
            "reset-password",
            "sign-up",
            "sign-in",
            "login"].includes(window.document.location.pathname.split("?")[0].split("/")[1])) {
            window.document.location.href = "/sign-in";
          }
          return
        default:
          message.text = error.response.data;
      }
    } else if (error.request) {
      message.text = t("No internet connection");
    } else if (error.message && error.message === cancelTokenMessage) {
      return // Ignore
    } else {
      message.text = t("Unexpected error. Try refreshing your browser");
    }

    setMessage(message);
  }, [setMessage, t]);

  const handle422Error = useCallback((error: any): string | undefined => {
    return handleError(error, true);
  }, [handleError]);

  const handle422ErrorAvoidingPopup = useCallback((error: any): string | undefined => {
    return handleError(error, true, true);
  }, [handleError]);

  const handleRequestError = useCallback((error: any) => {
    return handleError(error);
  }, [handleError]);

  const handleRequestErrorAvoidingPopup = useCallback((error: any) => {
    return handleError(error, false, true);
  }, [handleError]);

  return {
    handleRequestError,
    handle422Error,
    handleRequestErrorAvoidingPopup,
    handle422ErrorAvoidingPopup
  };
}
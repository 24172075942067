import React, { useEffect, useState } from "react";
import { Flag } from "../images";
import { Monitor, MonitorRegion, Region } from "../model";
import { formatResponseTime } from "../Helper";
import { useRecoilValue } from "recoil";
import { regionsState, teamState } from "../atoms";
import { responseCodes } from "../Consts";

type RegionsProps = {
  monitor: Monitor,
  monitorRegions: MonitorRegion[]
}

export const Regions: React.FC<RegionsProps> = (props) => {

  const regions = useRecoilValue(regionsState);
  const team = useRecoilValue(teamState);

  const [ slower, setSlower ] = useState(0);

  useEffect(() => {
    let slower = 0;
    for (const mr of props.monitorRegions) {
      // mr.totalRt is set when a ServerEvent is received. See MonitorShow
      const responseTime = mr.totalRt ?? mr.rt.reduce((t, v) => t + v);
      if (responseTime > slower) {
        slower = responseTime;
      }
    }
    setSlower(slower);
  }, [props.monitorRegions]);

  if (!team || !regions) {
    return null;
  } else {
    return (
      <div className="d-f fb-a fb-d-c">
        <div className="">
          {props.monitorRegions.map((mr, i) => {
            const region = regions.find(r => r.id === mr.regionId) as Region;

            // mr.totalRt is set when a ServerEvent is received. See MonitorShow
            const rt = mr.totalRt ?? mr.rt.reduce((t,v) => t + v);

            let barSize = rt / slower;
            if (barSize < 0.01 || isNaN(barSize)) {
              barSize = 0.01;
            }
            return  (
              <div 
                key={i}
                className="d-f fb-a h-1 f-s-0.75 t-c-gray m-b-1 pos-re">
                <div className="w-8 d-f a-i-c">
                  <div className="fb-a t-a-r p-r-0.5">
                    { region.name }
                  </div>
                  <div className="fb-n d-f p-r-0.5 a-i-c">
                    <Flag country={ region.country.code } size={ "mini" } />
                  </div>
                </div>
                {/* Bar max size 18.4 rem */}
                <div className="d-f fb-a">
                  {(mr.responseCode >= 400 && mr.responseCode <= 599) || (mr.responseCode >= 1400 && mr.responseCode <= 1599) ?
                    <div className="t-c-r">
                      { responseCodes[mr.responseCode.toString()] } { mr.responseCode >= 400 && mr.responseCode <= 599 ? `(${mr.responseCode})` : "" }
                    </div>
                  :
                    <>
                      <div className="bg-c-lightestGray h-100%" style={{width: `calc((100% - 4rem) * ${barSize})`}}></div>
                      <div className="w-4 fb-n t-c-nb m-l-0.5">
                        { formatResponseTime(rt) }
                      </div>
                    </>
                  }
                </div>

              </div>
            )}
          )}
        </div>
      </div>
    );
  }
}
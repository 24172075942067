import React, { useCallback, useEffect, useState } from 'react';
import logo from '../logo.png';
import { BaseInputOnChangeEvent, Button, Spinner, TextInput } from '../ui';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { apiBaseUrl } from '../Consts';
import { useHandleRequestError } from '../hooks';
import { ResetPasswordRequest } from '../model';
import { useValidator, Validations } from '../validator';
import { isFieldStringEmpty } from '../Validations';

const v = new Validations(new ResetPasswordRequest());
v.addField("password", (v: any) => isFieldStringEmpty(v));

export const ResetPassword: React.FC = () => {

  const { email, resetPasswordCode } = useParams<any>();
  const { t } = useTranslation();

  const { handleRequestErrorAvoidingPopup } = useHandleRequestError();
  const [ resetPassword, setResetPassword ] = useState(() => {
    const rpr = new ResetPasswordRequest();
    rpr.email = email;
    rpr.resetPasswordCode = resetPasswordCode;
    return rpr;
  });
  const { errors, validateField, validateAll, resetErrorAllFromServer } = useValidator(v);
  const [ isSaving, setIsSaving ] = useState(false);
  const [ isSent, setIsSent ] = useState(false);

  const onChangeField = useCallback(
    (fieldName: keyof ResetPasswordRequest, event: BaseInputOnChangeEvent) => {
      const value = event.value;
      if (event.dirtyAndTouched) {
        validateField(fieldName, event.value);
      }
      const _resetPassword = { ...resetPassword } as ResetPasswordRequest;
      _resetPassword[fieldName] = value as never;

      setResetPassword(_resetPassword);

    }, [resetPassword, validateField])

  const onBlurField = useCallback(
    (fieldName: keyof ResetPasswordRequest, event: BaseInputOnChangeEvent) => {
      if (event.dirty) {
        validateField(fieldName, event.value);
      }
    }, [validateField])

  const save = useCallback(() => {
    if (validateAll(resetPassword)) {
      setIsSaving(true);
      axios.put(`${apiBaseUrl}/reset-password`, resetPassword)
        .then(() => setIsSent(true))
        .catch(err => {
          handleRequestErrorAvoidingPopup(err);
          resetErrorAllFromServer(err.response.data);
        })
        .finally(() => setIsSaving(false));
    }
  }, [validateAll, resetPassword, handleRequestErrorAvoidingPopup, resetErrorAllFromServer]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (isSent) {
      timeout = setTimeout(() => window.location.href = "/sign-in", 5000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, [isSent]);

  return (
    <div className="d-f fb-d-c a-i-c bg-c-nw h-100vh">
      <div className="m-v-2">
        <img className="h-3" src={logo} alt="Statsignal" />
      </div>
      <div className="d-f fb-d-c a-i-c bg-c-w w-100% max-w-24 p-2">
        <div className="f-s-1.5 f-w-600 m-t-1">{t("Reset Password")}</div>
        {!isSent ?
          <form
            className="w-100% d-f fb-d-c a-i-c"
            onSubmit={(e) => { e.preventDefault(); save(); }}>
            <div className="m-t-1 f-w-300 t-a-c">
              {t("Enter a new password for {{email}}", {email: email})}
            </div>
            <div className="w-100% m-t-2">
              <TextInput
                autoFocus={true}
                value={resetPassword.password}
                placeholder={t("Password")}
                onChange={e => onChangeField("password", e)}
                onBlur={e => onBlurField("password", e)}
                error={errors.fields.password}
                type="password"
                material={true}
                maxLength={64}
                color="black" />
            </div>
            <div className="w-100% m-t-2">
              <TextInput
                value={resetPassword.passwordConfirmation}
                placeholder={t("Password confirmation")}
                onChange={e => onChangeField("passwordConfirmation", e)}
                onBlur={e => onBlurField("passwordConfirmation", e)}
                error={errors.fields.passwordConfirmation}
                type="password"
                material={true}
                maxLength={64}
                color="black" />
            </div>
            <div className="w-100% m-t-2 d-f j-c-c">
              {isSaving ?
                <Spinner type={"primary"} />
              :
                <Button type="primary" submit={true} fullWidth="always">
                  {t("Reset Password")}
                </Button>
              }
            </div>
            <Link to="/" className="t-c-g f-s-1.25 c-p m-t-1">
              {t("Cancel")}
            </Link>
          </form>
        :
          <div className="m-v-2">
            <div className="t-a-c">
              {t("The password has been reset.")}
            </div>
            <div className="m-t-1 t-a-c f-w-300">
              <Trans>You are being redirected... If you are not redirected <a href="/sign-in" className="t-c-g">click here</a> to continue</Trans>
            </div>
          </div>
        }
      </div>
    </div>
  )
}
import React from "react";

export const AvatarDegraded = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="203"
      height="160"
      fill="none"
      viewBox="0 0 203 160"
    >
      <g clipPath="url(#clip0)">
        <path
          fill="url(#paint0_linear)"
          d="M82.006 0H12.193a.966.966 0 00-.966.966v106.519c0 .533.433.966.966.966h69.813a.966.966 0 00.966-.966V.965A.966.966 0 0082.006 0z"
          opacity="0.5"
        ></path>
        <path
          fill="url(#paint1_linear)"
          d="M125.575 34.919H56.837a.966.966 0 00-.966.965V97.7c0 .533.433.965.966.965h68.738a.965.965 0 00.966-.965V35.884a.965.965 0 00-.966-.965z"
          opacity="0.5"
        ></path>
        <path
          fill="#F2F2F2"
          d="M81.086.796H13.112a.966.966 0 00-.966.966v104.326c0 .533.432.966.965.966h67.975a.966.966 0 00.966-.966V1.762a.966.966 0 00-.966-.966z"
        ></path>
        <path
          fill="#fff"
          d="M124.603 35.558H57.929a.966.966 0 00-.965.966v59.813c0 .533.432.966.965.966h66.674a.966.966 0 00.966-.966V36.524a.966.966 0 00-.966-.966z"
        ></path>
        <path
          fill="#B0BF53"
          d="M77.474 48.824H64.665a.966.966 0 00-.966.966v11.334c0 .534.433.966.966.966h12.81a.966.966 0 00.965-.966V49.79a.966.966 0 00-.966-.966z"
          opacity="0.5"
        ></path>
        <path
          fill="#B0BF53"
          d="M100.951 55.152c-67.438-.393-98.805 59.204-98.805 59.204l63.91 33.715s7.841-24.309 35.68-23.526c1.961.053 3.917.226 5.856.519l20.09-65.3c-9.065-2.458-17.339-4.571-26.731-4.612z"
        ></path>
        <path
          fill="#B0BF53"
          d="M100.951 55.152c-67.438-.393-98.805 59.204-98.805 59.204l63.91 33.715s7.841-24.309 35.68-23.526c1.961.053 3.917.226 5.856.519l20.09-65.3c-9.065-2.458-17.339-4.571-26.731-4.612z"
        ></path>
        <path
          fill="#B0BF53"
          d="M32.153 20.371h-12.05a.966.966 0 00-.966.966v10.652c0 .534.433.966.966.966h12.05a.966.966 0 00.965-.966V21.337a.966.966 0 00-.965-.966z"
          opacity="0.5"
        ></path>
        <path
          fill="#E0E0E0"
          d="M74.457 20.371H37.92a.605.605 0 00-.606.605v.19c0 .333.271.604.605.604h36.54c.333 0 .604-.27.604-.605v-.189a.605.605 0 00-.605-.605zM68.088 24.564h-30.17a.605.605 0 00-.605.606v.188c0 .335.271.606.605.606h30.17a.605.605 0 00.605-.606v-.188a.605.605 0 00-.605-.606z"
        ></path>
        <path
          fill="#B0BF53"
          d="M32.153 39.944h-12.05a.966.966 0 00-.966.966v10.652c0 .534.433.966.966.966h12.05a.966.966 0 00.965-.966V40.91a.966.966 0 00-.965-.966z"
          opacity="0.5"
        ></path>
        <path
          fill="#E0E0E0"
          d="M74.457 39.944H37.92a.605.605 0 00-.606.605v.19c0 .333.271.604.605.604h36.54c.333 0 .604-.27.604-.605v-.189a.605.605 0 00-.605-.605zM74.457 44.137H37.92a.605.605 0 00-.606.605v.19c0 .334.271.604.605.604h36.54c.333 0 .604-.27.604-.605v-.188a.605.605 0 00-.605-.606z"
        ></path>
        <path
          fill="#B0BF53"
          d="M32.153 59.517h-12.05a.966.966 0 00-.966.966v10.652c0 .534.433.966.966.966h12.05a.966.966 0 00.965-.966V60.483a.966.966 0 00-.965-.966z"
          opacity="0.5"
        ></path>
        <path
          fill="#E0E0E0"
          d="M74.457 59.517H37.92a.605.605 0 00-.606.605v.19c0 .333.271.604.605.604h36.54a.605.605 0 00.604-.605v-.189a.605.605 0 00-.605-.605zM40.904 63.712h-2.986a.605.605 0 00-.605.606v.188c0 .335.271.606.605.606h2.986a.605.605 0 00.605-.606v-.188a.605.605 0 00-.605-.606zM122.021 48.824H83.502a.64.64 0 00-.64.64v.195c0 .353.287.64.64.64h38.519a.64.64 0 00.64-.64v-.195a.64.64 0 00-.64-.64zM122.021 53.247H83.502a.64.64 0 00-.64.64v.195c0 .353.287.639.64.639h38.519a.64.64 0 00.64-.64v-.195a.64.64 0 00-.64-.64zM122.021 69.296H62.865a.64.64 0 00-.64.64v.195c0 .353.287.64.64.64h59.156a.64.64 0 00.64-.64v-.195a.64.64 0 00-.64-.64zM122.021 73.719H62.865a.64.64 0 00-.64.64v.195c0 .353.287.64.64.64h59.156a.64.64 0 00.64-.64v-.196a.64.64 0 00-.64-.64zM122.021 79.614H62.865a.64.64 0 00-.64.64v.194c0 .354.287.64.64.64h59.156a.64.64 0 00.64-.64v-.195a.64.64 0 00-.64-.64zM107.281 84.036H62.865a.64.64 0 00-.64.64v.195c0 .353.287.64.64.64h44.416a.64.64 0 00.64-.64v-.195a.64.64 0 00-.64-.64z"
        ></path>
        <path
          fill="#E0E0E0"
          d="M106.867 125.536c-1.94-.292-5.228-.59-7.189-.643-7-.197-10.542.845-15.207 3.036L44.1 70.923c14.706-8.903 34.286-15.903 56.988-15.77 9.393.04 18.389 1.939 27.454 4.396l-21.674 65.987z"
        ></path>
        <path
          fill="#E0E0E0"
          d="M127.897 59.395l-21.163 66.154c23.094 3.509 29.822 23 29.822 23l64.302-32.146s-24.16-43.755-72.961-57.008z"
        ></path>
        <path
          fill="url(#paint2_linear)"
          d="M114.431 55.517l-.562-.105 1.11-4.077c1.746-13.09-4.852-14.419-7.327-14.464a8.046 8.046 0 00-.869-.264v-1.545a8.202 8.202 0 006.127-6.622h.021c.393 0 .713-.599.713-1.337a1.86 1.86 0 00-.311-1.103 8.534 8.534 0 00-2.603-7.037 5.53 5.53 0 00-.605-1.626c-.608-1.073-1.618-2.185-1.206-3.346l-2.668.275-.026-1.758c-.832.15-1.182 1.114-1.676 1.801-1.188 1.652-3.572 1.882-5.15 3.17-.536.429-1.742 1.768-1.513 2.641a8.51 8.51 0 00-1.583 5.873 1.855 1.855 0 00-.311 1.104c0 .738.32 1.336.712 1.336h.021a8.201 8.201 0 006.127 6.623V36.6c-.294.072-.584.16-.869.264-2.474.045-9.073 1.365-7.326 14.463l1.11 4.078-.265.049a3.04 3.04 0 00-2.431 3.547l4.384 23.431a.92.92 0 001.073.736l.77-.143v.455a2.559 2.559 0 00-1.424 2.291v.29h5.167v-.29a2.552 2.552 0 00-1.247-2.195v-1.02l.704-.13a.92.92 0 00.736-1.073l-4.107-22.013h11.388l-4.103 22.015a.923.923 0 00.736 1.073l.704.131v1.02a2.553 2.553 0 00-1.247 2.195v.29h5.168v-.29a2.563 2.563 0 00-1.425-2.292v-.455l.77.144a.92.92 0 001.073-.736l4.429-23.726a2.743 2.743 0 00-.432-2.05 2.74 2.74 0 00-1.757-1.143z"
          opacity="0.7"
        ></path>
        <path
          fill="#FDB797"
          d="M101.91 80.043h-2.4V83.3h2.4v-3.257zM110.137 80.043h-2.399V83.3h2.399v-3.257z"
        ></path>
        <path
          fill="#4D8AF0"
          d="M112.71 53.305H96.94v5.656h15.77v-5.656z"
        ></path>
        <path
          fill="#000"
          d="M112.71 53.305H96.94v5.656h15.77v-5.656z"
          opacity="0.05"
        ></path>
        <path
          fill="#000"
          d="M96.94 54.333s5.884 1.37 7.884 1.37 7.884-1.37 7.884-1.37v-.859H96.94v.859z"
          opacity="0.1"
        ></path>
        <path
          fill="#F55F44"
          d="M112.708 54.161s-5.884 1.371-7.884 1.371-7.884-1.371-7.884-1.371v-9.427a7.884 7.884 0 1115.768 0v9.427z"
        ></path>
        <path
          fill="#FDB797"
          d="M102.94 32.906h3.77v4.536a1.634 1.634 0 01-1.633 1.633h-.502a1.635 1.635 0 01-1.633-1.633v-4.536h-.002z"
        ></path>
        <path
          fill="#4D8AF0"
          d="M102.361 81.215l-3.38.63a1.11 1.11 0 01-1.288-.886l-4.03-21.596a3.661 3.661 0 012.927-4.273l.871-.163a1.11 1.11 0 011.288.886l4.506 24.105a1.098 1.098 0 01-.177.835 1.11 1.11 0 01-.717.462zM107.279 81.215l3.38.63a1.108 1.108 0 001.287-.886l4.099-21.957a3.297 3.297 0 00-2.635-3.843l-1.232-.23a1.108 1.108 0 00-1.287.886l-4.507 24.106a1.103 1.103 0 00.491 1.135c.123.079.26.133.404.159z"
        ></path>
        <path
          fill="#000"
          d="M102.94 35.764a7.889 7.889 0 003.77 0v-2.687h-3.77v2.687z"
          opacity="0.1"
        ></path>
        <path
          fill="#333"
          d="M104.824 34.277a8.227 8.227 0 008.227-8.228 8.227 8.227 0 10-16.455 0 8.228 8.228 0 008.228 8.228z"
        ></path>
        <path
          fill="#FDB797"
          d="M104.824 35.82a7.884 7.884 0 100-15.769 7.884 7.884 0 000 15.769z"
        ></path>
        <path
          fill="#333"
          d="M99.618 18.682c1.517-1.238 3.811-1.459 4.953-3.049.474-.66.811-1.59 1.611-1.734l.024 1.691 2.575-.264c-.397 1.116.575 2.189 1.161 3.219a5.29 5.29 0 01.685 2.491c-.891.535-1.996-.049-2.988-.36-.991-.311-1.989-.335-3.004-.363a6.23 6.23 0 00-1.549.086 7.83 7.83 0 00-1.288.47c-.682.285-2.839 1.365-3.472.67-.712-.788.706-2.378 1.292-2.857z"
        ></path>
        <path
          fill="#000"
          d="M100.047 23.98c.431.237.906.383 1.395.43 2.02.276 4.077.045 5.985-.672.313.792 1.073 1.337 1.886 1.595.814.257 1.676.27 2.528.283a.936.936 0 00.543-.103c.38-.256.195-.844-.026-1.243l-1.098-1.991c-.324-.588-.659-1.189-1.161-1.633-.631-.558-1.468-.82-2.292-.985a12.926 12.926 0 00-5.884.195c-2.054.543-4.691 2.42-1.876 4.125z"
          opacity="0.1"
        ></path>
        <path
          fill="#333"
          d="M100.047 23.81c.431.235.906.382 1.395.428 2.02.277 4.077.046 5.985-.671.313.791 1.073 1.337 1.886 1.594.814.258 1.676.27 2.528.283a.936.936 0 00.543-.103c.38-.255.195-.843-.026-1.242l-1.098-1.992c-.324-.588-.659-1.188-1.161-1.633-.631-.558-1.468-.82-2.292-.985a12.927 12.927 0 00-5.884.196c-2.054.538-4.691 2.422-1.876 4.124z"
        ></path>
        <path
          fill="#FDB797"
          d="M112.625 29.221c.379 0 .686-.575.686-1.285s-.307-1.286-.686-1.286c-.38 0-.687.576-.687 1.286 0 .71.307 1.285.687 1.285zM97.026 29.221c.38 0 .687-.575.687-1.285s-.308-1.286-.687-1.286c-.38 0-.687.576-.687 1.286 0 .71.308 1.285.687 1.285z"
        ></path>
        <path
          fill="#F55F44"
          d="M102.97 37.373s-9.974-1.553-7.916 13.874l1.2 4.422 3.433-.137s-2.228-5.15-.858-8.57c1.369-3.421 4.141-9.589 4.141-9.589zM106.678 37.373s9.974-1.553 7.916 13.874l-1.199 4.422-3.434-.137s2.228-5.15.859-8.57c-1.369-3.421-4.142-9.589-4.142-9.589z"
        ></path>
        <path
          fill="#FDB797"
          d="M98.14 58.103a1.887 1.887 0 100-3.773 1.887 1.887 0 000 3.773zM111.852 58.103a1.886 1.886 0 100-3.772 1.886 1.886 0 000 3.772z"
        ></path>
        <path
          fill="#333"
          d="M100.294 81.93h.663a2.145 2.145 0 012.146 2.145v.588h-4.964v-.588a2.146 2.146 0 012.155-2.146zM108.693 81.93h.663a2.145 2.145 0 012.146 2.145v.588h-4.964v-.588a2.14 2.14 0 012.155-2.146z"
        ></path>
        <path
          fill="#000"
          d="M98.654 45.59s-.686 3.599.515 5.15l-.515-5.15zM110.996 45.59s.687 3.599-.515 5.15l.515-5.15z"
          opacity="0.1"
        ></path>
      </g>
      <path
        fill="#535461"
        d="M91.454 145.934L44 70.426l54.185 70.885a4.203 4.203 0 011.677 4.534 4.3 4.3 0 01-.762 1.54 4.414 4.414 0 01-4.681 1.532 4.296 4.296 0 01-1.524-.792l-.004-.011a4.179 4.179 0 01-1.313-1.816 2.245 2.245 0 01-.124-.364z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="47.099"
          x2="47.099"
          y1="108.451"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity="0.25"></stop>
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12"></stop>
          <stop offset="1" stopColor="gray" stopOpacity="0.1"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="30091.8"
          x2="30091.8"
          y1="29344.4"
          y2="10407.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity="0.25"></stop>
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12"></stop>
          <stop offset="1" stopColor="gray" stopOpacity="0.1"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="14697.6"
          x2="14697.6"
          y1="35306.1"
          y2="10093.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity="0.25"></stop>
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12"></stop>
          <stop offset="1" stopColor="gray" stopOpacity="0.1"></stop>
        </linearGradient>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H202.146V160H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
import React from 'react';
import { FlagUS } from './FlagUS';
import { FlagCA } from './FlagCA';
import { FlagGB } from './FlagGB';
import { FlagDE } from './FlagDE';
import { FlagIN } from './FlagIN';
import { FlagSG } from './FlagSG';
import { FlagAU } from './FlagAU';
import { FlagJP } from './FlagJP';

export type FlagProps = {
  country: string
  size: string
}

export const Flag: React.FC<FlagProps> = (props) => {

  switch (props.country) {
    case "US":
      return <FlagUS size={ props.size } />;
    case "CA":
      return <FlagCA size={ props.size } />;
    case "GB":
      return <FlagGB size={ props.size } />;
    case "DE":
      return <FlagDE size={ props.size } />;
    case "IN":
      return <FlagIN size={ props.size } />;
    case "SG":
      return <FlagSG size={ props.size } />;
    case "AU":
      return <FlagAU size={ props.size } />;
    case "JP":
      return <FlagJP size={ props.size } />;
    default:
      return <React.Fragment></React.Fragment>
  }
}
import React, { useState, useCallback, useMemo } from 'react';
import axios from 'axios';
import { Popover, AvatarNew } from '../ui';
import { Link, useHistory } from 'react-router-dom';
import { apiBaseUrl } from "../Consts";
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { teamsState, userState, invitationsState, teamState } from '../atoms';
import { useHandleRequestError } from '../hooks';

export const ProfileMenu = () => {

  const { t } = useTranslation();
  const invitations = useRecoilValue(invitationsState);
  const user = useRecoilValue(userState);
  const teams = useRecoilValue(teamsState);
  const { handleRequestError } = useHandleRequestError();
  const team = useRecoilValue(teamState);

  const [active, setActive] = useState(false);


  const toggleActive = useCallback(() => setActive(!active), [active]);

  const signOut = useCallback(() => {
    setActive(false);
    axios.delete(`${apiBaseUrl}/session`)
      .catch((error) => {
        handleRequestError(error);
      })
      .finally(() => {
        document.location.href = "/";
      })
  }, [handleRequestError])

  const history = useHistory();

  const isOneTeamPath = useMemo(() => {
    if (teams) {
      const pathParts = history.location.pathname.split('/');
      return pathParts.length > 1 && teams.length === 1 && pathParts[1] === teams[0].slug;
    }
    return false;
  }, [history.location.pathname, teams]);

  const activator = useMemo(() => {
    if (user) {
      const title = user.firstName || user.lastName ?
        `${user.firstName} ${user.lastName}` : user.email;

      return (
        <div title={((title === user.email) ? "" : title) + "\n" + user.email + `\n` + team?.name} className="d-f a-i-c c-p" onClick={toggleActive}>
          {team && teams && (teams.length > 1 || !team.isWorkspace) ?
            <AvatarNew title2={title} imageUrl2={user.avatarUrl} title={team?.name} />
          : <AvatarNew title={title} imageUrl={user.avatarUrl} />
          }
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.06 5.72668L8 8.78002L4.94 5.72668L4 6.66668L8 10.6667L12 6.66668L11.06 5.72668Z" fill="#979797"/>
          </svg>
        </div>
      )
    } else {
      return undefined;
    }
  }, [team, teams, toggleActive, user]);

  return (
    <Popover
      active={active}
      activator={activator}
      onClose={toggleActive}>
      <div className="m-t-2px bg-c-w d-f p-v-0.5 fb-d-c b-r-2px b-w-1px b-s-s b-c-lightestGray f-s-1 max-w-14">
        <Link to="/profile" onClick={() => setActive(false)} className="p-h-1 p-v-0.5 c-p t-c-b">{t("My Profile")}</Link>
        <div className="h-1px m-v-0.5 bg-c-lightestGray"></div>
        {(teams && teams.length !== 1) || (invitations && invitations.length > 0) ?
          <Link to="/" onClick={() => setActive(false)} className="p-h-1 p-v-0.5 c-p t-c-b">
            {invitations && invitations.length > 0 ?
              t("Teams & Invitations")
            :
              t("Teams")
            }
          </Link>
        : teams && !isOneTeamPath ?
          <Link
            to={`/${teams[0].slug}`}
            onClick={() => setActive(false)}
            className="p-h-1 p-v-0.5 c-p t-c-b t-t-c of-h t-o-e ws-n">
            {teams[0].name.toLowerCase()}
          </Link>
        :
          null
        }
        <Link to="/teams/new" onClick={() => setActive(false)} className="p-h-1 p-v-0.5 c-p t-c-b">Create a Team</Link>
        <div className="h-1px m-v-0.5 bg-c-lightestGray"></div>
        <div className="p-h-1 p-v-0.5 c-p" onClick={signOut}>{t("Sign out")}</div>
      </div>
    </Popover>
  );
}
import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import { Loading } from '../ui/loading/Loading';
import { BaseInputOnChangeEvent, Switch } from '../ui';
import { UpdateTeamDisplayStatusImageReq } from '../model';
import { useValidator, Validations } from '../validator';
import { apiBaseUrl } from "../Consts";
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isOwnerState, teamState } from '../atoms';
import { useHandleRequestError } from '../hooks';

const v = new Validations(new UpdateTeamDisplayStatusImageReq());

export const SettingsTeamDisplayStatusImage: React.FC = () => {

  const { t } = useTranslation();
  const [team, setTeam] = useRecoilState(teamState);
  const { handleRequestError } = useHandleRequestError();
  const isOwner = useRecoilValue(isOwnerState);

  const [ updateTeamDisplayStatusImageReq, setUpdateTeamDisplayStatusImageReq ] = useState<UpdateTeamDisplayStatusImageReq>();
  const [ isUpdatingTeamDisplayStatusImage, setIsUpdatingTeamDisplayStatusImage ] = useState<boolean>();

  const { errors, resetErrorField } = useValidator(v);

  // DisplayStatusImage
  const onChangeDisplayStatusImage = useCallback((event: BaseInputOnChangeEvent) => {

    if (team && setTeam) {
      const _updateTeamDisplayStatusImageReq = { 
        ...updateTeamDisplayStatusImageReq,
        displayStatusImage: event.value as boolean
      }

      setUpdateTeamDisplayStatusImageReq(_updateTeamDisplayStatusImageReq);
      setIsUpdatingTeamDisplayStatusImage(true);
      axios
        .put(`${apiBaseUrl}/${team.uuid}/team/display-status-image`, _updateTeamDisplayStatusImageReq)
        .then(response => {
          setTeam(response.data.team);
        })
        .catch(err => {
          if (err.response?.data?.message) {
            resetErrorField("displayStatusImage", err.response.data.message)
          } else {
            handleRequestError(err);
          }
          const _updateTeamDisplayStatusImageReq = { 
            ...updateTeamDisplayStatusImageReq,
            displayStatusImage: team.displayStatusImage
          }
          setUpdateTeamDisplayStatusImageReq(_updateTeamDisplayStatusImageReq);
        })
        .finally(() => setIsUpdatingTeamDisplayStatusImage(false));
    }

  }, [resetErrorField, setTeam, team, updateTeamDisplayStatusImageReq, handleRequestError])

  useEffect(() => {
    if (team && !updateTeamDisplayStatusImageReq) {
      setUpdateTeamDisplayStatusImageReq({ displayStatusImage: team.displayStatusImage });
    }
  }, [team, updateTeamDisplayStatusImageReq]);

  if (!team || !updateTeamDisplayStatusImageReq) {
    return <Loading />
  } return (
      <div className="d-f fb-d-c">
        <div className="d-f a-i-c p-v-1 b-sb-s b-c-lightestGray b-w-1px">
          <div className="d-f fb-a p-l-1 p-l-1.5-M">{t("Display Image for Monitor Status")}</div>
          <div className="d-f w-4 w-6-M j-c-e p-r-0.5">
            <Switch
              disabled={!isOwner || isUpdatingTeamDisplayStatusImage}
              activated={updateTeamDisplayStatusImageReq.displayStatusImage}
              onChange={e => onChangeDisplayStatusImage(e)} />
          </div>

        </div>
        <div className="t-c-r">
          {errors.fields.displayStatusImage}
        </div>
      </div>
  );
}

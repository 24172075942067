import React from "react";
import { getCheckBoxZise } from "../Helper";


type IconGitHubProps = {
  size?: string
}

export const IconGitHub: React.FC<IconGitHubProps> = (props) => {
  const size = getCheckBoxZise(props.size ? props.size : "small");

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 32 32"
    >
      <title>GitHub</title>
      <path
        fill="#000"
        d="M16 .396c-8.84 0-16 7.164-16 16 0 7.07 4.584 13.067 10.94 15.18.8.15 1.093-.344 1.093-.77 0-.38-.013-1.386-.02-2.72-4.45.966-5.389-2.146-5.389-2.146-.728-1.847-1.78-2.34-1.78-2.34-1.45-.992.112-.972.112-.972 1.607.112 2.45 1.648 2.45 1.648 1.427 2.447 3.746 1.74 4.66 1.33.145-1.034.557-1.74 1.014-2.14-3.553-.4-7.288-1.775-7.288-7.906 0-1.747.62-3.173 1.647-4.293-.18-.404-.72-2.031.14-4.235 0 0 1.34-.43 4.4 1.64 1.28-.356 2.64-.532 4-.54 1.36.008 2.72.184 4 .54 3.04-2.07 4.38-1.64 4.38-1.64.86 2.204.32 3.83.16 4.235 1.02 1.12 1.64 2.546 1.64 4.293 0 6.147-3.74 7.5-7.3 7.893.56.48 1.08 1.462 1.08 2.96 0 2.142-.02 3.862-.02 4.382 0 .42.28.92 1.1.76C27.42 29.456 32 23.456 32 16.396c0-8.836-7.164-16-16-16z"
      ></path>
    </svg>
  );
}
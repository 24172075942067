import axios from 'axios';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { lastInvoiceUpdatedState, teamState } from '../atoms';
import { apiBaseUrl } from '../Consts';
import { formatAmount } from '../Helper';
import { useHandleRequestError } from '../hooks';
import { NewTabIcon } from '../images/NewTabIcon';
import { Paginator as ModelPaginator } from '../model';
import { Invoice } from '../model';
import { Paginator } from '../ui';


export const Invoices: React.FC = () => {

  const { t } = useTranslation();
  const team = useRecoilValue(teamState);

  const { handleRequestError } = useHandleRequestError();
  const [ currentInvoice, setCurrentInvoice ] = useState<Invoice>();
  const [ invoices, setInvoices ] = useState<Invoice[]>();
  const [ paginator, setPaginator ] = useState<ModelPaginator>();
  const lastInvoiceUpdated = useRecoilValue(lastInvoiceUpdatedState);

  const fetch = useCallback((recordOffset: number = 0) => {
    if (team) {
      axios.get(`${apiBaseUrl}/${team.uuid}/invoices/current`)
        .then(response => {
          setCurrentInvoice(response.data.invoice as Invoice);
        })
        .catch(err => handleRequestError(err));

      axios.get(`${apiBaseUrl}/${team.uuid}/invoices`, {
        params: {
          recordOffset: recordOffset
        }})
        .then(response => {
          setInvoices(response.data.invoices as Invoice[]);
          setPaginator(response.data.paginator);
        })
        .catch(err => handleRequestError(err));
    }
  }, [handleRequestError, team]);

  useEffect(() => fetch(), [fetch]);

  useEffect(() => {
    if (lastInvoiceUpdated) {
      fetch();
    }
  }, [lastInvoiceUpdated])

  return (
    <>
      <div className="d-f fb-d-c w-100% p-h-1">
        {currentInvoice && team ?
          <div className="d-f fb-d-c m-t-2 m-t-4-M">
            <div className="f-s-1.5 f-s-2-M">1 {currentInvoice.startAt ?
              DateTime.fromISO(currentInvoice.startAt).toFormat("MMMM")
            :
              DateTime.now().toFormat("MMMM")
            } - {t("today")}</div>
            {currentInvoice.subTotal > 0 ?
              <>
                <div className="m-t-1 m-t-2-M p-b-0.75 b-sb-s b-w-1px b-c-lightestGray">
                  <div className="d-n d-f-M f-w-600">{t("Summary")}</div>
                </div>
                <div className="w-100% d-f p-b-0.75 b-sb-s b-w-1px b-c-lightestGray">
                  <div className="w-100% m-t-1 m-l-1-M">
                    {t("Accrued Charges")}&nbsp;&nbsp;&nbsp;&nbsp;
                    <Link to={`/${team.slug}/billing/invoices/current`}>
                      {t("Details")}
                    </Link>
                  </div>
                  <div className="w-7 t-a-r m-t-1 m-r-1-M f-w-b">{formatAmount(currentInvoice.subTotal)}</div>
                </div>
              </>
            : <div className="m-t-2 p-l-1-M p-t-1 b-st-s b-w-1px b-c-lightestGray">{t("There are not accrued charges at this time.")}</div>
            }
          </div>
        : null}
          <div className="d-f fb-d-c m-t-4 m-t-6-M">
            <div className="f-s-1.5 f-s-2-M">{t("Billing History")}</div>
            {invoices && invoices.length > 0 && team ?
              <div className="m-t-1 m-t-3-M p-b-0.75 d-f b-sb-s b-w-1px b-c-lightestGray">
                <div className="d-n d-f-M fb-a f-w-600">{t("Date")}</div>
                <div className="d-n d-f-M fb-n w-5-M w-7-L j-c-c f-w-600">{t("Status")}</div>
                <div className="d-n d-f-M fb-n w-7 j-c-e f-w-600 m-r-1">{t("Amount")}</div>
              </div>
            : null}
            {invoices && invoices.length > 0 && team ?
              <>
                {invoices.map((inv, i) =>
                  <div key={i} className="d-f fb-d-c fb-d-r-M b-sb-s b-w-1px b-c-lightestGray p-v-1">
                    <div className="d-f fb-a">
                      <div className="d-f fb-n w-9-M m-l-1-M">{DateTime.fromISO(inv.updatedAt).toFormat("MMMM d")}</div>
                      <div className="d-f fb-a j-c-e j-c-s-M">
                        <a href={inv.stripeUrl} target="_blank" rel="noreferrer" className="d-f t-c-g c-p">
                          {t("Invoice")}&nbsp;{inv.number}
                          <div className="d-f p-l-0.25 a-i-c"><NewTabIcon /></div>
                        </a>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <a href={`/${team.slug}/billing/invoices/${inv.uuid}`} className="t-c-g c-p">
                          {t("   details")}
                        </a>
                      </div>
                    </div>
                    <div className="d-f fb-d-rr fb-d-r-M  fb-a fb-n-M j-c-e m-t-1 m-t-0-M">
                      <div className="d-f w-5 fb-n w-7-L j-c-e j-c-c-M t-t-c">{inv.status}</div>
                      <div className="d-f w-7 fb-a fb-n-M j-c-s j-c-e-M m-r-1-M">{formatAmount(inv.total, 2)}</div>
                    </div>
                  </div>
                )}
              </>
            :
              <div className="m-t-2 p-t-1 p-l-1-M b-st-s b-w-1px b-c-lightestGray">{t("No billing history yet")}</div>
            }
          </div>

          {team && paginator ?
            <div className="d-f j-c-c m-t-2">
              <Paginator
                recordOffset={paginator.recordOffset}
                recordsCount={paginator.recordsCount}
                pageSize={paginator.pageSize}
                onSelect={(page) => fetch((page - 1) * paginator.pageSize)}
              />
            </div>
          : null}
      </div>
    </>
  );
}

import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeft } from '../../images/ChevronLeft';
import { ChevronRight } from '../../images/ChevronRight';

enum PartType {
  Link = "link",
  Current = "current",
  Dots = "dots"
}

class Part {
  constructor(
    public type: PartType,
    public page?: number) {}

  get title(): string {
    return this.type === PartType.Link || this.type === PartType.Current ?
      (this.page as number).toString() :
      "...";
  }
}

type PaginatorProps = {
  recordOffset: number;
  pageSize: number;
  recordsCount: number;
  url?: string;
  onSelect?: (page: number) => void
}

export const Paginator: React.FC<PaginatorProps> = (props) => {

  const [parts, setParts] = useState<Part[]>([]);

  const pagesCount = Math.ceil(props.recordsCount / props.pageSize);
  const currentPage = Math.ceil((props.recordOffset + 1) / props.pageSize)


  const url = useMemo(() => {
    if (props.url) {
      const urlArray = props.url.split("?");
      if (urlArray.length === 1) {
        return `${urlArray[0]}?`;
      } else {
        const queryArray = urlArray[1].split("&");
        const indexPage = queryArray.findIndex(q => q.split("=")[0] === "page");
        if (indexPage > -1) {
          queryArray.splice(indexPage, 1);
        }
        if (queryArray.length === 0) {
          return `${urlArray[0]}?`;
        } else {
          return `${urlArray[0]}?${queryArray.join("&")}&`;
        }
      }
    } else {
      return undefined;
    }
  }, [props.url]);

  useEffect(() => {
    const parts: Part[] = [];
    if (pagesCount > 0) {
      parts.push(new Part(currentPage !== 1 ? PartType.Link : PartType.Current, 1));
      if (currentPage > 4) {
        parts.push(new Part(PartType.Dots));
      }
      if (currentPage - 2 > 1) {
        parts.push(new Part(PartType.Link, currentPage - 2));
      }
      if (currentPage - 1 > 1) {
        parts.push(new Part(PartType.Link, currentPage - 1));
      }
      if (currentPage > 1) {
        parts.push(new Part(PartType.Current, currentPage));
      }
      if (currentPage + 1 < pagesCount) {
        parts.push(new Part(PartType.Link, currentPage + 1));
      }
      if (currentPage + 2 < pagesCount) {
        parts.push(new Part(PartType.Link, currentPage + 2));
      }
      if (pagesCount - currentPage > 3) {
        parts.push(new Part(PartType.Dots));
      }
      if (currentPage < pagesCount) {
        parts.push(new Part(PartType.Link, pagesCount));
      }
    }
    setParts(parts);
  }, [pagesCount, currentPage]);

  return (
    pagesCount > 1 ?
      <div className="d-f j-c-c w-100% d-f a-i-c m-b-2">
        {currentPage > 1 ?
          <div className="fb-n d-f a-i-c m-h-0.25 d-f a-i-c">
            {url ?
              <Link
                onClick={() => props.onSelect ? props.onSelect(1) : null}
                to={`${url}page=1`}
                className="d-f p-a-0.5">
                <ChevronLeft />
              </Link>
            :
              <div
                onClick={() => props.onSelect ? props.onSelect(1) : null}
                className="d-f p-a-0.5 c-p">
                <ChevronLeft />
              </div>
            }
          </div>
        : null}

        {parts.map((part, i) =>
          <React.Fragment key={i}>
            {part.type === PartType.Link ?
              url ?
                <Link
                  onClick={() => props.onSelect ? props.onSelect(part.page as number) : null}
                  to={`${url}page=${part.page}`} className="d-b p-v-0.5 p-h-1 b-s-s b-w-1px b-c-b t-c-b m-h-0.25">
                  { part.title }
                </Link>
              :
                <div
                  onClick={() => props.onSelect ? props.onSelect(part.page as number) : null}
                  className="d-b p-v-0.5 p-h-1 b-s-s b-w-1px b-c-b t-c-b m-h-0.25 c-p">
                  { part.title }
                </div>
            : part.title !== currentPage.toString() ?
              <div className="f-s-1 m-h-0.25 p-v-0.5 b-w-1px b-c-w">
                { part.title }
              </div>
            :
              <span className="d-b p-v-0.5 p-h-1 b-s-s b-w-1px b-c-b bg-c-nw t-c-gray m-h-0.25 c-p">
                { part.title }
              </span>}
          </React.Fragment>
        )}
        {currentPage < pagesCount ?
          <div className="fb-n d-f a-i-c m-h-0.25">
            {url ?
              <Link
                to={`${url}page=${currentPage + 1}`}
                className="d-f p-a-0.5">
                <ChevronRight />
              </Link>
            :
              <div
                onClick={() => props.onSelect ? props.onSelect(currentPage + 1) : null}
                className="d-f p-a-0.5 c-p">
                <ChevronRight />
              </div>
            }
          </div>
        : null}
      </div>
    : null
  );
}
import React, { useState, useCallback, useRef, useMemo } from 'react';
import { X } from '../../images';
import { BaseInputOnBlurEvent, BaseInputEvent } from '../event/BaseInputEvent';

export class FileInputOnChangeEvent<T> extends BaseInputEvent {
  constructor(
    readonly value: T | undefined,
    readonly dirty: boolean,
    readonly touched: boolean) {
    super(dirty, touched);
  }
}

type Alignment = 'left' | 'center' | 'right';

type FileInputProps = {
  label?: string
  autoFocus?: boolean
  placeholder: string
  value?: File[] | File
  align?: Alignment
  error?: string | string[]
  disabled?: boolean
  accept?: string
  multiple?: boolean
  onChange?: (event: FileInputOnChangeEvent<File | File[] | undefined>) => void
  onBlur?: (event: BaseInputOnBlurEvent) => void
}
export const FileInput: React.FC<FileInputProps> = (props) => {

  const [ dirty, setDirty ] = useState(false);
  const [ touched, setTouched ] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const files = useMemo(() => {
    if (props.value) {
      if ('length' in props.value) {
        return props.value as File[];
      } else {
        return [props.value as File];
      }
    } else {
      return undefined;
    }
  }, [props.value])

  let alignClass: string;

  switch (props.align) {
    case "center":
      alignClass = "j-c-c";
      break;
    case "right":
      alignClass = "j-c-e";
      break;
    case "left":
    default:
      alignClass = "j-c-s"
  }

  const errorClass = props.error ? 'b-c-r' : null;

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDirty(true);

    const fileList = event.currentTarget.files;
    if (props.onChange && fileList && fileList.length > 0) {
      if (props.multiple) {
        props.onChange(new FileInputOnChangeEvent(Array.from(fileList), true, touched));
      } else {
        props.onChange(new FileInputOnChangeEvent(
          fileList.length > 0 ? fileList.item(0) as File : undefined, true, touched));
      }
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }, [props, touched]);

  const onBlur = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setTouched(true);
    if (props.onBlur) {
      props.onBlur(new BaseInputOnBlurEvent(event.currentTarget.value, dirty, true));
    }
  }, [props, dirty]);

  const removeFile = useCallback((index: number) => {
    if (files && files.length > index) {
      const _files = [...files];
      _files.splice(index,1);
      if (props.onChange) {
        if (props.multiple) {
          props.onChange(new FileInputOnChangeEvent(_files, true, touched));
        } else {
          props.onChange(new FileInputOnChangeEvent(undefined, true, touched));
        }
      }
    }
  }, [files, props, touched])

  const renderError = (error: string, index: number) => {
    return <div key={index} className="t-c-r f-s-1 m-t-0.5">{ error }</div>
  }

  const renderErrors = (error?: string | string[]) => {
    if (error) {
      if (typeof error === "string") {
        return renderError(error, 0);
      } else {
        return (error as string[]).map((e, i) => renderError(e, i));
      }
    } else {
      return null;
    }
  }

  return (
    <div className={`d-f fb-d-c ${props.error ? "input-error" : ""}`}>
      <input
        ref={fileInputRef}
        placeholder={props.placeholder}
        onChange={ onChange }
        onBlur={ onBlur }
        type="file"
        accept={props.accept}
        disabled={props.disabled}
        className={`d-n`} />
      {props.label ?
        <div className="f-s-1 f-s-1.25-M m-b-0.5">{ props.label }</div>
        : null
      }
      <div
        className={`d-f fb-d-c j-c-c min-h-3 f-s-1 ${errorClass} ${alignClass}`}>
        { files?.map((v,i) =>
          <div key={i} className="d-f a-i-c">
            <div title={v.name} className="fb-a t-c-gray ws-n of-h t-o-e">
              {v.name}
            </div>
            <div className="c-p m-l-0.5 t-c-g" onClick={() => removeFile(i)}>
              <X />
            </div>
          </div>
        )}
        <div
          onClick={() => fileInputRef.current?.click()}
          className="t-dl-u c-p m-t-0.5">
          { props.placeholder }
        </div>
      </div>
      { renderErrors(props.error) }
    </div>
  );
}

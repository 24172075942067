import React from 'react';

export const IconWebhook = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="white"/>
      <path d="M10 15H15.88C16.15 14.69 16.55 14.5 17 14.5C17.83 14.5 18.5 15.17 18.5 16C18.5 16.83 17.83 17.5 17 17.5C16.56 17.5 16.16 17.31 15.88 17H11.9C11.44 19.28 9.42 21 7 21C4.24 21 2 18.76 2 16C2 13.58 3.72 11.56 6 11.1V13.17C4.84 13.58 4 14.7 4 16C4 17.65 5.35 19 7 19C8.65 19 10 17.65 10 16V15ZM12.5 4C14.15 4 15.5 5.35 15.5 7H17.5C17.5 4.24 15.26 2 12.5 2C9.74 2 7.5 4.24 7.5 7C7.5 8.43 8.1 9.71 9.05 10.62L6.7 14.52C6.02 14.66 5.5 15.27 5.5 16C5.5 16.83 6.17 17.5 7 17.5C7.83 17.5 8.5 16.83 8.5 16C8.5 15.84 8.48 15.69 8.43 15.55L11.81 9.92C10.49 9.61 9.5 8.42 9.5 7C9.5 5.35 10.85 4 12.5 4ZM17 13C16.36 13 15.77 13.2 15.28 13.54L12.23 8.47C11.53 8.35 11 7.74 11 7C11 6.17 11.67 5.5 12.5 5.5C13.33 5.5 14 6.17 14 7C14 7.15 13.98 7.29 13.94 7.43L16.13 11.08C16.41 11.03 16.7 11 17 11C19.76 11 22 13.24 22 16C22 18.76 19.76 21 17 21C15.15 21 13.53 19.99 12.67 18.5H15.34C15.82 18.82 16.39 19 17 19C18.65 19 20 17.65 20 16C20 14.35 18.65 13 17 13Z" fill="black"/>
    </svg>
  )
}

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Alert, AlertRecipient } from '../model';
import { TextInput, Button, BaseInputOnChangeEvent, Spinner, CheckboxWithStatus, BaseInputOnBlurEvent } from '../ui';
import { Validations, useValidator } from '../validator';
import { isFieldEmailNotMatching, isFieldPhoneNotMatching, isFieldStringEmpty } from '../Validations';
import { useTranslation } from 'react-i18next';
import { Recipients } from './Recipients';
import { countriesState, userState } from '../atoms';
import { useRecoilValue } from 'recoil';
import { MonitorStatusType } from '../Consts';

const v = new Validations(new Alert());
v.addField("name", (v: any) => isFieldStringEmpty(v));

v.addExtra("triggers", (alert: Alert) => {
  if (!alert.up &&
    !alert.degraded &&
    !alert.disrupted &&
    !alert.down &&
    !alert.maintenance) {
    return "At least one Trigger is required."
  }
  return undefined
});

type AlertFormProps = {
  alert: Alert
  onCancel: () => void
  onSave: (alert: Alert) => void
  errorsOnServer: any | undefined
  isSaving?: boolean
}


export const AlertForm: React.FC<AlertFormProps> = (props) => {

  const { t } = useTranslation();

  const user = useRecoilValue(userState);
  const countries = useRecoilValue(countriesState);
  const defaultPhoneCode = useMemo(() => countries?.find(c => c.id === user?.countryId)?.phoneCode, [user, countries])

  const [ alert, setAlert ] = useState(props.alert);
  const { errors, validateField, validateExtra, validateAll, resetErrorAllFromServer } = useValidator(v);
  const [ recipientErrors, setRecipientErrors ] = useState<{ [name: string]: string | undefined }[]>(() =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  alert.recipients.map(r => ({}) ));

  const onChangeField = useCallback(
    (fieldName: keyof Alert, event: BaseInputOnChangeEvent) => {
      const value = event.value;
      if (event.dirtyAndTouched) {
        validateField(fieldName, event.value);
      }
      const _alert = { ...alert } as Alert;
      _alert[fieldName] = value as never;

      if (["up",
        "degraded",
        "disrupted",
        "down",
        "maintenance"].includes(fieldName)) {
        validateExtra("triggers", _alert);
      }

      setAlert(_alert);

    }, [alert, validateExtra, validateField]);

  const onBlurField = useCallback(
    (fieldName: keyof Alert, event: BaseInputOnChangeEvent) => {
      if (event.dirty) {
        validateField(fieldName, event.value);
      }
    }, [validateField]);

  const validateRecipients = useCallback((recipients: AlertRecipient[]): boolean => {
    const _recipientErrors = [...recipientErrors];
    let valid = true;

    for (let i = 0; i < recipients.length; i++) {
      const r1 = alert.recipients[i];

      let error: string | undefined;
      _recipientErrors[i] = {};
      switch (r1.type) {
        case "email":
          error = isFieldEmailNotMatching(r1.target as string, r1.type);
          break;
        case "call":
        case "sms":
          error = isFieldPhoneNotMatching(r1.target as string, r1.type);
          break;
        case "zapier":
        case "slack":
        case "discord":
          error = isFieldStringEmpty(r1.target as string, r1.type);
          break;
      }
      if (error) {
        _recipientErrors[i].target = error;
        valid = false;
      } else {
        for (let j = 0; j < recipients.length; j++) {
          const r2 = alert.recipients[j];
          if (i !== j && r1.type === r2.type && r1.target === r2.target) {
            _recipientErrors[i].target = t("The recipient already was added");
            valid = false;
            break;
          }
        }
      }
    }

    setRecipientErrors(_recipientErrors);
    return valid;
  }, [alert.recipients, recipientErrors, t])

  const onChangeRecipientField = useCallback(
    (index: number, fieldName: keyof AlertRecipient, event: BaseInputOnChangeEvent) => {
    const recipient = alert.recipients[index];

    recipient[fieldName] = event.value as never;
    if (fieldName === "type") {
      if ((event.value as string) === "call" || (event.value as string) === "sms") {
        recipient["target"] = `+${defaultPhoneCode}`;
      } else {
        recipient["target"] = "";
      }
    }
    const _recipientErrors = [...recipientErrors];
    _recipientErrors[index] = {};
    setRecipientErrors(_recipientErrors);

    const recipients = [...alert.recipients];

    setAlert({
      ...alert,
      recipients: recipients
    })
    if (event.dirtyAndTouched && fieldName !== "type") {
      validateRecipients(recipients);
    }

  }, [alert, defaultPhoneCode, recipientErrors, validateRecipients]);

  const onBlurRecipientField = useCallback(
    (index: number, fieldName: keyof AlertRecipient, event: BaseInputOnBlurEvent) => {
    if (event.dirtyAndTouched && fieldName === "target") {
      validateRecipients(alert.recipients);
    }
  }, [alert.recipients, validateRecipients]);

  const onAddRecipient = useCallback(() => {
    const recipient = new AlertRecipient();
    recipient.type = "email";
    setAlert({
      ...alert,
      recipients: [...alert.recipients, recipient]
    })
    setRecipientErrors([...recipientErrors, {}])
  }, [alert, recipientErrors]);

  const onRemoveRecipient = useCallback((index: number) => {
    const recipients = [...alert.recipients];
    recipients.splice(index, 1);
    setAlert({
      ...alert,
      recipients: recipients
    });
    const errors = [...recipientErrors];
    errors.splice(index, 1);
    setRecipientErrors(errors);
  }, [alert, recipientErrors]);

  const save = useCallback(() => {
    let valid = validateAll(alert, {"triggers": alert});
    valid = validateRecipients(alert.recipients) && valid;
    if ( valid ) {
      props.onSave(alert);
    }
  }, [validateAll, alert, validateRecipients, props]);

  const cancel = useCallback(() => {
      props.onCancel();
  }, [props]);

  useEffect(() => {
    if (props.errorsOnServer) {
      resetErrorAllFromServer(props.errorsOnServer);

      setRecipientErrors((recipientErrors) => {
        const fields = Object.keys(props.errorsOnServer).filter(e => e.split(".")[0] === "recipients")
        if (fields.length > 0) {
          const _recipientErrors = [...recipientErrors];
          fields.forEach(f => {
            const [, index, field] = f.split(".");
            _recipientErrors[parseInt(index)][field] = props.errorsOnServer[f];
          })
          return _recipientErrors;
        }
        return recipientErrors;
      });
    }
  }, [props.errorsOnServer, resetErrorAllFromServer])

  useEffect(() => {
    if (props.errorsOnServer) {
      resetErrorAllFromServer(props.errorsOnServer);
    }

  }, [props.errorsOnServer, resetErrorAllFromServer])

  return (
    <>
      <form onSubmit={e => { e.preventDefault(); save(); }} className="d-f fb-d-c">
        <div className="d-f fb-d-c">
          <TextInput
            autoFocus={true}
            label={t("Name")}
            value={alert.name}
            placeholder={t("Enter Name")}
            onChange={e => onChangeField("name", e)}
            onBlur={e => onBlurField("name", e)}
            error={errors.fields.name}
            maxLength={50}
            type="text" />
        </div>

        <div className="d-f fb-d-c m-t-1.5 m-t-2-M">
          <div className="f-s-1 f-s-1.25-M">{t("Triggers")}</div>
          <div className={`d-f fb-d-c m-t-0.5 w-100% b-s-s-M b-w-1px-M b-c-lightestGray-M p-h-2-M p-v-1.5 ${errors.extras.triggers ? "b-c-r-M" : ""}`}>
            <div className="f-w-700">{t("Status")}</div>
            <div className="d-f fb-w-w">
              {
                [
                  [alert.up, MonitorStatusType.Up, t("Up")],
                  [alert.degraded, MonitorStatusType.Degraded, t("Degraded")],
                  [alert.disrupted, MonitorStatusType.Disrupted, t("Disrupted")],
                  [alert.down, MonitorStatusType.Down, t("Down")],
                  [alert.maintenance, MonitorStatusType.Maintenance, t("Maintenance")],
                ].map(([selected, status, title], i) =>
                  <span key={i} className={`${selected ? "t-c-g" : "" } ${i < 4 ? "m-r-2" : ""} d-f a-i-c m-t-1`}>
                    <CheckboxWithStatus
                      size={"large"}
                      status={status as MonitorStatusType}
                      checked={selected as boolean}
                      onChange={(e) => onChangeField(status as keyof Alert, e)} />
                    <span
                      className="c-p"
                      onClick={() => onChangeField(status as keyof Alert, new BaseInputOnChangeEvent(!selected, true, true))} >
                      &nbsp;&nbsp;{title}
                    </span>
                  </span>                
                )
              }
            </div>
          </div>
          {errors.extras.triggers ?
            <div className="t-c-r f-s-1 m-t-0.5">{ errors.extras.triggers }</div>
          : null}
        </div>

        <Recipients
          recipients={alert.recipients}
          onChangeField={onChangeRecipientField}
          onBlurField={onBlurRecipientField}
          onAddRecipient={onAddRecipient}
          onRemoveRecipient={onRemoveRecipient}
          errors={recipientErrors} />

        <div className="d-f fb-d-cr fb-d-r-M m-v-1 m-v-2-M j-c-e a-i-c w-100%">
          {props.isSaving ?
            <Spinner type={"primary"} />
          :
            <>
              <div onClick={cancel} className="d-f t-c-g f-s-1.25 c-p p-t-1 p-t-0-M j-c-e-M">
                {t("Cancel")}
              </div>
              <div className="m-l-1-M w-100% w-auto-M">
                <Button type="primary" submit={true} fullWidth="mobile">
                  { alert.uuid ? t("Save") : t("Add Alert") }
                </Button>
              </div>
            </>
          }
        </div>

      </form>

    </>
  );
}

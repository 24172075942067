import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { copyElementToClipboard } from "../Helper";
import { Loading, MenuInRow, Modal } from '../ui';
import axios from "axios";
import { apiBaseUrl } from "../Consts";
import { useRecoilState, useRecoilValue } from "recoil";
import { isOwnerState, teamState } from "../atoms";
import { useHandleRequestError } from "../hooks";
import { DateTime } from 'luxon';

export const SettingsHmacSecretKey: React.FC = () => {

  const { t } = useTranslation();
  const { handleRequestError } = useHandleRequestError();
  const [team, setTeam] = useRecoilState(teamState);
  const isOwner = useRecoilValue(isOwnerState)
  
  const secretKeyRef = useRef<HTMLDivElement>(null);
  const showSecretKeyTimeout = useRef<any>(null);
  const copySecretKeyTimeout = useRef<any>(null);
  const [secretKey, setSecretKey] = useState<string>();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isGenerating, setIsGenerating ] = useState(false);
  const [ recentlyCopied, setRecentlyCopied ] = useState(false);

  const showSecretKey = useCallback((secretKey: string) => {
    setSecretKey(secretKey);
    if (showSecretKeyTimeout.current) {
      clearTimeout(showSecretKeyTimeout.current);
    }
    showSecretKeyTimeout.current = setTimeout(() => {
      setSecretKey(undefined);
    }, 10000)

    return () => {
      if (showSecretKeyTimeout.current) {
        clearTimeout(showSecretKeyTimeout.current);
      }
    }
  }, [])

  const show = useCallback(() => {
    if (team) {
      setIsLoading(true);
      axios.get(`${apiBaseUrl}/${team.uuid}/hmac-secret-key`)
        .then(response => showSecretKey(response.data.hmacSecretKey))
        .catch(error => handleRequestError(error))
        .finally(() => setIsLoading(false));
    }
  }, [showSecretKey, team, handleRequestError]);

  const copyToClipboard = useCallback(() => {
    copyElementToClipboard(secretKeyRef.current as HTMLDivElement);
    setRecentlyCopied(true);
    copySecretKeyTimeout.current = setTimeout(() => {
      setRecentlyCopied(false)
    }, 3000)

    return () => {
      if (copySecretKeyTimeout.current) {
        clearTimeout(copySecretKeyTimeout.current);
      }
    }
  }, []);

  const regenerate = useCallback(() => setIsGenerating(true), []);

  const execRegenerate = useCallback(() => {
    setIsGenerating(false);
    if (copySecretKeyTimeout.current) {
      clearTimeout(copySecretKeyTimeout.current);
    }
    setRecentlyCopied(false);
    if (team && setTeam) {
      setIsLoading(true);
      axios.put(`${apiBaseUrl}/${team.uuid}/hmac-secret-key`)
        .then(response => {
          setTeam({...team, hmacSecretKeyCreatedAt: response.data.hmacSecretKeyCreatedAt});
          showSecretKey(response.data.hmacSecretKey);
        })
        .catch(error => handleRequestError(error))
        .finally(() => { setIsLoading(false) });
    }
  }, [setTeam, showSecretKey, team, handleRequestError]);

  if (!team) {
    return <Loading />
  }

  return (
    <>
      <div className="f-s-1.25">
        {t("HMAC Secret Key")}
      </div>
      <div className="f-s-1 m-t-0.5">
        {t("Used to validate webhook event messages.")}
      </div>
      <div className="d-f fb-d-c w-100%">
        <div className="d-n d-f-M h-5 a-i-c b-sb-s b-c-lightestGray b-wb-0.1 f-w-b t-c-b">
          <div className="d-b fb-a p-l-1 p-l-0.5-M">{t("Secret Key")}</div>
          <div className="w-6-M w-14-L">{t("Generated")}</div>
          <div className="d-f w-2.5 w-3.5-M"></div>
        </div>
        <div className="b-sb-s b-c-lightestGray b-w-1px p-v-1 p-v-1.5-M">
          <div className="d-f a-i-c">
            <div className="fb-a d-f fb-d-c fb-d-r-M a-i-c-M p-l-0.5">
              <div className="d-b fb-a p-r-1 ws-n of-h t-o-e">
                {secretKey ?
                  <span ref={secretKeyRef}>{ secretKey }</span>
                : "●●●●●●●●●●●●●●●●●●●●●●●●●●●●●"
                }
              </div>
              <div className="w-6-M w-14-L m-t-0.75 m-t-0-M">
                <span className="f-w-b d-ib d-n-M m-r-1">
                  { t("Generated") }
                </span>
                {DateTime.fromISO(team.hmacSecretKeyCreatedAt).toRelative()}
              </div>
            </div>
            <div className="w-2.5 w-3.5-M">
              {isLoading ?
                <Loading />
              : isOwner ?
                <MenuInRow items={[
                  ...(secretKey ? [] : [ { title: t("Show"), onClick: show }, { separator: true } ]),
                  { title: t("Regenerate"), onClick: regenerate } ]} />
              : null
              }
            </div>
          </div>
          {recentlyCopied || secretKey ?
            <div className="m-t-0.75 p-l-0.5 f-s-0.85" style={{wordWrap: "break-word"}}>
              {recentlyCopied ?
                <span className="f-s-1">{t("Copied")}</span>  
              :
                <span className="t-c-g c-p f-s-1" onClick={copyToClipboard}>
                  {t("Copy")}
                </span>
              }
            </div>
          : null}
        </div>
      </div>
      { isGenerating ? (() =>  {
          const title = t("Regenerate HMAC Secret Key");
          return <Modal
            open={isGenerating}
            onClose={ () => setIsGenerating(false) }
            title={title}
            secondaryActions={ [ { title: t("Cancel"), onAction: () => setIsGenerating(false) } ] }
            primaryAction={ { title: title, onAction: execRegenerate, type: "destructive" } }>
            <div className="p-2">
              <div className="f-s-1.5 f-w-300 t-c-b">
                {t("The current HMAC secret key will be erased and you can't reverse this action!")}
              </div>
            </div>
          </Modal>
          })()
      : null
      }
      </>
  );
}
export enum IconType {
  Dashboard,
  Monitor,
  Page,
  Group,
  Alert,
  Preferences,
  Settings,
  Billing,
  Custome
}
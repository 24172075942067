import React, { useEffect, useCallback, useState } from 'react';
import axios from 'axios';
import { StatusAvatar } from '../images';
import { GroupNameWithColor } from "../ui/groupNameWithColor";
import { GetMonitorEventsRequest, Monitor, MonitorEvent, MonitorEventWrap, MonitorRegion, MonitorRegionEvent, Paginator } from '../model';
import { Status, MenuInRow, Modal, Breadcrumbs } from '../ui';
import { MonitorStatus } from '../monitors/MonitorStatus';
import { Loading } from '../ui/loading/Loading';
import { useHistory, useParams } from 'react-router-dom';
import { apiBaseUrl, StatusType } from "../Consts";
import { MonitorEventShow } from "./MonitorEventShow";
import { Regions } from './Regions';
import { MonitorEdit } from '../monitor-edit';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { isOwnerOrMemberState, lastMonitorsUpdatedState, teamState } from '../atoms';
import { useHandleRequestError } from '../hooks';
import { MonitorSelector } from '../monitors/MonitorSelector';
import { DateTime } from "luxon";
import { MonitorUrl } from '../monitor-url';

export const MonitorShow: React.FC = () => {

  const { t } = useTranslation();
  const { handleRequestError } = useHandleRequestError();
  const history = useHistory();
  const team = useRecoilValue(teamState);
  const isOwnerOrMember = useRecoilValue(isOwnerOrMemberState);

  const lastMonitorsUpdated = useRecoilValue(lastMonitorsUpdatedState);

  const { monitorUuid } = useParams<any>();
  const [ selector, setSelector ] = useState<MonitorSelector>();
  const [ eventWraps, setEventWraps ] = useState<MonitorEventWrap[]>();
  const [ monitorRegions, setMonitorRegions ] = useState<MonitorRegion[]>();
  const [ editingMonitor, setEditingMonitor ] = useState<Monitor>();
  const [ isRemoving, setIsRemoving ] = useState<boolean>();
  const [ isPausing, setIsPausing ] = useState<boolean>();
  const [ isSwitchingToMaintenance, setIsSwitchingToMaintenance ] = useState<boolean>();
  const [ paginator, setPaginator ] = useState<Paginator>();

  const fetch = useCallback(() => {
    if (team) {
      axios.get(`${apiBaseUrl}/${team.uuid}/monitors/${monitorUuid}/details`)
        .then(response => {
          const monitor = response.data.monitor as Monitor;
          setSelector(new MonitorSelector(monitor));
          setMonitorRegions(response.data.regions);
          setEventWraps(MonitorEventWrap.wrapMonitorEvents(response.data.events, DateTime.fromISO(monitor.statusSince)));
          setPaginator(response.data.eventsPaginator);
        })
        .catch(err => handleRequestError(err));
    }
  }, [team, monitorUuid, handleRequestError])


  const fetchMoreEvents = useCallback(() => {
    if (team && paginator) {
      const request = new GetMonitorEventsRequest();
      request.recordOffset = paginator.recordOffset + paginator.pageSize;
      request.pageSize = paginator.pageSize;
      axios.get(`${apiBaseUrl}/${team.uuid}/monitors/${monitorUuid}/events`, { params: request })
        .then(response => {
          const newEventWraps = MonitorEventWrap.wrapMonitorEvents(response.data.monitorEvents);
          setEventWraps([...(eventWraps as MonitorEventWrap[]), ...newEventWraps]);
          setPaginator(response.data.paginator);
        })
        .catch(err => handleRequestError(err));
    }
  }, [team, paginator, monitorUuid, eventWraps, handleRequestError]);

  const execMaintenance = useCallback(() => {
    if (team && selector) {
      axios.put(`${apiBaseUrl}/${team.uuid}/monitors/${selector.monitor.uuid}/maintenance`)
        .then(response => setSelector(selector.updateFromRemote(response.data.monitor as Monitor)))
        .catch(err => handleRequestError(err));
        setIsSwitchingToMaintenance(false);
      }
  }, [team, selector, handleRequestError]);


  const execPause = useCallback(() => {
    if (team && selector) {
      axios.put(`${apiBaseUrl}/${team.uuid}/monitors/${selector.monitor.uuid}/pause`)
        .then(response => setSelector(selector.updateFromRemote(response.data.monitor as Monitor)))
        .catch(err => handleRequestError(err));
        setIsPausing(false);
      }
  }, [team, selector, handleRequestError]);


  const resume = useCallback(() => {
    if (team && selector) {
      axios.put(`${apiBaseUrl}/${team.uuid}/monitors/${selector.monitor.uuid}/resume`)
        .then(response => setSelector(selector.updateFromRemote(response.data.monitor as Monitor)))
        .catch(err => handleRequestError(err));
    }
  }, [team, selector, handleRequestError]);


  const execRemove = useCallback(() => {
    if (team && selector) {
      axios.delete(`${apiBaseUrl}/${team.uuid}/monitors/${selector.monitor.uuid}`)
        .then(() => {
          history.push(`/${team.slug}/monitors`);
        })
        .catch(err => handleRequestError(err));
        setIsRemoving(false);
    }
  }, [team, selector, history, handleRequestError]);

  const onUpdate = useCallback((monitor: Monitor) => {
    setSelector(selector => selector?.updateFromRemote(monitor));
    setEditingMonitor(undefined);
  }, [])

  useEffect(() => {
    if (lastMonitorsUpdated && eventWraps && selector) {
      let currentEvent: MonitorEvent | MonitorRegionEvent | undefined = undefined;
      // This condition is required because the monitor could be new without
      // events
      if (eventWraps.length > 0) {
        currentEvent = eventWraps[0].event;
      }
      const monitorServerEvent = lastMonitorsUpdated.find(mu => mu.monitor.uuid === selector.monitor.uuid);
      if (monitorServerEvent && monitorServerEvent.monitor.status !== "starting" && (!currentEvent || monitorServerEvent.monitor.status !== currentEvent.status)) {

        const monitorNewEvent = new MonitorEvent();
        monitorNewEvent.status = monitorServerEvent.monitor.status;
        monitorNewEvent.createdAt = monitorServerEvent.monitor.statusSince;
        monitorNewEvent.uuid = monitorServerEvent.monitor.uuid;
        monitorNewEvent.detail = monitorServerEvent.eventDetail;

        setEventWraps(MonitorEventWrap.wrapMonitorEvents(
          [monitorNewEvent, ...eventWraps.map(ew => ew.event)] as MonitorEvent[],
          DateTime.fromISO(monitorServerEvent.monitor.statusSince)));

        setPaginator(paginator => {
          if (paginator) {
            return {
              ...paginator,
              recordOffset: paginator.recordOffset + 1,
              recordsCount: paginator.recordsCount + 1
            } as Paginator
          } else {
            return paginator
          }
        });
      }
    }
  }, [lastMonitorsUpdated, eventWraps, selector]);

  useEffect(() => {
    if (lastMonitorsUpdated) {
      setSelector(selector => {
        if (selector) {
          const monitorServerEvent = [...lastMonitorsUpdated].reverse()
            .find(ms => ms.monitor.uuid === selector.monitor.uuid);
          if (monitorServerEvent) {
            return selector.updateFromRemote(monitorServerEvent?.monitor, true);
          }
        }
        return selector;
      })

      setMonitorRegions(monitorRegions => {

        if (monitorRegions && monitorRegions.length > 0) {
          const monitorServerEvents = lastMonitorsUpdated
            .filter(ms => ms.monitor.uuid === monitorUuid);
          monitorServerEvents.forEach(monitorServerEvent => {
            if (monitorServerEvent && monitorServerEvent.regionId && monitorRegions) {
              const index = monitorRegions.findIndex(mr => mr.regionId === monitorServerEvent.regionId);
              if (index > -1) {
                const _monitorRegions = [...monitorRegions];
                const monitorRegion = _monitorRegions[index];
                _monitorRegions.splice(index, 1);
                monitorRegion.totalRt = monitorServerEvent.regionTotalRt;
                monitorRegion.responseCode = monitorServerEvent.responseCode ?? 0;
                _monitorRegions.splice(index, 0, monitorRegion);

                return _monitorRegions;
              }
            }
          });
        }
        return monitorRegions;
      })
    }
  }, [lastMonitorsUpdated, monitorUuid]);

  useEffect(() =>  fetch(), [team, fetch]);

  useEffect(() => {
    const paginator = new Paginator();
    paginator.recordOffset = 0;
    paginator.pageSize = 10;
    setPaginator(paginator);
  }, []);

  if (!team || !selector || !monitorRegions || !eventWraps || !paginator) {
    return <Loading />

  } else 
  {
    const displayRegions = (
      <Regions monitor={selector.monitor} monitorRegions={monitorRegions} />
    )

    return (
      <div className="d-f fb-d-c m-h-a p-h-0.5 p-h-2-M w-100% max-w-66-L t-c-nb">
        <div className="d-f">
          <div className="p-l-0.5 m-t-1.5">
            <Breadcrumbs
              items={[
                { title: t("Monitors"), link: `/${team.slug}/monitors`},
                { title: selector.monitor.name },
              ]}
            />
          </div>
        </div>
        <div className="d-f fb-a fb-d-c p-l-0.5 m-t-1 m-t-1.5-M">
          {(paginator.recordsCount > 0 || selector.monitor.status !== "starting") || (!selector.blocked && isOwnerOrMember) ?
            <div className="fb-n d-f w-100% a-i-c">
              <div className="d-f fb-d-c fb-a j-c-c p-r-1">
                <div
                  title={selector.monitor.name}
                  className="f-s-1.5 f-w-300 fb-a m-b-0.5 ws-n of-h t-o-e max-w-100%">
                  {selector.monitor.name}
                </div>
                <div className="d-f">
                  <MonitorUrl monitor={selector.monitor} />
                </div>
              </div>

              <div className="d-f fb-n j-c-e">
                <MenuInRow title={t("Actions")} items={[
                  {
                    title: t("Today details"),
                    link: `/${team.slug}/monitors/${selector.monitor.uuid}/today`,
                    visible: paginator.recordsCount > 0 || selector.monitor.status !== "starting" && selector.status != "requiresPaymentMethod"
                  },
                  {
                    title: t("Edit"),
                    onClick: () => setEditingMonitor(selector.monitor),
                    visible: isOwnerOrMember && !selector.blocked
                  },
                  {
                    separator: true,
                    visible: isOwnerOrMember && !selector.blocked
                  },
                  {
                    title: t("Maintenance"),
                    onClick: () => setIsSwitchingToMaintenance(true),
                    visible: isOwnerOrMember && !selector.isMaintained && !selector.blocked && selector.status != "requiresPaymentMethod"
                  },
                  {
                    title: t("Resume"),
                    onClick: () => resume(),
                    visible: isOwnerOrMember && (selector.isPaused || selector.isMaintained) && !selector.blocked
                  },
                  {
                    title: t("Delete"),
                    onClick: () => setIsRemoving(true),
                    visible: isOwnerOrMember && !selector.blocked
                  }
                ]} />
              </div>
            </div>
          : null}

          {selector.monitor.groups ?
            <div className="fb-w-w d-f m-t-1 w-50%-M">
              {selector.monitor.groups.map((g, i) => 
                <div key={i} className="max-w-10">
                  <GroupNameWithColor group={g} textSize={"small"} margin={true} link={`/${team.slug}/monitors?groupUuid=${g.uuid}`}/>
                </div>
              )}
            </div>
          : null}
        </div>
        <div className="d-f fb-d-c fb-d-r-M fb-a p-l-0.5 m-v-2">
          <div className="w-100% w-50%-M d-f fb-d-c p-r-1">
            <div className="d-f fb-d-c m-b-1">
              <div className="d-f">
                <div className="d-f j-c-c p-t-0.50 h-6">
                  <Status
                    size={40}
                    urlStatusPending={selector.monitor.urlStatusPending}
                    status={selector.status as StatusType} />
                </div>
                <div className="d-f p-l-1 f-s-1.5 f-s-2-M f-w-300 p-t-0.5 p-t-0.25-M">
                  <MonitorStatus monitorSelector={selector} />
                </div>
              </div>
            </div>
            {team.displayStatusImage ?
              <div className="d-n d-f-M w-100% fb-a j-c-c p-v-2 m-b-1">
                <StatusAvatar status={selector.monitor.status}/>
              </div>
            : null
            }
            <div className="d-f d-n-M m-b-2">
              { monitorRegions.length > 0 ? displayRegions : null}
            </div>
            <div>
              {eventWraps.map((eventWrap,i) =>
              <React.Fragment key={i}>
                {i > 0 ?
                  <MonitorEventShow key={`${eventWrap.event.createdAt}${i}`} eventWrap={eventWrap} />
                : null}
              </React.Fragment>
              )}
              {paginator.recordsCount > (paginator.recordOffset + paginator.pageSize)  ?
                <div onClick={fetchMoreEvents} className="t-dl-u t-c-g c-p t-a-c m-t-2">
                  {t("View More")}
                </div>
              : null}
            </div>
          </div>

          <div className="w-100% w-50%-M fb-d-c m-t-6">
            <div className="d-n d-f-M w-100% fb-a a-i-s">
              { monitorRegions.length > 0 ? displayRegions : null}
            </div>
            <div className="d-f fb-w-w j-c-se-M p-v-2">
              {/* <Month /> */}
            </div>
          </div>
        </div>

        { editingMonitor ?
          <MonitorEdit
            monitorUuid={editingMonitor.uuid}
            onUpdate={onUpdate}
            onCancel={() => setEditingMonitor(undefined)} />
          : null
        }

        { isRemoving ?
          <Modal
            open={isRemoving}
            onClose={ () => setIsRemoving(false) }
            title={t("Delete Monitor")}
            description={ selector.monitor.name }
            secondaryActions={ [ { title: t("Cancel"), onAction: () => setIsRemoving(false) } ] }
            primaryAction={ { title: t("Delete Monitor"), onAction: execRemove, type: "destructive" } }>
            <div className="p-2">
              <div className="f-s-1.5 f-w-300 t-c-b">
                {t("You can't reverse this action!")}
              </div>
              <div className="f-s-1 f-w-300 m-t-0.5">
                {t("The stats for this Monitor will be archived.")}
              </div>
            </div>
          </Modal>
          : null
        }

        { isPausing ?
          <Modal
            open={isPausing}
            onClose={ () => setIsPausing(false) }
            title={t("Pause Monitor")}
            description={ selector.monitor.name }
            secondaryActions={ [ { title: t("Cancel"), onAction: () => setIsPausing(false) } ] }
            primaryAction={ { title: t("Yes, pause monitor"), onAction: execPause } }>
            <div className="p-2">
              <div className="f-s-1.5 f-w-300 t-c-b">
                {t("Are you sure?")}
              </div>
            </div>
          </Modal>
          : null
        }

        { isSwitchingToMaintenance ?
          <Modal
            open={isSwitchingToMaintenance}
            onClose={ () => setIsSwitchingToMaintenance(false) }
            title={t("Switch Monitor to Maintenance")}
            description={ selector.monitor.name }
            secondaryActions={ [ { title: t("Cancel"), onAction: () => setIsSwitchingToMaintenance(false) } ] }
            primaryAction={ { title: t("Yes, switch monitor to maintenance"), onAction: execMaintenance } }>
            <div className="p-2">
              <div className="f-s-1.5 f-w-300 t-c-b">
              {t("Are you sure?")}
              </div>
            </div>
          </Modal>
          : null
        }

      </div>
    );
  }
}
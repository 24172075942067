import React from "react";

export const AvatarDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="311"
      height="160"
      fill="none"
      viewBox="0 0 311 160"
    >
      <g clipPath="url(#clip0)">
        <path
          fill="#D0CDE1"
          d="M15.6 149.49l13.35-21.188-13.357 23.497-.007 2.414a51.661 51.661 0 01-2.829-.11l1.09-31.014-.015-.239.024-.046.104-2.931L0 96.651l13.975 21.028.041.62.823-23.432L2.838 70.841 14.933 90.75l.562-48.57.003-.16.002.158.274 38.293L27.242 65.15 15.764 83.77l-.054 20.973 10.64-20.256-10.646 23.339-.029 11.663 15.456-28.204-15.468 32.272-.064 25.933z"
        ></path>
        <path
          fill="#3F3D56"
          d="M203.002 60.057h-3.753v26.65h3.753v-26.65z"
        ></path>
        <path
          fill="#3F3D56"
          d="M64.108 3.341L62.696 6.82l138.902 56.378 1.412-3.478L64.108 3.341z"
        ></path>
        <path
          fill="#D0CDE1"
          d="M310.72 153.018c-5.814 9.713-311.027 8.897-310.564-.001 5.813-9.712 311.026-8.897 310.564.001z"
        ></path>
        <path
          fill="#3F3D56"
          d="M272.443 113.733h-32.656v12.763h32.656v-12.763z"
        ></path>
        <path
          fill="#3F3D56"
          d="M269.44 124.994H108.412v14.264H269.44v-14.264z"
        ></path>
        <path
          fill="#D0CDE1"
          d="M242.978 132.126h-38.662V83.517h28.902l9.76 24.516v24.093z"
        ></path>
        <path
          fill="#fff"
          d="M226.838 86.52h-16.516v16.516h16.516V86.52zM239.224 103.224h-10.51V86.708h4.505l6.005 16.516z"
        ></path>
        <path
          fill="#3F3D56"
          d="M214.826 112.42h-3.754v7.131h3.754v-7.131zM231.905 97.218h.187a3.567 3.567 0 010 7.132h-.187v-7.132zM92.272 124.994h.187a3.566 3.566 0 010 7.132h-.187v-7.132z"
        ></path>
        <path
          fill="#D0CDE1"
          d="M257.992 124.94c5.72 0 10.865 2.771 14.435 7.186h7.336l-7.132-19.866-33.219-6.552.939 26.418h3.206c3.57-4.415 8.715-7.186 14.435-7.186z"
        ></path>
        <path
          fill="#3F3D56"
          d="M258.368 153.146c9.017 0 16.328-7.31 16.328-16.328s-7.311-16.328-16.328-16.328c-9.018 0-16.329 7.31-16.329 16.328s7.311 16.328 16.329 16.328z"
        ></path>
        <path
          fill="#D0CDE1"
          d="M258.367 145.412a8.594 8.594 0 008.594-8.594 8.594 8.594 0 10-17.187 0 8.594 8.594 0 008.593 8.594z"
        ></path>
        <path
          fill="#3F3D56"
          d="M110.852 152.958c7.877 0 14.263-6.386 14.263-14.263 0-7.878-6.386-14.264-14.263-14.264-7.878 0-14.264 6.386-14.264 14.264 0 7.877 6.386 14.263 14.264 14.263z"
        ></path>
        <path
          fill="#D0CDE1"
          d="M110.852 146.202a7.507 7.507 0 100-15.014 7.507 7.507 0 000 15.014z"
        ></path>
        <path
          fill="#3F3D56"
          d="M205.442 76.76h-7.883v56.867h7.883V76.761z"
        ></path>
        <path
          fill="#3F3D56"
          d="M205.442 123.305H92.084v3.378h113.358v-3.378zM155.044 102.07L31.42 135.703l.886 3.26 123.625-33.633-.887-3.26z"
        ></path>
        <path
          fill="#3F3D56"
          d="M146.606 106.168l-2.117 1.555 13.005 17.697 2.117-1.556-13.005-17.696zM234.157 103.974h-3.754v1.502h3.754v-1.502z"
        ></path>
        <path
          fill="#3F3D56"
          d="M201.125 115.235h-49.172a.374.374 0 01-.371-.322.372.372 0 01.265-.413l48.046-14.264a.377.377 0 01.481.33l1.126 14.264a.371.371 0 01-.099.284.376.376 0 01-.276.121zm-46.588-.751h46.182l-1.057-13.396-45.125 13.396zM67.97 98.72h-.003l-10.775-.102a.376.376 0 01-.37-.418l5.6-48.853a.375.375 0 01.372-.332h.002a.376.376 0 01.371.336l5.177 48.953a.377.377 0 01-.373.415zm-10.354-.85l9.937.094-4.774-45.146-5.163 45.053zM275.085 119.22l-.175.066a3.568 3.568 0 01-4.597-2.073 3.564 3.564 0 012.074-4.597l.175-.067 2.523 6.671zM46.764 121.409l-10.47 2.931.465 1.662 10.47-2.931-.465-1.662z"
        ></path>
        <path
          fill="#CB2C2C"
          d="M122.584 100.635l-2.021 1.103-82.102 22.981-1.22-1.514a18.03 18.03 0 01-3.899-9.033c-.31-2.475-.083-5.083 1.644-6.671L31.85 82.212l45.407-12.77 21.537 9.266s8.249-2.465 14.32 1.885c1.922 1.378 3.626 3.439 4.778 6.48l.993 2.868 3.699 10.694z"
        ></path>
        <path
          fill="#3F3D56"
          d="M104.435 115.794c5.815 0 10.528-4.713 10.528-10.527 0-5.815-4.713-10.528-10.528-10.528-5.814 0-10.528 4.713-10.528 10.528 0 5.814 4.714 10.527 10.528 10.527z"
        ></path>
        <path
          fill="#D0CDE1"
          d="M104.435 110.808a5.54 5.54 0 100-11.082 5.54 5.54 0 100 11.082z"
        ></path>
        <path
          fill="#3F3D56"
          d="M54.41 129.797c5.814 0 10.527-4.714 10.527-10.528s-4.713-10.528-10.528-10.528c-5.814 0-10.527 4.714-10.527 10.528s4.713 10.528 10.527 10.528z"
        ></path>
        <path
          fill="#D0CDE1"
          d="M54.41 124.81a5.541 5.541 0 100-11.082 5.541 5.541 0 000 11.082z"
        ></path>
        <path
          fill="#fff"
          d="M91.348 79l-32.575 9.118-2.745-9.806 20.776-5.815L91.347 79zM52.04 79.429l-12.964 3.628 2.745 9.806 12.964-3.629-2.745-9.805z"
        ></path>
        <path
          fill="#3F3D56"
          d="M62.482 94.966l-1.662.465.884 3.157 1.662-.465-.884-3.157zM89.114 84.464l-.465-1.662-3.158.884.465 1.662 3.158-.884z"
        ></path>
        <path
          fill="#3F3D56"
          d="M84.915 77.145l.182-.051a3.607 3.607 0 111.945 6.947l-.183.05-1.944-6.946zM118.885 89.94a5.576 5.576 0 01-5.771-9.347c1.922 1.378 3.626 3.438 4.778 6.48l.993 2.867zM64.495 3.378h-3.754v54.803h3.754V3.378z"
        ></path>
        <path
          fill="#3F3D56"
          d="M62.618 10.51a5.255 5.255 0 100-10.51 5.255 5.255 0 000 10.51zM201.125 66.063a5.254 5.254 0 10.001-10.509 5.254 5.254 0 00-.001 10.509z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H310.72V160H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
import React from "react";

export const AvatarStarting = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="160"
      fill="none"
      viewBox="0 0 200 160"
    >
      <g clipPath="url(#clip0)">
        <path
          fill="#3F3D56"
          d="M7.844 139.942H199.33v2.111H7.844v-2.111zM7.844 157.889H199.33V160H7.844v-2.111z"
        ></path>
        <path
          fill="#3F3D56"
          d="M24.362 141.884c.115-.187 2.813-4.693 3.748-14.049.858-8.583.307-23.052-7.197-43.234-14.215-38.234 3.276-69.083 3.455-69.39l-.863-.5c-.045.077-4.559 7.942-7.225 20.467a89.293 89.293 0 003.698 49.77c14.191 38.17 3.641 56.238 3.533 56.417l.851.519zM29.92 12.966A6.483 6.483 0 1129.92 0a6.483 6.483 0 010 12.966z"
        ></path>
        <path
          fill="#3F3D56"
          d="M9.475 36.902a6.483 6.483 0 110-12.965 6.483 6.483 0 010 12.965z"
        ></path>
        <path
          fill="#AEE5AE"
          d="M23.438 52.86a6.483 6.483 0 110-12.966 6.483 6.483 0 010 12.966zM6.483 66.324a6.483 6.483 0 110-12.966 6.483 6.483 0 010 12.966z"
        ></path>
        <path
          fill="#3F3D56"
          d="M28.424 87.268a6.483 6.483 0 110-12.965 6.483 6.483 0 010 12.965zM20.446 142.123s6.482-15.958-12.966-27.926l12.966 27.926zM28.419 141.833s2.95-16.969 25.785-16.824L28.42 141.833z"
        ></path>
        <path
          fill="#A0616A"
          d="M167.494 99.942c-5.281 0-9.563-4.282-9.563-9.563 0-5.281 4.282-9.563 9.563-9.563 5.281 0 9.563 4.282 9.563 9.563 0 5.281-4.282 9.563-9.563 9.563z"
        ></path>
        <path
          fill="#2F2E41"
          d="M165.761 78.485c1.829-.45 3.796-.524 5.567.114 1.825.657 3.327 2.031 4.432 3.625 1.105 1.594 1.846 3.408 2.511 5.23.388.994.688 2.02.897 3.065a10.506 10.506 0 01-1.905 8.006c.28-.738-.425-1.543-1.198-1.703-.774-.16-1.561.11-2.324.315-1.344.36-2.733.524-4.124.485-.532-.015-1.091-.069-1.534-.365-1.388-.928-.653-3.327-1.808-4.533-.413-.43-1.001-.631-1.532-.901-1.913-.972-3.173-2.933-3.768-4.995-.373-1.291-1.299-5.002-.51-6.203.713-1.085 4.086-1.843 5.296-2.14z"
        ></path>
        <path
          fill="#A0616A"
          d="M141.473 79.835a5.687 5.687 0 014.356.243c2.495 1.17 6.906 4.004 6.738 9.368-.233 7.464-2.799 12.362-2.799 12.362l.467 15.16 2.332 27.522s11.429 9.329 6.997 11.662c-4.431 2.332-11.895-11.196-11.895-11.196l-5.831-15.626-2.099-15.161s-3.498-15.86 0-22.857c0 0-4.042-9.37 1.734-11.477zM113.616 103.907s3.965 16.793-10.029 21.457c-13.994 4.665-32.42 12.595-32.42 12.595l-2.332 3.965-5.364-7.23.932-5.598 9.097-2.565s17.026-13.062 24.49-13.995l-.933-8.629h16.559zM76.344 90.43L71.4 98.542s-16.327 9.33-20.525 15.627c-4.198 6.297-14.227 13.061-14.227 13.061l4.431 10.496s12.595-14.694 17.96-16.093c5.364-1.4 29.154-20.525 29.154-20.525L76.344 90.43z"
        ></path>
        <path
          fill="#AEE5AE"
          d="M120.364 61.999c1.821.286 3.643.572 5.442.973 4.179.932 8.198 2.476 12.12 4.193a141.932 141.932 0 0115.965 8.225c1.386.792 2.71 1.69 3.957 2.687.831.692 1.612 1.485 2.058 2.469.282.687.465 1.412.543 2.15.362 2.594.345 5.27-.358 7.793-.703 2.523-2.128 4.888-4.226 6.455a6.953 6.953 0 01-1.829 1.014c-.884.27-1.799.423-2.722.456-2.943.234-6.017.449-8.741-.69-1.73-.725-3.195-1.95-4.788-2.941-6.743-4.2-15.311-4.022-22.684-6.979-.717-.287-1.447-.623-1.949-1.21a5.283 5.283 0 01-.844-1.676l-2.797-7.74c-.59-1.636-1.181-3.272-1.677-4.938-.519-1.743-.932-3.515-1.345-5.285-.355-1.524-.721-3.12-.585-4.695.202-2.324.739-2.418 2.873-2.082 3.862.609 7.725 1.216 11.587 1.82z"
        ></path>
        <path
          fill="#A0616A"
          d="M148.47 79.835a5.687 5.687 0 014.356.243c2.495 1.17 6.906 4.004 6.738 9.368-.233 7.464-2.799 12.362-2.799 12.362l.467 15.16 2.332 27.522s11.429 9.329 6.997 11.662c-4.431 2.332-11.895-11.196-11.895-11.196l-5.831-15.626-2.099-15.161s-3.498-15.86 0-22.857c0 0-4.041-9.37 1.734-11.477z"
        ></path>
        <path
          fill="#2F2E41"
          d="M107.981 60.068s-20.72-1.876-19.787 10.952c.932 12.828 7.93 34.986 7.93 34.986l17.959.933s2.332-15.16-.933-20.292l-5.169-26.579z"
        ></path>
        <path
          fill="#2F2E41"
          d="M93.558 64.257s-5.131 3.265-7.93 9.795c-2.799 6.531-11.195 19.36-11.195 19.36l13.76 9.795 10.263-10.496-4.898-28.454zM68.835 137.026s4.431 1.633 1.4 4.898a29.525 29.525 0 00-4.665 6.764s.933 6.064-3.032 5.831c-3.965-.233-5.598-8.397-4.665-11.195.933-2.799 2.1-16.094 2.1-16.094s8.162.7 7.93 1.866c-.234 1.166-1.4 6.997.932 7.93zM41.08 132.362s4.198 2.099 3.032 3.965c-1.166 1.865-4.898 7.23-4.898 7.23s1.4 6.997-2.566 7.697c-3.965.699-6.297-8.397-5.597-10.263.7-1.866.7-9.096.7-9.096s-.234-7.23 2.099-7.23c2.332 0 6.297.933 6.297.933s-.933 6.53.933 6.764z"
        ></path>
        <path
          fill="#AEE5AE"
          d="M170.176 144.11h6.764v11.804h-6.764V144.11z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path
            fill="#fff"
            d="M0 0H199.331V160H0z"
            transform="matrix(-1 0 0 1 199.331 0)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}
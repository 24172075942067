import React from 'react';

export type ApdexProps = {
  apdexIndex: number
}

export const Apdex: React.FC<ApdexProps> = (props) => {

  return (
    <div className="w-120px fb-d-c">
      <div className="fb-a f-s-1 f-w-600 t-a-c" >Apdex</div>
      <div className="fb-a apdexBar"></div>
      <div className="d-f fb-n t-c-nb w-14px" style={{position: `relative`, left: `${props.apdexIndex*100 }%`, marginLeft: `-8px`}}>
        <svg 
          width="14"
          height="9"
          viewBox="0 0 14 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path 
            d="M12.1029 0L6.85714 5.23429L1.61143 0L0 1.61143L6.85714 8.46857L13.7143 1.61143L12.1029 0Z"
            fill="currentColor"
          />
        </svg>
      </div>
      <div className="d-f j-c-c w-80px m-t-0 p-t-0" style={{position: `relative`, left: `${props.apdexIndex*100 }%`, marginLeft: `-40px`}}>
        <div>{props.apdexIndex.toFixed(2)}</div>
      </div>
    </div>
  );
}

import React, { useState, useCallback, createRef, useMemo, useRef } from 'react';
import { BaseInputOnBlurEvent, BaseInputOnSelectEvent, BaseInputOnFocusEvent } from '../event/BaseInputEvent';
import { Popover } from '../popover/Popover';


export class SelectInputItem {
  constructor(
    public text: string,
    public data: any = undefined,
    public disabled: boolean = false) {}
}

type Alignment = 'left' | 'center' | 'right';

type SelectInputProps = {
  label?: string
  autoFocus?: boolean
  placeholder?: string
  align?: Alignment
  error?: string | string[]
  disabled?: boolean
  options?: SelectInputItem[]
  indexSelected?: number
  onSelect?: (event: BaseInputOnSelectEvent) => void
  onBlur?: (event: BaseInputOnBlurEvent) => void
  onFocus?: (event: BaseInputOnFocusEvent) => void
  suffix?: React.ReactNode,
  prefix?: React.ReactNode
}

export const SelectInput: React.FC<SelectInputProps> = (props) => {

  const [ active, setActive ] = useState(false);
  const [ focusedIndex, setFocusedIndex ] = useState(-1);
  const [ dirty, setDirty ] = useState(false);
  const [ touched, setTouched ] = useState(false);
  const options = useMemo(() => props.options, [props.options]);

  const refInput = useRef<HTMLInputElement>(null);
  const refOptionsContainer = useRef<HTMLInputElement>(null);
  const refOptions = useMemo(
    () => (options || []).map(() => createRef<HTMLDivElement>()), [options]);

  let alignClass: string;

  switch (props.align) {
    case "center":
      alignClass = "t-a-c";
      break;
    case "right":
      alignClass = "t-a-r";
      break;
    case "left":
    default:
      alignClass = "t-a-l"
  }

  const select = useCallback((index: number) => {
    if (options && !options[index].disabled) {
      const dirty = true;
      setDirty(true);
      setActive(false);
      setFocusedIndex(-1);
      if (props.onSelect && index > -1) {
        props.onSelect(new BaseInputOnSelectEvent(index, dirty, touched));
      }
      refInput.current?.focus();
    }
  }, [options, props, touched]);

  const onBlur = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if ((event.nativeEvent as FocusEvent).relatedTarget === refOptionsContainer.current) {
      select(focusedIndex);
    } else if (props.onBlur) {
      const touched = true;
      setActive(false);
      setTouched(touched);
      setFocusedIndex(-1);
      props.onBlur(new BaseInputOnBlurEvent(event.currentTarget.value, dirty, touched));
    }
  }, [props, select, focusedIndex, dirty]);


  const onFocus = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.select();
    if (props.onFocus) {
      props.onFocus(new BaseInputOnFocusEvent(event.currentTarget.value, dirty, touched));
    }
  }, [dirty, props, touched]);


  const onOptionKeydown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      let index = -1;
      if (options && options.length > 0) {
        setActive(true);

        switch (event.key) {
          case "ArrowDown":
            if (options.length > focusedIndex + 1) {
              index = focusedIndex + 1;
            } else {
              index = 0;
            }
            break;
          case "ArrowUp":
            if (focusedIndex - 1 >= 0) {
              index = focusedIndex - 1;
            } else {
              index = options.length - 1;
            }
        }
      }
      setFocusedIndex(index);
    } else if (event.key === "Enter" && focusedIndex >= 0) {
      event.preventDefault();
      select(focusedIndex);
    }
  }, [options, focusedIndex, select]);



  const renderError = (error: string, index: number) => {
    return <div key={index} className="t-c-r f-s-1 m-t-0.5">{ error }</div>
  }

  const renderErrors = (error?: string | string[]) => {
    if (error) {
      if (typeof error === "string") {
        return renderError(error, 0);
      } else {
        return (error as string[]).map((e, i) => renderError(e, i));
      }
    } else {
      return null;
    }
  }

  const inputText = (
    <div className="d-f a-i-c w-100% h-100%">
      <input
        ref={refInput}
        autoComplete="off"
        autoCorrect="off"
        autoFocus={props.autoFocus}
        value={props.indexSelected !== undefined && props.options ? props.options[props.indexSelected].text : ""}
        placeholder={props.placeholder}
        onKeyDown={onOptionKeydown}
        onBlur={ onBlur }
        onFocus={ onFocus }
        onClick={ () => setActive(true) }
        disabled={props.disabled}
        readOnly={true}
        className={`fb-a p-l-1 f-s-1 h-100% b-w-0px b-s-s t-c-nb ${alignClass}`} />
      <div
        className="fb-n p-r-1 t-c-nb c-p p-t-4px"
        onClick={() => { refInput.current?.focus(); setActive(true); }}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.59 8.59L12 13.17L7.41 8.59L6 10L12 16L18 10L16.59 8.59Z" fill="currentColor"/>
        </svg>
      </div>
    </div>
  )

  return (
    <div className={`d-f fb-d-c ${props.error ? "input-error" : ""}`}>
      {props.label ?
        <div className="f-s-1 f-s-1.25-M m-b-0.5">{ props.label }</div>
        : null
      }
      <div className={`d-f a-i-c b-s-s b-c-gray b-w-1px h-3 ${props.error ? 'b-c-r' : null}`}>
        {props.prefix}

        <Popover
          active={active}
          activator={inputText}
          useActivatorWidth={true}
          placement="bottom-start">
          {options ?
            <div
              tabIndex={0}
              ref={refOptionsContainer} className="bg-c-nw t-c-b p-v-0.5 m-v-1px w-100% s-drop max-h-14 of-y-a">
              {options.map((o, i) => {
                const focused = i === focusedIndex;

                let conditionalClasses = "";
                if (o.disabled) {
                  if (focused) {
                    conditionalClasses = "t-c-gray bg-c-lg"
                  } else {
                    conditionalClasses = "t-c-gray"
                  }
                } else if (focused) {
                  conditionalClasses = "bg-c-g t-c-w"
                } else if (props.indexSelected === i) {
                  conditionalClasses = "t-c-g"
                } else {
                  conditionalClasses = "t-c-nb"
                }
                return (
                  <div
                    ref={refOptions[i]}
                    key={i}
                    title={o.text}
                    onPointerMoveCapture={() => setFocusedIndex(i)}
                    onClick={() => select(i)}
                    className={`d-f a-i-c ${conditionalClasses} p-v-0.5 p-h-1 ws-n of-h t-o-e c-p`}>
                    <div className="fb-a of-h t-o-e ws-n p-r-0.5">
                      {o.text}
                    </div>
                    { props.indexSelected === i ?
                      <div className={`fb-n ${conditionalClasses}`}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.00002 16.2L4.80002 12L3.40002 13.4L9.00002 19L21 7L19.6 5.6L9.00002 16.2Z" fill="currentColor"/>
                        </svg>
                      </div>
                    : null
                    }
                  </div>
                )
              })}
            </div>
          : null
          }
        </Popover>

        {props.suffix}
      </div>
      { renderErrors(props.error) }
    </div>
  );
}
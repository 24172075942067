import React from "react";
import { getCheckBoxZise } from "../Helper";


type NewTabProps = {
  color?: string
  size?: string
}

export const NewTabIcon: React.FC<NewTabProps> = (props) => {
  const color = props.color ? props.color : "#979797"
  const size = getCheckBoxZise(props.size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M19 19H5V5h7V3H5a2 2 0 00-2 2v14a2 2 0 002 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"
      ></path>
    </svg>
  );
}

import React, { useEffect, useState } from "react";
import { Monitor, AverageRt } from "../model";
import { formatResponseTime } from "../Helper";
import { useRecoilValue } from "recoil";
import { regionsState, teamState } from "../atoms";

type ResponseTimeRegionsProps = {
  monitor: Monitor,
  monitorRegionAverageRt: AverageRt[],
}

export const ResponseTime: React.FC<ResponseTimeRegionsProps> = (props) => {

  const regions = useRecoilValue(regionsState);
  const team = useRecoilValue(teamState);

  const [ slower, setSlower ] = useState(0);
  const [ monitorRegionAverageRtByDay, setMonitorRegionAverageRtByDay ] = useState<number[]>()

  useEffect(() => {

    const monitorRegionAverageRtByDay = [0,0,0,0,0,0,0,0,0,0];
    for (const martHour of props.monitorRegionAverageRt) {
      for (let i = 0; i < martHour.rt.length; i++) {
        monitorRegionAverageRtByDay[i] += martHour.rt[i];
      }
    }

    if (props.monitorRegionAverageRt.length > 0) {
      for (let i = 0; i < monitorRegionAverageRtByDay.length; i++) {
        monitorRegionAverageRtByDay[i] = monitorRegionAverageRtByDay[i] / props.monitorRegionAverageRt.length;
      }
    }

    const monitorAverageRt = monitorRegionAverageRtByDay.reduce((t,v) => t + v);

    setSlower(monitorAverageRt);
    setMonitorRegionAverageRtByDay(monitorRegionAverageRtByDay);
  }, [props.monitorRegionAverageRt]);

  if (!team || !regions || !monitorRegionAverageRtByDay) {
    return null;
  } else {

    return (
      <div className="d-f fb-d-c">
        <div className="d-f fb-d-c fb-d-r-M m-v-2 t-c-nb">
          <div className="d-f fb-a w-100% j-c-c j-c-s-M p-l-2 p-l-0-M">
            {renderChart(slower, monitorRegionAverageRtByDay)}
          </div>
        </div>
      </div>
    );
  }
}

function renderChart(
  slower: number,
  rtDay: number[]) {

// Rt [10]float64
// [0] dns_start
// [1] dns_done
// [2] connect_start
// [3] connect_done
// [4] tls_handshake_start
// [5] tls_handshake_done
// [6] got_connection
// [7] wrote_headers
// [8] wrote_request
// [9] ttfb

  let dnsStartDay = 0;
  let dnsDoneDay = 0;
  let connectStartDay = 0;
  let connectDoneDay = 0;
  let tlsHandshakeStartDay = 0;
  let tlsHandshakeDoneDay = 0;
  let gotConnectionDay = 0;
  let wroteHeadersDay = 0;
  let wroteRequestDay = 0;
  let ttfbDay = 0;
  
  dnsStartDay += rtDay[0];
  dnsDoneDay += rtDay[1];
  connectStartDay += rtDay[2];
  connectDoneDay += rtDay[3];
  tlsHandshakeStartDay += rtDay[4];
  tlsHandshakeDoneDay += rtDay[5];
  gotConnectionDay += rtDay[6];
  wroteHeadersDay += rtDay[7];
  wroteRequestDay += rtDay[8];
  ttfbDay += rtDay[9];

  const namelookup = Math.round(dnsStartDay + dnsDoneDay);
  const connection = Math.round(connectStartDay + connectDoneDay);
  const tlsHandshake = Math.round(tlsHandshakeStartDay + tlsHandshakeDoneDay);
  const response = Math.round(gotConnectionDay + wroteHeadersDay + wroteRequestDay + ttfbDay);
  const responseTimeTotal = namelookup + connection + tlsHandshake + response

  if (responseTimeTotal === 0.0) {
    return (
      <div className="w-100% f-s-0.75 f-w-400 p-l-0.5-M" style={{color: "#000"}}>
        No Data
      </div>
    )
  } else {

    let barSize = ((responseTimeTotal / slower));
    if (barSize < 0.01) {
      barSize = 0.01;
    }
    return (
      <div className="d-f j-c-s a-i-c w-100%">
        <div className="d-f" style={{width: `calc((100% - 6rem) * ${barSize})`}}>
          <div
            className="bg-c-rt2 d-f h-2 j-c-c a-i-c t-a-c t-c-w f-s-1 f-w-600 min-w-1.5 of-h"
            style={{flex: `${connection}`, lineHeight: `1` }} title={formatResponseTime(connection)} >{ formatResponseTime(connection) }</div>
          <div
            className="bg-c-rt3 d-f h-2 j-c-c a-i-c t-a-c t-c-w f-s-1 f-w-600 min-w-1.5 of-h"
            style={{flex: `${tlsHandshake}`, lineHeight: `1` }} title={formatResponseTime(tlsHandshake)} >{ formatResponseTime(tlsHandshake) }</div>
          <div
            className="bg-c-rt4 d-f h-2 j-c-c a-i-c t-a-c t-c-w f-s-1 f-w-600 min-w-1.5 of-h"
            style={{flex: `${response}`, lineHeight: `1` }} title={formatResponseTime(response)} >{ formatResponseTime(response) }</div>
        </div>
        <div className="w-6 f-s-1 f-w-600 p-l-0.5" style={{color: "#000"}}>{ formatResponseTime(responseTimeTotal) }</div>
      </div>
    );
  }

}
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Page, UpdatePageReq } from '../model';
import axios from 'axios';
import { Loading, Modal } from '../ui';
import { apiBaseUrl } from "../Consts";
import { PageForm } from '../page-form';
import { Errors } from '../validator/Validator';
import { serialize } from 'object-to-formdata';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { messageState, teamState } from '../atoms';
import { useHandleRequestError } from '../hooks';

type PageEditProps = {
  pageUuid?: string,
  onUpdate: (page: Page) => void
  onCancel: () => void
  onFetch?: (page: Page) => void
}

export const PageEdit: React.FC<PageEditProps> = (props) => {

  const { t } = useTranslation();
  const team = useRecoilValue(teamState);
  const { handleRequestError, handleRequestErrorAvoidingPopup } = useHandleRequestError()

  const setMessage = useSetRecoilState(messageState);
  const [ page, setPage ] = useState<Page>();
  const pageUuid = useMemo(() => props.pageUuid, [props.pageUuid])
  const onFetch = useMemo(() => props.onFetch, [props.onFetch])
  const onParentCancel = useMemo(() => props.onCancel, [props.onCancel])
  const [ errorsOnServer, setErrorsOnServer ] = useState<Errors<Page>>()
  const [ isSaving, setIsSaving ] = useState(false);

  const fetch = useCallback(() => {
    if (team && pageUuid) {
      axios.get(`${apiBaseUrl}/${team.uuid}/pages/${pageUuid}`)
        .then(response => {
          const page = response.data.page as Page;
          setPage(page);
          if (onFetch) {
            onFetch(page);
          }
        })
        .catch(err => {
          onParentCancel();
          handleRequestError(err)
        });
    }
  }, [team, pageUuid, onFetch, handleRequestError, onParentCancel]);


  const onSave = useCallback((page: Page, logoFile?: File, removeLogo: boolean = false, faviconFile?: File, removeFavicon: boolean = false) => {
    if (team) {
      const req = new UpdatePageReq();
      req.name = page.name;
      req.title = page.title;
      req.slug = page.slug;
      req.customDomain = page.customDomain;
      req.description = page.description;
      req.useGroups = page.useGroups;
      req.statuses = page.statuses.map(s => s.uuid);
      req.googleAnalyticsId = page.googleAnalyticsId;
      req.removeLogo = removeLogo;
      req.removeFavicon = removeFavicon;
      setIsSaving(true);

      const data = serialize(req);
      if (logoFile && !req.removeLogo) {
        data.append("logoFile", logoFile);
      }

      if (faviconFile && !req.removeFavicon) {
        data.append("faviconFile", faviconFile);
      }

      axios.patch(`${apiBaseUrl}/${team.uuid}/pages/${page.uuid}`, data)
        .then(response => {

          if (response.data.checkoutUrl) {
            window.location.href = response.data.checkoutUrl;
            return
          } else {
            props.onUpdate(response.data.page as Page);
          }
          if (response.data.checkoutError) {
            setMessage({
              type: "error",
              text: response.data.checkoutError
            })
          }
        })
        .catch(err => {
          handleRequestErrorAvoidingPopup(err);
          setErrorsOnServer(err.response.data);
        })
      .finally(() => setIsSaving(false));
    }
  }, [team, props, handleRequestErrorAvoidingPopup]);

  const onCancel = useCallback(() => {
    props.onCancel();
  }, [props])

  useEffect(() => fetch(), [fetch])

  if (pageUuid !== undefined) {
    return (
      <Modal
        open={true}
        onClose={ () => props.onCancel() }
        title={t("Edit Page")}
        description={ page?.name }
        noPadding={true}>
        <div className="p-h-1 p-h-2-M m-t-1 m-t-2-M">
          { !page ?
            <Loading />
          :
            <PageForm
              errorsOnServer={errorsOnServer}
              onSave={onSave}
              onCancel={onCancel}
              page={page}
              isSaving={isSaving} />
          }
        </div>
      </Modal>
    );
  } else {
    return null;
  }
}

import React from "react";
import { sizeFlag } from "../Helper";

type FlagSGProps = {
  size?: string
}

export const FlagSG: React.FC<FlagSGProps> = (props) => {
  const size = sizeFlag(props.size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size.width}
      height={size.height}
      fill="none"
      viewBox="0 0 24 16"
    >
      <g clipPath="url(#clip0)">
        <path
          fill="#fff"
          stroke="#E6E6E6"
          d="M.463.5h23.075v15H.463V.5z"
        ></path>
        <path
          fill="#DF0000"
          fillRule="evenodd"
          d="M-.037 0h24.075v8H-.037V0z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M5.475 1.34a3.245 3.245 0 00-1.802.988 2.624 2.624 0 00-.698 1.776 2.63 2.63 0 00.718 1.77c.46.5 1.1.843 1.812.973a3.613 3.613 0 01-1.257.095 3.517 3.517 0 01-1.201-.34 3.16 3.16 0 01-.963-.725 2.766 2.766 0 01-.576-.997 2.587 2.587 0 01.285-2.172c.415-.663 1.108-1.152 1.927-1.361a3.456 3.456 0 011.759-.007h-.004z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M4.988 3.667l.183.5-.487-.307-.48.313.176-.506-.48-.31.596-.007.188-.5.187.5h.593l-.476.317zM5.644 5.4l.187.503-.487-.306-.484.31.18-.504-.48-.313.596-.003.184-.504.188.5h.6l-.484.317zm2.194-.013l.183.506-.487-.31-.48.31.176-.503-.48-.31.596-.007.188-.5.187.5h.593l-.476.314zm.652-1.72l.184.503-.488-.307-.48.31.18-.503-.483-.313.6-.004.18-.503.187.5h.6l-.48.317zM6.754 2.523l.187.507-.487-.31-.484.313.18-.506-.48-.314.593-.003.187-.503.188.5h.6l-.484.316z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H24V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
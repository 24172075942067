import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem, Popover } from '..';
import { SortAscIcon, SortDescIcon } from '../../images';

type SortResourceProps = {
  options: {[name: string]: string}
  order: string
  desc: boolean
  disabled?: boolean,
  onChange: (order: string, desc: boolean) => void
}

export const SortResource: React.FC<SortResourceProps> = (props) => {

  const { t } = useTranslation();
  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => {
    setActive(!active)
  }, [active]);

  const activator = (
    <div
      onClick={!props.disabled ? toggleActive : undefined}
      className={`d-f j-c-c a-i-c f-s-1 ${props.disabled ? "t-c-lightestGray c-d" : "c-p t-c-g"}`}>
      <div
        className="h-100% p-r-0.5">{props.options[props.order]}
      </div>
      <div className="d-f a-i-c">
        {props.desc ? <SortDescIcon /> : <SortAscIcon />}
      </div>
    </div>
  )

  const items = useMemo(() =>
    [
      ...Object.keys(props.options).map(k => {
        return {
          title: props.options[k],
          selected: props.order === k,
          onClick: () => {
            setActive(false);
            props.onChange(k, props.desc);
          }
        } as MenuItem
      }),
      {
        separator: true
      },
      {
        title: t("Ascending"),
        selected: !props.desc,
        onClick: () => {
          setActive(false);
          props.onChange(props.order, false)
        }
      },
      {
        title: t("Descending"),
        selected: props.desc,
        onClick: () => {
          setActive(false);
          props.onChange(props.order, true)
        }
      }
    ] as MenuItem[], [props, t]);

  if (items) {
    return (
      <Popover
        active={active}
        activator={activator}
        placement="bottom-end"
        onClose={toggleActive}>
        <Menu items={items} />
      </Popover>
    );
  } else {
    return null;
  }
}

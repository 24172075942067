import React, { useCallback, useMemo, useState } from 'react';
import { BaseInputOnChangeEvent, BaseInputOnBlurEvent } from '../event/BaseInputEvent';
import { CheckMark, MiddleCheck } from '../images/'

export enum MultiSelectState {
  All = "all",
  Partially = "partially",
  None = "none"
}

type SizeType = "small" | "medium" | "large";

type MultiSelectForStatusProps = {
  state: MultiSelectState
  onChange?: (event: BaseInputOnChangeEvent) => void
  onBlur?: (event: BaseInputOnBlurEvent) => void
  circle?: boolean
  size?: SizeType;
}

export const MultiSelectForStatus: React.FC<MultiSelectForStatusProps> = (props) => {

  const [ dirty, setDirty ] = useState(false);
  const [ touched, setTouched ] = useState(false);

  const size = useMemo<SizeType>(() =>
    props.size === undefined ? "large" : props.size, [props.size]);

  const onChange = useCallback(() => {
    const state = props.state !== MultiSelectState.None ? MultiSelectState.None : MultiSelectState.All;
    const dirty = true;
    setDirty(dirty);
    if (props.onChange) {
      props.onChange(new BaseInputOnChangeEvent(state, dirty, touched))
    }
  }, [props, touched]);

  const onBlur = useCallback(() => {
    setTouched(true);
    if (props.onBlur) {
      props.onBlur(new BaseInputOnBlurEvent(props.state || false, dirty, true));
    }
  }, [props, dirty]);

  return (
    <div
      tabIndex={0}
      onClick={onChange}
      onBlur={onBlur}
      className="d-f a-i-c c-p">
      <div className={`d-f t-c-w a-i-c j-c-c b-s-s b-w-1px b-c-gray ${props.circle ? "b-r-50%" : "b-r-20%"} ${size === "small" ? "h-1 w-1" : props.size === "medium" ? "h-1.5 w-1.5" : "h-2 w-2"} ${props.state !== MultiSelectState.None ? "bg-c-gray" : "bg-c-w" }`}>
        { props.state === MultiSelectState.All ?
          <CheckMark />
        : props.state === MultiSelectState.Partially ?
          <MiddleCheck />
        : null
        }
      </div>
    </div>
  );
}

import React, { useCallback, useEffect } from 'react';
import axios from 'axios';
import { Information } from './Information';
import { ChangeEmail } from './ChangeEmail';
import { ChangePassword } from './ChangePassword';
import { TimeZone, User } from '../model';
import { apiBaseUrl } from '../Consts';
import { Loading } from '../ui';
import { useHandleRequestError } from '../hooks';
import { DeleteAccount } from './DeleteAccount';
import { useRecoilState } from 'recoil';
import { timeZonesState, userState } from '../atoms';


export const Profile: React.FC = () => {
  const [ user, setUser ] = useRecoilState(userState);
  const [ timeZones, setTimeZones ] = useRecoilState(timeZonesState);
  const { handleRequestError } = useHandleRequestError();

  const fetch = useCallback(() => {
    axios.get(`${apiBaseUrl}/bootstrap/session-user`)
      .then(response => {
        const user = response.data.user as User;
        setUser(user);

        const timeZones = response.data.timeZones as TimeZone[];
        setTimeZones(timeZones);
      })
      .catch(err => handleRequestError(err))
  }, [handleRequestError, setTimeZones, setUser]);

  useEffect(() => fetch(), [fetch]);

  if (!user || !timeZones) {
    return <Loading />
  }

  return (
    <div className="d-f fb-d-c m-h-a w-100% max-w-44.5-M max-w-49-L p-h-1 p-h-2-M t-c-nb p-b-5">
      <Information />
      <ChangeEmail />
      <ChangePassword />
      <DeleteAccount />
    </div>
  )
}
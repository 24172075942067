import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Loading } from '../ui/loading/Loading';
import { useHistory, Redirect } from 'react-router-dom';
import { InitializeTeamReq, ValidateTeamSlugReq } from '../model';
import { TextInput, Button, BaseInputOnChangeEvent, Spinner } from '../ui';
import { Validations, useValidator } from '../validator';
import { isFieldStringEmpty, isFieldSlugNotMatching } from '../Validations';
import { useDebounce } from '../hooks/UseDebounce';
import { apiBaseUrl } from "../Consts";
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { invitationsState, teamsState } from '../atoms';
import { useHandleRequestError } from '../hooks';

const v = new Validations(new InitializeTeamReq());
v.addField("name", (v: any) => isFieldStringEmpty(v));
v.addField("slug", (v: any) => isFieldStringEmpty(v) || isFieldSlugNotMatching(v));

export const WorkspaceInitialize: React.FC = () => {

  const { t } = useTranslation();
  const history = useHistory();
  const teams = useRecoilValue(teamsState);
  const invitations = useRecoilValue(invitationsState);
  const { handleRequestError } = useHandleRequestError();

  const [ isEdited, setIsEdited ] = useState(false);
  const [ updatingTeam, setUpdatingTeam ] = useState<InitializeTeamReq>();
  const [ slugTerm, setSlugTerm ] = useState<string>();
  const debouncedSlugTerm = useDebounce(slugTerm, 500);
  const { errors, validateField, validateAll, resetErrorField, resetErrorAll, resetErrorAllFromServer } = useValidator(v);
  const [ isSaving, setIsSaving ] = useState(false);

  const onChangeField = useCallback(
    (fieldName: keyof InitializeTeamReq, event: BaseInputOnChangeEvent) => {
      let value = event.value;
      if (fieldName === "slug") {
        value = (value as string).toLowerCase();
      }
      if (event.dirtyAndTouched) {
        validateField(fieldName, event.value);

        if (fieldName === "slug") {
          setSlugTerm(value as string);
        }
      }
      const _updatingTeam = { ...updatingTeam } as InitializeTeamReq;
      _updatingTeam[fieldName] = value as never;

      setUpdatingTeam(_updatingTeam);
      setIsEdited(true);

    }, [updatingTeam, validateField])

  const onBlurField = useCallback(
    (fieldName: keyof InitializeTeamReq, event: BaseInputOnChangeEvent) => {
      if (event.dirty) {
        // Validating slug only with remote validation avoids clear the slug error message
        // when the error is because the slug already exists, since this validation only
        // is validated on the server, validating on the client removes this error
        if (fieldName === "slug") {
          setSlugTerm(event.value as string);
        } else {
          validateField(fieldName, event.value);
        }
      }
    }, [validateField])

  const save = useCallback(() => {
    setIsSaving(true);
    if (updatingTeam && teams && validateAll(updatingTeam)) {
      axios.patch(`${apiBaseUrl}/${teams[0].uuid}/initialize`, updatingTeam)
        .then((response) => window.location.href = `/${response.data.slug}`)
        .catch(err => {
          handleRequestError(err);
          resetErrorAllFromServer(err.response.data)
        })
        .finally(() => setIsSaving(false));
    }
  }, [updatingTeam, teams, validateAll, resetErrorAllFromServer, handleRequestError]);

  const reset = useCallback(() => {
    if (teams && teams.length === 1) {
      const t = new InitializeTeamReq();
      t.name = teams[0].name;
      t.slug = teams[0].slug;

      setUpdatingTeam(t);
      setIsEdited(false);
      resetErrorAll();
    }
  }, [teams, resetErrorAll]);

  useEffect(
    () => {
      if (debouncedSlugTerm) {
        if (teams && teams) {
          const req = new ValidateTeamSlugReq();
          req.slug = debouncedSlugTerm;
          axios.get(`${apiBaseUrl}/${teams[0].uuid}/validate-slug`, { params: req })
            .then(() => resetErrorField("slug"))
            .catch(err => {
              handleRequestError(err);
              resetErrorField("slug", err.response.data.slug)
            });
        }
      }
    },
    [debouncedSlugTerm, resetErrorField, teams, handleRequestError] // Only call effect if debounced search term changes
  );


  useEffect(() => reset(), [reset]);

  if (!teams || !invitations) {
    return <Loading />
  } else if (teams.length > 1 || invitations.length > 0) {
    return <Redirect to="/" />
  } else if (!updatingTeam) {
    return <Loading />
  } else {
    return (
      <form
        onSubmit={(e) => { e.preventDefault(); save() } }
        className="d-f fb-d-c m-h-a w-100% max-w-44.5-M max-w-49-L p-h-1 p-h-2-M t-c-nb">
        <div className="f-w-300 f-s-1.5 f-s-2-M m-t-1.5">{t("Workspace")}</div>

        <div className="d-f fb-d-c m-t-1 m-t-2-M">
          <TextInput
            autoFocus={true}
            label={t("Name")}
            maxLength={50}
            value={updatingTeam.name}
            placeholder={t("Enter Workspace Name")}
            onChange={e => onChangeField("name", e)}
            onBlur={e => onBlurField("name", e)}
            error={errors.fields.name}
            type="text" />
        </div>

        <div className="d-f j-c-e m-t-1 m-t-2-M">
          {isEdited ?
            <React.Fragment>
              <div className="m-r-1">
                <Button onClick={reset}>{t("Reset")}</Button>
              </div>
              <Button submit={true} type="primary">{t("Save")}</Button>
            </React.Fragment>
            :
            <React.Fragment>
              <div className="fb-a d-f j-c-e a-i-c m-r-1 m-r-1.5-M t-a-r">  
                <Trans t={t} i18nKey="You can change this<1/>information later">
                  You can change this <br className="d-n-M" />
                  information later
                </Trans>
              </div>
              { isSaving ?
                <Spinner type={"primary"} />
              :
                <Button type="primary" onClick={() => history.push(`/${updatingTeam.slug}`)}>{t("Looks Good")}</Button>
              }
            </React.Fragment>
          }
        </div>
      </form>
    )
  }
}
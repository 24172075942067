import React, { useMemo } from "react";

type AvatarNewProps = {
  title?: string,
  imageUrl?: string,
  title2?: string,
  imageUrl2?: string
}

const extractInitials = (title: string): string => {
  const words = title.split(" ") as string[];
  let initials = "";
  for (let i = 0; i < words.length; i++) {
    if (i === 2) {
      break;
    }
    initials += words[i].charAt(0).toUpperCase();
  }
  return initials;
}

export const AvatarNew: React.FC<AvatarNewProps> = (props) => { 

  const initials1 = useMemo(
    () => !props.imageUrl && props.title ? extractInitials(props.title) : undefined,
    [props.title, props.imageUrl])

  const initials2 = useMemo(
    () => !props.imageUrl2 && props.title2 ? extractInitials(props.title2) : undefined,
    [props.title2, props.imageUrl2]);

  return (
    <div className="pos-re">
      <div
        className="d-f b-r-1 a-i-c j-c-c h-2 w-2 p-0 b-s-s b-w-1px bg-c-g bg-s-c t-c-w f-s-0.75"
        style={{backgroundImage: props.imageUrl ? `url(${props.imageUrl})` : undefined}}>
        {initials1 ? initials1 : null}
      </div>
      {props.imageUrl2 || props.title2 ?
        <div
          className="pos-a d-f b-r-1 a-i-c j-c-c t-a-c h-1.25 w-1.25 p-0 b-s-s b-w-1px bg-c-gray bg-s-c t-c-w f-w-600 f-s-0.6"
          style={{
            bottom: "-0.5rem",
            right: "-0.25rem",
            backgroundImage: props.imageUrl2 ? `url(${props.imageUrl2})` : undefined
          }}>
          {initials2 ? initials2 : null}
        </div>
      : null}
    </div>
  );
}
import React from 'react';

export const StartImageSmall = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="328"
      height="259"
      fill="none"
      viewBox="0 0 328 259"
    >
      <g clipPath="url(#clip0)">
        <path
          fill="#F2F2F2"
          d="M201.425 229.297l-8.753 8.059 6.956-11.673c-5.48-9.939-14.444-18.529-14.444-18.529s-18.598 17.818-18.598 31.823c0 14.006 8.327 18.896 18.598 18.896 10.27 0 18.597-4.89 18.597-18.896 0-3.117-.923-6.423-2.356-9.68z"
        ></path>
        <path
          fill="#fff"
          d="M190.421 237.793v.69c-.041 8.025-1.395 14.286-4.05 18.694-.038.065-.079.127-.116.192l-.298-.181-.283-.178c2.946-4.761 3.988-11.49 4.025-18.49.004-.225.007-.454.004-.683a92.922 92.922 0 00-.482-8.872c-.02-.226-.044-.455-.068-.684a137.446 137.446 0 00-1.579-10.7c-.041-.226-.086-.452-.13-.674-1.029-5.393-2.078-9.299-2.317-10.164l-.048-.178.325-.092.003-.003.328-.092c.003.013.062.208.157.57.366 1.354 1.316 5.028 2.249 9.874.041.219.085.444.126.67.486 2.587.961 5.478 1.337 8.513.095.762.181 1.514.256 2.256.027.229.051.458.072.683.314 3.148.477 6.097.489 8.849z"
        ></path>
        <path
          fill="#fff"
          d="M188.141 216.822a18.816 18.816 0 01-2.625.184 18.345 18.345 0 01-7.929-1.79l-.434.546a19.013 19.013 0 008.363 1.928c.688 0 1.374-.037 2.058-.109.232-.024.465-.055.694-.089a18.858 18.858 0 005.451-1.651l-.431-.554a18.276 18.276 0 01-5.147 1.535zM189.86 228.261c-.236.013-.472.02-.707.02-.072.004-.147.004-.219.004a18.44 18.44 0 01-15.134-7.905c-.136.201-.273.403-.406.608a19.114 19.114 0 0015.54 7.98c.096 0 .191 0 .287-.003a19.024 19.024 0 0010.844-3.575 60.235 60.235 0 00-.331-.605 18.31 18.31 0 01-9.874 3.476z"
        ></path>
        <path
          fill="#fff"
          d="M190.421 237.793a19.728 19.728 0 01-1.487.061 18.49 18.49 0 01-17.724-13.301c-.154.276-.308.55-.455.827a19.175 19.175 0 006.962 9.518 19.172 19.172 0 0011.217 3.64c.256 0 .512-.004.765-.018.243-.006.482-.02.722-.037a19.108 19.108 0 0012.611-6.155c-.072-.253-.154-.503-.236-.756a18.43 18.43 0 01-12.375 6.221z"
        ></path>
        <path
          fill="#E4E4E4"
          d="M44.863 91.1c24.778 0 44.863-20.086 44.863-44.863S69.641 1.374 44.863 1.374C20.086 1.374 0 21.46 0 46.237S20.086 91.1 44.863 91.1z"
        ></path>
        <path
          fill="#168D13"
          d="M44.863 83.623c20.648 0 37.386-16.738 37.386-37.386 0-20.648-16.738-37.386-37.386-37.386-20.647 0-37.386 16.738-37.386 37.386 0 20.648 16.739 37.386 37.386 37.386z"
        ></path>
        <path
          fill="#E4E4E4"
          d="M164 91.1c24.777 0 44.863-20.086 44.863-44.863S188.777 1.374 164 1.374s-44.863 20.086-44.863 44.863S139.223 91.1 164 91.1z"
        ></path>
        <path
          fill="#fff"
          d="M164 83.623c20.648 0 37.386-16.738 37.386-37.386 0-20.648-16.738-37.386-37.386-37.386-20.648 0-37.386 16.738-37.386 37.386 0 20.648 16.738 37.386 37.386 37.386z"
        ></path>
        <path
          fill="#E4E4E4"
          d="M283.137 91.1C307.914 91.1 328 71.014 328 46.237S307.914 1.374 283.137 1.374s-44.863 20.086-44.863 44.863S258.36 91.1 283.137 91.1z"
        ></path>
        <path
          fill="#fff"
          d="M283.137 83.623c20.647 0 37.386-16.738 37.386-37.386 0-20.648-16.739-37.386-37.386-37.386-20.648 0-37.386 16.738-37.386 37.386 0 20.648 16.738 37.386 37.386 37.386zM41.686 59.757a4.105 4.105 0 01-3.244-1.586l-5.734-7.374a4.113 4.113 0 01.72-5.765l.206-.16a4.114 4.114 0 015.766.72 3.654 3.654 0 005.538.27L56.582 33.57a4.106 4.106 0 015.809-.157l.19.18a4.109 4.109 0 01.157 5.808l-18.07 19.073a4.103 4.103 0 01-2.982 1.283z"
        ></path>
        <path
          fill="#2F2E41"
          d="M144.31 37.153h-29.529v-25.68a6.007 6.007 0 016.104-5.893h9.816c7.504 0 13.609 5.893 13.609 13.136v18.437z"
        ></path>
        <path
          fill="#FFB6B6"
          d="M155.242 252.051l-5.605-.001-2.668-21.622 8.274.001-.001 21.622z"
        ></path>
        <path
          fill="#2F2E41"
          d="M156.672 257.485l-18.076-.001v-.229a7.039 7.039 0 017.036-7.035h11.04v7.265z"
        ></path>
        <path
          fill="#FFB6B6"
          d="M103.573 252.051l-5.606-.001-2.667-21.622 8.274.001-.001 21.622z"
        ></path>
        <path
          fill="#2F2E41"
          d="M105.003 257.485l-18.076-.001v-.229a7.034 7.034 0 017.036-7.035h11.04v7.265z"
        ></path>
        <path
          fill="#FFB6B6"
          d="M110.342 78.342l-3.658 23.32 34.75.457-4.572-22.405-26.52-1.372z"
        ></path>
        <path
          fill="#2F2E41"
          d="M107.692 95.968l-11.068 29.471-1.372 122.132h9.374l22.405-98.127 20.576 95.565h9.145l-6.401-107.453s1.887-21.567-7.774-34.294l-2.351-6.794-32.534-.5z"
        ></path>
        <path
          fill="#FFB6B6"
          d="M155.168 118.942a6.852 6.852 0 01-4.429-3.752 6.872 6.872 0 01-.628-2.906 6.876 6.876 0 01.661-2.899l-14.783-48.198 10.874-2.155 13.521 47.244a6.895 6.895 0 013.017 8.65 6.897 6.897 0 01-8.233 4.016z"
        ></path>
        <path
          fill="#E4E4E4"
          d="M135.603 91.22l-23.955-.462a3.13 3.13 0 01-2.968-2.359c-1.284-5.084-4.11-18.335-2.611-31.532a19.245 19.245 0 0121.876-16.837 19.257 19.257 0 0116.266 15.907c.444 2.677.318 5.419-.372 8.044-3.015 11.451-4.718 19.643-5.062 24.35a3.125 3.125 0 01-2.503 2.83 3.116 3.116 0 01-.671.06z"
        ></path>
        <path
          fill="#FFB6B6"
          d="M62.839 48.884c.144.323.262.657.354.998L93.177 60.07l5.794-5.062 8.782 7.746-9.386 9.957a5.11 5.11 0 01-6.042 1.045L60.24 57.368a6.702 6.702 0 112.599-8.484z"
        ></path>
        <path
          fill="#E4E4E4"
          d="M93.24 58.446a2.87 2.87 0 011.009-2.17l10.979-9.352A7.978 7.978 0 01119.2 51.6a7.974 7.974 0 01-1.921 5.789l-7.818 12.174a2.877 2.877 0 01-3.235 1.202 2.873 2.873 0 01-1.073-.59l-10.93-9.547a2.87 2.87 0 01-.984-2.182zM135.326 73.396a2.873 2.873 0 01-1.461-1.895l-3.246-14.052a7.98 7.98 0 0114.839-5.88l7.207 12.547a2.884 2.884 0 01.219 2.383 2.87 2.87 0 01-1.66 1.722l-13.508 5.307a2.874 2.874 0 01-2.39-.132z"
        ></path>
        <path
          fill="#2F2E41"
          d="M146.947 8.09c1.109-2.597-.798-5.901-4.26-7.38-3.462-1.479-7.168-.573-8.277 2.024-1.109 2.596.798 5.9 4.26 7.379 3.462 1.479 7.168.573 8.277-2.024z"
        ></path>
        <path
          fill="#FFB6B6"
          d="M136.207 27.39c3.704-6.776 1.214-15.27-5.561-18.974-6.775-3.704-15.27-1.214-18.974 5.561-3.703 6.776-1.213 15.27 5.562 18.974 6.775 3.703 15.27 1.214 18.973-5.561z"
        ></path>
        <path
          fill="#2F2E41"
          d="M131.609 4.671h-15.991a4.647 4.647 0 00-4.689 4.24 4.551 4.551 0 001.216 3.407 4.54 4.54 0 003.317 1.439 4.542 4.542 0 014.543 4.543v18.626h24.986V18.053a13.391 13.391 0 00-3.919-9.462 13.384 13.384 0 00-9.463-3.92z"
        ></path>
        <path
          fill="#CACACA"
          d="M210.44 258.167H37.354a.455.455 0 010-.909H210.44a.455.455 0 010 .909z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H328V258.167H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
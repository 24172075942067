import React from 'react';

export const StartImage = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="464"
      height="366"
      fill="none"
      viewBox="0 0 464 366"
    >
      <g clipPath="url(#clip0)">
        <path
          fill="#F2F2F2"
          d="M284.943 324.372l-12.383 11.4 9.841-16.513c-7.753-14.06-20.434-26.212-20.434-26.212s-26.308 25.206-26.308 45.019 11.778 26.731 26.308 26.731 26.308-6.918 26.308-26.731c0-4.41-1.305-9.087-3.332-13.694z"
        ></path>
        <path
          fill="#fff"
          d="M269.375 336.389v.977c-.058 11.352-1.972 20.209-5.729 26.446-.053.092-.111.179-.164.271l-.421-.257-.401-.251c4.168-6.735 5.642-16.254 5.695-26.156.005-.319.01-.643.005-.967-.014-4.191-.271-8.431-.681-12.551a70.338 70.338 0 00-.097-.967c-.571-5.434-1.393-10.626-2.234-15.137a55.407 55.407 0 00-.184-.953c-1.455-7.629-2.939-13.155-3.277-14.378-.039-.15-.063-.232-.068-.251l.459-.131.005-.005.464-.13c.005.019.087.295.222.807.518 1.915 1.862 7.112 3.182 13.968.058.309.121.628.179.947.686 3.66 1.358 7.75 1.89 12.043.135 1.079.256 2.142.363 3.191.038.324.072.648.101.967.445 4.453.675 8.625.691 12.517z"
        ></path>
        <path
          fill="#fff"
          d="M266.151 306.724c-.324.043-.653.087-.987.12a26.7 26.7 0 01-2.726.141 25.944 25.944 0 01-11.217-2.534l-.614.774a26.916 26.916 0 0011.831 2.727 27.738 27.738 0 002.91-.155c.329-.034.658-.077.981-.126a26.68 26.68 0 007.712-2.335l-.609-.783a25.899 25.899 0 01-7.281 2.171zM268.583 322.905c-.334.02-.668.029-1.001.029-.102.005-.208.005-.31.005a26.083 26.083 0 01-21.408-11.182c-.193.285-.387.57-.575.86a27.045 27.045 0 0021.983 11.289c.136 0 .271 0 .407-.005.338-.005.671-.014 1.005-.029a26.905 26.905 0 0014.335-5.028c-.155-.285-.309-.57-.469-.856a25.895 25.895 0 01-13.967 4.917z"
        ></path>
        <path
          fill="#fff"
          d="M269.375 336.389a26.289 26.289 0 01-2.103.087 26.15 26.15 0 01-25.073-18.816c-.217.391-.435.778-.643 1.17a27.12 27.12 0 009.849 13.464 27.104 27.104 0 0015.867 5.149c.363 0 .726-.004 1.083-.024.344-.009.682-.029 1.02-.053a27.036 27.036 0 0017.841-8.707c-.102-.358-.218-.711-.334-1.069a26.068 26.068 0 01-17.507 8.799z"
        ></path>
        <path
          fill="#E4E4E4"
          d="M63.465 128.874c35.05 0 63.465-28.415 63.465-63.465 0-35.051-28.414-63.465-63.465-63.465C28.415 1.944 0 30.358 0 65.409c0 35.05 28.414 63.465 63.465 63.465z"
        ></path>
        <path
          fill="#168D13"
          d="M63.465 118.296c29.209 0 52.887-23.678 52.887-52.887 0-29.21-23.678-52.888-52.887-52.888-29.21 0-52.888 23.679-52.888 52.888s23.679 52.887 52.888 52.887z"
        ></path>
        <path
          fill="#E4E4E4"
          d="M232 128.874c35.051 0 63.465-28.415 63.465-63.465 0-35.051-28.414-63.465-63.465-63.465s-63.465 28.414-63.465 63.465c0 35.05 28.414 63.465 63.465 63.465z"
        ></path>
        <path
          fill="#fff"
          d="M232 118.296c29.209 0 52.888-23.678 52.888-52.887 0-29.21-23.679-52.888-52.888-52.888S179.113 36.2 179.113 65.41s23.678 52.887 52.887 52.887z"
        ></path>
        <path
          fill="#E4E4E4"
          d="M400.535 128.874c35.051 0 63.465-28.415 63.465-63.465 0-35.051-28.414-63.465-63.465-63.465S337.07 30.358 337.07 65.409c0 35.05 28.414 63.465 63.465 63.465z"
        ></path>
        <path
          fill="#fff"
          d="M400.535 118.296c29.209 0 52.888-23.678 52.888-52.887 0-29.21-23.679-52.888-52.888-52.888S347.647 36.2 347.647 65.41s23.679 52.887 52.888 52.887zM58.971 84.534a5.805 5.805 0 01-4.59-2.244L46.27 71.86a5.82 5.82 0 011.02-8.156l.291-.227a5.82 5.82 0 018.157 1.019 5.17 5.17 0 007.833.382l16.473-17.39a5.811 5.811 0 018.217-.22l.269.254a5.812 5.812 0 01.222 8.216L63.189 82.72a5.806 5.806 0 01-4.218 1.815z"
        ></path>
        <path
          fill="#2F2E41"
          d="M204.145 52.558h-41.772v-36.33a8.501 8.501 0 018.635-8.335h13.885c10.616 0 19.252 8.337 19.252 18.584v26.081z"
        ></path>
        <path
          fill="#FFB6B6"
          d="M219.611 356.56l-7.93-.001-3.773-30.587h11.704l-.001 30.588z"
        ></path>
        <path
          fill="#2F2E41"
          d="M221.633 364.247l-25.57-.001v-.324a9.953 9.953 0 019.953-9.952h15.618l-.001 10.277z"
        ></path>
        <path
          fill="#FFB6B6"
          d="M146.518 356.56l-7.93-.001-3.773-30.587h11.704l-.001 30.588z"
        ></path>
        <path
          fill="#2F2E41"
          d="M148.54 364.247l-25.57-.001v-.324a9.953 9.953 0 019.953-9.952h15.618l-.001 10.277z"
        ></path>
        <path
          fill="#FFB6B6"
          d="M156.093 110.825l-5.175 32.989 49.16.647-6.469-31.695-37.516-1.941z"
        ></path>
        <path
          fill="#2F2E41"
          d="M152.345 135.759l-15.657 41.691-1.941 172.773h13.26l31.695-138.814 29.108 135.19h12.937l-9.056-152.008s2.671-30.509-10.996-48.513l-3.327-9.611-46.023-.708z"
        ></path>
        <path
          fill="#FFB6B6"
          d="M219.505 168.259a9.704 9.704 0 01-6.217-13.519l-20.913-68.182 15.382-3.05 19.127 66.834a9.754 9.754 0 014.269 12.236 9.751 9.751 0 01-11.648 5.681z"
        ></path>
        <path
          fill="#E4E4E4"
          d="M191.829 129.044l-33.887-.654a4.429 4.429 0 01-4.2-3.338c-1.816-7.192-5.813-25.937-3.693-44.607a27.224 27.224 0 0130.946-23.817A27.238 27.238 0 01203.48 90.51c-4.266 16.199-6.675 27.788-7.161 34.446a4.417 4.417 0 01-3.541 4.005 4.465 4.465 0 01-.855.084l-.094-.001z"
        ></path>
        <path
          fill="#FFB6B6"
          d="M88.894 69.153c.204.457.37.929.5 1.412l42.417 14.413 8.196-7.16 12.423 10.957-13.278 14.086a7.225 7.225 0 01-8.546 1.479L85.218 81.155a9.483 9.483 0 113.676-12.002z"
        ></path>
        <path
          fill="#E4E4E4"
          d="M131.899 82.68c.004-.585.133-1.161.379-1.69.247-.53.605-1 1.049-1.38l15.532-13.229a11.287 11.287 0 0115.907-1.102 11.29 11.29 0 011.141 15.905l-11.06 17.223a4.052 4.052 0 01-2.849 1.828 4.067 4.067 0 01-3.245-.963L133.29 85.766a4.063 4.063 0 01-1.391-3.086zM191.437 103.829a4.062 4.062 0 01-2.066-2.681l-4.592-19.879a11.288 11.288 0 0114.973-14.499 11.292 11.292 0 016.018 6.182L215.965 90.7a4.07 4.07 0 01.311 3.371 4.078 4.078 0 01-2.35 2.437l-19.108 7.507a4.067 4.067 0 01-3.381-.186z"
        ></path>
        <path
          fill="#2F2E41"
          d="M207.876 11.443c1.569-3.673-1.129-8.347-6.026-10.439-4.898-2.092-10.14-.81-11.709 2.863-1.569 3.673 1.129 8.347 6.027 10.439 4.897 2.092 10.139.81 11.708-2.863z"
        ></path>
        <path
          fill="#FFB6B6"
          d="M192.683 38.746c5.24-9.584 1.717-21.601-7.867-26.84-9.584-5.24-21.601-1.717-26.84 7.867-5.24 9.584-1.717 21.601 7.867 26.84 9.584 5.24 21.601 1.717 26.84-7.867z"
        ></path>
        <path
          fill="#2F2E41"
          d="M186.179 6.608h-22.622a6.572 6.572 0 00-6.633 5.999 6.415 6.415 0 00.36 2.587 6.416 6.416 0 003.495 3.736c.807.35 1.678.531 2.558.531a6.426 6.426 0 016.426 6.427v26.349h35.346V25.538a18.919 18.919 0 00-5.544-13.386 18.933 18.933 0 00-13.386-5.544z"
        ></path>
        <path
          fill="#CACACA"
          d="M297.696 365.212H52.842a.642.642 0 01-.454-1.097.642.642 0 01.454-.189h244.854a.64.64 0 01.642.643.64.64 0 01-.642.643z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H464V365.212H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
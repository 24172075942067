import React, { useCallback, useState } from 'react';
import axios from "axios";
import { TextInput, BaseInputOnChangeEvent, Spinner, Button, Loading } from '../ui';
import { ChangeSessionUserEmailReq, User } from '../model';
import { useValidator, Validations } from '../validator';
import { isFieldStringEmpty, isFieldEmailNotMatching } from '../Validations';
import { apiBaseUrl } from '../Consts';
import { Trans, useTranslation } from 'react-i18next';
import { useHandleRequestError } from '../hooks';
import { useRecoilState } from 'recoil';
import { userState } from '../atoms';

const v = new Validations(new ChangeSessionUserEmailReq());
v.addField("email", (v: any) => isFieldStringEmpty(v) || isFieldEmailNotMatching(v));
v.addField("password", (v: any) => isFieldStringEmpty(v));

export const ChangeEmail: React.FC = () => {

  const { t } = useTranslation();
  const [ user, setUser ] = useRecoilState(userState);
  const [ model, setModel ] = useState(new ChangeSessionUserEmailReq());
  const { errors, validateField, validateAll, resetErrorAll, resetErrorAllFromServer } = useValidator(v);
  const { handleRequestErrorAvoidingPopup } = useHandleRequestError();
  const [ isSaving, setIsSaving ] = useState(false);
  const [ dirty, setDirty ] = useState(false);
  const [ isEmailConfirmationResent, setIsEmailConfirmationResent ] = useState(false);

  const onChangeField = useCallback(
    (fieldName: keyof ChangeSessionUserEmailReq, event: BaseInputOnChangeEvent) => {
      const value = event.value;
      if (event.dirtyAndTouched) {
        validateField(fieldName, event.value);
      }
      const _model = { ...model } as ChangeSessionUserEmailReq;
      _model[fieldName] = value as never;

      setModel(_model);
      setDirty(dirty || event.dirty);

    }, [dirty, model, validateField])

  const onBlurField = useCallback(
    (fieldName: keyof ChangeSessionUserEmailReq, event: BaseInputOnChangeEvent) => {
      if (event.dirty) {
        validateField(fieldName, event.value);
      }
    }, [validateField])

  const save = useCallback(() => {
    if (validateAll(model)) {
      setIsSaving(true);
      axios.put(`${apiBaseUrl}/session/user/email`, model)
        .then((response) => {
          setModel(new ChangeSessionUserEmailReq());
          setUser(response.data.user as User);
          resetErrorAll();
          setDirty(false);
        })
        .catch(err => {
          handleRequestErrorAvoidingPopup(err)
          resetErrorAllFromServer(err.response.data)
        })
        .finally(() => setIsSaving(false));
    }
  }, [validateAll, model, setUser, resetErrorAll, resetErrorAllFromServer, handleRequestErrorAvoidingPopup])


  const cancel = useCallback(() => {
    setIsSaving(true);
    axios.delete(`${apiBaseUrl}/session/user/change-email`)
      .then((response) => {
        setUser(response.data.user as User);
        resetErrorAll();
        setDirty(false);
      })
      .catch(err => resetErrorAllFromServer(err.response.data))
      .finally(() => setIsSaving(false));
  }, [setUser, resetErrorAll, resetErrorAllFromServer])


  const resendConfirmation = useCallback(() => {
    setIsSaving(true);
    let timeout: ReturnType<typeof setTimeout>;
    axios.put(`${apiBaseUrl}/session/user/change-email/resend-confirmation`)
      .then(() => {
        resetErrorAll();
        setDirty(false);
        setIsEmailConfirmationResent(true);
        timeout = setTimeout(() => setIsEmailConfirmationResent(false), 5000);
      })
      .catch(err => resetErrorAllFromServer(err.response.data))
      .finally(() => setIsSaving(false));
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, [resetErrorAll, resetErrorAllFromServer])

  const discard = useCallback(() => {
    setModel(new ChangeSessionUserEmailReq());
    resetErrorAll();
    setDirty(false);
  }, [resetErrorAll]);

  if (!user) {
    return <Loading />
  }
  
  return (
    user.registeredWithEmail ?
      <form onSubmit={(e) => { e.preventDefault(); save(); }}>
        <div className="t-c-b f-w-b f-s-1.25 m-t-3">{t("Change Email Address")}</div>
        {errors.base ?
          <div className="b-s-s b-w-1px b-c-red t-c-r bg-c-lr p-1 f-s-1 w-100% m-t-1 m-t-2-M">
            {errors.base}
          </div>
        : null}
        <div className="d-f fb-d-c m-t-1 m-t-2-M">
          <div className="f-s-1 f-s-1.25-M"> {user.email} </div>
          <div className="m-t-0.5">
            {user.newEmail ?
              <>
                <div className="f-s-1 m-t-0.5">
                  <Trans
                    t={t}
                    i18nKey="You have requested to change your email address to <1/>. An email was sent to confirm this change."
                  >
                    You have requested to change your email address to {user.newEmail}. An email was sent to confirm this change.
                  </Trans>
                </div>
                <div className="d-f f-s-1 m-t-0.5">
                  <div onClick={cancel} className="t-c-r c-p">
                    {t("Cancel email change")}
                  </div>
                  {!isEmailConfirmationResent ?
                    <div onClick={resendConfirmation} className="m-l-2 t-c-g c-p">
                      {t("Resend email confirmation")}
                    </div>
                  :
                    <div className="m-l-2">
                      {t("The email confirmation was resent.")}
                    </div>
                  }
                </div>
              </>
            :
              <TextInput
                value={model.email}
                placeholder={t("New email address")}
                onChange={e => onChangeField("email", e)}
                onBlur={e => onBlurField("email", e)}
                error={errors.fields.email}
                maxLength={320}
                type="text" />
            }
          </div>
        </div>
        {dirty ?
          <>
            <div className="m-t-0.5">
              <TextInput
                value={model.password}
                placeholder={t("Password")}
                onChange={e => onChangeField("password", e)}
                onBlur={e => onBlurField("password", e)}
                error={errors.fields.password}
                maxLength={64}
                type="password" />
            </div>
            <div className="d-f j-c-e p-v-1 p-v-2-M b-sb-s b-c-lightestGray b-w-1px">
              { isSaving ?
                <Spinner type={"primary"} />
              :
                <div className="d-f fb-d-cr fb-d-r-M w-100% m-v-1 j-c-e a-i-c">
                  <div onClick={discard} className="t-c-g f-s-1.25 c-p p-t-1 p-t-0-M">
                    {t("Cancel")}
                  </div>
                  <div className="m-l-1-M w-100% w-auto-M">
                    <Button type="primary" submit={true } fullWidth="mobile">
                      {t("Change Email")}
                    </Button>
                  </div>
                </div>  
              }
            </div>
          </>
        : null }
      </form>
    :
      <>
        <div className="t-c-b f-w-b f-s-1.25 m-t-3">{t("Email Address")}</div>
        <div className="d-f fb-d-c p-v-1 p-v-2-M b-sb-s b-c-lightestGray b-w-1px">
        <div className="f-s-1 f-s-1.25-M"> {user.email} </div>
          <div className="f-s-1 m-t-0.25 t-c-gray">
            {t("For changing the email address you need to set a password in the section below")}
          </div>
        </div>
      </>
  )
}
import React, {  } from "react";
import { Route, useRouteMatch, Switch } from "react-router-dom";
import { MonitorShow } from "./monitor-show";
import { MonitorNew } from "./monitor-new";
import { Monitors } from "./monitors";
import { Settings } from "./settings";
import { PageNew } from "./page-new";
import { Pages } from "./pages";
import { Alerts } from "./alerts";
import { GroupNew } from "./group-new";
import { Groups } from "./groups";
import { Dashboard } from "./dashboard";
import { Sidebar } from "./sidebar";
import { TeamHeader } from "./header";
import { Billing } from "./billing";
import { MonitorShowDay } from "./monitor-show-day/MonitorShowDay";
import { MonitorShowRegionDay } from "./monitor-show-region-day/MonitorShowRegionDay";
import { MonitorEdit } from "./monitor-edit";
import { EmailConfirmation } from "./header/EmailConfirmation";
import { Preferences } from "./preferences";
import { AlertNew } from "./alert-new";


export const TeamApp: React.FC = () => {
  const match = useRouteMatch();

  return (
    <>
      <Sidebar />
      <TeamHeader />
      <div className="p-l-3.5-M p-l-14-L of-y-a pos-a pos-t-4 pos-l-0 pos-r-0 pos-b-0">
        <EmailConfirmation />
        <Switch>
          <Route path={`${match.url}/billing`} component={Billing} />
          <Route path={`${match.url}/monitors/new`} component={MonitorNew} />
          <Route path={`${match.url}/monitors/:monitorUuid/edit`} component={MonitorEdit} />
          <Route path={`${match.url}/monitors/:monitorUuid/:day/:regionSlug`} component={MonitorShowRegionDay} />
          <Route path={`${match.url}/monitors/:monitorUuid/:day`} component={MonitorShowDay} />
          <Route path={`${match.url}/monitors/:monitorUuid`} component={MonitorShow} />
          <Route path={`${match.url}/monitors`} component={Monitors} />
          <Route path={`${match.url}/preferences`} component={Preferences} />
          <Route path={`${match.url}/settings`} component={Settings} />
          <Route path={`${match.url}/pages/new`} component={PageNew} />
          <Route path={`${match.url}/pages`} component={Pages} />
          <Route path={`${match.url}/groups/new`} component={GroupNew} />
          <Route path={`${match.url}/groups`} component={Groups} />
          <Route path={`${match.url}/alerts/new`} component={AlertNew} />
          <Route path={`${match.url}/alerts`} component={Alerts} />
          <Route path={`${match.url}/`} component={Dashboard} />
        </Switch>
      </div>
    </>
  )
}

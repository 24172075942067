import React from 'react';
import { useRecoilValue } from 'recoil';
import { isOwnerOrMemberState } from '../atoms/roleState';
import { EmptyImage, EmptyImageSmall, StartImage, StartImageSmall } from '../images';

type EmptyProps = {
  description: React.ReactElement,
  actionTitle: string,
  onAction: () => void
  type?: "start" | "empty"
}

export const Empty: React.FC<EmptyProps> = (props) => {

  const isOwnerOrMember = useRecoilValue(isOwnerOrMemberState);

  return (
    <div className="d-f fb-d-c a-i-c fb-d-r-L m-h-a m-t-1 m-t-4-M m-t-10-L w-100% max-w-65-L p-l-2-L t-c-nb">
      <div className="d-f fb-a fb-d-c j-c-c a-i-c a-i-s-L">
        <div className="d-f fb-d-c fb-d-r-L w-100% f-s-2 f-s-3-M t-a-c t-a-l-L a-i-c m-b-1 m-b-4-L">
          <div className="d-f fb-d-c">
            <div className="d-f m-t-3 m-t-0-M">
              {props.description}
            </div>
            {isOwnerOrMember ?
              <div onClick={props.onAction} className="f-s-1 f-s-1.5-M t-a-c t-a-l-L m-t-3 t-c-g c-p">
                {props.actionTitle}
              </div>
            : null
            }
          </div>
          <div className="w-100% w-29-L d-n d-f-L fb-a j-c-c m-t-6 m-t-0-L">
            {props.type === "start" ? <StartImage /> : <EmptyImage />}
          </div>
          <div className="w-100% d-f d-n-L fb-a j-c-c m-t-4">
            {props.type === "start" ? <StartImageSmall /> : <EmptyImageSmall />}
          </div>
        </div>
      </div>
    </div>
  )

}
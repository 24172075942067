import React from 'react';
import { Link } from 'react-router-dom';
import { Group } from '../../model';

type GroupNameWithColorProps = {
  group: Group
  textSize?: "small" | "medium" | "large"
  margin?: boolean
  link?: string
}

export const GroupNameWithColor: React.FC<GroupNameWithColorProps> = (props) => {

  let textSize: string;

  switch (props.textSize) {
    case "small":
      textSize = "f-s-0.75";
      break;
    case "medium":
      textSize = "f-s-1";
      break;
    case "large":
      textSize = "f-s-1.25";
      break;
    default: textSize = "f-s-1";
  }

  const content = () =>
    <div
      className={`p-h-0.5 p-v-0.25 t-c-w b-r-4px ws-n of-h t-o-e ${props.margin ? "m-v-0.25 m-r-0.5" : null} ${textSize} ${props.link ?"c-p" : ""}`}
      style={
        props.group.color ?
          {backgroundColor: props.group.color}
        :
          {borderStyle: 'solid', borderColor: '#4a4a4a', borderWidth: 1, color: '#4a4a4a'}
      }
      title={props.group.name}>
      { props.group.name }
    </div>;

  return (
    props.link ?
      <Link to={props.link}>{content()}</Link>
    :
      <div>{content()}</div>
  );
}
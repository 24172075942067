import React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { teamState } from '../atoms';
import { Product } from '../model';
import { useTranslation } from 'react-i18next';

type PhoneAlertCreditsProductProps = {
  product: Product
  selected?: boolean
  isFirst?: boolean
}

export const PhoneAlertCreditsProduct = React.forwardRef<HTMLDivElement, PhoneAlertCreditsProductProps>((props, ref) => {

  const { t } = useTranslation();
  const team = useRecoilValue(teamState);

  return (
    team ?
      <Link to={`/${team.slug}/billing/phone-alert-credits/buy/${props.product.id}`}>
        <div
          ref={ref}
          className={`${props.selected ? "b-c-g t-c-g" : "b-c-gray t-c-nb"} d-f pos-re fb-n fb-d-c b-s-s b-w-1px ${!props.isFirst ? "m-l-1.5" : ""} c-p`}>
          <div className={`${props.selected ? "b-c-g bg-c-lg" : "b-c-gray bg-c-lightestGray"} d-f a-i-c j-c-c f-s-1.5 b-sb-s b-w-1px p-v-1`}>
            $ {props.product.prices[0].unitPrice}
          </div>
          <div className="p-1.5">
            <div className="d-f fb-n  j-c-c f-s-1.5 f-w-700">
              {props.product.phoneAlertCredits}
            </div>
            <div className=" m-t-1.5 d-f j-c-c t-a-c ws-n">
              {t("SMS or Voice Calls")}<br />
              {t("to any country")}
            </div>
          </div>
        </div>
      </Link>
    : null
  )
})
import React, { useEffect } from 'react';
import { apiBaseUrl } from '../Consts';
import { Loading } from '../ui';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { messageState, userState } from '../atoms';
import { useForceUpdate, useHandleRequestError } from '../hooks';
import { useHistory, useParams } from 'react-router-dom';
import { useInterval } from '../hooks/UseInterval';


export const ConfirmEmail: React.FC = () => {

  const { t } = useTranslation();
  const { confirmationCode } = useParams() as any;
  const history = useHistory();
  const setMessage = useSetRecoilState(messageState);
  const [user, setUser] = useRecoilState(userState);
  const { handleRequestError } = useHandleRequestError();

  useInterval(useForceUpdate(), 10000);

  useEffect(() => {
    axios.get(`${apiBaseUrl}/confirm-email/${confirmationCode}`)
      .catch(error => handleRequestError(error))
      .finally(() => history.push("/profile"));
  }, [confirmationCode, setMessage, t, handleRequestError, history, setUser, user]);

  return <Loading />
}

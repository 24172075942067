import { Page, Monitor } from "../model";
import { MultiSelectState } from "../ui";
import { PageSelector } from "./PageSelector";

// Immutable class
export class PageSelectors {

  private readonly selectors: PageSelector[];
  public readonly multiSelectState: MultiSelectState;

  public readonly hasSelectedForDelete: boolean;

  constructor(pages: Page[] | PageSelector[]) {
    if (pages.length > 0) {
      if ((pages[0] as Page).uuid) {
        this.selectors = (pages as Page[]).map(g => new PageSelector(g));
      } else {
        this.selectors = (pages as PageSelector[]);
      }
    } else {
      this.selectors = [];
    }

    let hasSelected = false;
    let hasUnSelected = false;

    let hasSelectedForDelete = false;
    
    this.selectors.forEach(ms => {
      hasSelected = hasSelected || ms.selected;
      hasUnSelected = hasUnSelected || !ms.selected;

      hasSelectedForDelete = hasSelectedForDelete || (ms.canDelete);
    });

    this.multiSelectState =
      hasUnSelected && hasSelected ?
        MultiSelectState.Partially :
      !hasUnSelected && hasSelected ? 
        MultiSelectState.All :
        MultiSelectState.None;

    this.hasSelectedForDelete = hasSelectedForDelete;
  }

  get length(): number {
    return this.selectors ? this.selectors.length : 0;
  }

  get list(): PageSelector[] {
    return this.selectors;
  }

  delete(pageSelector: PageSelector): PageSelectors {
    return new PageSelectors(this.selectors.map(
      ms => ms.page.uuid === pageSelector.page.uuid ? ms.delete() : ms));
  }

  remove(uuids: string | string[]): PageSelectors {
    if (!(uuids instanceof Array)) {
      uuids = [uuids];
    }
    return new PageSelectors(this.selectors.filter(ms => !uuids.includes(ms.page.uuid)));
  }

  select(pageSelector: PageSelector): PageSelectors {
    return new PageSelectors(this.selectors.map(
      ms => ms.page.uuid === pageSelector.page.uuid && !ms.blocked ? ms.select() : ms));
  }
  
  unselect(pageSelector: PageSelector): PageSelectors {
    return new PageSelectors(this.selectors.map(
      ms => ms.page.uuid === pageSelector.page.uuid ? ms.unselect() : ms));
  }

  toggle(pageSelector: PageSelector): PageSelectors {
    return pageSelector.selected ? this.unselect(pageSelector) : this.select(pageSelector);
  }

  expand(pageSelector: PageSelector): PageSelectors {
    return new PageSelectors(this.selectors.map(
      ms => ms.page.uuid === pageSelector.page.uuid ? ms.expand() : ms));
  }

  changeMultiSelectState(): PageSelectors {
    const state = this.multiSelectState !== MultiSelectState.None ?
      MultiSelectState.None :
      MultiSelectState.All;

    return state === MultiSelectState.All ? this.selectAll() : this.unselectAll();
  }

  unselectAll(): PageSelectors {
    return new PageSelectors(this.selectors.map(ms => ms.selected ? ms.unselect() : ms));
  }

  selectAll(): PageSelectors {
    return new PageSelectors(this.selectors.map(ms => !ms.selected && !ms.blocked ? ms.select() : ms));
  }

  updateMonitorsFromRemote(monitors: Monitor | Monitor[]): PageSelectors {
    return new PageSelectors(this.selectors.map(gs => gs.updateMonitorsFromRemote(monitors)));
  }

  updateFromRemote(pages: Page | Page[]): PageSelectors {
    if (!(pages instanceof Array)) {
      pages = [pages];
    }
    return new PageSelectors(this.selectors.map(
      gs => {
        const g = (pages as Page[]).find(g => g.uuid === gs.page.uuid);
        return g ? gs.updateFromRemote(g) : gs;
      }));
  }
}
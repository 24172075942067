import React from "react";
import { sizeFlag } from "../Helper";

type FlagGBProps = {
  size?: string
}

export const FlagGB: React.FC<FlagGBProps> = (props) => {
  const size = sizeFlag(props.size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size.width}
      height={size.height}
      fill="none"
      viewBox="0 0 24 16"
    >
      <path fill="#012169" d="M0 0h24v16H0V0z"></path>
      <path
        fill="#fff"
        d="M2.813 0l9.15 6.033L21.075 0H24v2.067l-9 5.966 9 5.934V16h-3l-9-5.967L3.037 16H0v-2l8.963-5.933L0 2.133V0h2.813z"
      ></path>
      <path
        fill="#C8102E"
        d="M15.9 9.367l8.1 5.3V16L13.838 9.367H15.9zm-6.9.666l.225 1.167-7.2 4.8H0l9-5.967zM24 0v.1l-9.338 6.267.075-1.467L22.126 0H24zM0 0l8.963 5.867h-2.25L0 1.4V0z"
      ></path>
      <path
        fill="#fff"
        d="M9.037 0v16h6V0h-6zM0 5.333v5.334h24V5.333H0z"
      ></path>
      <path
        fill="#C8102E"
        d="M0 6.433v3.2h24v-3.2H0zM10.238 0v16h3.6V0h-3.6z"
      ></path>
    </svg>
  )
}
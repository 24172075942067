import React, { useCallback, useState } from 'react';
import { BaseInputOnChangeEvent, BaseInputOnBlurEvent } from '../event/BaseInputEvent';
import { CheckMark } from '../images/'

type CheckboxProps = {
  color?: string
  checked?: boolean
  label?: string
  disabled?: boolean
  large?: boolean
  round?: boolean
  alignRight?: boolean
  onChange?: (event: BaseInputOnChangeEvent) => void
  onBlur?: (event: BaseInputOnBlurEvent) => void
}

export const Checkbox: React.FC<CheckboxProps> = (props) => {

  const [ dirty, setDirty ] = useState(false);
  const [ touched, setTouched ] = useState(false);

  const onChange = useCallback(() => {
    const checked = !props.checked;
    const dirty = true;
    setDirty(dirty);
    if (props.onChange) {
      props.onChange(new BaseInputOnChangeEvent(checked, dirty, touched))
    }
  }, [props, touched]);

  const onBlur = useCallback(() => {
    setTouched(true);
    if (props.onBlur) {
      props.onBlur(new BaseInputOnBlurEvent(props.checked || false, dirty, true));
    }
  }, [props, dirty]);

  const styleWhenColor =
    !props.disabled && props.color ? 
      {borderColor: props.color, backgroundColor: props.checked ? props.color : undefined}
    : undefined;
   
  return (
    <div
      tabIndex={0}
      onClick={!props.disabled ? onChange : undefined}
      onBlur={onBlur}
      className={`d-f a-i-c ${props.alignRight ? "fb-d-rr" : "fb-d-r"} ${!props.disabled ? "c-p" : undefined}`}>
      <div className={
        `d-f t-c-w a-i-c j-c-c b-s-s b-w-1px
          ${props.round ? "b-r-50%" : "b-r-4px"}
          ${props.large ? "w-2 h-2" : "w-1.5 h-1.5"}
          ${props.disabled ? "b-c-lightGray" : "b-c-g"}
          ${props.checked ? props.disabled ? "bg-c-gray" : "bg-c-g" : undefined}
        `}
        style={styleWhenColor} >
        { props.checked ?
          <CheckMark />
        : null
        }
      </div>
      {props.label ?
        <div className={`${props.alignRight ? "m-r-0.5" : "m-l-0.5"} m-l-0.5 ${props.disabled ? "t-c-lightGray" : null}`}>
          {props.label}
        </div>
      : null
      }
    </div>
  );
}

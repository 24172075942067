import { Monitor } from "../model";
import { MultiSelectState } from "../ui";
import { MonitorSelector } from "./MonitorSelector";

// Immutable class
export class MonitorSelectors {

  private readonly selectors: MonitorSelector[];
  public readonly multiSelectState: MultiSelectState;

  public readonly hasSelectedForPause: boolean;
  public readonly hasSelectedForMaintenance: boolean;
  public readonly hasSelectedForResume: boolean;
  public readonly hasSelectedForDelete: boolean;

  constructor(monitors: Monitor[] | MonitorSelector[]) {
    if (monitors.length > 0) {
      if ((monitors[0] as Monitor).uuid) {
        this.selectors = (monitors as Monitor[]).map(m => new MonitorSelector(m));
      } else {
        this.selectors = (monitors as MonitorSelector[]);
      }
    } else {
      this.selectors = [];
    }

    let hasSelected = false;
    let hasUnSelected = false;

    let hasSelectedForPause = false;
    let hasSelectedForMaintenance = false;
    let hasSelectedForResume = false;
    let hasSelectedForDelete = false;
    
    this.selectors.forEach(ms => {
      hasSelected = hasSelected || ms.selected;
      hasUnSelected = hasUnSelected || !ms.selected;

      hasSelectedForPause = hasSelectedForPause || (ms.canPause);
      hasSelectedForMaintenance = hasSelectedForMaintenance || (ms.canMaintain);
      hasSelectedForResume = hasSelectedForResume || (ms.selected && (ms.canResume));
      hasSelectedForDelete = hasSelectedForDelete || (ms.canDelete);
    });

    this.multiSelectState =
      hasUnSelected && hasSelected ?
        MultiSelectState.Partially :
      !hasUnSelected && hasSelected ? 
        MultiSelectState.All :
        MultiSelectState.None;

    this.hasSelectedForPause = hasSelectedForPause;
    this.hasSelectedForMaintenance = hasSelectedForMaintenance;
    this.hasSelectedForResume = hasSelectedForResume;
    this.hasSelectedForDelete = hasSelectedForDelete;
  }

  get length(): number {
    return this.selectors ? this.selectors.length : 0;
  }

  get list(): MonitorSelector[] {
    return this.selectors;
  }

  pause(monitorSelector: MonitorSelector): MonitorSelectors {
    return new MonitorSelectors(this.selectors.map(
      ms => ms.monitor.uuid === monitorSelector.monitor.uuid ? ms.pause() : ms));
  }

  maintain(monitorSelector: MonitorSelector): MonitorSelectors {
    return new MonitorSelectors(this.selectors.map(
      ms => ms.monitor.uuid === monitorSelector.monitor.uuid ? ms.maintain() : ms));
  }

  resume(monitorSelector: MonitorSelector): MonitorSelectors {
    return new MonitorSelectors(this.selectors.map(
      ms => ms.monitor.uuid === monitorSelector.monitor.uuid ? ms.resume() : ms));
  }

  delete(monitorSelector: MonitorSelector): MonitorSelectors {
    return new MonitorSelectors(this.selectors.map(
      ms => ms.monitor.uuid === monitorSelector.monitor.uuid ? ms.delete() : ms));
  }

  remove(uuids: string | string[]): MonitorSelectors {
    if (!(uuids instanceof Array)) {
      uuids = [uuids];
    }
    return new MonitorSelectors(this.selectors.filter(ms => !uuids.includes(ms.monitor.uuid)));
  }

  select(monitorSelector: MonitorSelector): MonitorSelectors {
    return new MonitorSelectors(this.selectors.map(
      ms => ms.monitor.uuid === monitorSelector.monitor.uuid && !ms.blocked ? ms.select() : ms));
  }
  
  unselect(monitorSelector: MonitorSelector): MonitorSelectors {
    return new MonitorSelectors(this.selectors.map(
      ms => ms.monitor.uuid === monitorSelector.monitor.uuid ? ms.unselect() : ms));
  }

  toggle(monitorSelector: MonitorSelector): MonitorSelectors {
    return monitorSelector.selected ? this.unselect(monitorSelector) : this.select(monitorSelector);
  }

  changeMultiSelectState(): MonitorSelectors {
    const state = this.multiSelectState !== MultiSelectState.None ?
      MultiSelectState.None :
      MultiSelectState.All;

    return state === MultiSelectState.All ? this.selectAll() : this.unselectAll();
  }

  pauseSelected(): MonitorSelectors {
    return new MonitorSelectors(this.selectors.map(ms => ms.selected && ms.canPause ? ms.pause() : ms));
  }

  maintainSelected(): MonitorSelectors {
    return new MonitorSelectors(this.selectors.map(ms => ms.selected && ms.canMaintain ? ms.maintain() : ms));
  }

  deleteSelected(): MonitorSelectors {
    return new MonitorSelectors(this.selectors.filter(ms => !ms.selected && ms.canDelete));
  }

  resumeSelected(): MonitorSelectors {
    return new MonitorSelectors(this.selectors.map(ms => ms.selected && ms.canResume ? ms.resume() : ms));
  }

  unselectAll(): MonitorSelectors {
    return new MonitorSelectors(this.selectors.map(ms => ms.selected ? ms.unselect() : ms));
  }

  selectAll(): MonitorSelectors {
    return new MonitorSelectors(this.selectors.map(ms => !ms.selected && !ms.blocked ? ms.select() : ms));
  }

  updateFromRemote(monitors: Monitor | Monitor[], serverEvent: boolean = false): MonitorSelectors {
    if (!(monitors instanceof Array)) {
      monitors = [monitors];
    }
    return new MonitorSelectors(this.selectors.map(
      ms => {
        const m = (monitors as Monitor[]).find(m => m.uuid === ms.monitor.uuid);
        return m ? ms.updateFromRemote(m, serverEvent) : ms;
      }));
  }
}
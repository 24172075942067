import React from 'react';
import { X } from '../../images';
import { BaseInputOnRemoveEvent } from '../event/BaseInputEvent';

type TagProps = {
  disabled?: boolean
  text: string | number
  backgroundColor?: string
  textColor?: string
  onClickRemove?: () => void
}

export const Tag: React.FC<TagProps> = (props) => {
  return (
    <div
      className={`d-f a-i-c ${props.disabled ? "t-c-lightGray bg-c-nw" : "bg-c-lightestGray"} p-v-1px p-l-0.5`}
      style={{
        backgroundColor: props.backgroundColor,
        color: props.textColor }}>
      <div>{ props.text }</div>
        {props.onClickRemove ? <div onClick={(e) => {
          e.stopPropagation();
          props.onClickRemove ? props.onClickRemove() : undefined
        }} className="d-f t-c-gray m-l-0.25 m-r-0.25 c-p"><X /></div>
        : null}
    </div>
  )
}

import React, { useState, useCallback, forwardRef, useMemo } from 'react';
import { HelpTip } from '..';
import { PasswordHide, PasswordShow } from '../../images';
import { BaseInputOnChangeEvent, BaseInputOnBlurEvent } from '../event/BaseInputEvent';

type Type =
  | 'text'
  | 'email'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'url'
  | 'date'
  | 'datetime-local'
  | 'month'
  | 'time'
  | 'week'
  | 'file'
  | 'currency';

type Alignment = 'left' | 'center' | 'right';

type TextInputProps = {
  label?: string
  value?: number | string
  autoFocus?: boolean
  placeholder?: string
  type?: Type
  align?: Alignment
  error?: string | string[]
  disabled?: boolean
  onChange?: (event: BaseInputOnChangeEvent) => void
  onBlur?: (event: BaseInputOnBlurEvent) => void
  suffix?: React.ReactNode,
  prefix?: React.ReactNode,
  maxLength?: number,
  autocomplete?: string,
  material?: boolean
  name?: string
  color?: string
  helpTip?: React.ReactNode
}

const fixValueType = (value: string, type?: Type): any => {
  return value && type === "number" ? parseFloat(value) : value;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {

  const [ dirty, setDirty ] = useState(false);
  const [ touched, setTouched ] = useState(false);

  const isPassword = useMemo(() => props.type === "password", [props.type]);

  const [ passwordType, setPasswordType ] = useState<Type>("password");

  let alignClass: string;
  let color: string;

  switch (props.align) {
    case "center":
      alignClass = "t-a-c";
      break;
    case "right":
      alignClass = "t-a-r";
      break;
    case "left":
    default:
      alignClass = "t-a-l"
  }

  switch (props.color) {
    case "gray":
      color = "b-c-gray";
      break;
    case "black":
      color = "b-c-b";
      break;
    default:
      color = "b-c-gray"
  }

  const errorClass = props.error ? 'b-c-r' : null;

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDirty(true);
    if (props.onChange) {
      props.onChange(new BaseInputOnChangeEvent(
        fixValueType(event.currentTarget.value, props.type), true, touched));
    }
  }, [props, touched]);

  const onBlur = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setTouched(true);
    if (props.onBlur) {
      props.onBlur(new BaseInputOnBlurEvent(
        fixValueType(event.currentTarget.value, props.type), dirty, true));
    }
  }, [props, dirty]);

  const renderError = (error: string, index: number) => {
    return <div key={index} className="t-c-r f-s-1 m-t-0.5">{ error }</div>
  }

  const renderErrors = (error?: string | string[]) => {
    if (error) {
      if (typeof error === "string") {
        return renderError(error, 0);
      } else {
        return (error as string[]).map((e, i) => renderError(e, i));
      }
    } else {
      return null;
    }
  }

  const toggleShowPassword = useCallback(() =>
    setPasswordType(pwd => pwd === "password" ? "text" : "password"), []);

  return (
    <div className={`d-f w-100% fb-d-c ${props.error ? "input-error" : ""}`}>
      {props.label || props.helpTip ?
        <div className="d-f a-i-c m-b-0.5">
          {props.label ?
            <label htmlFor={props.name} className={`d-b f-s-1 f-s-1.25-M ${alignClass} ${props.helpTip ? "m-r-1" : ""}`}>
              { props.label }
            </label>
            : null
          }
          {props.helpTip ?
            <HelpTip>
              {props.helpTip}
            </HelpTip>
            : null
          }
        </div>
      : null}
      <div className={`d-f a-i-c ${props.material ? "b-sb-s b-w-2px" : "b-s-s b-w-1px"} ${props.disabled ? "b-c-lightestGray" : color} h-3 ${errorClass}`}>
        {props.prefix}
        <input
          ref={ref}
          autoFocus={props.autoFocus}
          value={props.value === undefined || props.value === null ? '' : props.value}
          placeholder={props.placeholder}
          onChange={ onChange }
          onBlur={ onBlur }
          disabled={props.disabled}
          type={!isPassword ? props.type : passwordType}
          maxLength={props.maxLength}
          name={props.name}
          autoComplete={props.autocomplete}
          className={`fb-a w-100% ${props.material ? "" : "p-h-1"} f-s-1 h-100% b-w-0px b-s-s ${alignClass} ${props.disabled ? "t-c-lightGray" : "t-c-nb"}`} />
        {isPassword ?
          <div className="d-f t-c-nb m-h-0.5 a-i-c c-p" onClick={toggleShowPassword}>
            {passwordType === "text" ?
              <PasswordHide />
            :
              <PasswordShow />
            }
          </div>
        : null}
        <div className={`d-f a-i-c h-100% ${props.disabled ? "bg-c-nw" : ""}`}>{props.suffix}</div>
      </div>
      { renderErrors(props.error) }
    </div>
  );
})

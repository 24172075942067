import React, { useState, useCallback, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import { ReactSortable } from "react-sortablejs";
import { Group, Monitor } from '../model';
import { Colors } from './Colors';
import { TextInput, Button, BaseInputOnChangeEvent, Modal, BaseInputOnSelectEvent, ComboBox, ComboBoxItem, ComboBoxItemType, Spinner } from '../ui';
import { Validations, useValidator } from '../validator';
import { isFieldStringEmpty } from '../Validations';
import { Errors } from '../validator/Validator';
import { MonitorNew } from '../monitor-new';
import axios, { CancelTokenSource } from 'axios';
import { apiBaseUrl, cancelTokenMessage } from '../Consts';
import { Reorder, X } from '../images';
import { useDebounce } from '../hooks/UseDebounce';
import { usePrevious } from '../hooks/UsePrevious';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { productsState, teamState } from '../atoms';
import { useHandleRequestError } from '../hooks';
import { productsNonFreeEnabledState } from '../atoms/productsNonFreeEnabledState';

const v = new Validations(new Group());
v.addField("name", (v: any) => isFieldStringEmpty(v));

type GroupNewProps = {
  group: Group
  onCancel: () => void
  onSave: (group: Group) => void
  disallowSetMonitors?: boolean
  errorsOnServer: Errors<Group> | undefined
  isSaving?: boolean
  noRedirectToCheckout?: boolean
}

const monitorNewItem = new ComboBoxItem("New monitor ...", ComboBoxItemType.Add);

const pageSizeMonitors = 10;

export const GroupForm: React.FC<GroupNewProps> = (props) => {

  const { t } = useTranslation();
  const team = useRecoilValue(teamState);
  const { handleRequestError } = useHandleRequestError();

  const [ group, setGroup ] = useState(props.group);
  const { errors, validateField, validateAll, resetErrorAllFromServer } = useValidator(v);
  const [ isAddingMonitor, setIsAddingMonitor ] = useState(false);

  const [ monitorItems, setMonitorItems ] = useState<ComboBoxItem[]>();
  const [ monitorInputValue, setMonitorInputValue ] = useState<string>();
  const debouncedMonitorInputValue = useDebounce(monitorInputValue, 500);
  const cancelToken = useRef<CancelTokenSource>();
  const previousDebouncedMonitorInputValue = usePrevious(debouncedMonitorInputValue);

  const allProducts = useRecoilValue(productsState);
  const products = useMemo(() =>
    allProducts?.filter(p => p.productType === "monitor"), [allProducts])
  const productsNonFreeEnabled = useRecoilValue(productsNonFreeEnabledState);
  const [ hasNonFreeInterval, setHasNonFreeInterval ] = useState<boolean>(false);

  const [ monitorDefaultFocusedIndex, setMonitorDefaultFocusedIndex ] = useState<number>(-1);

  const scrollMonitorsToBottom = useRef(false);
  const refMonitors = useRef<HTMLDivElement>(null);

  const [ isLoadingMonitors, setIsLoadingMonitors ] = useState(false);
  const [ monitorsCount, setMonitorsCount ] = useState(0);

  const onChangeField = useCallback(
    (fieldName: keyof Group, event: BaseInputOnChangeEvent) => {
      const value = event.value;
      if (event.dirtyAndTouched) {
        validateField(fieldName, event.value);
      }
      const _group = { ...group } as Group;
      _group[fieldName] = value as never;

      setGroup(_group);

    }, [group, validateField]);

  const onBlurField = useCallback(
    (fieldName: keyof Group, event: BaseInputOnChangeEvent) => {
      if (event.dirty) {
        validateField(fieldName, event.value);
      }
    }, [validateField]);

  const onNewMonitor = useCallback((newMonitor: Monitor) => {
    setGroup(group => {
      return {...group, monitors: [...(group.monitors || []), newMonitor]};
    });
    setIsAddingMonitor(false);
    scrollMonitorsToBottom.current = true;
  }, []);

  const onChangeMonitorInputValue = useCallback((e: BaseInputOnChangeEvent) => {
    setMonitorInputValue(e.value as string);
    setMonitorDefaultFocusedIndex(0);
  }, []);

  const onSelectMonitor = useCallback((event: BaseInputOnSelectEvent) => {
    if (event.index === 0) {
      setIsAddingMonitor(true);
    } else if (monitorItems) {
      const selectedMonitor = monitorItems[event.index].data as Monitor;
      setGroup(group => {
        return {...group, monitors: [...group.monitors || [], selectedMonitor]};
      });
      monitorItems[event.index].type = ComboBoxItemType.Selected;
      monitorItems[event.index].disabled = true;
      setMonitorItems([...monitorItems]);
      scrollMonitorsToBottom.current = true;
    }
    setMonitorInputValue(undefined);
    setMonitorDefaultFocusedIndex(-1);
  }, [monitorItems]);

  const onBlurMonitors = useCallback(() => {
    setMonitorDefaultFocusedIndex(-1);
  }, []);


  const removeMonitor = useCallback((monitor: Monitor) => {
    if (group) {
      const _selectedMonitors = [...group.monitors];
      _selectedMonitors.splice(_selectedMonitors.indexOf(monitor), 1);
      setGroup(monitor => {
        return {...monitor, monitors: _selectedMonitors};
      });
    }
  }, [group]);

  const save = useCallback(() => {
    if (validateAll(group)) {
      props.onSave(group);
    }
  }, [validateAll, group, props]);

  const cancel = useCallback(() => {
      props.onCancel();
  }, [props]);

  const fetchMonitorList = useCallback((text: string, isLoadingNextPage: boolean = false) => {
    if (team) {
      setIsLoadingMonitors(true);
      if (cancelToken.current !== undefined) {
        cancelToken.current.cancel(cancelTokenMessage);
      }

      cancelToken.current = axios.CancelToken.source();

      axios.get(`${apiBaseUrl}/${team.uuid}/monitors`, {
          params: {
            short: true,
            text: text,
            pageSize: pageSizeMonitors,
            // length of monitorItems without the new item entry (monitorItems.length - 1)
            recordOffset: isLoadingNextPage && monitorItems ? monitorItems.length - 1 : 0
          },
          cancelToken: cancelToken.current.token
        })
        .then(response => {
          let items = response.data.monitors.map((m: Monitor) => {
            const alreadyAdded = group.monitors?.some(s => s.uuid === m.uuid);
            return new ComboBoxItem(
              m.name,
              alreadyAdded ? ComboBoxItemType.Selected : ComboBoxItemType.Normal,
              m,
              alreadyAdded);
          });

          if (isLoadingNextPage && monitorItems) {
            items = monitorItems.concat(items);
          } else {
            items = [monitorNewItem, ...items];
          }
          setMonitorsCount(response.data.paginator.recordsCount);
          setMonitorItems(items);
        })
        .catch(error => handleRequestError(error))
        .finally(() => setIsLoadingMonitors(false));
    }
  }, [group.monitors, monitorItems, team, handleRequestError]);

  const onFocusMonitors = useCallback(() => {
    fetchMonitorList(monitorInputValue as string);
  }, [fetchMonitorList, monitorInputValue]);

  const onScrollMonitorItems = useCallback((percent: number) => {
    // itemsCount is the total of records that could be fetched, if this value is not greater
    // than the current statusItems fetched, that means the total of records already has been
    // fetched
    if (percent >= 90 && !isLoadingMonitors && (!monitorItems || monitorsCount > monitorItems.length)) {
      scrollMonitorsToBottom.current = false
      fetchMonitorList(monitorInputValue as string, true);
    }
  }, [fetchMonitorList, isLoadingMonitors, monitorInputValue, monitorItems, monitorsCount]);

  useLayoutEffect(() => {
    if (refMonitors.current && scrollMonitorsToBottom.current) {
      refMonitors.current.scrollTop = refMonitors.current?.scrollHeight;
      scrollMonitorsToBottom.current = false;
    }
  }, [group.monitors]);

  useEffect(() => {
    if (previousDebouncedMonitorInputValue !== debouncedMonitorInputValue) {
      fetchMonitorList(debouncedMonitorInputValue as string);
    }
  }, [debouncedMonitorInputValue, fetchMonitorList, previousDebouncedMonitorInputValue]);

  useEffect(() => {
    if (props.errorsOnServer) {
      resetErrorAllFromServer(props.errorsOnServer);
    }

  }, [props.errorsOnServer, resetErrorAllFromServer])


  useEffect(() => {
    setHasNonFreeInterval(
      group !== undefined &&
      group.monitors !== undefined &&
      products !== undefined &&
      group.monitors.some(monitor => {
      return monitor &&
        monitor.intervalId &&
        products.some(p =>
          p.monitorInterval.id === monitor.intervalId && (p.prices && p.prices.length > 0))
    }));
  }, [group, products])


  return (
    <>
      <form onSubmit={e => { e.preventDefault(); save(); }} className="d-f fb-d-c">
        <div className="d-f fb-d-c">
          <TextInput
            autoFocus={true}
            label={t("Name")}
            value={group.name}
            placeholder={t("Enter Name")}
            onChange={e => onChangeField("name", e)}
            onBlur={e => onBlurField("name", e)}
            error={errors.fields.name}
            maxLength={50}
            type="text" />
        </div>

        <div className="d-f fb-d-c m-t-1 m-t-2-M">
          <TextInput
            label={t("Title (optional)")}
            value={group.title}
            placeholder={t("Enter Title")}
            onChange={e => onChangeField("title", e)}
            onBlur={e => onBlurField("title", e)}
            error={errors.fields.title}
            maxLength={50}
            helpTip={
              <div>
                When filled out, it appears as the title<br />
                on the Status Pages.
              </div>
            }
            type="text" />
        </div>

        <div className="d-f fb-d-c m-t-1 m-t-2-M">
          <TextInput
            label={t("Description (optional)")}
            value={group.description}
            placeholder={t("Enter Description")}
            onChange={e => onChangeField("description", e)}
            onBlur={e => onBlurField("description", e)}
            error={errors.fields.description}
            maxLength={250}
            type="text" />
        </div>
        <div>
          <Colors
            color={group.color}
            error={errors.fields.color}
            onChange={e => onChangeField("color", e)} />
        </div>

        {!props.disallowSetMonitors ?
          <div className="d-f fb-d-c m-t-1 m-t-2-M">
            <div className="f-s-1 f-s-1.25-M">{t("Monitors")}</div>
            <div className="d-f fb-d-c m-t-0.5 w-100% b-s-s-M b-w-1px-M b-c-lightestGray-M p-h-2-M p-v-1.5-M">
              <div ref={refMonitors} className="max-h-12 max-h-16-M of-a">
                <ReactSortable
                  list={group.monitors as any[] || []}
                  setList={(newList) => setGroup({ ...group, monitors: newList as Monitor[] || [] })}>
                  {group.monitors?.map( (m, i) =>
                    <div className="w-100% m-b-1 c-g h-3 bg-c-nw p-l-0.5 p-r-1 p-v-0.5 d-f a-i-c" key={i}>
                      <div className="fb-n d-f a-i-c">
                        <Reorder />
                      </div>
                      <div className="fb-a m-h-0.5 ws-n of-h t-o-e">
                        { m.name }
                      </div>
                      <div className="fb-n d-f a-i-c c-p" onClick={() => removeMonitor(m)}>
                        <X />
                      </div>
                    </div>
                  )}
                </ReactSortable>
              </div>

              <ComboBox
                value={monitorInputValue}
                defaultFocusedIndex={monitorDefaultFocusedIndex}
                onInputValueChange={onChangeMonitorInputValue}
                onFocus={onFocusMonitors}
                onSelect={onSelectMonitor}
                onBlur={onBlurMonitors}
                placeholder={t(`Select or add a Monitor...`)}
                options={ monitorItems }
                pageSize={ pageSizeMonitors }
                showSpinner={ isLoadingMonitors }
                onScroll={ onScrollMonitorItems } />
            </div>
          </div>
          : null
        }

        {!productsNonFreeEnabled && hasNonFreeInterval ?
          <div className="d-f m-t-2 j-c-e t-c-gray">
            {props.noRedirectToCheckout ?
              t("As the chosen Interval is non-free, you will need to add a Payment Method.")
            : group.uuid ?
              t("By clicking on the Save button, you will be redirected to add a Payment Method.")
            :
              t("By clicking on the Add Monitor button, you will be redirected to add a Payment Method.")
            }
          </div>
        : null}

        <div className="d-f fb-d-cr fb-d-r-M m-b-1 m-t-1 m-b-2-M j-c-e a-i-c w-100%">
          {props.isSaving ?
            <Spinner type={"primary"} />
          :
            <>
              <div onClick={cancel} className="d-f t-c-g f-s-1.25 c-p p-t-1 p-t-0-M j-c-e-M">
                {t("Cancel")}
              </div>
              <div className="m-l-1-M w-100% w-auto-M">
                <Button type="primary" submit={true} fullWidth="mobile">
                  { group.uuid ? t("Save") : t("Add Group") }
                </Button>
              </div>
            </>
          }
        </div>

      </form>

      <Modal
        open={isAddingMonitor}
        onClose={ () => setIsAddingMonitor(false) }
        title={t("New Monitor")}>
        <MonitorNew
          onSave={onNewMonitor}
          onCancel={ () => setIsAddingMonitor(false) }
          noRedirectToCheckout={true}
          disallowSetGroups={true} />
      </Modal>
    </>
  );
}

import React from "react";
import { getCheckBoxZise } from "../Helper";


type MonitorIconProps = {
  color?: string
  size?: string
}

export const MonitorIcon: React.FC<MonitorIconProps> = (props) => {
  const color = props.color ? props.color : "#4A4A4A"
  const size = getCheckBoxZise(props.size);

  return (
    <svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill={color}></circle>
    </svg>
  );
}
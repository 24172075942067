import React, { useState, useCallback } from 'react';
import { BaseInputOnChangeEvent, BaseInputOnBlurEvent } from '../event/BaseInputEvent';

export type SwitchProps = {
  activated?: boolean
  disabled?: boolean
  onBlur?: (event: BaseInputOnBlurEvent) => void
  onChange?: (event: BaseInputOnChangeEvent) => void
}

export const Switch: React.FC<SwitchProps> = (props) => {
  const [ dirty, setDirty ] = useState(false);
  const [ touched, setTouched ] = useState(false);

  const onChange = useCallback(() => {
    if (!props.disabled) {
      setDirty(true);
      if (props.onChange) {
        props.onChange(new BaseInputOnChangeEvent(!props.activated, true, touched));
      }
    }
  }, [props, touched]);

  const onBlur = useCallback(() => {
    setTouched(true);
    if (props.onBlur) {
      props.onBlur(new BaseInputOnBlurEvent(props.activated || false, dirty, true));
    }
  }, [props, dirty]);

  return (
    <div
      className={`h-1.5 d-f a-i-c ${props.disabled ? "" : " c-p"}`}
      tabIndex={0}
      onClick={ onChange }
      onBlur={ onBlur }>
      <div
        className={`h-1 w-3 d-f a-i-c b-r-0.5 ${props.activated ? props.disabled ? "j-c-e bg-c-gray" : "j-c-e bg-c-mg" : "j-c-s bg-c-lightestGray"}`}>
        <div className={`h-1.5 w-1.5 b-r-0.75 ${props.activated ? "bg-c-g" : "bg-c-gray"}`}></div>
      </div>
    </div>
  );
}
import React, { ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Button, SecondaryButton, Spinner } from '..';
import { XModal } from '../../images';
import { ButtonType } from '../button/Button';

export type ModalAction = {
  title?: string,
  onAction: () => void,
  type?: ButtonType
}

type ModalProps = {
  value?: boolean
  open?: boolean
  title?: string
  subtitle?: string
  description?: string
  noPadding?: boolean
  onClose: () => void
  closeWhenClickOutside?: boolean
  primaryAction?: ModalAction
  secondaryActions?: ModalAction[]
  isSaving?: boolean
  children?: ReactNode
}

export const Modal: React.FC<ModalProps> = (props) => {

  const { t } = useTranslation();

  useEffect(() => {
    if (props.open) {
      document.body.classList.add("of-h");
    } else {
      document.body.classList.remove("of-h")
    }
    return () => {
      document.body.classList.remove("of-h")
    };
  }, [props.open])

  return (
    props.open ?
      ReactDOM.createPortal(
        <div
          onClick={() => props.closeWhenClickOutside ? props.onClose() : null}
          className="pos-f pos-t-0 pos-l-0 pos-r-0 pos-b-0 w-100% h-100% bg-c-backPopup d-f a-i-s j-c-c z-999 of-s p-v-0 p-v-3-M">
          <div className="d-f fb-d-c bg-c-w w-100% max-w-46 min-h-16 m-h-a t-c-nb">
            <div className="h-4 fb-n d-f a-i-c b-sb-s b-c-lightestGray b-w-1px p-l-2 p-r-1.5">
              <div className="fb-a d-f a-i-c of-h">
                <div className="f-w-300 f-s-1.5 f-s-2-M of-h ws-n t-o-e">
                  { props.title }
                </div>
                <div className="f-s-1 m-l-1.5 ws-n of-h t-o-e">
                  { props.subtitle }
                </div>
                <div className="f-s-1 f-w-300 m-l-0.5 ws-n of-h t-o-e">
                  { props.description }
                </div>
              </div>
              <div onClick={props.onClose} className="fb-n c-p">
                <XModal />
              </div>
            </div>
            <div className={`fb-a of-y-a ${props.noPadding ? "p-0" : "p-1 p-2-M"}`}>
              { props.children }
            </div>
            { props.primaryAction || props.secondaryActions ?
              <div className="d-f fb-d-cr fb-d-r-M fb-a h-4-M a-i-c j-c-e p-v-2 p-h-2 p-h-2-M b-st-s b-w-1px b-c-lightestGray">
                { props.isSaving ?
                  <Spinner type={"primary"}/>
                :
                  <>
                    { props.secondaryActions?.map( (action, index) =>
                      <div key={index} className="d-f j-c-e p-r-2-M m-t-1 m-t-0-M">
                        <SecondaryButton type={action.type} onClick={action.onAction} fullWidth="mobile">
                          {t(action.title || "")}
                        </SecondaryButton>
                      </div>)
                    }
                    { props.primaryAction ?
                      <div className="d-f j-c-e w-100% w-initial-M">
                        <Button type={props.primaryAction.type} submit={true} onClick={props.primaryAction.onAction} fullWidth="mobile">
                          {t(props.primaryAction.title || "")}
                        </Button>
                      </div>
                      :
                      null
                    }
                  </>
                }
              </div>
              : null
            }
          </div>
        </div>, document.body)
      : null
  );
}
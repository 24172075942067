import React, { useState, useCallback, useEffect } from 'react';
import { CreateApiTokenReq, ApiToken } from '../model';
import { TextInput, Button, BaseInputOnChangeEvent, Checkbox, Spinner } from '../ui';
import { Validations, useValidator } from '../validator';
import { isFieldStringEmpty } from '../Validations';
import { Errors } from '../validator/Validator';
import { useTranslation } from 'react-i18next';

const v = new Validations(new ApiToken());
v.addField("name", (v: any) => isFieldStringEmpty(v));

type SettingsApiTokenFormProps = {
  apiToken: ApiToken
  onCancel: () => void
  onSave: (apiToken: ApiToken) => void
  errorsOnServer: Errors<ApiToken> | undefined
  isSaving?: boolean
}

export const SettingsApiTokenForm: React.FC<SettingsApiTokenFormProps> = (props) => {

  const { t } = useTranslation();
  const [ apiToken, setApiToken ] = useState(props.apiToken);
  const { errors, validateField, validateAll, resetErrorAllFromServer } = useValidator(v);

  const onChangeField = useCallback(
    (fieldName: keyof ApiToken, event: BaseInputOnChangeEvent) => {
      const value = event.value;
      if (event.dirtyAndTouched) {
        validateField(fieldName, event.value);
      }
      const _apiToken = { ...apiToken } as ApiToken;
      _apiToken[fieldName] = value as never;

      setApiToken(_apiToken);

    }, [apiToken, validateField]);

  const onBlurField = useCallback(
    (fieldName: keyof CreateApiTokenReq, event: BaseInputOnChangeEvent) => {
      if (event.dirty) {
        validateField(fieldName, event.value);
      }
    }, [validateField]);

  const save = useCallback(() => {
    if (validateAll(apiToken)) {
      props.onSave(apiToken);
    }
  }, [validateAll, apiToken, props]);

  const cancel = useCallback(() => {
      props.onCancel();
  }, [props]);

  useEffect(() => {
    if (props.errorsOnServer) {
      resetErrorAllFromServer(props.errorsOnServer);
    }

  }, [props.errorsOnServer, resetErrorAllFromServer])

  return (
    <form onSubmit={e => { e.preventDefault(); save(); }} className="d-f fb-d-c">
      { !props.onSave ?
        <div className="f-w-300 f-s-1.5 f-s-2-M m-t-1 m-t-1.5-M m-b-1">{t("New ApiToken")}</div>
      : null
      }
      <div className="d-f fb-d-c m-t-1">
        <TextInput
          autoFocus={true}
          label={t("Name")}
          value={apiToken.name}
          placeholder={t("Enter Name")}
          onChange={e => onChangeField("name", e)}
          onBlur={e => onBlurField("name", e)}
          error={errors.fields.name}
          maxLength={50}
          type="text" />
      </div>

      <div className="d-f m-t-1 m-t-2-M">
        <div className="d-f">
          <Checkbox
            disabled={true}
            label={t("Read (default)")}
            checked={true} />
        </div>
        <div className="d-f m-l-2">
          <Checkbox
            checked={apiToken.allowWrite}
            label={t("Write")}
            onChange={(e) => onChangeField("allowWrite", e)} />
        </div>
      </div>

      <div className="d-f fb-d-cr fb-d-r-M m-t-1 m-b-1 m-b-2-M j-c-e a-i-c w-100%">
        {props.isSaving ?
          <Spinner type={"primary"}/>
        :
          <>
            <div onClick={cancel} className="d-f t-c-g f-s-1.25 c-p p-t-1 p-t-0-M j-c-e-M">
              {t("Cancel")}
            </div>
            <div className="m-l-1-M w-100% w-auto-M">
              <Button type="primary" submit={true} fullWidth={"mobile"}>
                { apiToken.uuid ? t("Save") : t("Add API Token") }
              </Button>
            </div>
          </>
        }
      </div>
    </form>
  );
}

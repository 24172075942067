import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BaseInputOnSelectEvent, Button, CheckboxWithStatus, ComboBox, ComboBoxItem, ComboBoxItemType, Modal, RadioButton } from '../ui';
import { useTranslation } from 'react-i18next';
import { Alert, Group, MonitorsFilterModel, Page } from '../model';
import axios, { CancelTokenSource } from 'axios';
import { apiBaseUrl, cancelTokenMessage, MonitorStatusType } from '../Consts';
import { useHandleRequestError } from '../hooks';
import { useDebounce } from '../hooks/UseDebounce';
import { usePrevious } from '../hooks/UsePrevious';
import { useRecoilValue } from 'recoil';
import { teamState } from '../atoms';

const pageSizeEntityItems = 10;

const statusTypes: MonitorStatusType[] = [
  MonitorStatusType.Down,
  MonitorStatusType.Disrupted,
  MonitorStatusType.Degraded,
  MonitorStatusType.Maintenance,
  // StatusType.Paused,
  MonitorStatusType.Up
] as MonitorStatusType[]


type MonitorsFilterEditorProps = {
  filterModel: MonitorsFilterModel
  onApply: (filterModel: MonitorsFilterModel) => void
  onCancel: () => void
}

enum EntityFilterType {
  Group = "group",
  Page = "page",
  Alert = "alert"
}

export const MonitorsFilterEditor: React.FC<MonitorsFilterEditorProps> = (props) => {

  const { t } = useTranslation();
  const team = useRecoilValue(teamState);

  const [ filterModel, setFilterModel ] = useState(props.filterModel);
  const [ entityFilterType, setEntityFilterType ] = useState(
    filterModel.alertUuid ?
      EntityFilterType.Alert :
      filterModel.pageUuid ?
        EntityFilterType.Page :
        EntityFilterType.Group
  )
  const { handleRequestError } = useHandleRequestError();
  const [ isLoadingEntityItems, setIsLoadingEntityItems ] = useState(false);
  const cancelToken = useRef<CancelTokenSource>();
  
  const [ entityItems, setEntityItems ] = useState<ComboBoxItem[]>();
  const [ entityItemsCount, setEntityItemsCount ] = useState(0);
  const [ entityDefaultFocusedIndex, setEntityDefaultFocusedIndex ] = useState<number>(-1);
  
  const [ entityTitle, setEntityTitle ] = useState(props.filterModel.title);
  const debouncedEntityTitle = useDebounce(entityTitle, 500);
  const previousDebouncedEntityTitle = usePrevious(debouncedEntityTitle);

  const selectEntityPlaceholder = useMemo(() => {
    switch (entityFilterType) {
      case EntityFilterType.Page:
        return t("Select a page...");
      case EntityFilterType.Group:
        return t("Select a group...");
      case EntityFilterType.Alert:
        return t("Select an alert...");
    }
  }, [entityFilterType])

  const fetchEntityList = useCallback((text: string, isLoadingNextPage: boolean = false) => {
    if (team) {
      setIsLoadingEntityItems(true);
      if (cancelToken.current !== undefined) {
        cancelToken.current.cancel(cancelTokenMessage);
      }

      cancelToken.current = axios.CancelToken.source();

      const setResponse = (
        entities: Page[] | Group[] | Alert[],
        recordsCount: number,
        emptyMessage: string) => {
        let items = entities.map((e: Page | Group | Alert) => {
          return new ComboBoxItem(
            e.name,
            ComboBoxItemType.Normal,
            e,
            false);
        });
        if (isLoadingNextPage && entityItems) {
          items = entityItems.concat(items);
        }
        items = items.map((i: ComboBoxItem) => {
          const selected = filterModel.pageUuid === i.data.uuid;
          return selected ?
              {...i, type: ComboBoxItemType.Selected} as ComboBoxItem
            :
              i;
        });
        if (recordsCount === 0 && text === "") {
          items = [new ComboBoxItem(emptyMessage, ComboBoxItemType.Normal, undefined, true)]
        }
        setEntityItemsCount(recordsCount);
        setEntityItems(items);
      }

      switch (entityFilterType) {
      case EntityFilterType.Page:
        axios.get(`${apiBaseUrl}/${team.uuid}/pages`, {
          params: {
            short: true,
            text: text,
            pageSize: pageSizeEntityItems,
            recordOffset: isLoadingNextPage && entityItems ? entityItems.length : 0
          },
          cancelToken: cancelToken.current.token
        })
          .then(response => {
            setResponse(
              response.data.pages as Page[],
              response.data.paginator.recordsCount,
              t("There are not pages"));
          })
          .catch(error => handleRequestError(error))
          .finally(() => setIsLoadingEntityItems(false));
        break;
      case EntityFilterType.Group:
        axios.get(`${apiBaseUrl}/${team.uuid}/groups`, {
          params: {
            short: true,
            text: text,
            pageSize: pageSizeEntityItems,
            recordOffset: isLoadingNextPage && entityItems ? entityItems.length : 0
          },
          cancelToken: cancelToken.current.token
        })
          .then(response => {
            setResponse(
              response.data.groups as Group[],
              response.data.paginator.recordsCount,
              t("There are not groups"));
          })
          .catch(error => handleRequestError(error))
          .finally(() => setIsLoadingEntityItems(false));
        break;
      case EntityFilterType.Alert:
        axios.get(`${apiBaseUrl}/${team.uuid}/alerts`, {
          params: {
            short: true,
            text: text,
            pageSize: pageSizeEntityItems,
            recordOffset: isLoadingNextPage && entityItems ? entityItems.length : 0
          },
          cancelToken: cancelToken.current.token
        })
          .then(response => {
            setResponse(
              response.data.alerts as Alert[],
              response.data.paginator.recordsCount,
              t("There are not alerts"));
          })
          .catch(error => handleRequestError(error))
          .finally(() => setIsLoadingEntityItems(false));
        break;
      }
    }
  }, [team, entityFilterType, entityItems, filterModel.pageUuid, filterModel.groupUuid, t, handleRequestError]);

  const onSelectEntity = useCallback((event: BaseInputOnSelectEvent) => {
    if (entityItems) {
      switch (entityFilterType) {
      case EntityFilterType.Page:
        const page = (entityItems[event.index].data as Page);
        setEntityTitle(page.name);
        setFilterModel({
          ...filterModel,
          title: page.name,
          pageUuid: page.uuid,
          pageFaviconUrl: page.faviconUrl,
          pageLogoUrl: page.logoUrl
        });
        break;
      case EntityFilterType.Group:
        const group = (entityItems[event.index].data as Group);
        setEntityTitle(group.name);
        setFilterModel({
          ...filterModel,
          groupUuid: group.uuid,
          title: group.name,
          groupColor: group.color
        });
        break;
      case EntityFilterType.Alert:
        const alert = (entityItems[event.index].data as Alert);
        setEntityTitle(alert.name);
        setFilterModel({
          ...filterModel,
          alertUuid: alert.uuid,
          title: alert.name,
        });
      }
      const _entityItems = [...entityItems];
      _entityItems[event.index].type = ComboBoxItemType.Selected;
      setEntityItems(_entityItems);
    }
    setEntityDefaultFocusedIndex(-1);
  }, [filterModel, entityItems, entityFilterType]);

  const onFocusEntity = useCallback(() => {
    fetchEntityList(entityTitle ?? "");
  }, [fetchEntityList, entityTitle]);

  const onBlurEntity = useCallback(() => {
    setEntityDefaultFocusedIndex(-1);
    if (!entityTitle) {
      setFilterModel({
        ...filterModel,
        groupUuid: undefined,
        pageUuid: undefined,
        title: undefined
      });
    } else if (entityTitle !== filterModel.title) {
      if (filterModel.title) {
        setEntityTitle(filterModel.title)
      } else {
        setEntityTitle("");
      }
    }
  }, [filterModel, entityTitle]);

  const onChangeEntityTitle = useCallback((title: string) => {
    setEntityTitle(title);
    setEntityDefaultFocusedIndex(0);
  }, []);

  const onScrollEntity = useCallback((percent: number) => {
    // itemsCount is the total of records that could be fetched, if this value is not greater
    // than the current entitys fetched, that means the total of records already has been
    // fetched
    if (percent >= 90 && !isLoadingEntityItems && (!entityItems || entityItemsCount > entityItems.length)) {
      fetchEntityList(entityTitle ?? "", true);
    }
  }, [fetchEntityList, entityItems, entityItemsCount, entityTitle, isLoadingEntityItems]);

  const onClickStatus = useCallback((statusType: MonitorStatusType) => {
    const _status: MonitorStatusType[] = filterModel.status ? [...filterModel.status] : [];
    if (_status.includes(statusType)) {
      _status.splice(_status.indexOf(statusType), 1);
    } else {
      _status.push(statusType)
    }
    setFilterModel({...filterModel, status: _status.length > 0 ? _status : undefined})
  }, [filterModel]);

  const onChangeEntityFilterType = useCallback((entityFilterType: EntityFilterType) => {
    setEntityFilterType(entityFilterType)
    setEntityTitle("");
    setFilterModel(filterModel => {
      return {
        ...filterModel,
        pageUuid: undefined,
        groupUuid: undefined,
        alertUuid: undefined,
        title: undefined
      } as MonitorsFilterModel
    })
    setEntityItems([]);
  }, []);

  useEffect(() => {
    if (previousDebouncedEntityTitle !== debouncedEntityTitle) {
      fetchEntityList(debouncedEntityTitle ?? "");
    }
  }, [debouncedEntityTitle, fetchEntityList, previousDebouncedEntityTitle]);

  return (
    <Modal
      open={true}
      onClose={ () => props.onCancel() }
      title={t("Monitors Filter")}
      noPadding={true}>
      <form onSubmit={(e) => { e.preventDefault(); props.onApply(filterModel); }} className="p-h-1 p-h-2-M m-t-2">
        <div className="d-f fb-w-w m-b-1">
          <div className="m-r-2">
            <RadioButton
              onChange={() => onChangeEntityFilterType(EntityFilterType.Group)}
              checked={entityFilterType === EntityFilterType.Group}
              label={t("Group")} />
          </div>
          <div className="m-r-2">
            <RadioButton
              onChange={() => onChangeEntityFilterType(EntityFilterType.Page)}
              checked={entityFilterType === EntityFilterType.Page}
              label={t("Page")} />
          </div>
          <div className="m-r-2">
            <RadioButton
              onChange={() => onChangeEntityFilterType(EntityFilterType.Alert)}
              checked={entityFilterType === EntityFilterType.Alert}
              label={t("Alert")} />
          </div>
        </div>

        <ComboBox
          value={entityTitle}
          defaultFocusedIndex={entityDefaultFocusedIndex}
          onInputValueChange={(e) => onChangeEntityTitle(e.value as string)}
          onFocus={onFocusEntity}
          onSelect={onSelectEntity}
          onBlur={onBlurEntity}
          placeholder={selectEntityPlaceholder}
          options={ entityItems }
          showSpinner={ isLoadingEntityItems }
          pageSize={ pageSizeEntityItems }
          onScroll={ onScrollEntity } />

        <div className="m-v-2">
          <div className="f-w-400 f-s-1.25">Status</div>
          <div className="d-f fb-w-w w-100% m-t-0.5 b-s-s-M b-w-1px-M b-c-lightestGray-M p-h-2-M p-t-1.5-M p-b-0.5-M">
            {statusTypes.map(st =>
              <div key={st} className="d-f a-i-c m-b-1 m-r-2" title={st} style={{textTransform: "capitalize"}}>
                <CheckboxWithStatus
                  onChange={() => onClickStatus(st)}
                  checked={filterModel.status && filterModel.status.includes(st)}
                  status={st}
                  size={"large"} />
              </div>)
            }
          </div>
        </div>
        <div className="d-f j-c-e m-b-2">
          <div className="d-f fb-n j-c-e a-i-c">
            <div onClick={props.onCancel} className="t-c-g f-s-1.25 c-p">
              {t("Cancel")}
            </div>
            <div className="m-l-1">
              <Button type="primary" submit={true}>
                {t("Apply")}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BaseInputOnChangeEvent, BaseInputOnBlurEvent } from '../event/BaseInputEvent';
import { statusToColor } from "../../Helper";
import { MonitorStatusType, SelectorStatusType, StatusType } from "../../Consts";
import { CheckMark } from '../images';
import { CSSTransition } from "react-transition-group";


type CheckboxWithStatusProps = {
  checked?: boolean
  status: StatusType
  size?: string
  disabled?: boolean
  urlStatusPending?: boolean
  onChange?: (event: BaseInputOnChangeEvent) => void
  onBlur?: (event: BaseInputOnBlurEvent) => void
  unselectable?: boolean
}

export const CheckboxWithStatus: React.FC<CheckboxWithStatusProps> = (props) => {

  const nodeRef = React.useRef(null)
  const [ dirty, setDirty ] = useState(false);
  const [ touched, setTouched ] = useState(false);

  const newStatus = useMemo(() => props.status, [props.status]);
  const [status, setStatus] = useState(props.status);
  const color = statusToColor(props.status);

  const svgPath = useMemo<React.ReactElement>(() => {
    switch (status) {
      case MonitorStatusType.Up:
      case MonitorStatusType.Degraded:
      case MonitorStatusType.Disrupted:
        return (
          <React.Fragment>
            <path
              fill="#fff"
              d="M7.99 18L5 15l2.99-3v2.25H19.2v1.5H7.99V18zM16.21 12l2.99-3-2.99-3v2.25H5v1.5h11.21V12z"
            ></path>
          </React.Fragment>
        );
      case MonitorStatusType.Starting:
      case MonitorStatusType.Down:
        return (
          <path
            fill="#fff"
            d="M16.053 15L19 12l-2.947-3v2.25H5v1.5h11.053V15z"
          ></path>
        );
      case MonitorStatusType.Maintenance:
      case SelectorStatusType.Maintaining:
        return (
          <path
            fill="#fff"
            d="M19.79 17.078l-6.608-6.649c.654-1.68.29-3.653-1.09-5.04-1.452-1.462-3.63-1.754-5.373-.95L9.842 7.58 7.663 9.772 4.468 6.63c-.871 1.754-.508 3.945.944 5.407 1.38 1.388 3.34 1.753 5.01 1.095l6.609 6.649a.699.699 0 001.017 0l1.67-1.68c.363-.293.363-.804.073-1.023z"
          ></path>
        );
      case MonitorStatusType.Paused:
      case SelectorStatusType.Pausing:
      case SelectorStatusType.Deleting:
        return (
          <path fill="#fff" d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"></path>
        );
      case MonitorStatusType.RequiresPaymentMethod:
        return (
          <>
            <path
              fill="#fff"
              d="M15.333 13.8H19.5v2.6h-4.167v-2.6zM3.667 8.6c0-.479.373-.867.833-.867h5.833c.46 0 .834.388.834.867s-.373.867-.834.867H4.5c-.46 0-.833-.388-.833-.867z"
            ></path>
            <path
              fill="#fff"
              fillRule="evenodd"
              d="M4.5 6C3.12 6 2 7.164 2 8.6v7.8C2 17.836 3.12 19 4.5 19h15c1.38 0 2.5-1.164 2.5-2.6V8.6C22 7.164 20.88 6 19.5 6h-15zm15 .867h-15c-.92 0-1.667.776-1.667 1.733v7.8c0 .957.747 1.733 1.667 1.733h15c.92 0 1.667-.776 1.667-1.733V8.6c0-.957-.747-1.733-1.667-1.733zM15.333 13.8H19.5v2.6h-4.167v-2.6zM4.5 7.733h5.833c.46 0 .834.388.834.867s-.373.867-.834.867H4.5c-.46 0-.833-.388-.833-.867s.373-.867.833-.867z"
              clipRule="evenodd"
            ></path>
          </>
        );
      default:
        return <React.Fragment></React.Fragment>;
    }
  }, [status])

  const onChange = useCallback(() => {
    const checked = !props.checked;
    const dirty = true;
    setDirty(dirty);
    if (props.onChange) {
      props.onChange(new BaseInputOnChangeEvent(checked, dirty, touched))
    }
  }, [props, touched]);

  const onBlur = useCallback(() => {
    setTouched(true);
    if (props.onBlur) {
      props.onBlur(new BaseInputOnBlurEvent(props.checked || false, dirty, true));
    }
  }, [props, dirty]);

  useEffect(() => {
    if (newStatus !== status) {
      const timeout = setTimeout(() =>  {
        setStatus(newStatus);
      }, 250);

      return () => {
        clearTimeout(timeout);
      }
    }
  }, [newStatus, status]);

  return (
    <div
      tabIndex={0}
      onClick={props.disabled ? undefined : onChange}
      onBlur={onBlur}
      className={"d-f pos-re " + (!props.unselectable ? "c-p" : "")}>
      <CSSTransition
        nodeRef={nodeRef}
        unmountOnExit
        classNames="fade"
        timeout={250}
        in={newStatus === status}>
        <div
          className={`d-f a-i-c j-c-c t-c-w b-r-50% b-s-s b-w-1px + ${props.size === "large" ? "h-2 w-2" : "w-1.5 h-1.5"}`}
          style={{borderColor: color, backgroundColor: color}}>
          { props.checked ?
            <CheckMark />
          :
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox={`0 0 24 24`}>
              { svgPath }
            </svg>
          }
        </div>
      </CSSTransition>
      {props.disabled || props.urlStatusPending ?
        <div className="bg-c-w pos-a pos-t-0 pos-b-0 pos-l-0 pos-r-0 z-1" style={{opacity: "0.5"}}>
        </div>
      : null}
    </div>
  );
}

import React from 'react';

export const IconSlack = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.85351 20.1575C6.85351 21.9969 5.36689 23.4835 3.52752 23.4835C1.68815 23.4835 0.201538 21.9969 0.201538 20.1575C0.201538 18.3182 1.68815 16.8315 3.52752 16.8315H6.85351V20.1575ZM8.5165 20.1575C8.5165 18.3182 10.0031 16.8315 11.8425 16.8315C13.6819 16.8315 15.1685 18.3182 15.1685 20.1575V28.4725C15.1685 30.3119 13.6819 31.7985 11.8425 31.7985C10.0031 31.7985 8.5165 30.3119 8.5165 28.4725V20.1575Z"
        fill="#E01E5A"
      />
      <path
        d="M11.8425 6.80285C10.0032 6.80285 8.51655 5.31623 8.51655 3.47686C8.51655 1.63749 10.0032 0.150879 11.8425 0.150879C13.6819 0.150879 15.1685 1.63749 15.1685 3.47686V6.80285H11.8425ZM11.8425 8.49104C13.6819 8.49104 15.1685 9.97765 15.1685 11.817C15.1685 13.6564 13.6819 15.143 11.8425 15.143H3.50238C1.66301 15.143 0.176392 13.6564 0.176392 11.817C0.176392 9.97765 1.66301 8.49104 3.50238 8.49104H11.8425Z"
        fill="#36C5F0"
      />
      <path
        d="M25.1717 11.817C25.1717 9.97765 26.6583 8.49104 28.4976 8.49104C30.337 8.49104 31.8236 9.97765 31.8236 11.817C31.8236 13.6564 30.337 15.143 28.4976 15.143H25.1717V11.817ZM23.5087 11.817C23.5087 13.6564 22.022 15.143 20.1827 15.143C18.3433 15.143 16.8567 13.6564 16.8567 11.817V3.47686C16.8567 1.63749 18.3433 0.150879 20.1827 0.150879C22.022 0.150879 23.5087 1.63749 23.5087 3.47686V11.817Z"
        fill="#2EB67D"
      />
      <path
        d="M20.1827 25.1465C22.022 25.1465 23.5087 26.6331 23.5087 28.4725C23.5087 30.3119 22.022 31.7985 20.1827 31.7985C18.3433 31.7985 16.8567 30.3119 16.8567 28.4725V25.1465H20.1827ZM20.1827 23.4835C18.3433 23.4835 16.8567 21.9969 16.8567 20.1575C16.8567 18.3182 18.3433 16.8315 20.1827 16.8315H28.5228C30.3622 16.8315 31.8488 18.3182 31.8488 20.1575C31.8488 21.9969 30.3622 23.4835 28.5228 23.4835H20.1827Z"
        fill="#ECB22E"
      />
    </svg>
  );
}
import React from 'react';
import { SettingsApiTokens } from "./SettingsApiTokens";
import { SettingsHmacSecretKey } from "./SettingsHmacSecretKey";

export const SettingsApi: React.FC = () => {

  return (
    <div className="d-f fb-d-c w-100% m-b-2 p-h-1">
      <div className="d-f fb-d-c m-t-4">
        <SettingsApiTokens />
      </div>
      <div className="d-f fb-d-c m-t-2">
        <SettingsHmacSecretKey />
      </div>
    </div>
  );
}
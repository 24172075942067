import React from "react";
import { useTranslation } from "react-i18next";
import { Monitor, MonitorTcpOptions } from "../model";
import { BaseInputOnAddEvent, BaseInputOnChangeEvent, BaseInputOnRemoveEvent, TextArea, TextInput  } from "../ui";
import { SelectionButton } from "../ui/selection-button/SelectionButton";
import { Errors } from "../validator/Validator";

type TcpOptionsProps = {
  monitor: Monitor
  isFreeInterval: boolean
  onChangeTcpOptionsField: (fieldName: keyof MonitorTcpOptions, event: BaseInputOnChangeEvent) => void
  onBlurTcpOptionsField: (fieldName: keyof MonitorTcpOptions, event: BaseInputOnChangeEvent) => void
  errorsResponse: Errors<MonitorTcpOptions>
  onAddHttpResponseStatus: (event: BaseInputOnChangeEvent) => void
  onRemoveHttpResponseStatus: (event: BaseInputOnRemoveEvent) => void
  httpResponseStatusValue?: string
  onChangeHttpResponseStatusValue: (event: BaseInputOnAddEvent) => void
  errorResponseStatusValue?: string
}

export const TcpOptions: React.FC<TcpOptionsProps> = (props) => {

  const { t } = useTranslation();

  const tcpOptions = props.monitor.tcpOptions;

  return <div className="d-f fb-d-c">
    
    {tcpOptions ?
      <>
        <div className="d-f fb-d-c a-i-s w-100%">
          <div className={`${props.isFreeInterval ? "t-c-lightGray" : ""}`}>
            {t("Data Request")}
          </div>
          <div className="min-h-6 w-100% m-t-0.5">
            <TextArea
              disabled={props.isFreeInterval}
              value={tcpOptions.dataRequest}
              error={props.errorResponseStatusValue}
              onBlur={e => props.onBlurTcpOptionsField("dataRequest", e )}
              onChange={e => props.onChangeTcpOptionsField("dataRequest", e )}
            />
          </div>

          <div className={`${props.isFreeInterval ? "t-c-lightGray" : ""} m-t-2`}>
            {t("Keyword Response")}
            <span className="m-l-1 t-c-gray">{t("(case insensitive)")}</span>
          </div>
          <div className="d-f fb-d-c w-100% m-t-0.5">
            <TextInput
              disabled={props.isFreeInterval}
              placeholder={t("Keyword Response")}
              onChange={e => props.onChangeTcpOptionsField("keywordResponse", e )}
              onBlur={e => props.onBlurTcpOptionsField("keywordResponse", e )}
              value={tcpOptions.keywordResponse}
              error={props.errorsResponse.fields.keywordResponse}
              suffix=
                {["Exists", "Not Exists"].map((label, i) =>
                <div key={i} className="m-r-0.5">
                  <SelectionButton
                    disabled={props.isFreeInterval}
                    onChange={e => props.onChangeTcpOptionsField("keywordResponseExists", new BaseInputOnChangeEvent(i === 0, e.dirty, e.touched))}
                    checked={(i === 0 && [null, undefined, true].some(v => v === tcpOptions.keywordResponseExists) || (i === 1 && tcpOptions.keywordResponseExists === false))}
                    label={label} />
                </div>
              )}
            />
          </div>
        </div>
      </>
    : null}
  </div>
}
import React, { useState, useCallback, useMemo } from 'react';
import { CreateGroupReq, Group } from '../model';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { apiBaseUrl } from "../Consts";
import { Errors } from '../validator/Validator';
import { GroupForm } from '../group-form';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { messageState, teamState } from '../atoms';
import { useHandleRequestError } from '../hooks';
import { Breadcrumbs, Loading } from '../ui';

type GroupNewProps = {
  onCancel?: () => void
  onSave?: (group: Group) => void
  disallowSetMonitors?: boolean
  noRedirectToCheckout?: boolean
}

export const GroupNew: React.FC<GroupNewProps> = (props) => {

  const { t } = useTranslation();
  const team = useRecoilValue(teamState);
  const { handleRequestErrorAvoidingPopup } = useHandleRequestError();
  const [ isSaving, setIsSaving ] = useState(false);
  const setMessage = useSetRecoilState(messageState);

  const [ errorsOnServer, setErrorsOnServer ] = useState<Errors<Group>>()
  const group = useMemo(() => {
    const g = new Group();
    g.monitors = [];
    return g;
  }, []);

  const history = useHistory();

  const onSave = useCallback((group: Group) => {
    if (team) {
      const request = new CreateGroupReq();
      request.color = group.color;
      request.description = group.description;
      request.name = group.name;
      request.title = group.title;
      request.monitors = group.monitors.map(m => m.uuid);
      request.noRedirectToCheckout = props.noRedirectToCheckout ?? false;
      setIsSaving(true);
      
      axios.post(`${apiBaseUrl}/${team.uuid}/groups`, request)
        .then(response => {
          if (response.data.checkoutUrl) {
            window.location.href = response.data.checkoutUrl;
            return
          } else if (props.onSave) {
            props.onSave(response.data.group as Group);
          } else {
            history.push(`/${team.slug}/groups?order=lastCreated&descending=true`)
          }
          if (response.data.checkoutError) {
            setMessage({
              type: "error",
              text: response.data.checkoutError
            })
          }
        })
        .catch(err => {
          handleRequestErrorAvoidingPopup(err);
          setErrorsOnServer(err.response.data);
        })
        .finally(() => setIsSaving(false));
    }
  }, [team, props, history, handleRequestErrorAvoidingPopup]);

  const onCancel = useCallback(() => {
    if (props.onCancel) {
      props.onCancel()
    } else if (history.length > 2) {
      history.goBack();
    } else {
      history.push(`/${team?.slug}/groups`)
    }
  }, [history, team, props])

  const componentForm = useCallback(() =>
    <GroupForm
      errorsOnServer={errorsOnServer}
      onSave={onSave}
      onCancel={onCancel}
      group={group}
      disallowSetMonitors={props.disallowSetMonitors}
      isSaving={isSaving}
    ></GroupForm>
  , [errorsOnServer, group, isSaving, onCancel, onSave, props.disallowSetMonitors]);

  if (!team ) {
    return <Loading />
  }

  return (
    !props.onSave ?
      <div className="d-f fb-d-c m-h-a w-100% max-w-44.5-M max-w-49-L p-h-1 p-h-2-M t-c-nb">
        <div className="m-t-1.5">
          <Breadcrumbs
            items={[
              { title: t("Groups"), link: `/${team.slug}/groups`},
              { title: t("New") },
            ]}
          />
        </div>
        <div className="f-w-300 f-s-1.5 f-s-2-M m-v-1 m-v-1.5-M">{t("New Group")}</div>
        {componentForm()}
      </div>
    : componentForm()
  )
}

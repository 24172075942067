import React, { useState, useCallback } from 'react';
import { Loading } from '../ui/loading/Loading';
import { Link, Redirect } from 'react-router-dom';
import { useHandleRequestError } from '../hooks';
import { apiBaseUrl } from '../Consts';
import axios from "axios";
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { invitationsState, teamsPaginatorState, teamsState, userState } from '../atoms';
import { GetTeamsRequest, Invitation, Paginator, Team } from '../model';
import { Modal } from '../ui';

export const Teams: React.FC = () => {

  const { t } = useTranslation();
  const { handleRequestError } = useHandleRequestError();
  const tr = t;

  const user = useRecoilValue(userState);
  const invitations = useRecoilValue(invitationsState);
  const [teamsPaginator, setTeamsPaginator] = useRecoilState(teamsPaginatorState);
  const setInvitations = useSetRecoilState(invitationsState);
  const setTeams = useSetRecoilState(teamsState);

  const teams = useRecoilValue(teamsState);

  const [ showUserConfirmedError, setShowUserConfirmedError ] = useState(false);
  const [ isEmailConfirmationResent, setIsEmailConfirmationResent ] = useState(false);
  const [ isSendingEmailConfirmation, setIsSendingEmailConfirmation ] = useState(false);
  const [ invitationDeclining, setInvitationDeclining ] = useState<Invitation>();

  const updateTeamsAndInvitations = useCallback((teams: Team[], teamsPaginator: Paginator, invitations: Invitation[]) => {
    setInvitations(invitations);
    setTeams(teams);
    setTeamsPaginator(teamsPaginator);
  }, [setInvitations, setTeams, setTeamsPaginator]);

  const fetchMoreTeams = useCallback(() => {
    if (teams && teamsPaginator) {
      const request = new GetTeamsRequest();
      request.recordOffset = teamsPaginator.recordOffset + teamsPaginator.pageSize;
      request.pageSize = teamsPaginator.pageSize;
      axios.get(`${apiBaseUrl}/teams`, { params: request })
        .then(response => {
          setTeams([...teams, ...response.data.teams]);
          setTeamsPaginator(response.data.teamsPaginator);
        })
        .catch(error => handleRequestError(error));
    }
  }, [setTeams, setTeamsPaginator, teams, teamsPaginator, handleRequestError]);

  const acceptInvitation = useCallback((invitationUuid: string) => {
    if (updateTeamsAndInvitations && user) {
      if (user.requiresConfirmation) {
        setShowUserConfirmedError(true);
      } else {
        axios.put(`${apiBaseUrl}/invitations/${invitationUuid}/accept`)
          .then(response => {
            updateTeamsAndInvitations(response.data.teams || [], response.data.teamsPaginator, response.data.invitations || []);
          })
          .catch(error => handleRequestError(error));
      }
    }
  }, [updateTeamsAndInvitations, user, handleRequestError]);

  const execDecline = useCallback(() => {
    if (updateTeamsAndInvitations && invitationDeclining) {
      axios.put(`${apiBaseUrl}/invitations/${invitationDeclining?.uuid}/decline`)
        .then(response => {
          updateTeamsAndInvitations(response.data.teams || [], response.data.teamsPaginator, response.data.invitations || []);
        })
        .catch(error => handleRequestError(error))
        .finally(() => setInvitationDeclining(undefined));
    }
  }, [updateTeamsAndInvitations, invitationDeclining, handleRequestError]);

  const resendConfirmation = useCallback(() => {
    setIsSendingEmailConfirmation(true);
    let timeout: ReturnType<typeof setTimeout>;
    axios.put(`${apiBaseUrl}/session/user/change-email/resend-confirmation`)
      .then(() => {
        setIsEmailConfirmationResent(true);
        timeout = setTimeout(() => setIsEmailConfirmationResent(false), 5000);
      })
      .catch(error => handleRequestError(error))
      .finally(() => setIsSendingEmailConfirmation(false));
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, [handleRequestError])


  if (!teams || !invitations) {
    return <Loading />;
  } else if (teams.length === 1 && invitations.length === 0) {
    return <Redirect to={`/${teams[0].slug}`} />
  } else {
    return (
      <div className="w-100% bg-c-nw d-f j-c-c min-h-100vh">
        <div className="d-f w-100% fb-d-c p-h-0 p-h-1-M p-v-2 max-w-66">

          { invitations && invitations.length > 0 ?
            <React.Fragment>
              <div className="f-s-2 f-w-300 t-c-nb m-t-4 m-b-1 m-b-2-M p-h-1">{tr("Team Invitations")}</div>
              <div className="d-f fb-w-w">
                { invitations?.map((invitation, i) => (
                  <div key={i} className="w-100% w-33.3%-M w-25%-L p-h-1">
                    <div className="w-100% fb-d-c d-f b-s-s b-w-1px b-r-4px b-c-g p-t-1.5 p-b-1 p-h-1 m-b-2.3 bg-c-w t-c-b">
                      <div className="f-s-1 f-w-300 ws-n of-h t-o-e">
                        {tr("Join the Team")}
                      </div>
                      <div className="f-s-1.5 f-w-600 ws-n of-h t-o-e">
                        {invitation.teamName}
                      </div>
                      <div className="fb-n d-f j-c-sb p-t-1 a-i-c">
                        <div onClick={() => setInvitationDeclining(invitation)} className="fb-n t-c-r c-p">
                          {tr("Decline")}
                        </div>
                        <div onClick={() => acceptInvitation(invitation.uuid)} className="d-f a-i-c fb-n t-a-r t-c-g c-p">
                          <div className="m-r-0.5">
                            {tr("Join")}
                          </div>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" fill="#168D13"/>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                )) }
              </div>
            </React.Fragment>
          : null }

          <div className="f-s-2 f-w-300 t-c-nb m-b-1 m-b-2-M p-h-1">{tr("Teams")}</div>
          { teams.length > 0 ?
            <div className="d-f fb-w-w">
              { teams.map((t, i) => (
                <div key={i} className="w-100% w-33.3%-M w-25%-L p-h-1">
                  <Link to={`/${t.slug}`} className="w-100% d-f fb-d-c j-c-sb b-s-s b-w-1px b-r-4px b-c-g p-t-1.5 p-b-1 p-h-1 m-b-2.3 bg-c-w t-c-b h-10.5">
                    <div className="f-s-1.5 f-w-600 ws-n of-h t-o-e" title={t.name}>
                      {t.name} 
                    </div>
                    <div className="fb-n d-f p-t-1 a-i-c">
                      <div className="fb-a t-c-g">
                        {t.isWorkspace ? tr("Open Workspace") : tr("Open Team")}
                      </div>
                      <div className="fb-n">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" fill="#168D13"/>
                        </svg>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          :
            <div className="w-100% w-33.3%-M w-25%-L p-h-1">
              <Link to="/teams/new" className="w-100% d-f fb-d-c j-c-sb b-s-s b-w-1px b-r-4px b-c-g p-t-1.5 p-b-1 p-h-1 m-b-2.3 bg-c-w t-c-b h-10.5">
                <div className="f-s-1.5 f-w-600 ws-n of-h t-o-e">
                  {tr("Create a Team")}
                </div>
                <div className="fb-n d-f p-t-1 a-i-c">
                  <div className="fb-a t-c-g">
                    {tr("Add a new team")}
                  </div>
                  <div className="fb-n">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#168D13"/>
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
          }

          {teamsPaginator && teamsPaginator.recordsCount > (teamsPaginator.recordOffset + teamsPaginator.pageSize)  ?
            <div onClick={fetchMoreTeams} className="t-dl-u t-c-g c-p t-a-c m-t-2">
              {tr("View More")}
            </div>
          : null}
        </div>

        { showUserConfirmedError ?
          <Modal
            open={showUserConfirmedError}
            onClose={ () => setShowUserConfirmedError(false) }
            primaryAction={ { title: t("Got it"), type: "primary", onAction: () => { setShowUserConfirmedError(false) } } }>
            <div className="p-2">
              <div className="f-s-1.5 f-w-300 t-c-b">
                {t("You must confirm your email address {{email}} in order to accept Team invitations.", {email: user?.email})}
              </div>
              <div className="m-t-2">
                { isSendingEmailConfirmation ?
                  <Loading />
                : !isEmailConfirmationResent ?
                  <Trans t={t} i18nKey="If you have not received an email for confirmation you can <1>click here to resend</1>.">
                    If have not received an email for confirmation you can <span className="t-c-g c-p" onClick={resendConfirmation}>click here to resend</span>.
                  </Trans>
                :
                  t("The email confirmation was resent.")
                }
              </div>
            </div>
          </Modal>
          : null
        }

        { invitationDeclining ?
          <Modal
            open={invitationDeclining !== undefined}
            onClose={ () => setInvitationDeclining(undefined) }
            title={t("Decline Invitation")}
            secondaryActions={ [ { title: t("Cancel"), onAction: () => setInvitationDeclining(undefined) } ] }
            primaryAction={ { title: t("Decline Invitation"), onAction: execDecline, type: "destructive" } }>
            <div className="p-2">
              <div className="f-s-1.5 f-w-300 m-t-0.5">
                {t("This action will decline your invitation to {{teamName}}.", {teamName: invitationDeclining.teamName})}
              </div>
            </div>
          </Modal>
          : null
        }
      </div>
    )
  }
}
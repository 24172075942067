import { atom, selector } from "recoil";

export const roleState = atom<string | undefined>({
  key: 'roleState',
  default: undefined,
});

export const isBillerState = selector<boolean>({
  key: "isBillerState",
  get: ({get}) => get(roleState) === "biller"
})

export const isMemberState = selector<boolean>({
  key: "isMemberState",
  get: ({get}) => get(roleState) === "member"
})

export const isOwnerState = selector<boolean>({
  key: "isOwnerState",
  get: ({get}) => get(roleState) === "owner"
})

export const isOwnerOrMemberState = selector<boolean>({
  key: "isMemberOrOwnerState",
  get: ({get}) => get(isMemberState) || get(isOwnerState)
})

export const isOwnerOrBillerState = selector<boolean>({
  key: "isBillerOrOwnerState",
  get: ({get}) => get(isBillerState) || get(isOwnerState)
})
import React, { useCallback, useMemo, useState } from 'react';
import axios from 'axios';
import { Spinner, Modal, TextInput, BaseInputOnChangeEvent } from '../ui';
import { apiBaseUrl } from "../Consts";
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { teamState } from '../atoms';
import { useHandleRequestError } from '../hooks';
import { capitalize } from '../Helper';

export const SettingsTeamDelete: React.FC = () => {

  const { t } = useTranslation();
  const team = useRecoilValue(teamState);
  const { handle422Error } = useHandleRequestError();

  const [ isRemoving, setIsRemoving ] = useState(false);
  const [ confirmRemove, setConfirmRemove ] = useState(false);
  const [ teamNameConfirmation, setTeamNameConfirmation ] = useState<string>();
  const [ deleteConfirmationError, setDeleteConfirmationError ] = useState<string>();
  const [ error, setError ] = useState<string>();
  const subjectName = useMemo(() => team?.isWorkspace ? t("workspace") : t("team"), [team, t]);

  const cancelDelete = useCallback(() => {
    setConfirmRemove(false);
    setDeleteConfirmationError(undefined);
    setTeamNameConfirmation(undefined);
  }, []);

  const execRemove = useCallback(() => {
    if (team) {
      if (teamNameConfirmation === team.name) {
        cancelDelete();
        setIsRemoving(true);
        axios
          .delete(`${apiBaseUrl}/${team.uuid}`)
          .then(() => window.location.href = "/")
          .catch(err => {
            const error = handle422Error(err);
            if (error) {
              setError(error);
            }
          })
          .finally(() => setIsRemoving(false));
      } else {
        setDeleteConfirmationError(t("Invalid {{subject}} name.", {subject: subjectName}));
      }
    }
  }, [team, teamNameConfirmation, cancelDelete, handle422Error, t, subjectName]);

  const onChangeTeamNameConfirmation = useCallback((event: BaseInputOnChangeEvent) => {
    setTeamNameConfirmation(event.value as string);
  }, [])

  if (!team) {
    return null;
  } return (
    <>
      <div className="b-s-s b-w-1px b-c-red bg-c-nw p-1 f-s-1">
        <div className="f-w-700 m-b-0.5">
          {t("Delete {{subject}}", {subject: subjectName})} {team.name}
        </div>
        <div className="d-f fb-d-c fb-d-r-M">
          <div className="fb-a p-r-2-M d-f fb-d-c">
            {error ?
              <div className="t-c-r m-t-0.5">{t("Error")}: {error}</div>
            :
              <div>
                {t("Delete the {{subject}} with their pages, groups, and monitors", {subject: subjectName})}
              </div>
            }
          </div>
          <div className="d-f fb-n j-c-e p-t-1 p-t-0-M">
            {isRemoving ?
              <Spinner type={"destructive"} />
            :
              <div
                onClick={() => { setError(undefined); setConfirmRemove(true); }}
                tabIndex={0}
                className="t-c-r c-p">
                {t("Delete")}
              </div>
            }
          </div>
        </div>
      </div>

      { confirmRemove ?
        <Modal
          open={confirmRemove}
          onClose={ cancelDelete }
          title={t("Delete {{subject}}", {subject: capitalize(subjectName)})}
          description={ team.name }
          secondaryActions={ [ { title: t("Cancel"), onAction: cancelDelete } ] }
          primaryAction={ { title: t("Delete {{subject}}", {subject: subjectName}), onAction: execRemove, type: "destructive" } }>
          <div className="p-2">
            <div className="f-s-1.5 f-w-300 t-c-b">
              {t("You can't reverse this action!")}
            </div>
            <div className="f-s-1 f-w-300 m-t-0.5">
              {t("This action will delete all monitors, groups, and pages of this {{subject}}", {subject: subjectName})}.
            </div>
            <div className="f-s-1 f-w-300 m-t-0.5">
              <div className="m-b-0.5 m-t-1 f-w-700">
                {t("Write the {{subject}} name '{{name}}' to confirm this action", {subject: subjectName, name: team.name})}:
              </div>
              <TextInput
                autoFocus={true}
                placeholder={t("{{subject}} name", {subject: subjectName})}
                value={teamNameConfirmation}
                error={deleteConfirmationError}
                onChange={onChangeTeamNameConfirmation} />
            </div>
          </div>
        </Modal>
        : null
      }
    </>
  );
}

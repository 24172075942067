import React from "react";

type RemoveButtonProps = {
  size?: number
}

export const RemoveButton: React.FC<RemoveButtonProps> = (props) => {
  const size = props.size || 24

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#000"
        d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
      ></path>
    </svg>
  )
}

import React from "react";
import { sizeFlag } from "../Helper";

type FlagJPProps = {
  size?: string
}

export const FlagJP: React.FC<FlagJPProps> = (props) => {
  const size = sizeFlag(props.size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size.width}
      height={size.height}
      fill="none"
      viewBox="0 0 24 16"
    >
      <path fill="#fff" stroke="#E6E6E6" d="M.5.5h23v15H.5V.5z"></path>
      <path
        fill="#D30000"
        d="M12.002 12.974c3.09 0 5.595-2.226 5.595-4.973s-2.505-4.974-5.595-4.974-5.595 2.227-5.595 4.974c0 2.747 2.505 4.973 5.595 4.973z"
      ></path>
    </svg>
  )
}
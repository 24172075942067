import React, { useCallback, useState } from 'react';
import { BaseInputOnChangeEvent, BaseInputOnBlurEvent } from '../event/BaseInputEvent';

type RadioButtonProps = {
  checked?: boolean
  label?: string
  onChange?: (event: BaseInputOnChangeEvent) => void
  onBlur?: (event: BaseInputOnBlurEvent) => void
}

export const RadioButton: React.FC<RadioButtonProps> = (props) => {

  const [ dirty, setDirty ] = useState(false);
  const [ touched, setTouched ] = useState(false);

  const onChange = useCallback(() => {
    const checked = !props.checked;
    const dirty = true;
    setDirty(dirty);
    if (props.onChange) {
      props.onChange(new BaseInputOnChangeEvent(checked, dirty, touched))
    }
  }, [props, touched]);

  const onBlur = useCallback(() => {
    setTouched(true);
    if (props.onBlur) {
      props.onBlur(new BaseInputOnBlurEvent(props.checked || false, dirty, true));
    }
  }, [props, dirty]);

  return (
    <div
      tabIndex={0}
      onClick={onChange}
      onBlur={onBlur}
      className="d-f a-i-c c-p">
      <div
        className={`d-f a-i-c j-c-c w-1.5 h-1.5 b-r-50% b-s-s b-c-g ${props.checked ? "b-w-8px" : "b-w-1px"}`}>
      </div>
      {props.label ?
        <div className="m-l-0.5">
          {props.label}
        </div>
      : null
      }
    </div>
  );
}

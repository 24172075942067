import axios from "axios";
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { MenuInRow, Modal, Loading, Paginator } from '../ui';
import { apiBaseUrl } from "../Consts";
import { ApiToken, Paginator as ModelPaginator } from "../model";
import { useTranslation } from 'react-i18next';
import { SettingsApiTokenNew } from "./SettingsApiTokenNew";
import { SettingsApiTokenEdit } from "./SettingsApiTokenEdit";
import { copyElementToClipboard } from "../Helper";
import { useIsMounted } from "../hooks/UseIsMounted";
import { useRecoilValue } from "recoil";
import { isOwnerState, teamState, userState } from "../atoms";
import { useHandleRequestError } from "../hooks";
import { DateTime } from "luxon";


export const SettingsApiTokens: React.FC = () => {

  const { t } = useTranslation();
  const { handleRequestError } = useHandleRequestError();
  const user = useRecoilValue(userState);
  const team = useRecoilValue(teamState);
  const isOwner = useRecoilValue(isOwnerState);

  const [ apiTokens, setApiTokens ] = useState<ApiToken[]>();
  const [ paginator, setPaginator ] = useState<ModelPaginator>();
  const [ isAdding, setIsAdding ] = useState(false);
  const [ isFetching, setIsFetching ] = useState(true);

  const fetch = useCallback((recordOffset = 0) => {
    if (team) {
      axios.get(`${apiBaseUrl}/${team.uuid}/api-tokens`, {
        params: {
          recordOffset: recordOffset
        }})
        .then(response => {
          setApiTokens(response.data.apiTokens);
          setPaginator(response.data.paginator);
        })
        .catch(err => handleRequestError(err))
        .finally(() => setIsFetching(false));
    }

  }, [team, handleRequestError]);

  const onSave = useCallback((apiTokens: ApiToken[], paginator: ModelPaginator) => {
    if (apiTokens) {
      setIsAdding(false);
      setApiTokens(apiTokens);
      setPaginator(paginator);
    }
  }, []);

  const onUpdate = useCallback((apiToken: ApiToken) => {
    if (apiTokens) {
      const _apiTokens = [...apiTokens];
      const index = _apiTokens?.findIndex(m => m.uuid === apiToken.uuid);
      _apiTokens[index] = apiToken;
      setApiTokens(_apiTokens);
    }
  }, [apiTokens]);

  const onDelete = useCallback(() => {
    fetch(paginator ? paginator.recordOffset : 0)
  }, [fetch, paginator])

  useEffect(() => fetch(), [team, fetch]);

  return (
    <>
      <div className="f-s-1.25">
        {t("API Tokens")}
      </div>
      {isFetching ?
        <Loading />
      : !apiTokens || apiTokens.length === 0 ?
          <div className="d-f a-i-c fb-d-c w-100% p-v-4 t-a-c f-s-1.25">
            {t("No API tokens added yet")}.
            <div onClick={() => setIsAdding(true) } className="m-t-1 c-p t-c-g">
              {t("Add an API token")}
            </div>
          </div>
      :
        <div className="d-f fb-d-c w-100%">
          <div className="d-f fb-d-c">
            <div className="d-f j-c-e p-b-0.75 t-a-r b-sb-s b-sb-n-M b-c-lightestGray b-w-1px">
              {isOwner?
                <div onClick={() => setIsAdding(true)} className="c-p t-c-g">{t("New Token")}</div>
              : null
              }
            </div>
            <div className="d-n d-f-M h-5 a-i-c b-sb-s b-c-lightestGray b-wb-0.1 f-w-b t-c-b">
              <div className="d-b fb-a p-l-1 p-l-0.5-M">{t("Name")}</div>
              <div className="w-8-M w-12-L">{t("Scope")}</div>
              <div className="w-6-M w-14-L">{t("Created")}</div>
              <div className="d-f w-2.5 w-3.5-M"></div>
            </div>
            {!apiTokens || !user ? <Loading />
            : apiTokens.map((m, i) =>
                <SettingsApiToken
                  onUpdate={onUpdate}
                  onDelete={onDelete}
                  apiToken={m}
                  key={i} />
              )
            }
          </div>

          {team && paginator ?
            <div className="d-f j-c-c m-t-2">
              <Paginator
                recordOffset={paginator.recordOffset}
                recordsCount={paginator.recordsCount}
                pageSize={paginator.pageSize}
                onSelect={(page) => fetch((page - 1) * paginator.pageSize)} />
            </div>
          : null}
        </div>
      }

      { isAdding ?
        <SettingsApiTokenNew
          onSave={onSave}
          onCancel={() => setIsAdding(false)} />
        : null
      }
    </>
  );
}

type SettingsApiTokenProps = {
  apiToken: ApiToken
  onDelete: (apiToken: ApiToken) => void
  onUpdate: (apiToken: ApiToken) => void
}


const SettingsApiToken: React.FC<SettingsApiTokenProps> = (props) => {

  const { t } = useTranslation();
  const { handleRequestError } = useHandleRequestError();
  const apiToken = props.apiToken;
  const team = useRecoilValue(teamState);
  const isOwner = useRecoilValue(isOwnerState);
  const [ isSaving, setIsSaving ] = useState(false);

  const [ isLoading, setIsLoading ] = useState(false);
  const [ isRemoving, setIsRemoving ] = useState(false);
  const [ isEditing, setIsEditing ] = useState(false);
  const tokenRef = useRef<HTMLDivElement>(null);
  const [ recentlyCopied, setRecentlyCopied ] = useState(false);
  const isMounted = useIsMounted()

  const execRemove = useCallback(() => {
    if (team && isRemoving) {
      setIsLoading(true);
      setIsSaving(true)
      const url = `${apiBaseUrl}/${team.uuid}/api-tokens/${apiToken.uuid}`;
      axios.delete(url)
        .then(() => props.onDelete(apiToken))
        .catch(error => handleRequestError(error))
        .finally(() => {
          if (isMounted()) {
            setIsLoading(false);
            setIsRemoving(false);
          }
        })
      .finally(() => setIsSaving(false));
    }
  }, [team, isRemoving, apiToken, props, isMounted, handleRequestError]);

  const copy = useCallback(() => {
    copyElementToClipboard(tokenRef.current as HTMLDivElement);
    setRecentlyCopied(true);
    const timeout = setTimeout(() => {
      setRecentlyCopied(false)
    }, 3000)

    return () => {
      clearTimeout(timeout);
    }
  }, []);

  return (
    <>
      <div className="b-sb-s b-c-lightestGray b-w-1px p-v-1 p-v-1.5-M">
        <div className="d-f a-i-c">
          <div className="fb-a d-f fb-d-c fb-d-r-M a-i-c-M p-l-0.5">
            <div className="d-b fb-a p-r-1 ws-n of-h t-o-e">
              { apiToken.name }
            </div>
            <div className="w-8-M w-12-L m-t-0.75 m-t-0-M">
              <span className="f-w-b d-ib d-n-M m-r-1">
                { t("Scope") }
              </span>
              <span className="b-s-s b-r-0.25 p-v-0.25 p-h-0.5 b-w-1px b-c-nb t-c-nb">
                {t("Read")}
              </span>
              {apiToken.allowWrite ?
                <span className=" m-l-0.5 b-s-s b-r-0.25 p-v-0.25 p-h-0.5 b-w-1px b-c-nb t-c-nb">
                  {t("Write")}
                </span>
              : null}
            </div>
            <div className="w-6-M w-14-L m-t-0.75 m-t-0-M">
              <span className="f-w-b d-ib d-n-M m-r-1">
                { t("Created") }
              </span>
              {DateTime.fromISO(apiToken.createdAt).toRelative()}
            </div>
          </div>
          <div className="w-2.5 w-3.5-M">
            {isLoading ?
              <Loading />
            : isOwner ?
              <MenuInRow items={[
              { title: t("Edit"), onClick: () => setIsEditing(true) },
              { title: t("Delete"), onClick: () => setIsRemoving(true) } ]} />
            : null
            }
          </div>
        </div>
        { apiToken.token ?
          <div className="d-f fb-d-c fb-d-r-M m-t-0.75 p-l-0.5 f-s-0.85" style={{wordWrap: "break-word"}}>
            <span className="p-r-1" ref={tokenRef}>{ apiToken.token }</span>
            {recentlyCopied ?
              <span className="f-s-1">{t("Copied")}</span>  
            :
              <span className="t-c-g c-p f-s-1" onClick={copy}>
                {t("Copy")}
              </span>
            }
          </div>
        : null}
      </div>
      { isRemoving ? (() =>  {
          const title = t("Delete API Token");
          return <Modal
            open={isRemoving}
            onClose={ () => setIsRemoving(false) }
            title={title}
            subtitle={ apiToken.name }
            secondaryActions={ [ { title: t("Cancel"), onAction: () => setIsRemoving(false) } ] }
            primaryAction={ { title: title, onAction: execRemove, type: "destructive" } }
            isSaving={isSaving} >
            <div className="p-2">
              <div className="f-s-1.5 f-w-300 t-c-b">
                {t("You can't reverse this action!")}
              </div>
            </div>
          </Modal>
          })()
      : isEditing ?
          <SettingsApiTokenEdit
            apiTokenUuid={apiToken.uuid}
            onUpdate={(apiToken) => { setIsEditing(false); props.onUpdate(apiToken) }}
            onCancel={() => setIsEditing(false)} />
      : null
      }
    </>
  )
}
import React from "react";
import { sizeFlag } from "../Helper";

type FlagDEProps = {
  size?: string
}

export const FlagDE: React.FC<FlagDEProps> = (props) => {
  const size = sizeFlag(props.size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size.width}
      height={size.height}
      fill="none"
      viewBox="0 0 24 16"
    >
      <path fill="#FFCE00" d="M0 10.667h24V16H0v-5.333z"></path>
      <path fill="#000" d="M0 0h24v5.333H0V0z"></path>
      <path fill="#D00" d="M0 5.333h24v5.334H0V5.333z"></path>
    </svg>
  )
}
import { Loading } from '../ui';
import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { BillingInformation } from '../model';
import { apiBaseUrl } from "../Consts";
import { useRecoilValue } from 'recoil';
import { teamState } from '../atoms';
import { useHandleRequestError } from '../hooks';
import { useTranslation } from 'react-i18next';
import { productsNonFreeEnabledState } from '../atoms/productsNonFreeEnabledState';
import { CompletedIcon, PaymentMethodImage, ExclamationDisabledIcon, PoweredByStripe } from '../images';

export const BillingInformationComponent: React.FC = () => {

  const { t } = useTranslation();

  const [ billingInformation, setBillingInformation ] = useState<BillingInformation>();

  const productsNonFreeEnabled = useRecoilValue(productsNonFreeEnabledState);

  const { handleRequestError } = useHandleRequestError();
  const team = useRecoilValue(teamState);
  const [ isFetching, setIsFetching ] = useState(false);

  const fetchBillingInformation = useCallback(() => {
    setIsFetching(true);
    if (team) {
      axios.get(`${apiBaseUrl}/${team.uuid}/billing/information`)
        .then(response => {
          setBillingInformation(response.data.billingInformation as BillingInformation);
        })
        .catch(err => handleRequestError(err))
        .finally(() => setIsFetching(false));
    }
  }, [handleRequestError, team]);

  const addPaymentMethod = useCallback(() => {
    if (team) {

      axios.post(`${apiBaseUrl}/${team?.uuid}/billing/payment-methods`)
        .then(response => window.location.href = response.data.nextUrl)
        .catch(err => {
          handleRequestError(err)
        });
    }
  }, [handleRequestError, team])

  const openCustomerPortal = useCallback(() => {
    if (team) {

      axios.post(`${apiBaseUrl}/${team?.uuid}/billing/portal-sessions`)
        .then(response => window.location.href = response.data.nextUrl)
        .catch(err => {
          handleRequestError(err)
        });
    }
  }, [handleRequestError, team])

  useEffect(() => {
    fetchBillingInformation()
  }, [fetchBillingInformation])

  return (
    <>
      {isFetching || !team ?
        <Loading />
      :
        <div className="d-f fb-d-c m-h-a m-b-1 m-b-2-M p-h-0.5 p-h-2-M w-100% max-w-66-L t-c-nb">
          <div className="d-f fb-d-c w-100% p-l-0.5 p-v-1.5 m-t-0.5">
            <div className="d-f m-t-2-M b-sb-s b-w-1px b-c-lightGray">
              <div className="fb-a">
                <div className="f-s-1.5 f-s-2-M f-w-300">
                  {t("Payment Methods")}
                </div>
                <div className="d-f m-t-2">
                  {!productsNonFreeEnabled ?
                    <div className="d-f w-100% p-b-4">
                      <div className="h-2 w-2 d-f">
                        <ExclamationDisabledIcon />
                      </div>
                      <div className="d-f fb-d-c w-100% p-l-1 f-s-1.25">
                        {t("No Payment Methods added yet")}
                        <div onClick={addPaymentMethod} className="m-t-1 c-p t-c-g">
                          {t("Add a Payment Method")}
                        </div>
                      </div>
                    </div>
                  :
                    <div className="d-f w-100% p-b-4">
                      <div className="h-2 w-2 d-f">
                        <CompletedIcon />
                      </div>
                      <div className="d-f fb-d-c w-100% p-l-1 f-s-1.25">
                        {t("You have set a payment method!")}
                        <div onClick={openCustomerPortal} className="m-t-1 c-p t-c-g">
                          {t("Modify the Payment Method")}
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="d-n d-f-M h-180px">
                <PaymentMethodImage active={productsNonFreeEnabled ? true : false}/>
              </div>
            </div>
            <div className="fb-a m-t-2 m-t-4-M">
              <div className="f-s-1.5 f-s-2-M f-w-300">
                {t("Billing Information")}
              </div>
              <div className="d-f m-t-2">
                {!billingInformation || !billingInformation.address ||
                  (
                    !billingInformation.address.line1 &&
                    !billingInformation.address.line2 &&
                    !billingInformation.address.city &&
                    !billingInformation.address.state &&
                    !billingInformation.address.postalCode &&
                    !billingInformation.address.country
                  ) ?
                  <div className="d-f w-100% p-b-4">
                    <div className="h-2 w-2 d-f">
                      <ExclamationDisabledIcon />
                    </div>
                    <div className="d-f fb-d-c w-100% p-l-1 f-s-1.25">
                      {t("No Billing Addres added yet")}
                      <div onClick={openCustomerPortal} className="m-t-1 c-p t-c-g">
                        {t("Add a Billing Address")}
                      </div>
                    </div>
                  </div>
                :
                  <div className="d-f w-100% p-b-4">
                    <div className="h-2 w-2 d-f">
                      <CompletedIcon />
                    </div>
                    <div className="d-f fb-d-c w-100% p-l-1 f-s-1.25 lh-2">
                      {billingInformation.address ?
                      <>
                        {billingInformation.name ? <>{billingInformation.name}<br /></> : null}
                        {billingInformation.email ? <>{billingInformation.email}<br /></> : null}
                        {billingInformation.address.line1 ? <>{billingInformation.address?.line1}<br /></> : null}
                        {billingInformation.address.line2 ? <>{billingInformation.address?.line2}<br /></> : null}
                        {billingInformation.address.city ? <>{billingInformation.address.city}<br /></> : null}
                        {billingInformation.address.state ? <>{billingInformation.address.state},&nbsp;</> : null}
                        {billingInformation.address.postalCode ? <>{billingInformation.address.postalCode},&nbsp;</> : null}
                        {billingInformation.address.country ? <>{billingInformation.address.country}&nbsp;</> : null}
                      </>
                      : null}
                      {billingInformation.taxIds?.length > 0 ?
                        <div className="m-t-1">
                          <div className="f-w-600 p-b-0.25">Tax ID</div>
                          {/* <div> */}
                            {billingInformation.taxIds.map((taxId, i) =>
                              <div key={i} className="t-t-u p-v-0.10">
                                {taxId.type} {taxId.value}
                              </div>
                            )}
                          {/* </div> */}
                        </div>
                      : null}
                      <div onClick={openCustomerPortal} className="m-t-1 c-p t-c-g">
                      {t("Modify Billing Information")}
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className="d-f j-c-e h-30px p-r-2.7">
              <a href="https://www.stripe.com" rel="noreferrer" target="_blank">
                  <PoweredByStripe active={productsNonFreeEnabled ? true : false} />
              </a>
            </div>
          </div>
        </div>
      }
    </>
  );
}

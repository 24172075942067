import React from 'react';
import { DashboardIcon, MonitorIcon, PageIcon, GroupIcon, PreferencesIcon, SettingsIcon, BillingIcon, AlertIcon } from '../../images';
import { CustomeIcon } from '../../images/CustomeIcon';
import { IconType } from '../types';

type IconProps = {
  type: IconType,
  color?: string,
  background?: string,
  size?: string
}

export const Icon: React.FC<IconProps> = (p) => {

  switch (p.type) {
    case IconType.Dashboard:
      return <DashboardIcon color={p.color} size={p.size} />
    case IconType.Monitor:
      return <MonitorIcon color={p.color} size={p.size} />
    case IconType.Page:
      return <PageIcon color={p.color} size={p.size} />
    case IconType.Group:
      return <GroupIcon color={p.color} size={p.size} />
    case IconType.Alert:
      return <AlertIcon color={p.color} size={p.size} />
    case IconType.Preferences:
      return <PreferencesIcon color={p.color} size={p.size} />
    case IconType.Settings:
      return <SettingsIcon color={p.color} size={p.size} />
    case IconType.Billing:
        return <BillingIcon color={p.color} size={p.size} />
    case IconType.Custome:
        return <CustomeIcon size={p.size} background={p.background} />
    default:
      return null
  }
}

import React from "react";

export const AvatarDisrupted = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="192"
      height="160"
      fill="none"
      viewBox="0 0 192 160"
    >
      <g clipPath="url(#clip0)">
        <path
          fill="url(#paint0_linear)"
          d="M87.126 0H0v97.242h87.126V0z"
        ></path>
        <path fill="#fff" d="M85.684 64.925H1.44v30.2h84.245v-30.2z"></path>
        <path
          fill="#FFB800"
          d="M11.619 72.088H8.354v15.475h3.265V72.088z"
        ></path>
        <path
          fill="#FFB800"
          d="M18.063 72.088H14.8v15.475h3.264V72.088z"
          opacity="0.7"
        ></path>
        <path
          fill="#FFB800"
          d="M24.51 72.088h-3.265v15.475h3.265V72.088z"
          opacity="0.6"
        ></path>
        <path
          fill="#FFB800"
          d="M30.954 72.088h-3.265v15.475h3.265V72.088zM37.398 72.088h-3.264v15.475h3.264V72.088z"
          opacity="0.5"
        ></path>
        <path
          fill="#FFB800"
          d="M43.843 72.088h-3.265v15.475h3.265V72.088z"
          opacity="0.4"
        ></path>
        <path
          fill="#FFB800"
          d="M50.29 72.088h-3.265v15.475h3.264V72.088z"
          opacity="0.3"
        ></path>
        <path
          fill="#FFB800"
          d="M73.758 87.603a7.877 7.877 0 100-15.755 7.877 7.877 0 000 15.755z"
        ></path>
        <path fill="#fff" d="M85.684 33.549H1.44v30.2h84.245v-30.2z"></path>
        <path
          fill="#CB2C2C"
          d="M11.619 40.712H8.354v15.475h3.265V40.712zM73.758 56.23a7.877 7.877 0 100-15.756 7.877 7.877 0 000 15.755z"
        ></path>
        <path fill="#fff" d="M85.684 2.173H1.44v30.2h84.245v-30.2z"></path>
        <path fill="#FFB800" d="M11.619 9.338H8.354v15.475h3.265V9.338z"></path>
        <path
          fill="#FFB800"
          d="M18.063 9.338H14.8v15.475h3.264V9.338z"
          opacity="0.7"
        ></path>
        <path
          fill="#FFB800"
          d="M24.51 9.338h-3.265v15.475h3.265V9.338z"
          opacity="0.6"
        ></path>
        <path
          fill="#FFB800"
          d="M30.954 9.338h-3.265v15.475h3.265V9.338zM37.398 9.338h-3.264v15.475h3.264V9.338z"
          opacity="0.5"
        ></path>
        <path
          fill="#FFB800"
          d="M43.843 9.338h-3.265v15.475h3.265V9.338z"
          opacity="0.4"
        ></path>
        <path
          fill="#FFB800"
          d="M50.29 9.338h-3.265v15.475h3.264V9.338z"
          opacity="0.3"
        ></path>
        <path
          fill="url(#paint1_linear)"
          d="M73.758 25.468a8.492 8.492 0 100-16.984 8.492 8.492 0 000 16.984z"
        ></path>
        <path
          fill="url(#paint2_linear)"
          d="M73.758 57.054a8.492 8.492 0 100-16.985 8.492 8.492 0 000 16.985z"
        ></path>
        <path
          fill="url(#paint3_linear)"
          d="M73.758 88.427a8.492 8.492 0 100-16.984 8.492 8.492 0 000 16.984z"
        ></path>
        <path
          fill="#FFB800"
          d="M73.758 24.853a7.877 7.877 0 100-15.755 7.877 7.877 0 000 15.755z"
        ></path>
        <g
          stroke="#FFB800"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          opacity="0.7"
        >
          <path d="M97.725 33.636h1.272" opacity="0.7"></path>
          <path
            strokeDasharray="12.52 33.38"
            d="M106.073 33.636h35.387"
            opacity="0.7"
          ></path>
          <path d="M144.998 33.636h1.272v1.272" opacity="0.7"></path>
          <path
            strokeDasharray="11.93 31.8"
            d="M146.27 41.65v24.44"
            opacity="0.7"
          ></path>
          <path d="M146.27 69.461v1.272" opacity="0.7"></path>
        </g>
        <g
          stroke="#FFB800"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          opacity="0.7"
        >
          <path d="M77.921 129.665l-1.272-.006" opacity="0.7"></path>
          <path
            strokeDasharray="12.52 33.38"
            d="M69.573 129.625l-35.384-.17"
            opacity="0.7"
          ></path>
          <path d="M30.649 129.44l-1.272-.006.008-1.272" opacity="0.7"></path>
          <path
            strokeDasharray="11.55 30.79"
            d="M29.428 121.635l.097-14.684"
            opacity="0.7"
          ></path>
          <path d="M29.546 103.686l.009-1.272" opacity="0.7"></path>
        </g>
        <path
          fill="#E0E0E0"
          d="M152.886 133.648s2.527 17.061 17.44 22.748H109.91c14.913-5.687 17.44-22.748 17.44-22.748h25.536z"
        ></path>
        <path
          fill="url(#paint4_linear)"
          d="M170.593 156.396h-60.978V160h60.978v-3.604z"
        ></path>
        <path
          fill="#F5F5F5"
          d="M170.326 156.396h-60.408v2.781h60.408v-2.781z"
        ></path>
        <path
          fill="url(#paint5_linear)"
          d="M189.356 73.003H91.313a2.278 2.278 0 00-2.28 2.28v61.77a2.724 2.724 0 002.727 2.726h97.148a2.731 2.731 0 002.519-1.683c.137-.331.207-.685.207-1.043v-61.77a2.28 2.28 0 00-2.278-2.28z"
        ></path>
        <path
          fill="#fff"
          d="M92.546 73.936h95.152a2.28 2.28 0 012.279 2.279v52.884h-99.71V76.217a2.278 2.278 0 012.28-2.28z"
        ></path>
        <path
          fill="#F5F5F5"
          d="M187.251 138.197H92.994a2.727 2.727 0 01-2.726-2.726v-6.372h99.709v6.372a2.727 2.727 0 01-2.726 2.726z"
        ></path>
        <path
          fill="#FFB800"
          d="M185.428 78.405H95.196v44.529h90.232v-44.53z"
        ></path>
        <path
          fill="#fff"
          d="M98.766 81.36h86.719v-2.97H95.253v44.53h3.513V81.36z"
          opacity="0.2"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="43.563"
          x2="43.563"
          y1="97.242"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity="0.25"></stop>
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12"></stop>
          <stop offset="1" stopColor="gray" stopOpacity="0.1"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="5974.76"
          x2="5974.76"
          y1="2048.97"
          y2="688.193"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity="0.25"></stop>
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12"></stop>
          <stop offset="1" stopColor="gray" stopOpacity="0.1"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="5974.76"
          x2="5974.76"
          y1="4611.21"
          y2="3250.44"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity="0.25"></stop>
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12"></stop>
          <stop offset="1" stopColor="gray" stopOpacity="0.1"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="5974.76"
          x2="5974.76"
          y1="7156.25"
          y2="5795.48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity="0.25"></stop>
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12"></stop>
          <stop offset="1" stopColor="gray" stopOpacity="0.1"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="40410.3"
          x2="40410.3"
          y1="2876.39"
          y2="2815.13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity="0.25"></stop>
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12"></stop>
          <stop offset="1" stopColor="gray" stopOpacity="0.1"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="83195.7"
          x2="83195.7"
          y1="48952.5"
          y2="27918.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity="0.25"></stop>
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12"></stop>
          <stop offset="1" stopColor="gray" stopOpacity="0.1"></stop>
        </linearGradient>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H191.635V160H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
import React from 'react';
import { Flag } from '../images';
import { Checkbox } from '../ui';
import { Region } from '../model';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { regionsState } from '../atoms';

type RegionsProps = {
  selectedRegions?: Region[],
  error?: string | string[],
  onChange: (selectedRegions: Region[]) => void
}

export const Regions: React.FC<RegionsProps> = (props) => {

  const { t } = useTranslation();
  const regions = useRecoilValue(regionsState);

  const addRegion = (region: Region) => {
    const newRegions = props.selectedRegions ? [...props.selectedRegions, region] : [region];
    props.onChange(newRegions);
  }

  const removeRegion = (region: Region) => {
    const newRegions = props.selectedRegions ? props.selectedRegions.filter(r => r.id !== region.id) : [];
    props.onChange(newRegions);
  }

  const renderError = (error: string) => {
    return <div className="t-c-r f-s-1 m-t-0.5">{ error }</div>
  }

  const renderErrors = (error?: string | string[]) => {
    if (error) {
      if (typeof error === "string") {
        return renderError(error);
      } else {
        return (error as string[]).map(e => renderError(e));
      }
    } else {
      return null;
    }
  }

  return (
    <div className={`d-f fb-d-c m-t-1 m-t-2-M ${props.error ? "input-error" : ""}`}>
      <div className="f-s-1 f-s-1.25-M">{t("Choose Regions")}</div>
      <div className="d-f fb-d-c m-t-1">
        <div className={`d-f fb-w-w w-100% b-s-s-M b-w-1px-M b-c-lightestGray-M p-h-2-M p-t-1.5-M ${props.error ? 'b-c-r-M' : null}`}>
          { regions?.map( (region, i) => {
            const checked = props.selectedRegions?.some(r => r.id === region.id);
            return (
              <div key={i} className="d-f fb-a a-i-c p-b-1.5">
                <div className="d-f a-i-c">
                  <Checkbox
                    checked={checked}
                    onChange={e => e.value ? addRegion(region) : removeRegion(region) } />
                </div>
                <div
                  onClick={() => checked ? removeRegion(region) : addRegion(region)}
                  className="d-f m-l-0.75 c-p a-i-c">
                  <div className="d-f w-3.5">
                    <Flag country={ region.country.code } size={ "small" } />

                  </div>
                  <div className="w-7 f-s-1 f-w-400 p-r-0.25">
                    { region.name }
                  </div>
                </div>
              </div>)
            }
          )}
        </div>

        { renderErrors(props.error) }
      </div>
    </div>
  )
}
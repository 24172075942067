import React from "react";
import { MonitorStatusType } from "../Consts";
import {
  AvatarStarting, 
  AvatarUp,
  AvatarDegraded,
  AvatarDisrupted,
  AvatarPaused,
  AvatarMaintenance,
  AvatarDown
} from "./";


type StatusAvatarProps = {
  status: MonitorStatusType
}

export const StatusAvatar: React.FC<StatusAvatarProps> = (props) => {

  switch (props.status) {
    case MonitorStatusType.Starting:
      return (
        <AvatarStarting />
      );
    case MonitorStatusType.Up:
      return (
        <AvatarUp />
      );
    case MonitorStatusType.Degraded:
      return (
        <AvatarDegraded />
      );
    case MonitorStatusType.Disrupted:
      return (
        <AvatarDisrupted />
      );
    case MonitorStatusType.Paused:
      return (
        <AvatarPaused />
      );
    case MonitorStatusType.Maintenance:
      return (
        <AvatarMaintenance />
      );
    case MonitorStatusType.Down:
      return (
        <AvatarDown />
      );
    default:
      return <React.Fragment></React.Fragment>;
  }
}
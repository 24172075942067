import React, { useCallback, useState } from 'react';
import { Switch } from '../ui';
import { useRecoilValue } from 'recoil';
import { teamState } from '../atoms';
import axios from 'axios';
import { apiBaseUrl } from '../Consts';
import { useHandleRequestError } from '../hooks';
import { Preferences as PreferencesModel } from "../model";

type PreferencesNotificationProps = {
  field: keyof PreferencesModel
  title: string
  selected: boolean
  onUpdate: (selected: boolean) => void
}

export const PreferencesNotification: React.FC<PreferencesNotificationProps> = (props) => {

  const team = useRecoilValue(teamState);
  const { handleRequestError } = useHandleRequestError();
  const [ selected, setSelected ] = useState(props.selected);

  const updatePreference = useCallback((selected: boolean) => {
    setSelected(selected);
    if (team) {
      const request = {} as any;
      request[props.field] = selected;
      axios.patch(`${apiBaseUrl}/${team.uuid}/preferences`, request)
        .then(response => props.onUpdate(response.data.preferences[props.field]))
        .catch(err => {
          setSelected(!selected);
          handleRequestError(err)
        });
    }
  }, [handleRequestError, props, team]);

  return (
    <div className="d-f h-3 a-i-c b-sb-s b-c-lightestGray b-w-1px">
      <div className="d-f fb-a p-l-1 p-l-1.5-M">{props.title}</div>
      <div className="d-f w-4 w-6-M j-c-e p-r-0.5">
        <Switch
          activated={selected}
          onChange={e => updatePreference(e.value as boolean)} />
      </div>
    </div>
  );
}

import React from "react";

export const ExclamationDisabledIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        fill="#E6E6E6"
      />
      <path
        d="M15.9998 29.3334C23.3636 29.3334 29.3332 23.3639 29.3332 16.0001C29.3332 8.63628 23.3636 2.66675 15.9998 2.66675C8.63604 2.66675 2.6665 8.63628 2.6665 16.0001C2.6665 23.3639 8.63604 29.3334 15.9998 29.3334Z"
        fill="#979797"
      />
      <path
        d="M16.9504 25.6601C15.5807 26.2014 14.0316 25.5299 13.4903 24.1602C12.949 22.7905 13.6205 21.2414 14.9902 20.7001C16.3599 20.1588 17.909 20.8303 18.4503 22.2C18.9916 23.5697 18.3201 25.1188 16.9504 25.6601Z"
        fill="#E6E6E6"
      />
      <path
        d="M16.7408 20.0087C17.0801 19.8746 17.3753 19.65 17.5906 19.3621C17.8059 19.0741 17.9321 18.7352 17.9538 18.3863L18.5564 8.54347C18.5764 8.21742 18.527 7.89326 18.4112 7.5909C18.2955 7.28854 18.1159 7.01437 17.8834 6.78526C17.651 6.55614 17.3706 6.37691 17.0595 6.25858C16.7484 6.14025 16.4132 6.08533 16.0744 6.09719C15.7357 6.10905 15.4006 6.18743 15.0898 6.32753C14.7789 6.46763 14.4988 6.66648 14.2668 6.91186C14.0347 7.15724 13.8556 7.44396 13.7404 7.7544C13.6252 8.06484 13.5764 8.39245 13.5969 8.71708L14.2168 18.5172C14.2346 18.7977 14.3206 19.068 14.4679 19.3063C14.6153 19.5446 14.8199 19.7443 15.0654 19.8893C15.3108 20.0344 15.5903 20.1207 15.8812 20.1415C16.1722 20.1622 16.4666 20.1167 16.7408 20.0087Z"
        fill="#E6E6E6"
      />
    </svg>
  );
}
import React from 'react';
import { Loading } from '../ui';
import { SettingsTeamDisplayStatusImage } from './PreferencesTeamDisplayStatusImage';
import { useTranslation } from 'react-i18next';
import { teamState } from '../atoms';
import { useRecoilValue } from 'recoil';

export const PreferencesAppearance: React.FC = () => {

  const { t } = useTranslation();
  const team = useRecoilValue(teamState);

  if (!team) {
    return <Loading />
  } return (
    <div className="d-f fb-d-c w-100% max-w-25 max-w-40.5-M max-w-45-L m-t-1 m-t-3-M m-b-2">
      <div className="d-f h-5 a-i-c b-sb-s b-c-lightestGray b-wb-0.1 f-w-b t-c-b">
        <div className="d-f fb-a p-l-1 p-l-1.5-M">{t("Monitor")}</div>
      </div>
      <SettingsTeamDisplayStatusImage />
    </div>
  );
}

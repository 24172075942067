import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { CheckMark } from '..';
import { ChevronLeft } from '../../images/ChevronLeft';

export type MenuItem = {
  title?: string,
  link?: string,
  onClick?: () => void,
  visible?: boolean,
  separator?: boolean,
  selected?: boolean,
  subItems?: MenuItem[]
}

export type MenuProps = {
  items?: MenuItem[]
}

export const Menu: React.FC<MenuProps> = (props) => {
  
  const [breadcrumb, setBreadcrumb] = useState<MenuItem[]>([]);
  const [parent, setParent] = useState<MenuItem>();

  const items = useMemo(() => parent ?  parent.subItems : props.items, [parent, props.items])

  const selectable = useMemo(() => items && items.findIndex(i => i.selected) > -1, [items]);

  const onClick = useCallback((item: MenuItem) => {
    if (item.subItems) {
      setBreadcrumb([...breadcrumb, item]);
    } else if (item.onClick) {
      item.onClick();
    }
  }, [breadcrumb])

  const onClickBack = useCallback(() => {
    const _breadcrumb = [...breadcrumb];
    _breadcrumb.pop();
    setBreadcrumb(_breadcrumb);
  }, [breadcrumb])

  useEffect(() => {
    setParent(
      breadcrumb.length > 0 ?
      breadcrumb[breadcrumb.length - 1] :
      undefined)
  }, [breadcrumb])

  return (
    <div className="bg-c-w d-f p-v-0.5 fb-d-c b-w-1px b-s-s b-c-lightestGray f-s-1 s-drop">
      <>
        {parent ? 
          <>
            <div
              onClick={() => onClickBack()}
              className="d-f p-l-0.5 p-r-1 p-v-0.5 t-c-nb c-p items-center">
              <div className="p-r-0.5">
                <ChevronLeft />
              </div>
              <div>
                {parent.title}
              </div>
            </div>
            <div className="w-100% h-1px m-v-0.5 bg-c-lightestGray"></div>
          </>
        : null }
        {items?.filter(i => i.visible === undefined || i.visible).map((item, index) =>
          !item.separator ?
            <div key={index} className={`${parent && !selectable ? "p-l-2.5 p-r-1" : "p-h-1"} d-f`}>
              {selectable ?
                <div className="w-1 m-r-0.5 t-c-nb fb-n d-f a-i-c j-c-c">
                  {item.selected ?
                    <CheckMark />
                  : null}
                </div>
              : null}
              {item.link ?
                <Link
                  to={item.link}
                  onClick={() => onClick(item)}
                  className="fb-a p-v-0.5 t-c-nb">
                  {item.title}
                </Link>
              :
                <div
                  onClick={() => onClick(item)}
                  className="fb-a p-v-0.5 t-c-nb c-p">
                  {item.title}
                </div>
              }
            </div>
          :
            <div key={index} className="w-100% h-1px m-v-0.5 bg-c-lightestGray"></div>
          )
        }
      </>
    </div>
  );
}
import React from "react";
import { getCheckBoxZise } from "../Helper";


type IconGoogleProps = {
  size?: string
}

export const IconGoogle: React.FC<IconGoogleProps> = (props) => {
  const size = getCheckBoxZise(props.size ? props.size : "small");

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <title>Google</title>
      <g clipPath="url(#clip0)">
        <path
          fill="#4285F4"
          d="M24 12.276c0-.816-.067-1.636-.211-2.439H12.24v4.621h6.613a5.549 5.549 0 01-2.448 3.647v2.998h3.946C22.668 19.013 24 15.926 24 12.276z"
        ></path>
        <path
          fill="#34A853"
          d="M12.24 24c3.303 0 6.087-1.063 8.116-2.897l-3.945-2.998c-1.098.732-2.515 1.146-4.166 1.146-3.194 0-5.902-2.112-6.874-4.952h-4.07v3.091C3.377 21.443 7.61 24 12.24 24z"
        ></path>
        <path
          fill="#FBBC04"
          d="M5.367 14.3a7.053 7.053 0 010-4.595V6.614H1.3a11.798 11.798 0 000 10.776l4.067-3.09z"
        ></path>
        <path
          fill="#EA4335"
          d="M12.24 4.749a6.727 6.727 0 014.697 1.799l3.496-3.426A11.9 11.9 0 0012.24 0C7.61 0 3.378 2.557 1.3 6.614l4.066 3.091c.967-2.844 3.68-4.956 6.874-4.956z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
import PhoneNumber from "awesome-phonenumber";

export const isStringEmpty = (value: string): boolean => {
  return !value || value.trim().length === 0;
}

export const isFieldStringEmpty = (value: string, fieldName?: string): string | undefined => {

  return isStringEmpty(value) ?
    ( fieldName ? `${fieldName} can't be empty` : "Can't be empty" )
    :
    undefined
}

export const isFieldIntegerEmptyAndNotZero = (value: any, fieldName?: string): string | undefined => {
  const v = parseInt(value);
  if (isNaN(v) || v === 0) {
    return fieldName ? `${fieldName} can't be empty` : "Can't be empty";
  }

  return undefined;
}

export const isStringNotMatching = (value: string, regex: RegExp): boolean => {
  return !regex.test(value);
}

export const isFieldStringNotMatching = (value: string, regex: RegExp, fieldName?: string): string | undefined => {
  return isStringNotMatching(value, regex) ?
    ( fieldName ? `${fieldName} is not valid` : "Is not valid" )
    :
    undefined
}

export const isSlugNotMatching = (value: string): boolean => {
  return isStringNotMatching(value, /^[a-z\d](?:[a-z\d_-]*[a-z\d])?$/);
}

export const isFieldSlugNotMatching = (value: string, fieldName?: string): string | undefined => {
  return isSlugNotMatching(value) ?
    ( fieldName ?
      `${fieldName} may contain characters, numbers, underscore and dashes. Can only start or end with character or number` :
      "Slugs may contain characters, numbers and dashes. Can only start or end with character or number" )
    :
    undefined
}

export const isDomainNotMatching = (value: string): boolean => {
  // The eslint-disable-line is added since it is saying that escape special characters inside [ ]
  // is not required, however, removing them is causing an error by the browser and npm start
  return isStringNotMatching(value, /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/); // eslint-disable-line
}

export const isFieldDomainNotMatching = (value: string, fieldName?: string): string | undefined => {
  return isDomainNotMatching(value) ?
    ( fieldName ? `${fieldName} is not valid` : "Is not valid" )
    :
    undefined
}

export const isSubDomainNotMatching = (value: string): boolean => {
  return isStringNotMatching(value, /^[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?$/);
}

export const isFieldSubDomainNotMatching = (value: string, fieldName?: string): string | undefined => {
  return isSubDomainNotMatching(value) ?
    ( fieldName ? `${fieldName} is not valid` : "Is not valid" )
    :
    undefined
}

export const isEmailNotMatching = (value: string): boolean => {
  // The eslint-disable-line is added since it is saying that escape special characters inside [ ]
  // is not required, however, removing them is causing an error by the browser and npm start
  return isStringNotMatching(value, /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/); // eslint-disable-line
}

export const isFieldEmailNotMatching = (value: string, fieldName?: string): string | undefined => {
  return isEmailNotMatching(value) ?
    ( fieldName ? `${fieldName} is not valid` : "Is not valid" )
    :
    undefined
}

export const isPhoneNotMatching = (value: string): boolean => {
  return !value || !(new PhoneNumber(value)).isValid();
}

export const isFieldPhoneNotMatching = (value: string, fieldName?: string): string | undefined => {
  return isPhoneNotMatching(value) ?
    ( fieldName ? `${fieldName} is not valid` : "Is not valid" )
    :
    undefined
}
import React, { useRef, useLayoutEffect, useCallback, ReactNode } from 'react';
import { usePopper, Modifier } from 'react-popper';
import ReactDOM from 'react-dom';
import { Placement } from '@popperjs/core/lib/enums';
import { ModifierArguments } from '@popperjs/core/lib/types';

type PopoverProps = {
  active: boolean
  activator?: React.ReactElement
  placement?: Placement
  useActivatorWidth?: boolean
  onClose?: () => void
  children?: ReactNode
}

const sameWidth: Partial<Modifier<string, object>> = {
  name: "sameWidth",
  enabled: true,
  phase: "beforeWrite",
  requires: ["computeStyles"],
  fn: ({ state }: ModifierArguments<object>) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }: ModifierArguments<object>) => () => {
    state.elements.popper.style.width = `${
      state.elements.reference.getBoundingClientRect().width
    }px`;
  }
};

export const Popover: React.FC<PopoverProps> = (props) => {
  const referenceElement = useRef(null);
  const popperElement = useRef(null);

  const modifiers: Partial<Modifier<string, object>>[] = [ {
    name: 'preventOverflow',
    options: { padding: 10, mainAxis: !props.useActivatorWidth }
  } ];

  if (props.useActivatorWidth) {
    modifiers.push(sameWidth);
  }

  const { styles, attributes, forceUpdate } = usePopper(
    referenceElement.current, popperElement.current, {
      placement: props.placement || "auto",
      modifiers: modifiers
    });

  useLayoutEffect(() => { if (forceUpdate) { setTimeout(() => forceUpdate()) } }, [props, forceUpdate]);

  const clickOutside = useCallback((event: MouseEvent) => {
    const popper = (popperElement.current as unknown) as Element;
    const activator = (referenceElement.current as unknown) as Element;

    if (!activator.contains(event.target as Node) && !popper.contains(event.target as Node) && props.onClose) {
      props.onClose();
    }
  }, [props]);

  useLayoutEffect(() => {
    if (props.active) {
      document.addEventListener('click', clickOutside);

      return () => {
        document.removeEventListener('click', clickOutside);
      };
    }
  });

  return (
    <React.Fragment>
      <div className="w-100% h-100%" ref={referenceElement}>
        { props.activator }
      </div>

      { ReactDOM.createPortal(<div
        className="z-9999"
        ref={popperElement}
        style={{...styles['popper']}}
        {...attributes.popper}>
        <div style={{display: props.active ? 'block' : 'none'}}>
          { props.children }
        </div>
      </div>, document.body)}
    </React.Fragment>
  );
};
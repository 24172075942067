import React, { useEffect, useRef, useState } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { hasBillingInformationState, lastInvoiceUpdatedState, lastMonitorsUpdatedState, roleState, teamsState, teamState, userState } from "./atoms";
import axios from "axios";
import { apiBaseUrl } from "./Consts";
import { Invoice, MemberRoleServerEvent, MonitorServerEvent, PhoneAlertLog } from "./model";
import { useHandleRequestError } from "./hooks";
import { productsNonFreeEnabledState } from "./atoms/productsNonFreeEnabledState";
import { InvoiceDetails } from "./invoice-details";
import { TeamApp } from "./TeamApp";
import { lastPhoneAlertLogState } from "./atoms/lastPhoneAlertLogState";

export const TeamScope: React.FC = () => {
  const { teamSlug } = (useParams() as any);
  const { handleRequestError } = useHandleRequestError()

  const user = useRecoilValue(userState);
  const [ team, setTeam ] = useRecoilState(teamState);
  const [ teams, setTeams ] = useRecoilState(teamsState);
  const setRole = useSetRecoilState(roleState);
  const setProductsNonFreeEnabled = useSetRecoilState(productsNonFreeEnabledState);
  const setLastMonitorsUpdated = useSetRecoilState(lastMonitorsUpdatedState);
  const setHasBillingInformation = useSetRecoilState(hasBillingInformationState);
  const setLastPhoneAlertLogUpdated = useSetRecoilState(lastPhoneAlertLogState);
  const setLastInvoiceUpdated = useSetRecoilState(lastInvoiceUpdatedState);
  const history = useHistory();

  useEffect(() => {
    if (teams && teams.length > 0 && !team) {
      axios.get(`${apiBaseUrl}/teams/${teamSlug}`)
        .then(response => {
          setTeam(response.data.team);
          setRole(response.data.role);
          setProductsNonFreeEnabled(response.data.productsNonFreeEnabled)
          setHasBillingInformation(response.data.hasBillingInformation);
        })
        .catch(error => handleRequestError(error));
    }
  }, [handleRequestError, setHasBillingInformation, setProductsNonFreeEnabled, setRole, setTeam, team, teamSlug, teams])

  const wsClientRef = useRef<WebSocket>();
  const [wsWaitingToReconnect, setWsWaitingToReconnect] = useState(false);

  useEffect(() => {

    if (wsWaitingToReconnect || !team) {
      return;
    }

    // Only set up the websocket once
    if (!wsClientRef.current && apiBaseUrl) {
      let protocol = "ws:";
      if (apiBaseUrl.match(/^https:/)) {
        protocol = "wss:";
      }
      const wsClient = new WebSocket(`${apiBaseUrl.replace(/^(http:|https:)/, protocol)}/${team.uuid}/server-events`);
      wsClientRef.current = wsClient;
      // Used in useEffect callback
      (window as any).wsClientOnTeam = wsClient;

      wsClient.onerror = (e) => console.error(e);
      let timeout: ReturnType<typeof setTimeout>;

      wsClient.onclose = () => {
        if (wsClientRef.current) {
          // Connection failed
        } else {
          // Cleanup initiated from app side, can return here, to not attempt a reconnect
          return;
        }

        if (wsWaitingToReconnect) {
          return;
        }

        // Setting this will trigger a re-run of the effect,
        // cleaning up the current websocket, but not setting
        // up a new one right away
        setWsWaitingToReconnect(true);

        // This will trigger another re-run, and because it is false,
        // the socket will be set up again
        timeout = setTimeout(() => setWsWaitingToReconnect(false), 5000);
      };

      wsClient.onmessage = e => {
        if (e.data === "ping") {
          wsClient.send("pong");
        } else {
          const json = JSON.parse(e.data);
          switch (json.serverEventName) {
            case "ServerEventMonitorsUpdated":
              setLastMonitorsUpdated(json.data as MonitorServerEvent[]);
              break;
            case "ServerEventMemberRoleChanged":
              const serverEvent = json.data as MemberRoleServerEvent;
              if (user && user.uuid === serverEvent.userUuid) {
                setRole(serverEvent.role);
              }
              break;
            case "ServerEventProductsNonFreeEnabledChanged":
              setProductsNonFreeEnabled(json.data.productsNonFreeEnabled as boolean);
              break;
            case "ServerEventPhoneAlertLogUpdated":
              setLastPhoneAlertLogUpdated(json.data.phoneAlertLog as PhoneAlertLog);
              break;
            case "ServerEventInvoiceUpdated":
              setLastInvoiceUpdated(json.data.invoice as Invoice);
              break;
          }
        }
      };

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      }
    }
  }, [setLastMonitorsUpdated, setProductsNonFreeEnabled, setRole, setTeam, setTeams, team, user, wsWaitingToReconnect]);

  useEffect(() => {
    if (team && teamSlug && teamSlug !== team.slug) {
      const pathSplitted = history.location.pathname.split("/");
      pathSplitted[1] = team.slug;
      history.replace(pathSplitted.join("/"));

      // setTeams(teams => {
      //   if (teams) {
      //     const teamIndex = teams?.findIndex(t => t.uuid === team.uuid);
      //     if (teamIndex > -1) {
      //       const _teams = [...teams]
      //       _teams.splice(teamIndex, 1, team);
      //       teams = _teams
      //     }
      //   }
      //   return teams;
      // })
    }

  }, [history, setTeams, team, teamSlug]);

  useEffect(() => {
    return () => {
      setTeam(undefined);
      wsClientRef.current = undefined;

      if ((window as any).wsClientOnTeam) {
        (window as any).wsClientOnTeam.close();
      }
    }
  }, [setTeam]);

  return (
    <Switch>
      <Route path="/:teamSlug/billing/invoices/:invoiceUuid" exact={true} component={InvoiceDetails} />
      <Route path={"/:teamSlug"} component={TeamApp} />
    </Switch>
  )
}

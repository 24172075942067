import React, { useEffect, useState } from "react";
import { formatResponseTime, roundMaxChartAxis, statusToColor } from "../../Helper";
import { AverageRt, MonitorEvent, MonitorRegionEvent } from "../../model";
import { DateTime } from "luxon";
import { MonitorStatusType } from "../../Consts";

type ResponseTimeHourProps = {
  date: DateTime,
  averageRt: AverageRt[]
  events: MonitorEvent[] | MonitorRegionEvent[]
}

class HourInformation {
  public readonly status: MonitorStatusType[] = [];

  constructor(
    public readonly date: DateTime,
    public readonly hour: number,
    public readonly responseTime: number,
    events: MonitorEvent[] | MonitorRegionEvent[]
  ) {
    const _events = [...events].reverse();
    const currentDateTime = DateTime.now();
    const firstEvent = _events[0];

    const firstEventDateTime = DateTime.fromISO(firstEvent.createdAt);

    if (
        (
          date.year === currentDateTime.year &&
          date.month === currentDateTime.month &&
          date.day === currentDateTime.day &&
          hour > currentDateTime.hour
        ) ||
        (
          date.year <= firstEventDateTime.year &&
          date.month <= firstEventDateTime.month &&
          date.day <= firstEventDateTime.day &&
          hour < firstEventDateTime.hour
        )
      ) {
        return;
    }

    this.status.push(firstEvent.status);

    for (let i = 1; i < _events.length; i++) {
       const createdAtDateTime = DateTime.fromISO(_events[i].createdAt);
      if (createdAtDateTime < date.plus({hours: hour})) {
        this.status[0] = _events[i].status;
      } else if (createdAtDateTime.hasSame(date, "day")) {
        if (createdAtDateTime.hour === hour) {
          const existingIndex = this.status.findIndex(s => s === _events[i].status);
          if (existingIndex >= 0) {
            this.status.splice(existingIndex, 1);
          }
          this.status.push(_events[i].status);
        } else if (createdAtDateTime.hour > hour) {
          break;
        }
      } else {
        break;
      }
    }
  }
}

export const ResponseTimeHour: React.FC<ResponseTimeHourProps> = (props) => {

  const [ hours, setHours ] = useState<HourInformation[]>();
  const [ maxChartValue, setMaxChartValue ] = useState(0);

  useEffect(() => {
      const hours: HourInformation[] = [];
      const mart = props.averageRt
      const responseTimeHours: number[] = [];
      for (let hour = 0; hour < 24; hour++) {
        const martHour = mart.find(m => {
          return DateTime.fromISO(m.createdAt).hour === hour
        });
        const responseTimeHour = martHour ? martHour.rt.reduce((t,v) => t+v) : 0;
        responseTimeHours.push(responseTimeHour);

        hours.push(new HourInformation(props.date, hour, responseTimeHour, props.events));
      }

      setMaxChartValue(roundMaxChartAxis(Math.max(...responseTimeHours)));
      setHours(hours);
  }, [props.events, props.date, props.averageRt]);

  return (
    <div className="d-f fb-a fb-d-c">
      <div className="d-f fb-d-r">
        <div className="d-f">Hours</div>
      </div>
      <div className="d-f h-8 m-t-0.5">
        <div className="d-f fb-d-c j-c-sb fb-n w-4 h-100% m-r-0.5 p-b-2">
          <div className="f-s-0.75 t-c-gray t-a-r">{ formatResponseTime(maxChartValue) }</div>
          <div className="f-s-0.75 t-c-gray t-a-r">{ formatResponseTime(maxChartValue / 2) }</div>
          <div className="f-s-0.75 t-c-gray t-a-r">{ formatResponseTime(0) }</div>
        </div>
        <div className="fb-a d-f h-100% of-x-s p-b-1">
          {hours?.map((h, i) => {
            let barSize = ((h.responseTime / maxChartValue));
            if (barSize < 0.01) {
              barSize = 0.01;
            }
            barSize = barSize * 100;
            return(
              <div key={i} className="d-f fb-d-c w-1.2 m-r-0.5">
                <div className="fb-a d-f a-i-e h-100%">
                  {h.responseTime === 0 ?
                    <div
                      className="w-1.2 h-1.2 d-f fb-d-r"
                      style={{clipPath: "circle(50% at 50% 50%)"}}>
                      {h.status.map((s,i) =>
                        <div
                          key={i}
                          className="fb-a h-100%"
                          style={{backgroundColor: statusToColor(s)}}></div>
                      )}
                    </div>
                    : h.status.map((s,i) =>
                      <div
                        key={i}
                        className="fb-a"
                        style={{height: `${barSize}%`, backgroundColor: statusToColor(s)}}></div>
                    )
                  }
                </div>
                <div className="fb-n h-1 t-c-gray t-a-c w-100% f-s-0.75">{ `${i < 10 ? '0' : ''}${i}`}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}
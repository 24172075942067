import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiBaseUrl, MonitorStatusType } from '../Consts';
import { FilterIcon, X } from '../images';
import { Group, MonitorsFilterModel } from '../model';
import { CheckboxWithStatus, Icon, Menu, MenuItem, Popover } from '../ui';
import { MonitorsFilterEditor } from './MonitorsFilterEditor';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { teamState } from '../atoms';
import { useHandleRequestError } from '../hooks';
import { IconType } from '../ui/types';
import { GroupNameWithColor } from '../ui/groupNameWithColor';

type MonitorsFilterProps = {
  model: MonitorsFilterModel
  onChange: (model?: MonitorsFilterModel) => void
}

export const MonitorsFilter: React.FC<MonitorsFilterProps> = (props) => {

  const { t } = useTranslation();
  const team = useRecoilValue(teamState);
  const { handleRequestError } = useHandleRequestError();
  const [ menuActive, setMenuActive ] = useState(false);
  const [ showEditor, setShowEditor ] = useState(false);
  const [ menuComponentVersion, setMenuComponentVersion ] = useState(new Date().getUTCMilliseconds());
  const hasValue = (props.model.status && props.model.status.length > 0) || props.model.groupUuid || props.model.pageUuid || props.model.alertUuid;
  const [ model, setModel ] = useState<MonitorsFilterModel>();

  const toggleActive = useCallback(() => {
    setMenuActive(!menuActive)
    setMenuComponentVersion(new Date().getUTCMilliseconds());
  }, [menuActive]);

  const clear = useCallback(() => {
    setMenuActive(false);
    props.onChange(new MonitorsFilterModel());
  }, [props]);

  const items = useMemo(() => [
    {
      title: t("Editor"),
      onClick: () => {
        setMenuActive(false);
        setShowEditor(true);
      }
    },
    ...(model?.title ? [
      {
        separator: true
      },
      {
        title: model.title,
        selected: true,
        onClick: () => {
          setMenuActive(false);
          props.onChange({
            ...model,
            groupUuid: undefined,
            pageUuid: undefined,
            alertUuid: undefined,
            title: undefined
          });
        }
      }] : []),
    {
      separator: true
    },
    ...[
      [MonitorStatusType.Down, "Down"],
      [MonitorStatusType.Disrupted, "Disrupted"],
      [MonitorStatusType.Degraded, "Degraded"],
      [MonitorStatusType.Maintenance, "Maintenance"],
      [MonitorStatusType.Up, "Up"]
    ].map<MenuItem>(o => {
      return {
        title: t(o[1]),
        selected: model?.status && model?.status.includes(o[0] as MonitorStatusType),
        onClick: () => {
          const statusType = o[0] as MonitorStatusType;
          setMenuActive(false);

          const _status: MonitorStatusType[] = model?.status ? [...model.status] : [];
          if (_status.includes(statusType)) {
            _status.splice(_status.indexOf(statusType), 1);
          } else {
            _status.push(statusType)
          }
          props.onChange(
            {...model, status: _status.length > 0 ? _status : undefined} as MonitorsFilterModel)
        }
      } as MenuItem
    }),
    ...(model?.title || (model?.status && model.status.length > 0) ? [
      {
        separator: true
      },
      {
        title: t("Clear"),
        onClick: clear
      }] : []),
  ] as MenuItem[], [clear, model, props, t]);

  useEffect(() => {
    if (team) {
      if (!props.model.title && (props.model.groupUuid || props.model.pageUuid || props.model.alertUuid)) {
        let url = "";
        if (props.model.groupUuid) {
          url = `${apiBaseUrl}/${team.uuid}/groups/${props.model.groupUuid}`;
        } else if (props.model.pageUuid) {
          url = `${apiBaseUrl}/${team.uuid}/pages/${props.model.pageUuid}`;
        } else {
          url = `${apiBaseUrl}/${team.uuid}/alerts/${props.model.alertUuid}`;
        }
        axios.get(url, {
          params: {
            short: true
          }
        })
        .then((res) => {
          if (props.model.groupUuid) {
            setModel({
              ...props.model,
              title: res.data.group.name,
              groupColor: res.data.group.color
            } as MonitorsFilterModel);
          } else if (props.model.pageUuid) {
            setModel({
              ...props.model,
              title: res.data.page.name,
              pageFaviconUrl: res.data.page.faviconUrl,
              pageLogoUrl: res.data.page.logoUrl
            } as MonitorsFilterModel);
          } else {
            setModel({
              ...props.model,
              title: res.data.alert.name
            } as MonitorsFilterModel);
          }
        }).catch(err => {
          handleRequestError(err);
          props.onChange(new MonitorsFilterModel());
        })
      } else {
        setModel(props.model);
      }
    }
  }, [handleRequestError, props, team])

  const activator = (
    <div
      onClick={toggleActive}
      className={`d-f p-t-0.5 a-i-s j-c-e b-c-g b-sb-s b-w-0px m-t-1px c-p ${hasValue ? "b-wb-1px max-w-15 w-15-M" : "j-c-e m-b-1px"}`}>
      <div
        className={`fb-n d-f ${hasValue ? "t-c-g" : ""}`}>
        <FilterIcon />
      </div>
      <div className={`d-f a-i-c fb-w-w ${hasValue ? "fb-a" : "fb-n"}`}>
        {model?.status ?
          model.status.map(st => <div className="m-l-0.5 m-b-0.5" key={st}>
            <CheckboxWithStatus status={st} size={"medium "} />
          </div>)
        : null}
        {model?.groupUuid ?
          <div className="d-f a-i-c m-l-0.5 m-b-0.5">
            <div className="d-f"><Icon type={IconType.Group} color={model.groupColor} size={"small"} /></div>
            <div className="m-l-0.25 f-s-0.85 m-r-0.5 max-w-6 of-h t-o-e ws-n">
              <GroupNameWithColor
                group={{uuid: model.groupUuid, name: model.title, color: model.groupColor} as Group}
                textSize={"small"}/>
            </div>
          </div>
        : null}
        {model?.pageUuid ?
          <div className="d-f a-i-c m-l-0.5 m-b-0.5 h-24px" title={t("Page")}>
            <div className="d-f fb-n">
              {model.pageFaviconUrl ?
                <Icon type={IconType.Custome} background={model.pageFaviconUrl} size={"small"}/>
              :
                <Icon type={IconType.Page} size={"small"} />
              }
            </div>
            <div className="m-l-0.25 f-s-0.85 m-r-0.5 max-w-6 of-h t-o-e ws-n">
              {model.title}
            </div>
          </div>
        : null}
        {model?.alertUuid ?
          <div className="d-f a-i-c m-l-0.5 m-b-0.5 h-24px" title={t("Alert")}>
            <div className="d-f fb-n">
              <Icon type={IconType.Alert} color="black" size={"small"} />
            </div>
            <div className="m-l-0.25 f-s-0.85 m-r-0.5 max-w-6 of-h t-o-e ws-n">
              {model.title}
            </div>
          </div>
        : null}
        {!hasValue ?
          <div
            className="p-h-0.5 f-s-1 t-c-gray d-f a-i-c">
            {t("Filter")}
          </div>
        : null}
      </div>
      {hasValue ?
        <div
          onClick={(e) => {
            e.stopPropagation();
            clear();
          }}
          className="fb-n d-f a-i-c p-h-0.5 t-c-gray c-p">
          <X />
        </div>
      : null}
    </div>);

  return (
    model ?
      <>
        <div className="d-f j-c-e">
          <div className={`${hasValue ? "fb-a" : "fb-n"}`}>
            <Popover
              active={menuActive}
              activator={activator}
              placement="bottom-start"
              onClose={toggleActive}>
              <Menu items={items} key={menuComponentVersion} />
            </Popover>
          </div>
        </div>
        {showEditor ?
          <MonitorsFilterEditor
            filterModel={model}
            onApply={(filterModel) => {
              setShowEditor(false);
              props.onChange(filterModel);
            }}
            onCancel={() => {
              setShowEditor(false);
            }} />
        : null}
      </>
    : null
  );
}

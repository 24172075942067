import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChevronDown } from "../images";
import { ChevronRight } from "../images/ChevronRight";
import { Monitor, MonitorHttpResponse } from "../model";
import { BaseInputOnAddEvent, BaseInputOnChangeEvent, BaseInputOnRemoveEvent, HelpTip, TagsInput, TagsInputItem, TextInput  } from "../ui";
import { SelectionButton } from "../ui/selection-button/SelectionButton";
import { Errors } from "../validator/Validator";

type HttpResponseProps = {
  monitor: Monitor
  isFreeInterval: boolean
  onChangeHttpResponseField: (fieldName: keyof MonitorHttpResponse, event: BaseInputOnChangeEvent) => void
  onBlurHttpResponseField: (fieldName: keyof MonitorHttpResponse, event: BaseInputOnChangeEvent) => void
  errorsResponse: Errors<MonitorHttpResponse>
  onAddHttpResponseStatus: (event: BaseInputOnChangeEvent) => void
  onRemoveHttpResponseStatus: (event: BaseInputOnRemoveEvent) => void
  httpResponseStatusValue?: string
  onChangeHttpResponseStatusValue: (event: BaseInputOnAddEvent) => void
  errorResponseStatusValue?: string
  onShow: () => void
}

export const HttpResponse: React.FC<HttpResponseProps> = (props) => {

  const { t } = useTranslation();

  const httpResponse = props.monitor.httpResponse;

  const statusTags = useMemo(() =>
    httpResponse ? (httpResponse.status || []).map(s => new TagsInputItem(s)) : [],
    [httpResponse?.status]);

  const [show, setShow] = useState(!!httpResponse);

  const activateShow = useCallback(() => {
    setShow(true);
    props.onShow();
  }, [props.onShow]);

  return <div className="d-f fb-d-c m-t-1.5 m-t-2.5-M">
    <div
      onClick={() => show ? setShow(false) : activateShow()}
      className={`d-f a-i-c p-b-0.5 c-p ${ !show ? "b-sb-s b-w-1px b-c-lightestGray" : "" }`}>
      <div className="fb-a f-s-1 f-w-600">{t("Response")}</div>
      <div
        className="d-f fb-n m-l-1 t-c-g f-s-1 j-c-s">
        <div className="d-f t-c-g">{show ? <ChevronDown /> : <ChevronRight />}</div>
      </div>
    </div>
    {show && httpResponse ?
      <>
        <div className="d-f fb-d-c a-i-s m-t-1 m-t-1.5-M, w-100%">
          <div className="d-f">
            <div className={`${props.isFreeInterval ? "t-c-lightGray" : ""} m-r-1`}>
              {t("Status")}
            </div>
            <HelpTip disabled={props.isFreeInterval}>
              <div>
                In this field, one or more HTTP status codes<br />
                are set as a range of numbers (200-299)<br />
                or exact status codes.<br /><br />
                Example: 200-299 301 302
              </div>
            </HelpTip>
          </div>
          <div className="d-f fb-d-c w-100% m-t-0.5">
            <TagsInput
              placeholder={t("Type a new status")}
              disabled={props.isFreeInterval}
              onInputValueChange={e => props.onChangeHttpResponseStatusValue( e )}
              inputValue={props.httpResponseStatusValue}
              charSeparators=" ,;"
              autoFocus={false}
              tags={statusTags}
              onAdd={props.onAddHttpResponseStatus}
              onRemove={props.onRemoveHttpResponseStatus}
              error={props.errorResponseStatusValue}
              suffix={["Matches", "Not Matches"].map((label, i) =>
                <div key={i} className="m-r-0.5">
                  <SelectionButton
                    disabled={props.isFreeInterval}
                    onChange={e => props.onChangeHttpResponseField("statusMatches", new BaseInputOnChangeEvent(i === 0, e.dirty, e.touched))}
                    checked={(i === 0 && [null, undefined, true].some(v => v === httpResponse.statusMatches) || (i === 1 && httpResponse.statusMatches === false))}
                    label={label} />
                </div>
              )}
            />
          </div>
          <div className={`${props.isFreeInterval ? "t-c-lightGray" : ""} m-t-2`}>
            {t("Keyword in Header")}
            <span className={`m-l-1 ${!props.isFreeInterval ? "t-c-gray" : ""}`}>{t("(case insensitive)")}</span>
          </div>
          <div className="d-f fb-d-c w-100% m-t-0.5">
            <TextInput
              disabled={props.isFreeInterval}
              placeholder={t("Keyword in Header")}
              onChange={e => props.onChangeHttpResponseField("keywordHeader", e )}
              onBlur={e => props.onBlurHttpResponseField("keywordHeader", e )}
              value={httpResponse.keywordHeader}
              error={props.errorsResponse.fields.keywordHeader}
              suffix=
                {["Exists", "Not Exists"].map((label, i) =>
                <div key={i} className="m-r-0.5">
                  <SelectionButton
                    disabled={props.isFreeInterval}
                    onChange={e => props.onChangeHttpResponseField("keywordHeaderExists", new BaseInputOnChangeEvent(i === 0, e.dirty, e.touched))}
                    checked={(i === 0 && [null, undefined, true].some(v => v === httpResponse.keywordHeaderExists) || (i === 1 && httpResponse.keywordHeaderExists === false))}
                    label={label} />
                </div>
              )}
            />
          </div>
          <div className={`${props.isFreeInterval ? "t-c-lightGray" : ""} m-t-2`}>
            {t("Keyword in Body")}
            <span className={`m-l-1 ${!props.isFreeInterval ? "t-c-gray" : ""}`}>{t("(case insensitive)")}</span>
          </div>
          <div className="d-f fb-d-c w-100% m-t-0.5">
            <TextInput
              disabled={props.isFreeInterval}
              placeholder={t("Keyword in Body")}
              onChange={e => props.onChangeHttpResponseField("keywordBody", e )}
              onBlur={e => props.onBlurHttpResponseField("keywordBody", e )}
              value={httpResponse.keywordBody}
              error={props.errorsResponse.fields.keywordBody}
              suffix=
                {["Exists", "Not Exists"].map((label, i) =>
                <div key={i} className="m-r-0.5">
                  <SelectionButton
                    disabled={props.isFreeInterval}
                    onChange={e => props.onChangeHttpResponseField("keywordBodyExists", new BaseInputOnChangeEvent(i === 0, e.dirty, e.touched))}
                    checked={(i === 0 && [null, undefined, true].some(v => v === httpResponse.keywordBodyExists) || (i === 1 && httpResponse.keywordBodyExists === false))}
                    label={label} />
                </div>
              )}
            />
          </div>
        </div>
      </>
    : null}
  </div>
}
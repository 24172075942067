import { MonitorStatusType } from "../Consts";
import { reduceStatus } from "../Helper";
import { Page, Monitor } from "../model";

// Immutable class
export class PageSelector {

  constructor(
    public readonly page: Page,
    public readonly expanded: boolean = false,
    public readonly selected: boolean = false,
    public readonly deleting: boolean = false) {
  }

  delete(): PageSelector {
    return new PageSelector(this.page, this.expanded, this.selected, true);
  }

  select(): PageSelector {
    return new PageSelector(this.page, this.expanded, !this.deleting ? true : this.selected, this.deleting);
  }
  
  unselect(): PageSelector {
    return new PageSelector(this.page, this.expanded, !this.deleting ? false : this.selected, this.deleting);
  }

  toggle(): PageSelector {
    return new PageSelector(this.page, this.expanded, !this.deleting ? !this.selected : this.selected, this.deleting);
  }

  expand(): PageSelector {
    return new PageSelector(this.page, true, this.selected, this.deleting);
  }

  updateFromRemote(page: Page): PageSelector {
    return new PageSelector(page, this.expanded);
  }

  updateMonitorsFromRemote(monitors: Monitor | Monitor[]): PageSelector {
    if (!this.page.statuses || this.page.statuses.length === 0) {
      return this;
    }

    if (!(monitors instanceof Array)) {
      monitors = [monitors];
    }

    let found = false;
    const newStatuses = [...this.page.statuses];

    if (newStatuses[0].type === "monitor") {
      for (const monitor of monitors) {
        const index = newStatuses.findIndex(s => s.uuid === monitor.uuid);
    
        if (index > -1) {
          found = true;
          switch (monitor.status) {
            case MonitorStatusType.Deleted:
            case MonitorStatusType.Archived:
              newStatuses.splice(index, 1);
              break;
            default:
              newStatuses[index].status = monitor.status;
          }

        }
      }
  
    } else {

      for (const monitor of monitors) {
        for (const status of newStatuses) {
          if (!status.monitors || status.monitors.length === 0) {
            continue;
          }

          const newMonitors = [...status.monitors];

          const index = newMonitors.findIndex(s => s.uuid === monitor.uuid);
    
          if (index > -1) {
            found = true;
            switch (monitor.status) {
              case MonitorStatusType.Deleted:
              case MonitorStatusType.Archived:
                newMonitors.splice(index, 1);
                break;
              default:
                newMonitors[index].status = monitor.status;
            }
          }
          status.monitors = newMonitors;
          if (!status.monitors || status.monitors.length === 0) {
            status.status = MonitorStatusType.Paused;
          } else {
            status.status = reduceStatus(status.monitors.map(m => m.status as MonitorStatusType))
          }
        }
      }
    }

    return found ?
      new PageSelector({...this.page, statuses: newStatuses}, this.expanded, this.selected, this.deleting) :
      this;
  }

  public get blocked(): boolean {
    return this.deleting;
  }

  public get canDelete(): boolean {
    return !this.blocked;
  }
}
import React, { useCallback, useState } from 'react';
import logo from '../logo.png';
import { BaseInputOnChangeEvent, Button, Spinner, TextInput } from '../ui';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { apiBaseUrl } from '../Consts';
import { useHandleRequestError } from '../hooks';
import { SendResetPasswordEmailRequest } from '../model';
import { useValidator, Validations } from '../validator';
import { isFieldEmailNotMatching, isFieldStringEmpty } from '../Validations';
import { Link } from 'react-router-dom';

const v = new Validations(new SendResetPasswordEmailRequest());
v.addField("email", (v: any) => isFieldStringEmpty(v) || isFieldEmailNotMatching(v));

export const ForgotPassword: React.FC = () => {

  const { t } = useTranslation();
  const { handleRequestError } = useHandleRequestError();
  const [ resetPasswordEmail, setResetPasswordEmail ] = useState(new SendResetPasswordEmailRequest());
  const { errors, validateField, validateAll, resetErrorAllFromServer } = useValidator(v);
  const [ isSaving, setIsSaving ] = useState(false);
  const [ isSent, setIsSent ] = useState(false);

  const onChangeField = useCallback(
    (fieldName: keyof SendResetPasswordEmailRequest, event: BaseInputOnChangeEvent) => {
      const value = event.value;
      if (event.dirtyAndTouched) {
        validateField(fieldName, event.value);
      }
      const _resetPasswordEmail = { ...resetPasswordEmail } as SendResetPasswordEmailRequest;
      _resetPasswordEmail[fieldName] = value as never;

      setResetPasswordEmail(_resetPasswordEmail);

    }, [resetPasswordEmail, validateField])

  const onBlurField = useCallback(
    (fieldName: keyof SendResetPasswordEmailRequest, event: BaseInputOnChangeEvent) => {
      if (event.dirty) {
        validateField(fieldName, event.value);
      }
    }, [validateField])

  const save = useCallback(() => {
    if (validateAll(resetPasswordEmail)) {
      setIsSaving(true);
      axios.post(`${apiBaseUrl}/send-reset-password-email`, resetPasswordEmail)
        .then(() => setIsSent(true))
        .catch(err => {
          handleRequestError(err)
          resetErrorAllFromServer(err.response.data)
        })
        .finally(() => setIsSaving(false));
    }
  }, [validateAll, resetPasswordEmail, handleRequestError, resetErrorAllFromServer]);

  return (
    <div className="d-f fb-d-c a-i-c bg-c-nw h-100vh">
      <div className="m-v-2">
        <img className="h-3" src={logo} alt="Statsignal" />
      </div>

      <div
        className="d-f fb-d-c a-i-c bg-c-w w-100% max-w-24 p-2">
        <div className="f-s-1.5 f-w-600 m-t-1">{t("Forgot Password")}</div>
        <div className="m-t-1 f-w-300 t-a-c">
          {t("We will send you an email to reset your password")}
        </div>
        {!isSent ?
          <form className="w-100% d-f fb-d-c a-i-c" onSubmit={(e) => { e.preventDefault(); save(); }}>
            <div className="w-100% m-t-2">
              <TextInput
                autoFocus={true}
                value={resetPasswordEmail.email}
                placeholder={t("Email Address")}
                onChange={e => onChangeField("email", e)}
                onBlur={e => onBlurField("email", e)}
                error={errors.fields.email}
                type="text"
                material={true}
                color="black" />
            </div>
            <div className="w-100% m-t-2">
              {isSaving ?
                <Spinner type={"primary"} />
              :
                <Button submit={true} type="primary" fullWidth="always">
                  {t("Send Email")}
                </Button>
              }
            </div>
            <Link to="/sign-in" className="t-c-g f-s-1.25 c-p m-t-1">
              {t("Cancel")}
            </Link>
          </form>
        :
          <div className="m-v-2">
            <div className="t-a-c">
              {t("If a matching account is found then we will send an email to {{email}} to allow you to reset your password.", {email: resetPasswordEmail.email})}
            </div>

            <Link to="/sign-in" className="f-w-400 f-s-1 t-c-g d-f j-c-c m-t-1">
              {t("Got it")}
            </Link>
          </div>
        }
      </div>
    </div>
  )
}
import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { Spinner, Modal } from '../ui';
import { apiBaseUrl } from "../Consts";
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { teamState } from '../atoms';
import { useHandleRequestError } from '../hooks';
import { productsNonFreeEnabledState } from '../atoms/productsNonFreeEnabledState';

export const SettingsMembersConvert: React.FC = () => {

  const { t } = useTranslation();
  const team = useRecoilValue(teamState);
  const [ hasFreeMonitors, setHasFreeMonitors ] = useState(false);
  const [ isConvertingToTeam, setIsConvertingToTeam ] = useState(false);
  const productsNonFreeEnabled = useRecoilValue(productsNonFreeEnabledState);

  const { handleRequestError } = useHandleRequestError();

  const confirmConvertToTeam = useCallback(() => {

    if (team) {
      axios.get(`${apiBaseUrl}/${team.uuid}/team/has-free-monitors`)
        .then(response => {
          setHasFreeMonitors(response.data.hasFreeMonitors);
          setIsConvertingToTeam(true);
        })
        .catch(error => handleRequestError(error));
    }
  }, [handleRequestError, team])

  const convertToTeam = useCallback(() => {
    if (team) {
      axios.put(`${apiBaseUrl}/${team.uuid}/team/workspace-to-team`)
        .then((response) => {
          if (response.data.checkoutUrl) {
            window.location.href = response.data.checkoutUrl
          } else {
            window.location.reload();
          }
        })
        .catch(error => handleRequestError(error));
    }
  }, [handleRequestError, hasFreeMonitors, productsNonFreeEnabled, team])

  if (!team) {
    return null;
  } return (

      <>
        <div className="b-s-s b-w-1px b-c-g bg-c-nw p-1 f-s-1 m-t-4">
          <div className="f-w-700 m-b-0.5">
            {t("Convert the workspace \"{{workspace}}\" to a team", {workspace: team.name})}
          </div>
          <div className="d-f fb-d-c fb-d-r-M">
            <div className="fb-a p-r-2-M d-f fb-d-c">
              {t("You must convert the workspace into a team in order to add members")}.
            </div>
            <div className="d-f fb-n j-c-e p-t-1 p-t-0-M">
              {isConvertingToTeam ?
                <Spinner type={"primary"} />
              :
                <div
                  onClick={confirmConvertToTeam}
                  tabIndex={0}
                  className="t-c-g c-p">
                  {t("Convert now")}
                </div>
              }
            </div>
          </div>
        </div>

        { isConvertingToTeam ?
          <Modal
            open={isConvertingToTeam}
            onClose={ () => setIsConvertingToTeam(false) }
            title={t("Converting workspace into a team")}
            secondaryActions={ [ { title: t("Cancel"), type: "primary", onAction: () => setIsConvertingToTeam(false) } ] }
            primaryAction={ { title: t("Yes, continue"), type: "primary", onAction: () => {
              setIsConvertingToTeam(false);
              convertToTeam();
            } }}>
            <div className="p-v-1 p-h-2 f-s-1.25 f-s-1.5-M f-w-300 t-c-b">
              {hasFreeMonitors ?
                t("Teams don't allow free monitors. Statsignal will convert the free monitors of this workspace to paid monitors. The interval of the converted monitors will be 1 minute")
              :
                t("Teams don't allow free monitors. So, you won't be able to add free monitors after converting this workspace into a team")
              }.<br /><br />
              {t("Do you wan't to continue?")}
            </div>
          </Modal>
          : null
        }
      </>
  );
}

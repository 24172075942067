import React, { useEffect, useCallback, useState, useRef, useMemo } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { Interval, MaintenanceButton, RemoveButton, ResumeButton} from '../images';
import { GroupNameWithColor } from "../ui/groupNameWithColor";
import { Monitor, MonitorsFilterModel, Paginator as ModelPaginator } from '../model';
import { MenuInRow, Modal, CheckboxWithStatus, MultiSelectForStatus, MultiSelectState, Paginator, SearchResource, SortResource } from '../ui';
import { MonitorEdit } from '../monitor-edit';
import { Empty } from '../empty';
import { Link, useHistory } from 'react-router-dom';
import { MonitorStatus } from './MonitorStatus';
import { Loading } from '../ui/loading/Loading';
import { apiBaseUrl, cancelTokenMessage, DefaultPageSize } from "../Consts";
import { useQuery } from '../hooks/UseQuery';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { isOwnerOrMemberState, lastMonitorsUpdatedState, teamState } from '../atoms';
import { useAdjustPage, useHandleRequestError } from '../hooks';
import { MonitorSelector } from './MonitorSelector';
import { MonitorSelectors } from './MonitorSelectors';
import { MonitorsFilter } from '../monitors-filter';
import { MonitorUrl } from '../monitor-url';

export const Monitors: React.FC = () => {

  const { t } = useTranslation();
  const { handleRequestError } = useHandleRequestError();

  const history = useHistory();
  const query = useQuery();
  const team = useRecoilValue(teamState);
  const baseUrl = useMemo(() => team ? `/${team.slug}/monitors` : undefined, [team])
  const isOwnerOrMember = useRecoilValue(isOwnerOrMemberState);
  const lastMonitorsUpdated = useRecoilValue(lastMonitorsUpdatedState);

  const [ selectors, setSelectors ] = useState<MonitorSelectors>()

  const [ editingSelector, setEditingSelector ] = useState<MonitorSelector>()
  const [ deletingSelector, setDeletingSelector ] = useState<MonitorSelector>()
  const [ pausingSelector, setPausingSelector ] = useState<MonitorSelector>()
  const [ maintainingSelector, setMaintainingSelector ] = useState<MonitorSelector>()

  const [ isSelectingForPause, setIsSelectingForPause ] = useState<boolean>(false);
  const [ isSelectingForMaintenance, setIsSelectingForMaintenance ] = useState<boolean>(false);
  const [ isSelectingForDelete, setIsSelectingForDelete ] = useState<boolean>(false);

  const [ paginator, setPaginator ] = useState<ModelPaginator>();
  const fetchCancelToken = useRef<CancelTokenSource>();
  const [ filterModel, setFilterModel ] = useState<MonitorsFilterModel>(new MonitorsFilterModel());

  const queryText = useMemo(() => query.get("text") ?? undefined, [query]);
  const queryOrder = useMemo(() => query.get("order") ?? undefined, [query]);
  const queryDescending = useMemo(() => query.get("descending") ? query.get("descending") === "true" : false, [query]);
  const [updatingQuery, setUpdatingQuery] = useState(false);
  const { adjustPage, shouldFetchAndReset } = useAdjustPage(baseUrl);

  const fetch = useCallback(() => {
    if (team) {
      if (fetchCancelToken.current !== undefined) {
        fetchCancelToken.current.cancel(cancelTokenMessage);
      }
      fetchCancelToken.current = axios.CancelToken.source();
      axios.get(`${apiBaseUrl}/${team.uuid}/monitors`, {params: {
          text: query.get("text"),
          order: query.get("order"),
          descending: query.get("descending"),
          status: query.get("status"),
          groupUuid: query.get("groupUuid"),
          pageUuid: query.get("pageUuid"),
          alertUuid: query.get("alertUuid"),
          recordOffset: query.get("page") ?
            (parseInt(query.get("page") as string) - 1) * DefaultPageSize : 0,
          cancelToken: fetchCancelToken
        }})
        .then(response => {
          setSelectors(new MonitorSelectors(response.data.monitors as Monitor[]))
          setPaginator(response.data.paginator);
          adjustPage(response.data.paginator as ModelPaginator);
        })
        .catch(err => handleRequestError(err))
        .finally(() => setUpdatingQuery(false));
    }

  }, [team, query, adjustPage, handleRequestError]);

  const onUpdate = useCallback((monitor: Monitor) => {
    if (selectors) {
      setSelectors(selectors.updateFromRemote([monitor]));
    }
    setEditingSelector(undefined);
  }, [selectors]);

  const execPause = useCallback(() => {
    if (team && pausingSelector && selectors) {
      setSelectors(selectors.pause(pausingSelector));

      axios.put(`${apiBaseUrl}/${team.uuid}/monitors/${pausingSelector.monitor.uuid}/pause`)
        .then(response => setSelectors(selectors => selectors?.updateFromRemote(response.data.monitor)))
        .catch(err => handleRequestError(err));
      setPausingSelector(undefined);
    }
  }, [team, pausingSelector, selectors, handleRequestError]);

  const execMaintain = useCallback(() => {
    if (team && maintainingSelector && selectors) {
      setSelectors(selectors.maintain(maintainingSelector));

      axios.put(`${apiBaseUrl}/${team.uuid}/monitors/${maintainingSelector.monitor.uuid}/maintenance`)
        .then(response => setSelectors(selectors => selectors?.updateFromRemote(response.data.monitor)))
        .catch(err => handleRequestError(err));
      setMaintainingSelector(undefined);
    }
  }, [handleRequestError, maintainingSelector, selectors, team]);

  const resume = useCallback((resumingSelector: MonitorSelector) => {
    if (team && selectors) {
      setSelectors(selectors.resume(resumingSelector));

      axios.put(`${apiBaseUrl}/${team.uuid}/monitors/${resumingSelector.monitor.uuid}/resume`)
        .then(response => setSelectors(selectors => selectors?.updateFromRemote(response.data.monitor)))
        .catch(err => handleRequestError(err));
    }
  }, [team, selectors, handleRequestError]);


  const execDelete = useCallback(() => {
    if (team && selectors && deletingSelector) {
      setSelectors(selectors.delete(deletingSelector));
      const uuid = deletingSelector.monitor.uuid;
      axios.delete(`${apiBaseUrl}/${team.uuid}/monitors/${uuid}`,
        {
          params: {
            text: query.get("text"),
            order: query.get("order"),
            descending: query.get("descending"),
            status: query.get("status"),
            groupUuid: query.get("groupUuid"),
            pageUuid: query.get("pageUuid"),
            alertUuid: query.get("alertUuid"),
            recordOffset: query.get("page") ?
              (parseInt(query.get("page") as string) - 1) * DefaultPageSize : 0
          }
        })
        .then(response => {
          setSelectors(new MonitorSelectors(response.data.monitors as Monitor[]));
          setPaginator(response.data.paginator);
          adjustPage(response.data.paginator as ModelPaginator);
        })
        .catch(err => handleRequestError(err));

      setDeletingSelector(undefined);
    }
  }, [adjustPage, deletingSelector, handleRequestError, query, selectors, team]);

  const pauseMonitors = useCallback(() => {
    if (team && selectors) {
      setSelectors(selectors.pauseSelected());

      axios.put(`${apiBaseUrl}/${team.uuid}/monitors/pause`, {
          uuids: selectors.list.filter(ms => ms.selected).map(ms => ms.monitor.uuid)
        })
        .then(response =>
          setSelectors(selectors => selectors?.updateFromRemote(response.data.monitors).unselectAll()))
        .catch(err => handleRequestError(err));
    }
    setIsSelectingForPause(false);
  }, [handleRequestError, selectors, team]);

  const resumeMonitors = useCallback(() => {
    if (team && selectors) {
      axios.put(`${apiBaseUrl}/${team.uuid}/monitors/resume`, {
          uuids: selectors.list.filter(ms => ms.selected).map(ms => ms.monitor.uuid)
        })
        .then(response =>
          setSelectors(selectors => selectors?.updateFromRemote(response.data.monitors).unselectAll()))
        .catch(err => handleRequestError(err));
    }
  }, [handleRequestError, selectors, team]);

  const maintainingSelectors = useCallback(() => {
    if (team && selectors) {
      axios.put(`${apiBaseUrl}/${team.uuid}/monitors/maintenance`, {
          uuids: selectors.list.filter(ms => ms.selected).map(ms => ms.monitor.uuid)
        })
        .then(response =>
          setSelectors(selectors => selectors?.updateFromRemote(response.data.monitors).unselectAll()))
        .catch(err => handleRequestError(err));
    }
    setIsSelectingForMaintenance(false);
  }, [handleRequestError, selectors, team]);

  const removeMonitors = useCallback(() => {
    if (team && selectors) {
      const uuids = selectors.list.filter(ms => ms.selected).map(ms => ms.monitor.uuid);
      axios.delete(`${apiBaseUrl}/${team.uuid}/monitors`,
        {
          params: {
            uuids: uuids,
            text: query.get("text"),
            order: query.get("order"),
            descending: query.get("descending"),
            status: query.get("status"),
            groupUuid: query.get("groupUuid"),
            pageUuid: query.get("pageUuid"),
            alertUuid: query.get("alertUuid"),
            recordOffset: query.get("page") ?
              (parseInt(query.get("page") as string) - 1) * DefaultPageSize : 0
          }
        })
        .then(response => {
          setSelectors(new MonitorSelectors(response.data.monitors as Monitor[]));
          setPaginator(response.data.paginator);
          adjustPage(response.data.paginator as ModelPaginator);
        })
        .catch(err => handleRequestError(err));
    }
      setIsSelectingForDelete(false);
  }, [adjustPage, handleRequestError, query, selectors, team]);

  useEffect(() => {
    if (shouldFetchAndReset()) {
      fetch();
    }
  }, [fetch, shouldFetchAndReset]);

  const changeQuery = useCallback((queryText?: string, queryOrder?: string, queryDescending?: boolean, filterModel?: MonitorsFilterModel) => {
    if (baseUrl) {
      setUpdatingQuery(true);
      const _query: string[] = []
      if (queryText) {
        _query.push(`text=${encodeURIComponent(queryText)}`);
      } else {
        if (queryOrder) {
          _query.push(`order=${queryOrder}`);
        }
        if (queryDescending) {
          _query.push(`descending=${queryDescending.toString()}`);
        }
      }
      if (filterModel) {
        setFilterModel(filterModel);
        if (filterModel.status) {
          _query.push(`status=${filterModel.status.join(",")}`)
        }
        if (filterModel.groupUuid) {
          _query.push(`groupUuid=${filterModel.groupUuid}`)
        } else if (filterModel.pageUuid) {
          _query.push(`pageUuid=${filterModel.pageUuid}`)
        } else if (filterModel.alertUuid) {
          _query.push(`alertUuid=${filterModel.alertUuid}`)
        }
      }
      history.replace(`${baseUrl}${_query.length > 0 ? "?"+_query.join("&") : ""}`);
    }
  }, [baseUrl, history]);

  const onCancelEdit = useCallback(() => setEditingSelector(undefined), []);

  useEffect(() => {
    setFilterModel(filterModel => {
      return {
        ...filterModel,
        title: undefined,
        status: query.get("status")?.split(","),
        groupUuid: query.get("groupUuid"),
        pageUuid: query.get("pageUuid"),
        alertUuid: query.get("alertUuid")
      } as MonitorsFilterModel;
    });
  }, [query]);

  useEffect(() => {
    if (lastMonitorsUpdated) {
      setSelectors(selectors => selectors?.updateFromRemote(lastMonitorsUpdated.map(mse => mse.monitor), true));
    }
  }, [lastMonitorsUpdated]);

  if (!selectors || !team || !paginator) {

    return <Loading />

  } else if (selectors.length === 0 && !queryText && !updatingQuery && !filterModel.status && !filterModel.groupUuid && !filterModel.pageUuid && !filterModel.alertUuid) {

    return (
      <Empty
        description={
          <span>
            <Trans t={t} i18nKey="No monitors<1/>added yet">
              No monitors<br/>added yet
            </Trans>
          </span>
        }
        actionTitle={t("Add a monitor")}
        onAction={() => history.push(`${baseUrl}/new`)} />
    )

  } else {
    return (
      <React.Fragment>
        <div className="d-f fb-d-c m-h-a m-b-1 m-b-2-M p-h-0.5 p-h-2-M w-100% max-w-66-L t-c-nb">
          <div className="p-l-0.5 p-v-1.5 m-t-0.5 d-f fb-d-cr fb-d-r-L">
            <div className="d-n d-b-L f-s-2 f-w-300 fb-n">
              {t("Monitors")}
            </div>
            <div className="fb-a w-100% p-h-2-L m-t-2 m-t-0-L">
              <SearchResource initValue={queryText} onChange={(value) => value !== queryText ? changeQuery(value) : null} />
            </div>
            <div className="d-f a-i-s j-c-e fb-n">
              <div className="d-n-L f-s-1.5 f-w-300 fb-n">
                {t("Monitors")}
              </div>
              <div className="fb-a fb-n-L m-r-1.5 m-r-2.5-M d-f j-c-e">
                <MonitorsFilter model={filterModel} onChange={(model) => changeQuery(queryText, queryOrder, queryDescending, model)}  />
              </div>
              <div className="fb-n m-v-0.5">
                <SortResource
                  options={{
                    "name": t("Name"),
                    "lastCreated": t("Last created"),
                    "lastModified": t("Last modified"),
                  }}
                  desc={queryDescending}
                  order={queryOrder ?? "name"}
                  disabled={queryText !== undefined}
                  onChange={(order: string, desc: boolean) => { changeQuery(undefined, order, desc) }} />
              </div>
            </div>
          </div>
          {selectors.length === 0 ?
            <div className="d-f h-5 a-i-c f-s-1 p-l-0.5">
              {t("No result. Try your search again with more specific keywords.")}
            </div>
          :
            <>
              <div className="d-f a-i-c f-w-b p-v-1">
                <div className="d-f j-c-c w-3">
                  {isOwnerOrMember ?
                    <MultiSelectForStatus
                      circle={true}
                      onChange={() => setSelectors(selectors.changeMultiSelectState())}
                      state={selectors.multiSelectState} />
                    : null
                  }
                </div>
                <div className="d-b fb-a p-l-0.5 t-c-b">
                  {selectors.multiSelectState === MultiSelectState.None ?
                    t("Monitor")
                  :
                  <div className="d-f">
                    {selectors.hasSelectedForMaintenance ?
                      <div onClick={() => setIsSelectingForMaintenance(true)} title={t("Maintenance")} className="d-f c-p m-r-1">
                        <div className="t-c-nb">
                          <MaintenanceButton />
                        </div>
                      </div>
                    : null}

                    {selectors.hasSelectedForResume ?
                      <div onClick={() => resumeMonitors()} title={t("Resume")} className="d-f c-p m-r-1">
                        <div className="t-c-nb">
                          <ResumeButton />
                        </div>
                      </div>
                    : null}

                    {selectors.hasSelectedForDelete ?
                      <div onClick={() => setIsSelectingForDelete(true)} title={t("Delete")} className="d-f c-p">
                        <div className="t-c-nb">
                          <RemoveButton />
                        </div>
                      </div>
                    : null}
                  </div>
                  }
                </div>
                <div className="d-n d-f-M w-15 t-c-b">{t("Status")}</div>
                <div className="d-n d-f-L w-6 t-c-b">{t("Interval")}</div>
                <div className="d-f w-2"></div>
              </div>

              { selectors?.list.map((ms, i) =>
                <React.Fragment key={i}>
                  <div className="d-f a-i-s p-v-0.5 b-st-s b-c-lightestGray b-w-1px">
                    <div className="d-f fb-a fb-d-c p-r-1">
                      <div className="d-f">
                        <div className="d-f j-c-c fb-n w-3">
                          <CheckboxWithStatus
                            onChange={isOwnerOrMember ? () => setSelectors(selectors.toggle(ms)) : undefined }
                            checked={isOwnerOrMember ? ms.selected : false}
                            disabled={!isOwnerOrMember || ms.blocked}
                            status={ms.status}
                            urlStatusPending={ms.monitor.urlStatusPending}
                            size={"large"} />
                        </div>
                        <div className="d-f fb-a p-l-0.5 m-t-0.30">
                          <div className="ws-n of-h t-o-e max-w-100%">
                            <Link
                              to={`${baseUrl}/${ms.monitor.uuid}`}
                              className="t-c-nb t-o-e">
                              { ms.monitor.name}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="d-f m-l-3.5 m-b-0.5">
                        <MonitorUrl monitor={ms.monitor} size="small" />
                      </div>
                    </div>
                    <div className="d-n d-f-M fb-n w-15 m-t-0.30">
                      <MonitorStatus monitorSelector={ms} />
                    </div>
                    <div className="d-n d-f-L fb-n w-6 t-c-nb a-i-c m-t-0.30">
                      <Interval /> &nbsp; { ms.monitor.interval.seconds } s
                    </div>
                    <div className="d-f j-c-c fb-n w-2 m-t-0.30">
                      <div title={t("Options")}>
                        <MenuInRow items={[
                          {
                            title: t("Show"),
                            link: `${baseUrl}/${ms.monitor.uuid}`
                          },
                          {
                            title: t("Edit"),
                            onClick: () => setEditingSelector(ms),
                            visible: !ms.blocked && isOwnerOrMember,
                          },
                          {
                            separator: true,
                            visible: isOwnerOrMember && (ms.canMaintain || ms.canResume || ms.canDelete)
                          },
                          {
                            title: t("Maintenance"),
                            onClick: () => setMaintainingSelector(ms),
                            visible: ms.canMaintain && isOwnerOrMember && ms.status != "requiresPaymentMethod"
                          },
                          {
                            title: t("Resume"),
                            onClick: () => resume(ms),
                            visible: ms.canResume && isOwnerOrMember
                          },
                          {
                            title: t("Delete"),
                            onClick: () => setDeletingSelector(ms),
                            visible: ms.canDelete && isOwnerOrMember
                          }
                        ]} />
                      </div>
                    </div>
                  </div>
                  <div className="d-f fb-a">
                    <div className="d-f fb-w-w p-l-3.5 p-b-0.5">
                      {ms.monitor.groups ?
                        ms.monitor.groups.map((g, i) =>
                          <div key={i} className="max-w-10">
                            <GroupNameWithColor
                              key={i}
                              group={g}
                              textSize={"small"}
                              margin={true}
                              link={`/${team.slug}/monitors?groupUuid=${g.uuid}`} />
                          </div>
                        )
                      : null}
                    </div>
                    <div className="fb-n w-2 w-17-M w-23-L"></div>
                  </div>

                </React.Fragment>
              )}
            </>
          }
        </div>
        <div className="d-f j-c-c">
          <Paginator
            recordOffset={paginator.recordOffset}
            recordsCount={paginator.recordsCount}
            pageSize={paginator.pageSize}
            url={`${history.location.pathname}${history.location.search}`} />
        </div>


        { editingSelector ?
          <MonitorEdit
            monitorUuid={editingSelector.monitor.uuid}
            onUpdate={onUpdate}
            onCancel={onCancelEdit} />
          : null
        }

        { deletingSelector ?
          <Modal
            open={deletingSelector !== undefined}
            onClose={ () => setDeletingSelector(undefined) }
            title={t("Delete Monitor")}
            description={ deletingSelector.monitor.name }
            secondaryActions={ [ { title: t("Cancel"), onAction: () => setDeletingSelector(undefined) } ] }
            primaryAction={ { title: t("Delete Monitor"), onAction: execDelete, type: "destructive" } }>
            <div className="p-2">
              <div className="f-s-1.5 f-w-300 t-c-b">
                You can&apos;t reverse this action!
              </div>
              <div className="f-s-1 f-w-300 m-t-0.5">
                The stats for this Monitor will be archived.
              </div>
            </div>
          </Modal>
          : null
        }

        { pausingSelector ?
          <Modal
            open={pausingSelector !== undefined}
            onClose={ () => setPausingSelector(undefined) }
            title={t("Pause Monitor")}
            description={ pausingSelector.monitor.name }
            secondaryActions={ [ { title: t("Cancel"), type: "primary", onAction: () => setPausingSelector(undefined) } ] }
            primaryAction={ { title: t("Yes, pause monitor"), type: "primary", onAction: execPause } }>
            <div className="p-2">
              <div className="f-s-1.5 f-w-300 t-c-b">
                {t("Are you sure?")}
              </div>
            </div>
          </Modal>
          : null
        }

        { maintainingSelector ?
          <Modal
            open={maintainingSelector !== undefined}
            onClose={ () => setMaintainingSelector(undefined) }
            title={t("Switch Monitor to Maintenance")}
            description={ maintainingSelector.monitor.name }
            secondaryActions={ [ { title: t("Cancel"), type: "primary", onAction: () => setMaintainingSelector(undefined) } ] }
            primaryAction={ { title: t("Yes, switch monitor to maintenance"), type: "primary", onAction: execMaintain } }>
            <div className="p-2">
              <div className="f-s-1.5 f-w-300 t-c-b">
                {t("Are you sure?")}
              </div>
            </div>
          </Modal>
          : null
        }

        { isSelectingForDelete ?
          <Modal
            open={isSelectingForDelete}
            onClose={ () => setIsSelectingForDelete(false) }
            title={t("Delete Monitors")}
            secondaryActions={ [ { title: t("Cancel"), onAction: () => setIsSelectingForDelete(false) } ] }
            primaryAction={ { title: t("Delete Monitors"), onAction: removeMonitors, type: "destructive" } }>
            <div className="p-2">
              <div className="f-s-1.5 f-w-300 t-c-b">
                {t("You can't reverse this action!")}
              </div>
              <div className="f-s-1 f-w-300 m-t-0.5">
                {t("The stats for these Monitors will be archived.")}
              </div>
            </div>
          </Modal>
          : null
        }

        { isSelectingForPause ?
          <Modal
            open={isSelectingForPause}
            onClose={ () => setIsSelectingForPause(false) }
            title={t("Pause Monitors")}
            secondaryActions={ [ { title: t("Cancel"), type: "primary", onAction: () => setIsSelectingForPause(false) } ] }
            primaryAction={ { title: t("Yes, pause monitors"), type: "primary", onAction: pauseMonitors } }>
            <div className="p-2">
              <div className="f-s-1.5 f-w-300 t-c-b">
                {t("Are you sure?")}
              </div>
            </div>
          </Modal>
          : null
        }

        { isSelectingForMaintenance ?
          <Modal
            open={isSelectingForMaintenance}
            onClose={ () => setIsSelectingForMaintenance(false) }
            title={t("Switch Monitors to Maintenance")}
            secondaryActions={ [ { title: t("Cancel"), type: "primary", onAction: () => setIsSelectingForMaintenance(false) } ] }
            primaryAction={ { title: t("Yes, switch monitors to maintenance"), type: "primary", onAction: maintainingSelectors } }>
            <div className="p-2">
              <div className="f-s-1.5 f-w-300 t-c-b">
                {t("Are you sure?")}
              </div>
            </div>
          </Modal>
          : null
        }

      </React.Fragment>
    );
  }
}
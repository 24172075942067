import React, { useEffect, useState } from "react";
import { Breakpoints } from "../../Consts";

type Size = "medium" | "large";
export type ButtonType = "normal" | "destructive" | "primary";

type ButtonProps = {
  children?: string,
  size?: Size,
  fullWidth?: "always" | "mobile",
  disabled?: boolean,
  type?: ButtonType,
  submit?: boolean,
  onClick?: () => void
}
export const Button: React.FC<ButtonProps> = (props) => {

  function getWindowSize() {
    const {innerWidth} = window;
    return {innerWidth};
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const type = props.type ? props.type : "normal";

  let typeClass: string;
  switch (type) {
    case "destructive":
      typeClass = "bg-c-r";
      break;
    case "primary":
      typeClass = "bg-c-g";
      break;
    case "normal":
    default:
      typeClass = "bg-c-gray";
  }

  let fullWidthClass: string;
  switch (props.fullWidth) {
    case "always":
      fullWidthClass = "w-100%";
      break;
    case "mobile":
      fullWidthClass = windowSize.innerWidth < Breakpoints.Medium ? "w-100%" : "";
      break;
    default:
      fullWidthClass = "";
  }

  return (
    <button
      type={props.submit ? "submit" : "button"}
      disabled={props.disabled}
      onClick={() => props.onClick ? props.onClick() : null}
      className={`ws-n-M c-p t-a-c f-s-1.25 b-r-0.5 b-w-0 p-v-0.25 p-h-1 p-h-2-M min-h-3 t-c-w ${typeClass} ${fullWidthClass}`}>
      {props.children}
    </button>
  );
}

import Fuse from 'fuse.js'
import { useState, useMemo, useEffect } from 'react'
import { useDebounce } from './UseDebounce';

export function useFuse<T>(
  data?: T[],
  term?: string,
  fuseOptions?: Fuse.IFuseOptions<T>,
  debouceTime: number = 0) {

  const [ _fuseOptions, setFuseOptions ] = useState<Fuse.IFuseOptions<T> | undefined>(fuseOptions);

  const [ _data, setData ] = useState<T[] | undefined>(data);

  const [ _term, setTerm ] = useState<string | undefined>(term);

  const debouncedTerm = useDebounce(_term, debouceTime);

  const [ results, setResults ] = useState<Fuse.FuseResult<T>[]>();

  const fuse = useMemo(() => {
    return _data ? new Fuse(_data, _fuseOptions) : undefined
  }, [_data, _fuseOptions])

  useEffect(() => {
    if (fuse) {
      setResults( fuse.search(debouncedTerm || "") );
    }
  }, [debouncedTerm, fuse]);

  return { results, setTerm, setData, setFuseOptions };

}
import React, { useEffect, useRef, useState } from 'react';
import './common.css';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { TeamScope } from './TeamScope';
import { apiBaseUrl, teamsPath } from "./Consts";
import { GeneralScope } from './GeneralScope';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { countriesState, invitationsState, lastCheckoutCompletedState, lastPhoneAlertLogState, messageState, monitorTypesState, regionsState, teamsPaginatorState, teamsState, teamState, userState, userTimeZoneState } from './atoms';
import axios from "axios";
import { CheckoutCompleted, GetBootstrapRes, Message, PhoneAlertLog, Team, TimeZone, User } from './model';
import { productsState } from './atoms/productsState';
import { MessagePopup } from './message-popup';
import { useHandleRequestError } from './hooks';
import { SignUp } from './sign-up';
import { Loading } from './ui';
import { SignIn } from './sign-in';
import { AccountConfirmation } from './account-confirmation';
import { ForgotPassword } from './forgot-password';
import { ResetPassword } from './reset-password';
import { Invitation } from './invitation';
import { ConfirmEmail } from './confirm-email';
import { useTranslation } from 'react-i18next';



function App() {

  const [axiosInitialized, setAxiosInitialized] = useState(false);

  const versionRef = useRef<number>(0);

  const { handleRequestError } = useHandleRequestError();

  const [hasSession, setHasSession] = useState(true);
  const [user, setUser] = useRecoilState(userState);
  const setTeams = useSetRecoilState(teamsState);
  const setTeam = useSetRecoilState(teamState);
  const setInvitations = useSetRecoilState(invitationsState);
  const setTeamsPaginator = useSetRecoilState(teamsPaginatorState);
  const setProducts = useSetRecoilState(productsState);
  const setRegions = useSetRecoilState(regionsState);
  const setMonitorTypes = useSetRecoilState(monitorTypesState);
  const setUserTimeZone = useSetRecoilState(userTimeZoneState);
  const setCountries = useSetRecoilState(countriesState);
  const setLastCheckoutCompleted = useSetRecoilState(lastCheckoutCompletedState);
  const setLastPhoneAlertLogState = useSetRecoilState(lastPhoneAlertLogState);

  const { t } = useTranslation();
  const setMessage = useSetRecoilState(messageState);
  const wsClientRef = useRef<WebSocket>();
  const [wsWaitingToReconnect, setWsWaitingToReconnect] = useState(false);

  useEffect(() => {

    if (!axiosInitialized) {
      return;
    }

    if (wsWaitingToReconnect) {
      return;
    }

    // Only set up the websocket once
    if (!wsClientRef.current && apiBaseUrl) {
      let protocol = "ws:";
      if (apiBaseUrl.match(/^https:/)) {
        protocol = "wss:";
      }
      const wsClient = new WebSocket(`${apiBaseUrl.replace(/^(http:|https:)/, protocol)}/server-events`);
      wsClientRef.current = wsClient;
      // Used in useEffect callback
      (window as any).wsClient = wsClient;

      wsClient.onerror = (e) => console.error(e);
      let timeout: ReturnType<typeof setTimeout>;

      wsClient.onclose = () => {
        if (wsClientRef.current) {
          // Connection failed
        } else {
          // Cleanup initiated from app side, can return here, to not attempt a reconnect
          return;
        }

        if (wsWaitingToReconnect) {
          return;
        }

        // Setting this will trigger a re-run of the effect,
        // cleaning up the current websocket, but not setting
        // up a new one right away
        setWsWaitingToReconnect(true);

        // This will trigger another re-run, and because it is false,
        // the socket will be set up again
        timeout = setTimeout(() => setWsWaitingToReconnect(false), 5000);
      };

      wsClient.onmessage = e => {
        if (e.data === "ping") {
          wsClient.send("pong");
        } else {
          const json = JSON.parse(e.data);
          switch (json.serverEventName) {
            case "ServerEventAccountConfirmed":
              setUser({...user, requiresConfirmation: false } as User);
              setMessage({
                type: "success",
                text: t("Your account has been successfully confirmed.")
              });
              break;
            case "ServerEventChangeEmailConfirmed":
              setUser({...user, newEmail: undefined, email: json.data.email } as User);
              setMessage({
                type: "success",
                text: t("Your email has been successfully changed.")
              });
              break;
            case "ServerEventCheckoutCompleted":
              setLastCheckoutCompleted(json.data as CheckoutCompleted)
              break;
            case "ServerEventPhoneAlertLogUpdated":
              setLastPhoneAlertLogState(json.data.phoneAlertLog as PhoneAlertLog)
              break;
            case "ServerEventTeamUpdated":
              const team = json.data.team as Team;
              setTeams(teams => {
                if (teams) {
                  const teamIndex = teams?.findIndex(t => t.uuid === team.uuid);
                  if (teamIndex > -1) {
                    const _teams = [...teams]
                    _teams.splice(teamIndex, 1, team);
                    teams = _teams
                  }
                }
                return teams;
              })

              setTeam(_team => {
                if (_team && _team.uuid === team.uuid) {
                  return {...team, groups: _team.groups} as Team;
                }
                return _team
              })
              break;
          }
        }
      };

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      }
    }
  }, [axiosInitialized, setMessage, setTeam, setTeams, setUser, t, user, wsWaitingToReconnect]);

  useEffect(() => {
    if (!axiosInitialized) {
      return;
    }

    axios.get(`${apiBaseUrl}/bootstrap`)
      .then(response => {
        const res = response.data as GetBootstrapRes;
        res.teams = res.teams || [];
        res.invitations = res.invitations || [];
        setRegions(res.regions);
        setProducts(res.products);
        setMonitorTypes(res.monitorTypes);
        setUser(res.user);
        setInvitations(res.invitations);
        setTeams(res.teams);
        setCountries(res.countries);
        setTeamsPaginator(res.teamsPaginator);

        const timeZone = res.timeZones.find(tz => tz.id === res.user.timeZoneId) as TimeZone;
        setUserTimeZone(timeZone);
      })
      .catch(error => handleRequestError(error));
  }, [handleRequestError, setProducts, setInvitations, setMonitorTypes, setRegions, setTeams, setTeamsPaginator, setUser, setUserTimeZone, setCountries, axiosInitialized]);

  useEffect(() => {
    if (!axiosInitialized) {
      axios.interceptors.response.use((res) => {
        const responseVersion = parseInt(res.headers["x-console-version"] || 0);
        if (responseVersion > versionRef.current) {
          if (versionRef.current > 0) { // Notifiy new version only if it was not ready
            setMessage({
              type: "info",
              text: <span className="f-s-1.25">{t("A new version is available")}.<br /><span className="t-c-g c-p" onClick={() => window.location.reload()}>Update now!</span></span>
            } as Message)
          } else {
            versionRef.current = responseVersion;
          }
        }
        return res;
      }, (error) => {
        console.log(error)
        if (error.response.status === 401) {
          setHasSession(false);
        }
        return Promise.reject(error);
      })
      setAxiosInitialized(true);
    }
    return () => {
      wsClientRef.current = undefined;

      if ((window as any).wsClient) {
        (window as any).wsClient.close();
      }
    }
  }, [axiosInitialized, setMessage, t]);

  return (
    <>
      <BrowserRouter>
        {hasSession && !user ?
          <Loading />
        : !hasSession ?
          <Switch>
            <Route path="/confirm-account/:email/:confirmationCode" exact={true} component={AccountConfirmation} />
            <Route path="/forgot-password" exact={true} component={ForgotPassword} />
            <Route path="/invitation/:invitationCode" exact={true} component={Invitation} />
            <Route path="/reset-password/:email/:resetPasswordCode" exact={true} component={ResetPassword} />
            <Route path="/sign-up" exact={true} component={SignUp} />
            <Route path="/sign-in" exact={true} component={SignIn} />
            <Route path="/login" exact={true} component={() => <Redirect to="/sign-in" />} />
            <Redirect to="/sign-in" />
          </Switch>
        :
          <Switch>
            <Route path="/confirm-account/:email/:confirmationCode" exact={true} component={AccountConfirmation} />
            <Route path="/forgot-password" exact={true} component={ForgotPassword} />
            <Route path="/invitation/:invitationCode" exact={true} component={Invitation} />
            <Route path="/reset-password/:email/:resetPasswordCode" exact={true} component={ResetPassword} />
            <Route path={[
              "/sign-in",
              "/login",
              "/sign-up"
            ]} component={() => <Redirect to="/" />} />
            <Route path="/confirm-email/:confirmationCode" exact={true} component={ConfirmEmail} />
            <Route path="/profile" exact={true} component={GeneralScope} />
            <Route path="/checkout/:checkoutKey" exact={true} component={GeneralScope} />
            <Route path={`/workspace/initialize`} exact={true} component={GeneralScope} />
            <Route path={`/${teamsPath}/*`} exact={true} component={GeneralScope} />
            <Route path="/:teamSlug" component={TeamScope} />
            <Route path="/" exact={true} component={GeneralScope} />
          </Switch>
        }
      </BrowserRouter>
      <MessagePopup />
    </>
  );
}

export default App;

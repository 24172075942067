import React from "react";
import { getCheckBoxZise } from "../Helper";


type GroupIconProps = {
  color?: string
  size?: string
}

export const GroupIcon: React.FC<GroupIconProps> = (props) => {
  const color = props.color ? props.color : "#4A4A4A"
  const size = getCheckBoxZise(props.size ? props.size : "small");

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="4.5" cy="4.5" r="4.5" fill={color}></circle>
      <circle cx="4.5" cy="19.5" r="4.5" fill={color}></circle>
      <circle cx="19.5" cy="4.5" r="4.5" fill={color}></circle>
      <circle cx="19.5" cy="4.5" r="4.5" fill={color}></circle>
      <circle cx="19.5" cy="19.5" r="4.5" fill={color}></circle>
    </svg>
  );
}
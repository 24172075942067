import React, { useState, useCallback, useMemo, createRef, useRef, useEffect, useLayoutEffect } from 'react';
import { escapeStringRegexp } from '../../Helper';
import { useIsMounted } from '../../hooks/UseIsMounted';
import { BaseInputOnChangeEvent, BaseInputOnBlurEvent, BaseInputOnAddEvent, BaseInputOnRemoveEvent, BaseInputOnSelectEvent } from '../event/BaseInputEvent';
import { Tag } from '../tag/Tag';

export class TagsInputItem {
  constructor(
    public text: string,
    public backgroundColor?: string,
    public textColor?: string) { }
}

type TagsInputProps = {
  label?: string
  inputValue?: number | string
  autoFocus?: boolean
  placeholder?: string
  error?: string | string[]
  disabled?: boolean
  charSeparators?: string
  enterSeparator?: boolean
  tags?: TagsInputItem[]
  selectedIndex?: number
  onInputValueChange?: (event: BaseInputOnChangeEvent) => void
  onAdd?: (event: BaseInputOnAddEvent) => void
  onRemove?: (event: BaseInputOnRemoveEvent) => void
  onSelect?: (event: BaseInputOnSelectEvent) => void
  onBlur?: (event: BaseInputOnBlurEvent) => void
  suffix?: React.ReactNode
}
export const TagsInput: React.FC<TagsInputProps> = (props) => {

  const enterSeparator = props.enterSeparator === undefined ? true : props.enterSeparator;

  const isMounted = useIsMounted();
  const [ selectedIndex, setSelectedIndex ] = useState(-1);
  const [ dirty, setDirty ] = useState(false);
  const [ touched, setTouched ] = useState(false);
  const [ pasteBuffer, setPasteBuffer ] = useState<string[]>([])
  const refIsPasting = useRef(false);

  const refTags = useMemo(
    () => (props.tags || []).map(() => createRef<HTMLDivElement>()), [props.tags]);

  const refInput = useRef<HTMLInputElement>(null);

  const errorClass = props.error ? 'b-c-r' : null;

  const onInputValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onInputValueChange) {
      if (refIsPasting.current && props.charSeparators) {
        const rx = new RegExp(`[${escapeStringRegexp(props.charSeparators)}]+`)
        setPasteBuffer(event.currentTarget.value.split(rx));
      } else {
        props.onInputValueChange(new BaseInputOnChangeEvent(event.currentTarget.value, true, touched));
      }
    }
  }, [props, touched])

  const onSelect = useCallback((index: number) => {
    setSelectedIndex(index);
    if (props.onSelect) {
      props.onSelect(new BaseInputOnSelectEvent(index, dirty, touched));
    }
  }, [dirty, props, touched])

  const onInputValueKeydown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    setDirty(true);
    setTouched(true);

    // if is pasting
    if (event.key.toLowerCase() === "v" && event.metaKey) {
      refIsPasting.current = true;

    // If the key is a separator
    } else if (
      (enterSeparator && event.key === "Enter") ||
      (props.charSeparators && props.charSeparators.indexOf(event.key) > -1)
    ) {
      event.preventDefault();
      event.stopPropagation();
      if (event.currentTarget.value.length > 0 && props.onAdd) {
        const value = event.key === " " ?
          event.currentTarget.value.trimRight() : event.currentTarget.value
        props.onAdd(new BaseInputOnAddEvent(value, true, true));
      }

    // If the key is backspace or arrow left and the field is empty and the tags are
    // not empty, then call onSelect
    } else if (
      (event.key === "Backspace" || event.key === "ArrowLeft") &&
      event.currentTarget.selectionEnd === 0 &&
      props.tags && props.tags.length > 0
    ) {
      event.preventDefault();
      event.stopPropagation();
      onSelect(props.tags.length - 1);
    }
  }, [enterSeparator, onSelect, props]);

  const onBlur = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0 && props.onAdd) {
      props.onAdd(new BaseInputOnAddEvent(event.currentTarget.value, dirty, touched));
    }
    if (props.onBlur) {
      props.onBlur(new BaseInputOnBlurEvent(event.currentTarget.value, dirty, touched));
    }
  }, [props, dirty, touched]);

  const removeTag = useCallback((selectedIndex: number) => {
    if (props.onRemove) {
      props.onRemove(new BaseInputOnRemoveEvent(selectedIndex, dirty, true));
    }
    setSelectedIndex(selectedIndex - 1);
    if (selectedIndex === 0) {
      setTimeout(() => refInput.current?.focus());
    }
  },[dirty, props.onRemove])

  const onTagKeydown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    setTouched(true);
    switch (event.key) {
      case "Backspace":
        removeTag(selectedIndex);
        break;
      case "ArrowLeft":
        setSelectedIndex(selectedIndex - 1);
        break;
      case "ArrowRight":
        if (selectedIndex === (props.tags as []).length - 1) {
          setSelectedIndex(-1);
        } else {
          setSelectedIndex(selectedIndex + 1);
        }
    }
  }, [props, selectedIndex, dirty]);

  const renderError = (error: string, index: number) => {
    return <div key={index} className="t-c-r f-s-1 m-t-0.5">{ error }</div>
  }

  const renderErrors = (error?: string | string[]) => {
    if (error) {
      if (typeof error === "string") {
        return renderError(error, 0);
      } else {
        return (error as string[]).map((e, i) => renderError(e, i));
      }
    } else {
      return null;
    }
  }

  useLayoutEffect(() => {
    if (isMounted()) {
      if (selectedIndex > -1) {
        refTags[selectedIndex].current?.focus();
      }
    }
  }, [refTags, selectedIndex, isMounted])

  useEffect(() => {
    if (pasteBuffer.length > 0 && props.onInputValueChange && refInput.current && props.onAdd) {
      const _pasteBuffer = [...pasteBuffer];
      const entry = _pasteBuffer.splice(0, 1)[0];

      props.onInputValueChange(new BaseInputOnChangeEvent(entry, true, touched));
      props.onAdd(new BaseInputOnAddEvent(entry, true, true));

      setPasteBuffer(_pasteBuffer);
    }
  }, [pasteBuffer, props, touched])

  return (
    <div className={`d-f fb-d-c ${props.error ? "input-error" : ""}`}>
      {props.label ?
        <div  className="f-s-1 f-s-1.25-M">{ props.label }</div>
        : null
      }
      <div className={`d-f a-i-s b-s-s b-w-1px min-h-3.5 p-0.5 m-t-0.5 ${props.disabled ? "t-c-lightGray b-c-lightestGray bg-c-nw" : "b-c-gray"}`}>
        <div className="d-f fb-a fb-w-w max-h-12 max-h-16-M of-a">
          {props.tags?.map((t, i) =>
            <div
              tabIndex={0}
              ref={refTags[i]}
              onClick={() => onSelect(i)}
              onKeyDown={onTagKeydown}
              key={i}
              className="fb-n bg-c-g m-0.5">
              <Tag
                disabled={props.disabled}
                text={t.text}
                textColor={t.textColor}
                onClickRemove={() => removeTag(i)}
                backgroundColor={t.backgroundColor} />
            </div>
          )}
          <input
            ref={refInput}
            autoFocus={props.autoFocus}
            value={props.inputValue || ''}
            placeholder={props.placeholder}
            onFocus={() => onSelect(-1)}
            onKeyDown={onInputValueKeydown}
            onChange={onInputValueChange}
            onBlur={onBlur}
            disabled={props.disabled}
            style={{width: 140}}
            className={`fb-a m-0.5 f-s-1 h-1.5 b-w-0px ${errorClass}`} />
        </div>
        <div className="d-f fb-n m-v-0.5">{props.suffix}</div>
      </div>
      { renderErrors(props.error) }
    </div>
  );
}

import React from 'react';
import { ProfileMenu } from './ProfileMenu';
import logo from '../logo.png';
import { useLocation } from 'react-router-dom';

export const GeneralHeader: React.FC = () => {

  const location = useLocation();

  return (
    <>
      <div className="z-9 pos-l-0 d-f pos-f pos-t-0 pos-r-0 h-4 b-sb-s b-c-lightestGray bg-c-w b-w-1px a-i-c">
        <div className="fb-a d-f a-i-c p-l-1.5 h-100%">
          <a className="d-f" href="/">
            <img className="h-2" src={logo} alt="Statsignal" />
          </a>
        </div>
        <div className="fb-n d-f a-i-c m-r-0.5 fb-as-c">
          {!location.pathname.startsWith("/checkout/") ?
            <ProfileMenu />
          : null
          }
        </div>
      </div>
    </>
  );
}


import React, { useState } from 'react';
import { Popover } from '../popover/Popover';
import { Help } from '../../images';

type HelpTipProps = {
  children: React.ReactNode
  disabled?: boolean
}

export const HelpTip: React.FC<HelpTipProps> = (props) => {

  const [active, setActive] = useState(false);

  const activator = (
    <div
      className={`${props.disabled ? "t-c-lightGray" : "c-p t-c-g"}`}
      onClick={() => setActive(a => !a)}>
      <Help />
    </div>
  )

  return (
    <div className="w-1.25 h-1.25">
      <Popover
        active={active}
        activator={activator}
        placement="top-end"
        onClose={() => setActive(false)}>
        <div className="p-0.25">
          <div className="bg-c-w s-drop b-r-0.5 p-h-1.5 p-v-1 b-s-s b-w-1px b-c-g">
            {props.children}
          </div>
        </div>
      </Popover>
    </div>
  );
}

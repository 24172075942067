import React, { useState, useCallback, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import { Alert, Group, Monitor, MonitorHttpHeader, MonitorHttpRequest, MonitorHttpResponse, MonitorTcpOptions, ValidateMonitorUrnReq } from '../model';
import { Intervals } from './Intervals';
import { Regions } from './Regions';
import { TextInput,
  BaseInputOnChangeEvent,
  ComboBox,
  ComboBoxItem,
  ComboBoxItemType,
  RadioButton,
  Modal,
  BaseInputOnSelectEvent, 
  Button,
  Checkbox,
  Spinner,
  HelpTip, 
  BaseInputOnBlurEvent,
  BaseInputOnAddEvent,
  BaseInputOnRemoveEvent} from '../ui';
import { useValidator, Validations } from '../validator';
import { isFieldStringEmpty } from '../Validations';
import { GroupNew } from '../group-new';
import axios, { CancelTokenSource } from 'axios';
import { apiBaseUrl, cancelTokenMessage } from '../Consts';
import { useDebounce } from '../hooks/UseDebounce';
import { usePrevious } from '../hooks/UsePrevious';
import { Errors } from '../validator/Validator';
import { ChevronDown, X } from '../images';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { messageState, monitorTypesState, productsState, teamState } from '../atoms';
import { useHandleRequestError } from '../hooks';
import { productsNonFreeEnabledState } from '../atoms/productsNonFreeEnabledState';
import { extractNestedIndexedServerErrors, extractNestedServerErrors, isMonitorIntervalFree, scrollToInputError } from '../Helper';
import { AlertNew } from '../alert-new';
import { HttpRequest } from './HttpRequest';
import { HttpResponse } from './HttpResponse';
import { ChevronRight } from '../images/ChevronRight';
import { TcpOptions } from './TcpOptions';

const v = new Validations(new Monitor());
v.addField("name", (v: any) => isFieldStringEmpty(v));
v.addField("regions", (v: any) => !v || v.length === 0 ? "Regions can't be empty" : undefined);
v.addField("timeout", (v: any) => !v || v < 0.1 || v > 60 ? "Timeout must be between 0.1 to 60 seconds" : undefined);

const vHttpRequest = new Validations(new MonitorHttpRequest());
vHttpRequest.addField("body", (v: any) => v && v !== "" && isFieldStringEmpty(v) ? "Can't be blank" : undefined);

const vHttpResponse = new Validations(new MonitorHttpResponse());
vHttpResponse.addField("keywordHeader", (v: any) => v && v !== "" && isFieldStringEmpty(v) ? "Can't be blank" : undefined);
vHttpResponse.addField("keywordBody", (v: any) => v && v !== "" && isFieldStringEmpty(v) ? "Can't be blank" : undefined);

const vTcpOptions = new Validations(new MonitorTcpOptions());
vTcpOptions.addField("keywordResponse", (v: any) => v && v !== "" && isFieldStringEmpty(v) ? "Can't be blank" : undefined);
vTcpOptions.addField("dataRequest", (v: any) => v && v !== "" && isFieldStringEmpty(v) ? "Can't be blank" : undefined);

function isResponseStatusValid(value: string): string | undefined {
  let valid = true;
  const valuesArray = value.split("-");

  valid = valuesArray.length >= 1 && valuesArray.length <= 2;
  
  if (valid) {
    valid = !valuesArray.some(v =>
      !v.match(/^[1-9][0-9]+$/) ||
      parseInt(v) < 100 || parseInt(v) > 599
    )
  }

  if (valid && valuesArray.length === 2) {
    valid = valuesArray[0] < valuesArray[1];
  }

  return !valid ? "The status is not valid. This must be a valid HTTP status number (e.g. 200) or a range (e.g. 200-299)" : undefined;
}

type MonitorFormProps = {
  monitor: Monitor
  onSave: (monitor: Monitor) => void
  onCancel: () => void
  disallowSetGroups?: boolean
  disallowSetAlerts?: boolean
  noRedirectToCheckout?: boolean
  errorsOnServer: Errors<Monitor> | undefined
  disallowSetMonitors?: boolean
  isSaving?: boolean
}

const groupNewItem = new ComboBoxItem("New group ...", ComboBoxItemType.Add);
const alertNewItem = new ComboBoxItem("New alert ...", ComboBoxItemType.Add);
const pageSizeGroupItems = 10;
const pageSizeAlertItems = 10;

export const MonitorForm: React.FC<MonitorFormProps> = (props) => {

  const { t } = useTranslation();
  const monitorTypes = useRecoilValue(monitorTypesState);
  const team = useRecoilValue(teamState);
  const allProducts = useRecoilValue(productsState);
  const products = useMemo(() =>
    allProducts?.filter(p => p.productType === "monitor"), [allProducts])
  const productsNonFreeEnabled = useRecoilValue(productsNonFreeEnabledState);
  const { handleRequestError } = useHandleRequestError();

                  
  const setMessage = useSetRecoilState(messageState);
  const [ isAddingGroup, setIsAddingGroup ] = useState(false);
  const [ isAddingAlert, setIsAddingAlert ] = useState(false);
  const [ groupItems, setGroupItems ] = useState<ComboBoxItem[]>();
  const [ alertItems, setAlertItems ] = useState<ComboBoxItem[]>();
  const [ groupInputValue, setGroupInputValue ] = useState<string>();
  const [ alertInputValue, setAlertInputValue ] = useState<string>();
  const [ monitor, setMonitor ] = useState(props.monitor);
  const [ hasDegradedThreshold, setHasDegradedThreshold ] = useState(props.monitor.degradedThreshold ? true : false);
  const [ hasApdexThreshold, setHasApdexThreshold ] = useState(props.monitor.apdexThreshold ? true : false);
  const { errors, validateField, validateAll, resetErrorField, resetErrorAllFromServer } = useValidator(v);

  const { errors: httpResponseErrors,
    validateField: httpResponseValidateField,
    validateAll: httpResponseValidateAll,
    resetErrorAllFromServer: httpResponseResetErrorAllFromServer } = useValidator(vHttpResponse);

  const { errors: tcpOptionsErrors,
    validateField: tcpOptionsValidateField,
    validateAll: tcpOptionsValidateAll,
    resetErrorAllFromServer: tcpoptionsResetErrorAllFromServer } = useValidator(vTcpOptions);

  const { errors: httpRequestErrors,
    validateField: httpRequestValidateField,
    validateAll: httpRequestValidateAll,
    resetErrorAllFromServer: httpRequestResetErrorAllFromServer } = useValidator(vHttpRequest);

  const [ alertItemsInitialized, setAlertItemsInitialized ] = useState(false);

  const debouncedGroupInputValue = useDebounce(groupInputValue, 500);
  const cancelToken = useRef<CancelTokenSource>();
  const previousDebouncedGroupInputValue = usePrevious(debouncedGroupInputValue);

  const [ groupDefaultFocusedIndex, setGroupDefaultFocusedIndex ] = useState<number>(-1);
  const [ alertDefaultFocusedIndex, setAlertDefaultFocusedIndex ] = useState<number>(-1);

  const [ isLoadingGroupItems, setIsLoadingGroupItems ] = useState(false);
  const [ isLoadingAlertItems, setIsLoadingAlertItems ] = useState(false);
  const [ groupItemsCount, setGroupItemsCount ] = useState(0);
  const [ alertItemsCount, setAlertItemsCount ] = useState(0);

  const [ isMonitorFree, setIsMonitorFree ] = useState<boolean>(false);
  const [ upgradingFreeMonitorIntervalId, setUpgradingFreeMonitorIntervalId ] = useState<number>();

  const [ isInitialMonitorFreeMonitor, setIsInitialMonitorFreeMonitor ] = useState<boolean>();

  const [ urlTerm, setUrlTerm ] = useState<string>();
  const debouncedUrlTerm = useDebounce(urlTerm, 500);

  const scrollGroupItemsToBottom = useRef(false);
  const scrollAlertItemsToBottom = useRef(false);
  const refGroupItems = useRef<HTMLDivElement>(null);
  const refAlertItems = useRef<HTMLDivElement>(null);

  const [ httpRequestHeaderErrors, setHttpRequestHeaderErrors ] = useState<{ [name: string]: string | undefined }[]>(() =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  monitor.httpRequest?.headers?.map(r => ({}) ) ?? []);

  const [ httpResponseStatusValue, setHttpResponseStatusValue ] = useState("");
  const [ httpResponseStatusError, setHttpResponseStatusError ] = useState<string>();

  const [ enableHttpDefaultMethod, setEnableHttpDefaultMethod ] = useState(false);

  const fetchGroupItems = useCallback((text: string, isLoadingNextPage: boolean = false) => {
    if (team) {
      setIsLoadingGroupItems(true);
      if (cancelToken.current !== undefined) {
        cancelToken.current.cancel(cancelTokenMessage);
      }

      cancelToken.current = axios.CancelToken.source();

      axios.get(`${apiBaseUrl}/${team.uuid}/groups`, {
          params: {
            short: true,
            text: text,
            pageSize: pageSizeGroupItems,
            // length of groupItems without the new item entry (groupItems.length - 1)
            recordOffset: isLoadingNextPage && groupItems ? groupItems.length - 1 : 0
          },
          cancelToken: cancelToken.current.token
        })
        .then(response => {
          let items = response.data.groups.map((g: Group) => {
            const alreadyAdded = monitor.groups?.some(s => s.uuid === g.uuid);
            return new ComboBoxItem(
              g.name,
              alreadyAdded ? ComboBoxItemType.Selected : ComboBoxItemType.Normal,
              g,
              alreadyAdded);
          });

          if (isLoadingNextPage && groupItems) {
            items = groupItems.concat(items);
          } else {
            items = [groupNewItem, ...items];
          }
          setGroupItemsCount(response.data.paginator.recordsCount);
          setGroupItems(items);
        })
        .catch(error => handleRequestError(error))
        .finally(() => setIsLoadingGroupItems(false));
    }
  }, [groupItems, monitor.groups, team, handleRequestError]);

  const fetchAlertItems = useCallback((text?: string, isLoadingNextPage: boolean = false) => {
    if (team) {
      setIsLoadingAlertItems(true);
      if (cancelToken.current !== undefined) {
        cancelToken.current.cancel(cancelTokenMessage);
      }

      cancelToken.current = axios.CancelToken.source();

      axios.get(`${apiBaseUrl}/${team.uuid}/alerts`, {
          params: {
            short: true,
            text: text,
            pageSize: pageSizeAlertItems,
            // length of alertItems without the new item entry (alertItems.length - 1)
            recordOffset: isLoadingNextPage && alertItems ? alertItems.length - 1 : 0
          },
          cancelToken: cancelToken.current.token
        })
        .then(response => {
          let items = response.data.alerts.map((a: Alert) => {
            const alreadyAdded = monitor.alerts?.some(s => s.uuid === a.uuid);
            return new ComboBoxItem(
              a.name,
              alreadyAdded ? ComboBoxItemType.Selected : ComboBoxItemType.Normal,
              a,
              alreadyAdded);
          });

          if (isLoadingNextPage && alertItems) {
            items = alertItems.concat(items);
          } else {
            items = [alertNewItem, ...items];
          }
          setAlertItemsCount(response.data.paginator.recordsCount);
          setAlertItems(items);
        })
        .catch(error => handleRequestError(error))
        .finally(() => setIsLoadingAlertItems(false));
    }
  }, [alertItems, monitor.alerts, team, handleRequestError]);

  const save = useCallback(() => {
    if (errors.fields.urn) {
      scrollToInputError();
    } else if (validateAll(monitor) &&
      (!monitor.httpRequest || (httpRequestValidateAll(monitor.httpRequest) && validateHttpRequestHeaders(monitor.httpRequest.headers))) &&
      (!monitor.httpResponse || httpResponseValidateAll(monitor.httpResponse))
    ) {
      let _monitor = monitor;
      if (_monitor.httpRequest && _monitor.httpRequest.headers) {
        _monitor = {
          ..._monitor,
          httpRequest: {
            ...monitor.httpRequest as MonitorHttpRequest,
            headers: (monitor.httpRequest as MonitorHttpRequest).headers.filter(h =>
              (h.name && h.name.trim().length > 0) || (h.value && h.value.trim().length > 0)
            )
          }
        }
      }
      props.onSave(_monitor);
    }
  }, [errors.fields.urn, monitor, products, props, validateAll, httpRequestValidateAll, httpResponseValidateAll]);

  const cancel = useCallback(() => {
    props.onCancel();
  }, [props]);

  const removeGroup = useCallback((group: Group) => {
    if (monitor) {
      const _selectedGroups = [...monitor.groups];
      _selectedGroups.splice(_selectedGroups.indexOf(group), 1);
      setMonitor(monitor => {
        return {...monitor, groups: _selectedGroups};
      });
    }
  }, [monitor]);

  const removeAlert = useCallback((alert: Alert) => {
    if (monitor) {
      const _selectedAlerts = [...monitor.alerts];
      _selectedAlerts.splice(_selectedAlerts.indexOf(alert), 1);
      setMonitor(monitor => {
        return {...monitor, alerts: _selectedAlerts};
      });
    }
  }, [monitor]);

  const validateHttpRequestHeaders = useCallback((headers: MonitorHttpHeader[]): boolean => {
    let valid = true;
    if (headers) {
      const _httpRequestHeaderErrors = [...httpRequestHeaderErrors];

      for (let i = 0; i < headers.length; i++) {
        const h1 = headers[i];

        let error: string | undefined;
        _httpRequestHeaderErrors[i] = {};
        if (h1.name?.length > 0 && h1.name?.trim().length === 0) {
          error = "Name can't be blank";
        } else if (h1.value?.trim().length > 0 && (!h1.name)) {
          error = "Name is required";
        }
        if (error) {
          _httpRequestHeaderErrors[i].name = t(error);
          valid = false;
        } else {
          for (let j = 0; j < headers.length; j++) {
            const h2 = headers[j];
            if (i !== j && h1.name === h2.name) {
              _httpRequestHeaderErrors[i].name = t("The name is duplicated");
              valid = false;
              break;
            }
          }
        }
      }

      setHttpRequestHeaderErrors(_httpRequestHeaderErrors);
    }
    return valid;
  }, [monitor.httpRequest?.headers, httpRequestHeaderErrors, t])

  const onChangeField = useCallback(
    (fieldName: keyof Monitor, event: BaseInputOnChangeEvent) => {
      let value = event.value;
      const _monitor = { ...monitor } as Monitor;

      switch (fieldName) {
        case "urn":
          let monitorType = monitor.type;
          value = (value as string).trim();
          const regexpUrl = value.match(/^(http|https|ftp|tcp):\/\/(.+)/);
          if (regexpUrl) {
            if ( ["http", "https", "tcp", "icmp"].includes(regexpUrl[1]) ) {
              monitorType = regexpUrl[1];
              value = regexpUrl[2];
              _monitor["type"] = monitorType as never;
            }
          }
          setUrlTerm(`${monitorType}://${value || ""}`);
          break;
        case "type":
          setUrlTerm(`${value}://${monitor.urn || ""}`);
          break;
      }

      if (event.dirtyAndTouched) {
        switch (fieldName) {
          case "urn":
          case "type":
            break;
          default:
            validateField(fieldName, event.value);
        }
      }
      _monitor[fieldName] = value as never;

      setMonitor(_monitor);
  }, [monitor, validateField]);

  const onBlurField = useCallback(
    (fieldName: keyof Monitor, event: BaseInputOnChangeEvent) => {
    if (event.dirty) {
      switch (fieldName) {
        case "urn":
        case "type":
          break;
        default:
          validateField(fieldName, event.value);
      }
    }
  }, [monitor.type, monitor.urn, validateField]);

  const onChangeHttpRequestField = useCallback(
    (fieldName: keyof MonitorHttpRequest, event: BaseInputOnChangeEvent) => {

    let value: never | undefined = event.value as never;
    if (fieldName === "method" && value === "") {
      value = undefined;
    }

    if (event.dirtyAndTouched) {
      switch (fieldName) {
        case "method":
          if (value == "") {
            value = undefined;
          }
          break;
        default:
          httpRequestValidateField(fieldName, event.value);
      }
    }

    setMonitor({...monitor, httpRequest: {
      ...monitor.httpRequest,
      [fieldName]: value
    } as MonitorHttpRequest} as Monitor);

  }, [monitor]);

  const onBlurHttpRequestField = useCallback(
    (fieldName: keyof MonitorHttpRequest, event: BaseInputOnChangeEvent) => {

    if (event.dirty) {
      switch (fieldName) {
        case "method":
          break;
        default:
          httpRequestValidateField(fieldName, event.value);
      }
    }
  }, [monitor]);

  const onChangeTcpOptionsField = useCallback(
    (fieldName: keyof MonitorTcpOptions, event: BaseInputOnChangeEvent) => {

      let value: never | undefined = event.value as never;
      if (fieldName === "keywordResponse" && value === "") {
        value = undefined;
      } else if (fieldName === "dataRequest" && value === "") {
        value = undefined;
      }

      setMonitor({...monitor, tcpOptions: {
        ...monitor.tcpOptions,
        [fieldName]: event.value as never
      } as MonitorTcpOptions} as Monitor)
    }, [props.monitor, monitor])

  const onBlurTcpOptionsField = useCallback(
    (fieldName: keyof MonitorTcpOptions, event: BaseInputOnChangeEvent) => {

    if (event.dirty) {
      switch (fieldName) {
        case "keywordResponse":
          break;
        default:
          tcpOptionsValidateField(fieldName, event.value);
      }
    }
  }, [monitor]);

  const onChangeHttpResponseField = useCallback(
    (fieldName: keyof MonitorHttpResponse, event: BaseInputOnChangeEvent) => {
  
      if (event.dirtyAndTouched) {
        switch (fieldName) {
          case "status":
            break;
          default:
            httpResponseValidateField(fieldName, event.value);
        }
      }

      setMonitor({...monitor, httpResponse: {
        ...monitor.httpResponse,
        [fieldName]: event.value as never
      } as MonitorHttpResponse} as Monitor)
    }, [props.monitor, monitor])

  const onBlurHttpResponseField = useCallback(
    (fieldName: keyof MonitorHttpResponse, event: BaseInputOnChangeEvent) => {

    if (event.dirty) {
      switch (fieldName) {
        case "status":
          break;
        default:
          httpResponseValidateField(fieldName, event.value);
      }
    }
  }, [monitor]);

  const onChangeHasDegradedThreshold = useCallback((has: boolean) => {
    setHasDegradedThreshold(has);
    onChangeField("degradedThreshold", new BaseInputOnChangeEvent(has ? 2 : 0, false, false));
    resetErrorField("degradedThreshold");
  }, [onChangeField, resetErrorField]);

  const onChangeHasApdexThreshold = useCallback((has: boolean) => {
    setHasApdexThreshold(has);
    onChangeField("apdexThreshold", new BaseInputOnChangeEvent(has ? 0.75 : 0, false, false));
    resetErrorField("apdexThreshold");
  }, [onChangeField, resetErrorField]);

  const onChangeHttpResponseStatusValue = useCallback((event: BaseInputOnChangeEvent) => {
    setHttpResponseStatusError(undefined);
    setHttpResponseStatusValue(event.value as string);
  }, []);

  const onAddHttpResponseStatus = useCallback((event: BaseInputOnAddEvent) => {
    const error = isResponseStatusValid(event.value as string);
    if (error) {
      setHttpResponseStatusError(error);
    } else {
      setHttpResponseStatusError(undefined);
      setMonitor({
        ...monitor,
        httpResponse: {
          ...monitor.httpResponse as MonitorHttpResponse,
          status: [...(monitor.httpResponse?.status || []), event.value as string]
        }
      });
      setHttpResponseStatusValue("");
    }
  }, [monitor]);

  const onRemoveHttpResponseStatus = useCallback((event: BaseInputOnRemoveEvent) => {
    setMonitor({
      ...monitor,
      httpResponse: {
        ...monitor.httpResponse  as MonitorHttpResponse,
        status: (monitor.httpResponse as MonitorHttpResponse).status.filter((_, i) => i !== event.index)
      }
    });
    setHttpResponseStatusValue("");
  }, [monitor]);

  const onChangeHttpRequestHeaderField = useCallback(
    (index: number, fieldName: keyof MonitorHttpHeader, event: BaseInputOnChangeEvent) => {

    const _monitor = {
      ...monitor, httpRequest: {
        ...monitor.httpRequest as MonitorHttpRequest,
        headers: (monitor.httpRequest as MonitorHttpRequest).headers.map((h, i) => i === index ?
          {...h, [fieldName]: event.value} : h
        )
      }
    };

    setMonitor(_monitor);

    setHttpRequestHeaderErrors(
      [...httpRequestHeaderErrors.map((errors, i) => i === index ? {} : errors)]);

    if (event.dirtyAndTouched) {
      validateHttpRequestHeaders(_monitor.httpRequest.headers);
    }
  }, [monitor, httpRequestHeaderErrors]);
  
  const onBlurHttpRequestHeaderField = useCallback(
    (index: number, fieldName: keyof MonitorHttpHeader, event: BaseInputOnBlurEvent) => {
    if (event.dirtyAndTouched && monitor.httpRequest) {
      validateHttpRequestHeaders(monitor.httpRequest.headers);
    }
  }, [monitor, httpRequestHeaderErrors]);

  const onAddHttpRequestHeader = useCallback(() => {
    setMonitor({
      ...monitor, httpRequest: {
        ...monitor.httpRequest as MonitorHttpRequest,
        headers: [...(monitor.httpRequest as MonitorHttpRequest).headers ?? [], {} as MonitorHttpHeader]
      }
    })
    setHttpRequestHeaderErrors([...httpRequestHeaderErrors, {}])
  }, [monitor, httpRequestHeaderErrors]);

  const onRemoveHttpRequestHeader = useCallback((index: number) => {
    setMonitor({
      ...monitor, httpRequest: {
        ...monitor.httpRequest as MonitorHttpRequest,
        headers: (monitor.httpRequest as MonitorHttpRequest).headers.filter((_, i) => i !== index )
      }
    })
    setHttpRequestHeaderErrors([...httpRequestHeaderErrors.filter((_, i) => i !== index )]);
  }, [monitor, httpRequestHeaderErrors]);

  const onNewGroup = useCallback((newGroup: Group) => {
    setMonitor(monitor => {
      return {...monitor, groups: [...(monitor.groups || []), newGroup]};
    });
    setIsAddingGroup(false);
    scrollGroupItemsToBottom.current = true;
  }, []);

  const onNewAlert = useCallback((newAlert: Alert) => {
    setMonitor(monitor => {
      return {...monitor, alerts: [...(monitor.alerts || []), newAlert]};
    });
    setIsAddingAlert(false);
    scrollAlertItemsToBottom.current = true;
  }, []);

  const onChangeInterval = useCallback((intervalId: number) => {
    if (products) {
      if (monitor.uuid) {
        const currentProduct = products.find(p => p.monitorInterval.id === monitor.intervalId);
        const newProduct = products.find(p => p.monitorInterval.id === intervalId);

        if (currentProduct && currentProduct.prices?.length > 0 &&
            !isInitialMonitorFreeMonitor &&
            newProduct && (!newProduct.prices || newProduct.prices.length === 0)) {
          setMessage({
            type: "error",
            text: t("Downgrading to a free monitor is not allowed")
          });
          return;
        } else if (newProduct && newProduct.prices?.length > 0 &&
          currentProduct && (!currentProduct.prices || currentProduct.prices.length === 0)) {
          setUpgradingFreeMonitorIntervalId(intervalId);
          return;
        }
      }

      setMonitor({...monitor, intervalId: intervalId});
    }
  }, [isInitialMonitorFreeMonitor, monitor, products, setMessage, t]);

  const onChangeGroupInputValue = useCallback((e: BaseInputOnChangeEvent) => {
    setGroupInputValue(e.value as string);
    setGroupDefaultFocusedIndex(0);
  }, []);

  const onChangeAlertInputValue = useCallback((e: BaseInputOnChangeEvent) => {
    setAlertInputValue(e.value as string);
    setAlertDefaultFocusedIndex(0);
  }, []);

  const onSelectGroup = useCallback((event: BaseInputOnSelectEvent) => {
    if (event.index === 0) {
      setIsAddingGroup(true);
    } else if (groupItems) {
      const selectedGroup = groupItems[event.index].data as Group;
      setMonitor(monitor => {
        return {...monitor, groups: [...monitor.groups || [], selectedGroup]};
      });
      groupItems[event.index].type = ComboBoxItemType.Selected;
      groupItems[event.index].disabled = true;
      setGroupItems([...groupItems]);
      scrollGroupItemsToBottom.current = true;
    }
    setGroupInputValue(undefined);
    setGroupDefaultFocusedIndex(-1);
  }, [groupItems]);

  const onBlurGroups = useCallback(() => {
    setGroupDefaultFocusedIndex(-1);
  }, []);

  const onSelectAlert = useCallback((event: BaseInputOnSelectEvent) => {
    if (event.index === 0) {
      setIsAddingAlert(true);
    } else if (alertItems) {
      const selectedAlert = alertItems[event.index].data as Alert;
      setMonitor(monitor => {
        return {...monitor, alerts: [...monitor.alerts || [], selectedAlert]};
      });
      alertItems[event.index].type = ComboBoxItemType.Selected;
      alertItems[event.index].disabled = true;
      setAlertItems([...alertItems]);
      scrollAlertItemsToBottom.current = true;
    }
    setAlertInputValue(undefined);
    setAlertDefaultFocusedIndex(-1);
  }, [alertItems]);

  const onBlurAlerts = useCallback(() => {
    setAlertDefaultFocusedIndex(-1);
  }, []);

  const onFocusGroupItems = useCallback(() => {
    fetchGroupItems(groupInputValue as string);
  }, [fetchGroupItems, groupInputValue]);

  const onFocusAlertItems = useCallback(() => {
    fetchAlertItems(alertInputValue as string);
  }, [fetchAlertItems, alertInputValue]);

  const onScrollGroupItems = useCallback((percent: number) => {
    // itemsCount is the total of records that could be fetched, if this value is not greater
    // than the current groupsItems fetched, that means the total of records already has been
    // fetched
    if (percent >= 90 && !isLoadingGroupItems && (!groupItems || groupItemsCount > groupItems.length)) {
      scrollGroupItemsToBottom.current = false
      fetchGroupItems(groupInputValue as string, true);
    }
  }, [fetchGroupItems, isLoadingGroupItems, groupInputValue, groupItems, groupItemsCount]);

  const onScrollAlertItems = useCallback((percent: number) => {
    // itemsCount is the total of records that could be fetched, if this value is not greater
    // than the current groupsItems fetched, that means the total of records already has been
    // fetched
    if (percent >= 90 && !isLoadingAlertItems && (!alertItems || alertItemsCount > alertItems.length)) {
      scrollAlertItemsToBottom.current = false
      fetchAlertItems(alertInputValue as string, true);
    }
  }, [fetchAlertItems, isLoadingAlertItems, alertInputValue, alertItems, alertItemsCount]);

  useLayoutEffect(() => {
    if (refGroupItems.current && scrollGroupItemsToBottom.current) {
      refGroupItems.current.scrollTop = refGroupItems.current?.scrollHeight;
      scrollGroupItemsToBottom.current = false;
    }
  }, [monitor.groups]);

  useEffect(() => {
    if (previousDebouncedGroupInputValue !== debouncedGroupInputValue) {
      fetchGroupItems(debouncedGroupInputValue as string);
    }
  }, [debouncedGroupInputValue, fetchGroupItems, previousDebouncedGroupInputValue]);

  useEffect(() => {
    if (props.errorsOnServer) {
      resetErrorAllFromServer(props.errorsOnServer);

      httpRequestResetErrorAllFromServer(
        extractNestedServerErrors("httpRequest", props.errorsOnServer));

      httpResponseResetErrorAllFromServer(
        extractNestedServerErrors("httpResponse", props.errorsOnServer));

      setHttpRequestHeaderErrors(headerErrors => {
        const indexedErrors = extractNestedIndexedServerErrors("httpRequest.headers", props.errorsOnServer as {[field: string]: any});
        return headerErrors.map((errors, i) =>
          ({...indexedErrors[i] ?? errors})
        )
      });
    }
  }, [props.errorsOnServer, resetErrorAllFromServer])

  useEffect(
    () => {
      if (team && debouncedUrlTerm) {
        const req = new ValidateMonitorUrnReq();
        const [type, urn] = debouncedUrlTerm.split("://")
        req.type = type;
        req.urn = urn;
        if (req.urn) {
          axios.get(`${apiBaseUrl}/${team.uuid}/monitors/${monitor.uuid ? `${monitor.uuid}/` : ""}validate-urn`, { params: req })
            .then(() => {
              resetErrorField("urn");
              resetErrorField("type");
            })
            .catch(err => {
              if (err.response && err.response.data && (err.response.data.urn || err.response.data.type)) {
                if (err.response.data.urn) {
                  resetErrorField("urn", err.response.data.urn)
                }
                if (err.response.data.type) {
                  resetErrorField("type", err.response.data.type)
                }
              } else {
                handleRequestError(err)
              }
            })
        }
      }
    },
    [debouncedUrlTerm, handleRequestError, monitor.uuid, resetErrorField, team] // Only call effect if debounced search term changes
  );

  useEffect(() => {
    if (monitor?.intervalId && products) {
      if (isMonitorIntervalFree(products, monitor.intervalId)) {
        setIsMonitorFree(true);
        if (hasApdexThreshold || hasDegradedThreshold) {
          onChangeHasApdexThreshold(false);
          onChangeHasDegradedThreshold(false);
        }
      } else {
        setIsMonitorFree(false);
      }
    }
  }, [hasApdexThreshold, hasDegradedThreshold, monitor.intervalId, onChangeHasApdexThreshold, onChangeHasDegradedThreshold, products])

  useEffect(() => {
    if (isInitialMonitorFreeMonitor === undefined) {
      const product = products?.find(p => p.monitorInterval.id === monitor.intervalId);
      setIsInitialMonitorFreeMonitor(product && (!product.prices || product.prices.length === 0));
    }
  }, [products, monitor, isInitialMonitorFreeMonitor]);

  useEffect(() => {
    if (team && !monitor.uuid && !alertItemsInitialized) {
      fetchAlertItems();
    }
  }, [team, alertItemsInitialized, fetchAlertItems]);

  useEffect(() => {
    if (!monitor.uuid && alertItems && !alertItemsInitialized) {
      setAlertItemsInitialized(true);
      if (alertItems.length === 2) { // The index 0 is for new Alert Item
        onSelectAlert(new BaseInputOnSelectEvent(1, false, false));
      }
    }
  }, [alertItems, alertItemsInitialized, onSelectAlert])

  useEffect(() => {
    const enable = (!monitor.httpRequest || (
      (!monitor.httpRequest.headers  || monitor.httpRequest.headers?.filter(h => h.name?.trim().length > 0 || h.value?.trim().length > 0).length == 0) &&
      (!monitor.httpRequest.body || monitor.httpRequest.body?.trim().length == 0)
    )) && (!monitor.httpResponse || (
      (!monitor.httpResponse.status || monitor.httpResponse.status?.length == 0) &&
      (!monitor.httpResponse.keywordHeader || monitor.httpResponse.keywordHeader?.trim().length == 0) &&
      (!monitor.httpResponse.keywordBody || monitor.httpResponse.keywordBody?.trim().length == 0)
    ))


    if (!enable && !monitor.httpRequest?.method) {
      setMonitor({
        ...monitor,
        httpRequest: {...monitor.httpRequest || {}, method: "GET"} as MonitorHttpRequest
      });
    }

    setEnableHttpDefaultMethod(enable);

  }, [monitor]);

  useEffect(() => {
    if (monitor.type === "tcp" && !monitor.tcpOptions) {
      setMonitor({
        ...monitor,
        tcpOptions: monitor.tcpOptions ?? {
          dataRequest: "",
          keywordResponse: "",
          keywordResponseExists: true} as MonitorTcpOptions,
      })
    }
  }, [monitor])

  return (
    <>
      <form onSubmit={(e) => { e.preventDefault(); save(); } } className="d-f fb-d-c">
        <div className="d-f fb-d-c">
          <TextInput
            autoFocus={true}
            label={t("Name")}
            maxLength={50}
            value={monitor.name}
            placeholder={t("Enter Name")}
            onChange={e => onChangeField("name", e)}
            onBlur={e => onBlurField("name", e)}
            error={errors.fields.name}
            type="text" />
        </div>

        <div className="d-f fb-d-c m-t-1 m-t-2-M">
          <TextInput
            label={t("Title (optional)")}
            maxLength={50}
            value={monitor.title}
            placeholder={t("Enter Title")}
            onChange={e => onChangeField("title", e)}
            onBlur={e => onBlurField("title", e)}
            error={errors.fields.title}
            helpTip={
              <div>
                When filled out, it appears as the title<br />
                on the Status Pages.
              </div>
            }
            type="text" />
        </div>

        <div className="d-f fb-d-c m-t-1 m-t-2-M">
          <div className="f-s-1 f-s-1.25-M">{t("URL")}</div>
          <div className="d-f fb-d-c a-i-s m-t-1 w-100% b-s-s-M b-w-1px-M b-c-lightestGray-M p-h-2-M p-v-1.5-M">
            <div className="d-f fb-w-w m-b-1">
              {monitorTypes?.map((mt, i) =>
                <div key={i} className="m-r-2 p-t-1 p-t-0-M">
                  <RadioButton
                    onChange={e => { onChangeField("type", new BaseInputOnChangeEvent(mt, e.dirty, true)) } }
                    checked={mt === monitor.type}
                    label={`${mt.toUpperCase()}${mt === "icmp" ? " (Ping)" : ""}`} />
                </div>
              )}
            </div>

            <TextInput
              value={monitor.urn}
              placeholder={t("Enter URN")}
              maxLength={2000}
              prefix={
                <div className="d-f c-p p-h-1 bg-c-lightestGray b-w-0px h-100% d-f a-i-c">
                  {monitor.type}://
                </div>
              }
              onChange={e => onChangeField("urn", e)}
              onBlur={e => onBlurField("urn", e)}
              error={errors.fields.urn}
              type="text" />
            <div className="d-f w-100% fb-a fb-d-c fb-d-r-M a-i-s a-i-c-M m-t-0.25">
              <div className="d-f fb-n m-t-1">
                {monitor.type === "http" || monitor.type === "https" ?
                  <div className="fb-n">
                    <Checkbox
                      checked={monitor.httpFollowRedirects}
                      label={t("Follow Redirects")}
                      onChange={e => onChangeField("httpFollowRedirects", e)} />
                  </div>
                : null}
              </div>
            </div>
          </div>
        </div>
        <div className="d-f fb-d-c m-t-1 m-t-2-M">
          <div className="f-s-1 f-s-1.25-M">{t("Response")}</div>
          <div className="d-f a-i-c m-t-1 w-100% b-s-s-M b-w-1px-M b-c-lightestGray-M p-h-2-M p-v-1.5-M">
            <div className="d-f fb-a">{t("Timeout")}<span className="m-l-1 t-c-gray">{t("(includes connection and read)")}</span></div>
            <div className="d-f w-11">
              <TextInput
                value={monitor.timeout}
                suffix={<div className="p-h-1 bg-c-lightestGray t-c-nb h-100% d-f a-i-c">{t("Seconds")}</div>}
                onChange={e => onChangeField("timeout", e)}
                onBlur={e => onBlurField("timeout", e)}
                error={errors.fields.timeout}
                align="right"
                maxLength={2}
                type="number" />
            </div>
          </div>
        </div>
        <div>
          <Intervals
            intervalId={monitor.intervalId}
            error={errors.fields.intervalId}
            onChange={intervalId => onChangeInterval(intervalId)} />
        </div>
        <div>
          <Regions
            selectedRegions={monitor.regions}
            error={errors.fields.regions}
            onChange={selectedRegions => {
              validateField("regions", selectedRegions);
              setMonitor({...monitor, regions: selectedRegions} as Monitor)
            }} />
        </div>

        <div className="d-f fb-d-c m-t-1 m-t-2-M">
          <TextInput
            label={t("Description (optional)")}
            value={monitor.description}
            placeholder={t("Enter Description")}
            maxLength={250}
            onChange={e => onChangeField("description", e)}
            onBlur={e => onBlurField("description", e)}
            error={errors.fields.description}
            type="text" />
        </div>

        { !props.disallowSetGroups ?
          <div className="d-f fb-d-c m-t-1 m-t-2-M">
            <div className="f-s-1 f-s-1.25-M">{t("Groups")}</div>
            <div className="d-f fb-d-c m-t-1 w-100% b-s-s-M b-w-1px-M b-c-lightestGray-M p-h-2-M p-v-1.5-M">
              <div ref={refGroupItems} className="max-h-12 max-h-16-M of-a">
                {monitor.groups?.map( (m, i) =>
                  <div className="w-100% m-b-1 h-3 bg-c-nw p-l-0.5 p-r-1 p-v-0.5 d-f a-i-c" key={i}>
                    <div className="fb-a m-h-0.5 ws-n of-h t-o-e">
                      { m.name }
                    </div>
                    <div className="fb-n d-f a-i-c c-p" onClick={() => removeGroup(m)}>
                      <X />
                    </div>
                  </div>
                )}
              </div>
              <ComboBox
                value={groupInputValue}
                defaultFocusedIndex={groupDefaultFocusedIndex}
                onInputValueChange={onChangeGroupInputValue}
                onFocus={onFocusGroupItems}
                onSelect={onSelectGroup}
                onBlur={onBlurGroups}
                placeholder={t(`Select or add a Group...`)}
                options={ groupItems }
                pageSize={ pageSizeGroupItems }
                showSpinner={ isLoadingGroupItems }
                onScroll={ onScrollGroupItems } />
            </div>
          </div>
        : null
        }

        { !props.disallowSetAlerts ?
          <div className="d-f fb-d-c m-t-1 m-t-2-M">
            <div className="f-s-1 f-s-1.25-M">{t("Alerts")}</div>
            <div className="d-f fb-d-c m-t-1 w-100% b-s-s-M b-w-1px-M b-c-lightestGray-M p-h-2-M p-v-1.5-M">
              <div ref={refAlertItems} className="max-h-12 max-h-16-M of-a">
                {monitor.alerts?.map( (m, i) =>
                  <div className="w-100% m-b-1 h-3 bg-c-nw p-l-0.5 p-r-1 p-v-0.5 d-f a-i-c" key={i}>
                    <div className="fb-a m-h-0.5 ws-n of-h t-o-e">
                      { m.name }
                    </div>
                    <div className="fb-n d-f a-i-c c-p" onClick={() => removeAlert(m)}>
                      <X />
                    </div>
                  </div>
                )}
              </div>
              <ComboBox
                value={alertInputValue}
                defaultFocusedIndex={alertDefaultFocusedIndex}
                onInputValueChange={onChangeAlertInputValue}
                onFocus={onFocusAlertItems}
                onSelect={onSelectAlert}
                onBlur={onBlurAlerts}
                placeholder={t(`Select or add an Alert...`)}
                options={ alertItems }
                pageSize={ pageSizeAlertItems }
                disabled={!monitor.uuid && !alertItemsInitialized}
                showSpinner={ isLoadingAlertItems }
                onScroll={ onScrollAlertItems } />
            </div>
          </div>
        : null}

{monitor.type === "http" || monitor.type === "https" ?
          <div className="d-f fb-d-c m-t-3">
            <div className={`d-f a-i-c b-w-1px b-c-lightestGray p-b-0.5`}>
              <div className="f-s-1 f-s-1.25-M">
              {monitor.type.toUpperCase()} {t("Options")}
              </div>
            </div>
            <>
              {isMonitorFree ?
                <div className="f-s-1 m-t-0.5 m-b-1 t-c-b">
                  {t("These options are not available with free monitors.")}
                </div>
              : null}
              <div className="b-s-s b-w-1px b-c-lightestGray p-1 p-2-M">
                <HttpRequest
                  monitor={monitor}
                  isFreeInterval={isMonitorFree}
                  onChangeHttpRequestField={onChangeHttpRequestField}
                  onBlurHttpRequestField={onBlurHttpRequestField}
                  onAddRequestHeader={onAddHttpRequestHeader}
                  onRemoveRequestHeader={onRemoveHttpRequestHeader}
                  onChangeHttpRequestHeaderField={onChangeHttpRequestHeaderField}
                  onBlurHttpRequestHeaderField={onBlurHttpRequestHeaderField}
                  errorsRequest={httpRequestErrors}
                  errorsRequestHeader={httpRequestHeaderErrors}
                  enableDefaultMethod={enableHttpDefaultMethod}
                  onShow={() => {
                    if (!monitor.httpRequest || !monitor.httpRequest.headers) {
                      setMonitor({...monitor,
                        httpRequest: { ...monitor.httpRequest || {}, headers: [{}] } as MonitorHttpRequest
                      })
                    }
                  }} />

                <HttpResponse
                  monitor={monitor}
                  isFreeInterval={isMonitorFree}
                  onChangeHttpResponseField={onChangeHttpResponseField}
                  onBlurHttpResponseField={onBlurHttpResponseField}
                  errorsResponse={httpResponseErrors}
                  httpResponseStatusValue={httpResponseStatusValue}
                  onAddHttpResponseStatus={onAddHttpResponseStatus}
                  onRemoveHttpResponseStatus={onRemoveHttpResponseStatus}
                  onChangeHttpResponseStatusValue={onChangeHttpResponseStatusValue}
                  errorResponseStatusValue={httpResponseStatusError}
                  onShow={() => {
                    if (!monitor.httpResponse) {
                      setMonitor({
                        ...monitor,
                        httpResponse: monitor.httpResponse ?? {
                          statusMatches: true,
                          keywordBodyExists: true,
                          keywordHeaderExists: true} as MonitorHttpResponse,
                      })
                    }
                  }}
                />
              </div>
            </>
          </div>
        : null}
        {monitor.type === "tcp" ?
          <div className="d-f fb-d-c m-t-3">
            <div className={`d-f a-i-c b-w-1px b-c-lightestGray p-b-0.5`}>
              <div className="f-s-1 f-s-1.25-M">
              {t("TCP Options")}
              </div>
            </div>
            {isMonitorFree ?
              <div className="f-s-1 m-t-0.5 m-b-1 t-c-b">
                {t("These options are not available with free monitors.")}
              </div>
            : null}
            <div className="b-s-s b-w-1px b-c-lightestGray p-1 p-2-M">
              <TcpOptions
                monitor={monitor}
                isFreeInterval={isMonitorFree}
                onChangeTcpOptionsField={onChangeTcpOptionsField}
                onBlurTcpOptionsField={onBlurTcpOptionsField}
                errorsResponse={tcpOptionsErrors}
                httpResponseStatusValue={httpResponseStatusValue}
                onAddHttpResponseStatus={onAddHttpResponseStatus}
                onRemoveHttpResponseStatus={onRemoveHttpResponseStatus}
                onChangeHttpResponseStatusValue={onChangeHttpResponseStatusValue}
                errorResponseStatusValue={httpResponseStatusError}
              />
            </div>
          </div>
        : null}

        <div className="d-f fb-d-c m-t-1 m-t-2-M">
          <div className="f-s-1 f-s-1.25-M">{t("More Options")}</div>
          {isMonitorFree ?
            <div className="f-s-1 m-t-0.5 t-c-b">
              {t("These options are not available with free monitors.")}
            </div>
          : null}
          <div className="d-f fb-d-c w-100% b-s-s-M b-w-1px-M b-c-lightestGray-M m-t-1-M p-v-1 m-l-1 m-l-0-M">
            <div className="d-f fb-d-c fb-d-r-M a-i-s a-i-c-M p-h-2-M">
              <div className="fb-a d-f">
                <Checkbox
                  disabled={isMonitorFree}
                  checked={hasDegradedThreshold}
                  label={t("Degraded status")}
                  onChange={() => onChangeHasDegradedThreshold(!hasDegradedThreshold)} />
                  <span className="p-l-1">
                    <HelpTip disabled={isMonitorFree}>
                      <div>
                        The response time threshold<br />you consider degraded.
                      {/* <a className="d-f p-t-0.5" href="http://localhost:3000/doc/degraded-status" target="_blank" rel="noreferrer">More Information</a> */}
                      </div>
                    </HelpTip>
                  </span>
              </div>
              <div className="w-100% w-auto-M fb-n d-f fb-d-c fb-d-r-M a-i-s a-i-c-M p-l-2 p-l-0-M p-r-2 p-r-0-M">
                <div className={`m-v-1 m-v-0-M m-r-1-M ${!hasDegradedThreshold ? "t-c-lightGray" : ""}`}>
                  {t("Threshold")}
                </div>
                <div className="w-100% w-8-M m-l-0-M j-c-s">
                  {hasDegradedThreshold}
                  <TextInput
                    value={hasDegradedThreshold ? monitor.degradedThreshold : undefined}
                    disabled={!hasDegradedThreshold}
                    suffix={<div className={`p-h-1 bg-c-nw ${isMonitorFree || !hasDegradedThreshold ? "t-c-lightGray" : "t-c-nb"} h-100% d-f a-i-c`}>{t("sec")}</div>}
                    onChange={e => onChangeField("degradedThreshold", e)}
                    onBlur={e => onBlurField("degradedThreshold", e)}
                    error={errors.fields.degradedThreshold}
                    align="right"
                    maxLength={5}
                    type="number" />
                </div>
              </div>
            </div>
            <div className="d-f j-c-c fb-d-c fb-d-r-M a-i-s a-i-c-M p-h-2-M m-t-2 m-t-1-M">
              <div className="fb-a d-f a-i-c">
                <Checkbox
                  disabled={isMonitorFree}
                  checked={hasApdexThreshold}
                  onChange={() => onChangeHasApdexThreshold(!hasApdexThreshold)}
                  label={t("Apdex index")} />
                <span className="p-l-1">
                  <HelpTip disabled={isMonitorFree} >
                    <div>
                      The Apdex measures the degree to which<br />
                      customer expectations and actual<br />
                      delivery match, and then assigns a<br />
                      numerical score from 0 (no customer<br />
                      satisfaction) to 1 (every customer is<br />
                      satisfied).<br /><br />
                      <a className="d-f p-t-0.5" href="https://www.apdex.org/overview.html" target="_blank" rel="noreferrer">More Information</a>
                    </div>
                  </HelpTip>
                </span>
              </div>
              <div className="w-100% w-auto-M fb-n d-f fb-d-c fb-d-r-M a-i-c p-l-2 p-l-0-M a-i-s a-i-c-M p-r-2 p-r-0-M">
                <div className={`m-v-1 m-r-1 ${!hasApdexThreshold ? "t-c-lightGray" : ""}`}>
                  {t("Threshold")}
                </div>
                <div className="w-100% w-8-M">
                  <TextInput
                    value={hasApdexThreshold ? monitor.apdexThreshold : undefined}
                    disabled={!hasApdexThreshold}
                    suffix={<div className={`p-h-1 bg-c-nw ${isMonitorFree || !hasApdexThreshold ? "t-c-lightGray" : "t-c-nb"} h-100% d-f a-i-c`}>{t("sec")}</div>}
                    onChange={e => onChangeField("apdexThreshold", e)}
                    onBlur={e => onBlurField("apdexThreshold", e)}
                    error={errors.fields.apdexThreshold}
                    align="right"
                    maxLength={5}
                    type="number" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="m-v-1 m-v-2-M d-f fb-d-c j-c-e a-i-e">
          {props.isSaving ?
            <Spinner type={"primary"} />
          :
            <>
              {monitor.alerts?.length === 0 ?
                <div className="m-b-1 t-c-gray">
                  <Trans>
                    Warning: You have not selected any Alert.
                  </Trans>
                </div>
              : null}
              {!productsNonFreeEnabled && !isMonitorFree ?
                <div className="m-b-1 t-c-gray t-a-r">
                  <span className="t-c-nb">
                  {props.noRedirectToCheckout ?
                    t("As the chosen Interval is non-free, you will need to add a Payment Method.")
                  : monitor.uuid ?
                    t("By clicking on the Save button, you will be redirected to add a Payment Method.")
                  :
                    t("By clicking on the Add Monitor button, you will be redirected to add a Payment Method.")
                  }</span><br />
                  {t("We invoice and charge at the end of each month, and only if your balance reaches at least 3 US$.")}
                </div>
              : null}
              <div className="d-f fb-d-cr fb-d-r-M a-i-c j-c-e w-100%">
                <div onClick={cancel} className="d-f t-c-g f-s-1.25 c-p p-t-1 p-t-0-M j-c-e-M">
                  {t("Cancel")}
                </div>
                <div className="m-l-1-M w-100% w-auto-M">
                  <Button type="primary" submit={true} fullWidth="mobile">
                    { monitor.uuid ? t("Save") : t("Add Monitor") }
                  </Button>
                </div>
              </div>
            </>
          }
        </div>
      </form>
      <Modal
        open={isAddingGroup}
        onClose={ () => setIsAddingGroup(false) }
        title={t("New Group")}>
        <GroupNew
          onSave={onNewGroup}
          onCancel={ () => setIsAddingGroup(false) }
          disallowSetMonitors={true}
          />
      </Modal>

      <Modal
        open={isAddingAlert}
        onClose={ () => setIsAddingAlert(false) }
        title={t("New Alert")}>
        <AlertNew
          onSave={onNewAlert}
          onCancel={ () => setIsAddingAlert(false) }
          />
      </Modal>

      { upgradingFreeMonitorIntervalId ?
          <Modal
            open={upgradingFreeMonitorIntervalId !== undefined}
            onClose={ () => setUpgradingFreeMonitorIntervalId(undefined) }
            title={t("Upgrade Monitor")}
            secondaryActions={ [ { title: t("Cancel"), onAction: () => setUpgradingFreeMonitorIntervalId(undefined) } ] }
            primaryAction={ { title: t("Yes, upgrade this monitor"), onAction: () => {
              setMonitor({...monitor, intervalId: upgradingFreeMonitorIntervalId});
              setUpgradingFreeMonitorIntervalId(undefined);
            }}}>
            <div className="p-2">
              <div className="f-s-1 f-w-300 m-t-0.5">
                {t("You are upgrading from free to paid monitor.")}
              </div>
              <div className="f-s-1.5 f-w-300 t-c-b m-t-1" >
                {t("You won't be able to change this monitor to free again.")}<br />
                {t("Do you wan't to continue?")}
              </div>
            </div>
          </Modal>
          : null
        }
    </>
  );
}

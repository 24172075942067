import axios from "axios";
import React, { useState, useCallback, useEffect } from 'react';
import { Paginator as ModelPaginator } from '../model';
import { MenuInRow, Modal, Loading, AvatarNew, Spinner, MenuItem, Paginator } from '../ui';
import { InviteMembers } from './InviteMembers';
import { apiBaseUrl } from "../Consts";
import { Member as MemberModel, User } from "../model";
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from "recoil";
import { isOwnerState, messageState, teamState, userState } from "../atoms";
import { useHandleRequestError } from "../hooks";
import { SettingsMembersConvert } from "./SettingsMembersConvert";

export class Member extends MemberModel {
  public status: string;

  public constructor(init?: Partial<Member>) {
    super();
    (Object as any).assign(this, init);
    this.role = this.role.charAt(0).toUpperCase() + this.role.slice(1);
    this.status = this.invited ? "Invited" : "Joined";
  }
}

export const SettingsMembers: React.FC = () => {

  const { t } = useTranslation();
  const { handleRequestError } = useHandleRequestError();
  const user = useRecoilValue(userState);
  const team = useRecoilValue(teamState);
  const isOwner = useRecoilValue(isOwnerState);

  const [ members, setMembers ] = useState<Member[]>();
  const [ isInviting, setIsInviting ] = useState(false);
  const [ paginator, setPaginator ] = useState<ModelPaginator>();

  const fetch = useCallback((recordOffset = 0) => {
    if (team && !team.isWorkspace) {
      axios.get(`${apiBaseUrl}/${team.uuid}/members`, {params: {
          recordOffset: recordOffset
        }})
        .then(response => {
          setMembers(response.data.members.map((m: any) => new Member(m)));
          setPaginator(response.data.paginator);
        })
        .catch(error => handleRequestError(error));
    }

  }, [team, handleRequestError]);

  const onInvitationsSent = useCallback((memberModels: MemberModel[], paginator: ModelPaginator) => {
    setMembers(memberModels.map(mm => new Member(mm)));
    setPaginator(paginator);
    setIsInviting(false);
  }, [])

  const onUpdate = useCallback((member: Member) => {
    if (members) {
      const _members = [...members];
      const index = _members?.findIndex(m => m.uuid === member.uuid);
      _members[index] = member;
      setMembers(_members);
    }
  }, [members]);

  const onDelete = useCallback(() => {
    fetch(paginator ? paginator.recordOffset : 0)
  }, [fetch, paginator]);



  useEffect(() => fetch(), [team, fetch]);

  if (!team) {
    return <Loading />
  }

  return (
    !team.isWorkspace ?
      <>
        <div className="d-f fb-d-c w-100%">
          <div className="d-f fb-d-c m-t-0.75 m-t-2-M">
            <div className="d-f j-c-e p-b-0.75 t-a-r b-sb-s b-sb-n-M b-c-lightestGray b-w-1px">
              {isOwner?
                <div onClick={() => setIsInviting(true)} className="c-p t-c-g">{t("Invite Members")}</div>
              : null
              }
            </div>
            <div className="d-n d-f-M h-5 a-i-c b-sb-s b-c-lightestGray b-wb-0.1 f-w-b t-c-b">
              <div className="d-b fb-a p-l-1 p-l-0.5-M">{t("Name")}</div>
              <div className="w-7-M w-12-L">{t("Role")}</div>
              <div className="w-6-M w-9-L">{t("Status")}</div>
              <div className="d-f w-2.5 w-3.5-M"></div>
            </div>
            {!members || !user ? <Loading />
            : members.map((m, i) =>
                <SettingsMember
                  onUpdate={onUpdate}
                  onDelete={onDelete}
                  user={user}
                  member={m}
                  key={i} />
              )
            }
          </div>

          {team && paginator ?
            <div className="d-f j-c-c m-t-2">
              <Paginator
                recordOffset={paginator.recordOffset}
                recordsCount={paginator.recordsCount}
                pageSize={paginator.pageSize}
                onSelect={(page) => fetch((page - 1) * paginator.pageSize)} />
            </div>
          : null}
        </div>

        <Modal
          open={isInviting}
          onClose={ () => setIsInviting(false) }
          title={t("Invite Members")}>
          <InviteMembers
            onInvitationsSent={onInvitationsSent}
            onCancel={() => setIsInviting(false)} />
        </Modal>
      </>
    :
      <SettingsMembersConvert />
  );
}

type SettingsMemberProps = {
  member: Member
  user: User
  onDelete: (member: Member) => void
  onUpdate: (member: Member) => void
}


const SettingsMember: React.FC<SettingsMemberProps> = (props) => {

  const { t } = useTranslation();
  const { handleRequestError } = useHandleRequestError();
  const member = props.member;
  const user = props.user;
  const isYou = member.email === user.email;
  const team = useRecoilValue(teamState);
  const isOwner = useRecoilValue(isOwnerState);
  const setMessage = useSetRecoilState(messageState);
  const [ isSaving, setIsSaving ] = useState(false);

  const [ isLoading, setIsLoading ] = useState(false);
  const [ isRemoving, setIsRemoving ] = useState(false);

  const execRemove = useCallback(() => {
    if (team && isRemoving) {
      setIsLoading(true);
      setIsSaving(true);
      const isYou = member.email === user?.email;
      const url = isYou ? `${apiBaseUrl}/${team.uuid}/leave` : `${apiBaseUrl}/${team.uuid}/members/${member.uuid}`;
      axios.delete(url)
      // eslint-disable-next-line
        .then((response) => {
          if (isYou) {
            window.document.location.href = "/";
          } else {
            props.onDelete(member);
          }
        })
        .catch(error => handleRequestError(error))
        .finally(() => {
          setIsLoading(false);
          setIsRemoving(false);
          setIsSaving(false);
        });
    }
  }, [team, isRemoving, member, user, props, handleRequestError]);

  const execChangeRole = useCallback((role: string) => {
    if (team) {
      setIsLoading(true);
      const isYou = member.email === user?.email;
      axios.put(`${apiBaseUrl}/${team.uuid}/members/${member.uuid}/role/${role}`)
        .then(response => {
          if (isYou) {
            window.document.location.reload();
          } else {
            props.onUpdate(new Member(response.data.member as Member));
          }
        })
        .catch(error => handleRequestError(error))
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [team, member, user, props, handleRequestError]);

  const sendInvitation = useCallback((member: Member) => {
    if (team) {
      setIsLoading(true);
      axios.post(`${apiBaseUrl}/${team.uuid}/members/${member.uuid}/send-invitation`)
        .then(() => setMessage({type: "success", text: t("The invitation was resent")}))
        .catch(error => handleRequestError(error))
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [team, setMessage, t, handleRequestError]);

  const menu = useCallback((member: Member) => {
    if (isLoading) {
      return <Spinner />;
    } else if (isOwner) {
      let items: MenuItem[] = [
        { title: t("Change Role"), subItems:
          [
            { title: t("Member"), onClick: () => execChangeRole("member") },
            { title: t("Biller"), onClick: () => execChangeRole("biller") },
            { title: t("Owner"), onClick: () => execChangeRole("owner") }
          ],
        }, {
          title: user.email !== member.email ? t("Delete") : t("Leave this team"),
          onClick: () => setIsRemoving(true)
        }
      ];

      if (member.invited) {
        items = items.concat([
          {separator: true},
          {
            title: t("Resend invitation"),
            onClick: () => sendInvitation(member)
          }
        ]);
      }

      return <MenuInRow items={items} />;
    } else if (user.email === member.email) {
      return <MenuInRow
        items={[{
          title: t("Leave this team"),
          onClick: () => setIsRemoving(true)
        }]} />
    }
  }, [execChangeRole, isLoading, isOwner, sendInvitation, t, user.email]);

  return (
    <>
      <div className="d-f p-v-1 p-v-1.5-M a-i-c b-sb-s b-c-lightestGray b-w-1px">
        <div className="d-f a-i-c fb-a">
          <div className="w-3.5 p-l-0.5">
            <AvatarNew
              title={ member.firstName || member.lastName ? `${member.firstName} ${member.lastName}` : member.email }
              imageUrl={ member.avatarUrl }
            />
          </div>
          <div className="fb-a d-f fb-d-c a-i-c-M fb-d-r-M l-h-1.5">
            <div className="d-b fb-a p-r-1">
              { member.firstName || member.lastName ?
                <div className="f-w-b p-b-0.5 ws-n of-h t-o-e">
                  { member.firstName } { member.lastName }
                </div>
              : null }
              <div className="ws-n of-h t-o-e">
                { member.email } { isYou ? "(You)" : null }
              </div>
            </div>
            <div className="w-7-M w-12-L m-t-0.5 m-t-0-M">
              <span className="f-w-b d-ib d-n-M m-r-1">
                { t("Role") }
              </span>
              { member.role }
            </div>
            <div className="w-6-M w-9-L m-t-0.5 m-t-0-M">
              <span className="f-w-b d-ib d-n-M m-r-1">
                { t("Status") }
              </span>
              { member.status }
            </div>
          </div>
          <div className="d-f w-2.5 w-3.5-M">
            {menu(member)}
          </div>
        </div>
      </div>
      { isRemoving ? (() =>  {
          const title = member.email === user?.email ? t("Leave this team") : t("Delete Member");
          return <Modal
            open={isRemoving}
            onClose={ () => setIsRemoving(false) }
            title={title}
            subtitle={ member.firstName ? member.firstName : member.email }
            secondaryActions={ [ { title: t("Cancel"), onAction: () => setIsRemoving(false) } ] }
            primaryAction={ { title: title, onAction: execRemove, type: "destructive" } }
            isSaving={isSaving}>
            <div className="p-2">
              <div className="f-s-1.5 f-w-300 t-c-b">
                {t("You can't reverse this action!")}
              </div>
            </div>
          </Modal>
          })() : null
      }
    </>
  )
}
import React, { useEffect, useCallback, useState } from 'react';
import axios from 'axios';
import { GroupNameWithColor } from "../ui/groupNameWithColor";
import { Monitor, AverageRt, MonitorEvent, MonitorEventStatusByDate, MonitorRegionAverageRtByDay, MonitorEventWrap } from '../model';
import { Month, Status } from '../ui';
import { Loading } from '../ui/loading/Loading';
import { useParams } from 'react-router-dom';
import { apiBaseUrl, MonitorStatusType } from "../Consts";
import { MonitorEventShow } from "../monitor-show/MonitorEventShow";
import { ResponseTime } from './ResponseTime';
import { ResponseTimeHour } from '../ui/response-time-hour/ResponseTimeHour';
import { ResponseTimeLegend } from '../ui/legend/ResponseTimeLegend';
import { Day, MonthProps } from '../ui/month/Month';
import { Breadcrumbs } from '../ui/breadcrumbs/Breadcrumbs'
import { useTranslation } from 'react-i18next';
import { calculateApdex } from '../Helper';
import { useRecoilValue } from 'recoil';
import { teamState, userTimeZoneState } from '../atoms';
import { Apdex } from '../ui/apdex/apdex';
import { useHandleRequestError } from '../hooks';
import { DateTime } from 'luxon';
import { MonitorUrl } from '../monitor-url';

export const MonitorShowDay: React.FC = () => {

  const { t } = useTranslation();
  const userTimeZone = useRecoilValue(userTimeZoneState);
  const team = useRecoilValue(teamState);
  const { handleRequestError } = useHandleRequestError();

  const { monitorUuid, day } = useParams<any>();
  const [ date, setDate ] = useState<DateTime>();
  const [ now, setNow ] = useState<DateTime>();
  const [ monitor, setMonitor ] = useState<Monitor>();
  const [ events, setEvents ] = useState<MonitorEvent[]>();
  const [ eventWraps, setEventWraps ] = useState<MonitorEventWrap[]>();
  const [ monitorAverageRt, setMonitorAverageRt ] = useState<AverageRt[]>();
  const [ monitorRegionAverageRtByDay, setMonitorRegionAverageRtByDay ] = useState<MonitorRegionAverageRtByDay[]>();
  const [ monitorEventsStatusByDate, setMonitorEventsStatusByDate ] = useState<MonitorEventStatusByDate[]>();
  const [ apdexIndex, setApdexIndex ] = useState<number>()
  const [ status, setStatus ] = useState<string[]>();

  const [ months, setMonths ] = useState<MonthProps[]>();

  const fetch = useCallback(() => {
    if (team && day) {
      axios.get(`${apiBaseUrl}/${team.uuid}/monitors/${monitorUuid}/history/${day}`)
        .then(response => {
          setMonitor(response.data.monitor);
          setMonitorAverageRt(response.data.monitorAverageRt);
          setMonitorRegionAverageRtByDay(response.data.regionsAverageRt);
          setMonitorEventsStatusByDate(response.data.eventsStatusByDate);
          setApdexIndex(calculateApdex(response.data.monitorAverageRt));
          setEvents(response.data.events);
        })
        .catch(error => handleRequestError(error));
    }
  }, [team, monitorUuid, day, handleRequestError])

  useEffect(() => {
    if (events && events.length > 0 && monitor && date) {
      setEventWraps(MonitorEventWrap.wrapMonitorEvents(events));
      const _events = [...events].reverse();
      const status = [_events[0].status];
      
      for (let i = 1; i < events.length; i++) {
        const createdAt = DateTime.fromISO(_events[i].createdAt).startOf("day");

        if (createdAt < date) {
          status[0] = _events[i].status;
        } else if (createdAt.hasSame(date, "day")) {
          const existingIndex = status.findIndex(s => s === _events[i].status);
          if (existingIndex >= 0) {
            status.splice(existingIndex, 1);
          }
          status.push(_events[i].status);
        } else {
          break;
        }
      }
      setStatus(status);
    }
  }, [events, monitor, date])

  useEffect(() =>  fetch(), [team, fetch]);

  useEffect(() => {
    if (day && now) {
      const date = (day === "today") ? now.startOf('day') : DateTime.fromISO(day);
      setDate(date);
    }
  }, [day, now])

  useEffect(() => {
    if (userTimeZone) setNow(DateTime.now());
  }, [userTimeZone])

  useEffect(() => {
    if (date && now && monitorEventsStatusByDate && monitorEventsStatusByDate.length > 0) {
      const numberOfDays = Math.floor(Math.abs(now.startOf("day").diff(DateTime.fromISO(monitorEventsStatusByDate[0].date)).as("days"))) + 1;
      const months: MonthProps[] = [];

      let indexEvent = 0;
      let previousDate: DateTime | undefined = undefined;
      let lastStatus: MonitorStatusType | undefined = undefined;

      [...Array(numberOfDays)].forEach((_, i) => {
        const _date = DateTime.fromISO(monitorEventsStatusByDate[0].date).startOf("day").plus({days: i});

        if (!previousDate || previousDate.startOf("month") <  _date.startOf("month")) {
          months.push({
            monitor: monitor,
            year: _date.year,
            month: _date.month,
            days: []
          } as MonthProps)
        }

        let day: Day | undefined = undefined;

        const eventDate = monitorEventsStatusByDate.length - 1 >= indexEvent ?
          DateTime.fromISO(monitorEventsStatusByDate[indexEvent].date).startOf("day")
          : undefined;

        if (eventDate && _date.hasSame(eventDate, "day")) {
          let statusArray: MonitorStatusType[] = [];
          do {
            statusArray = statusArray.concat(monitorEventsStatusByDate[indexEvent].status as MonitorStatusType[]);
            indexEvent++;
          } while (
            indexEvent < monitorEventsStatusByDate.length &&
            _date.hasSame(DateTime.fromISO(monitorEventsStatusByDate[indexEvent].date), "day")
          );
          day = new Day(_date.hasSame(date, "day"), _date, statusArray.filter((v,i) => statusArray.indexOf(v) === i));
          lastStatus = statusArray[statusArray.length - 1];
        } else {
          day = new Day(_date.hasSame(date, "day"), _date, [lastStatus as MonitorStatusType]);
        }
        months[months.length - 1].days.push(day);

        previousDate = _date;
      });

      setMonths(months);
    }
  }, [now, monitorEventsStatusByDate, monitor, date]);

  if (team && monitor && events && date && months) {
    const _date = day === "today" ? "Today" : date.toFormat("MMM d yyyy");

    return (
      <div className="d-f fb-d-c m-h-a p-h-0.25 p-h-2-M w-100% max-w-66-L t-c-nb p-b-4">
        <div className="d-f">
          <div className="p-l-0.5 m-t-1.5">
            <Breadcrumbs
              items={[
                { title: t("Monitors"), link: `/${team.slug}/monitors`},
                { title: monitor.name, link: `/${team.slug}/monitors/${monitor.uuid}`},
                { title: _date }
              ]}
            />
          </div>
        </div>
        <div className="d-f fb-d-c fb-d-r-M fb-a j-c-c p-l-0.5 m-t-1 m-t-1.5-M">
         
          <div className="fb-a p-r-1.5">
            <div className="d-f a-i-c">
              <div className="fb-a f-s-1 f-s-1.5-M f-w-300">{ monitor.name} - {_date}</div>
            </div>
            <div className="d-f m-t-0.5">
              <MonitorUrl monitor={monitor} />
            </div>
            {monitor.groups ?
              <div className="fb-w-w m-t-1 d-f">
                {monitor.groups.map((g, i) =>
                  <div key={i} className="max-w-10">
                    <GroupNameWithColor group={g} textSize={"small"} margin={true} link={`/${team.slug}/monitors?groupUuid=${g.uuid}`} />
                  </div>
                )}
              </div>
            : null}
            { status && monitorAverageRt && monitorRegionAverageRtByDay ?
              <>
                <div className="">
                  <div className="d-f m-t-4 j-c-c">
                    {status.map((s, i ) =>
                      <div key={i} style={{marginLeft: "-0.5rem"}}>
                        <Status animate={false} status={s as MonitorStatusType} size={48} />
                      </div>
                    )}
                  </div>
                  {monitor.apdexThreshold && apdexIndex !== undefined ?
                    <div className="d-f j-c-c p-t-2">
                      <Apdex apdexIndex={apdexIndex}></Apdex>
                    </div>
                  : null}
                  {monitor.interval.seconds <= 60 || date.hasSame(DateTime.now(), "day") ?
                    <>
                      <div className="m-t-0.5">
                        <ResponseTime
                          day={day}
                          monitor={monitor}
                          monitorAverageRt={monitorAverageRt}
                          monitorRegionAverageRtByDay={monitorRegionAverageRtByDay}/>
                      </div>
                      <div className="d-f m-t-1.5 a-i-c m-l-2 m-l-7.5-M">
                        <ResponseTimeLegend monitorType={monitor.type} />
                      </div>
                    </>
                  : null}
                  <div className="m-t-4">
                    <ResponseTimeHour averageRt={monitorAverageRt} events={events} date={date} />
                  </div>
                </div>
                <div className="m-t-4">
                  <div className="m-b-1">
                    {t("Events")}
                  </div>
                  <div className="p-b-">
                    {eventWraps?.map((eventWrap, i) =>
                      DateTime.fromISO(eventWrap.event.createdAt).hasSame(date, "day") ?
                        <MonitorEventShow key={i} eventWrap={eventWrap} />
                      :
                      null
                    )}
                  </div>
                </div>
              </>
            :
              <div className="m-t-1 f-s-1.5">
                {t("No events this day")}
              </div>
            }
          </div>
          <div className="d-f fb-n fb-d-c a-i-e-M b-sl-s-M b-w-1px-M b-c-lightestGray-M w-100% w-11.5-M f-s-1">
            <div className="d-f fb-w-w fb-nw-M fb-d-r fb-d-c-M a-i-e">
              {months.map((m, i) =>
                <Month key={i} {...m} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Loading />
  }
}
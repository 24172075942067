import React from "react";

export const MiddleCheck = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path fill="#fff" d="M19 13H5v-2h14v2z"></path>
    </svg>
  );
}
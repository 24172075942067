import React from 'react';

export const IconEmail = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path
      d="M29.3333 8.00016C29.3333 6.5335 28.1333 5.3335 26.6666 5.3335H5.33329C3.86663 5.3335 2.66663 6.5335 2.66663 8.00016V24.0002C2.66663 25.4668 3.86663 26.6668 5.33329 26.6668H26.6666C28.1333 26.6668 29.3333 25.4668 29.3333 24.0002V8.00016ZM26.6666 8.00016L16 14.6668L5.33329 8.00016H26.6666ZM26.6666 24.0002H5.33329V10.6668L16 17.3335L26.6666 10.6668V24.0002Z"
      fill="black"
    />
    </svg>
  );
}
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { MonitorHttpMethods } from "../Consts";
import { ChevronDown } from "../images";
import { ChevronRight } from "../images/ChevronRight";
import { Monitor, MonitorHttpHeader, MonitorHttpRequest } from "../model";
import { BaseInputOnChangeEvent, TextArea  } from "../ui";
import { SelectionButton } from "../ui/selection-button/SelectionButton";
import { Errors } from "../validator/Validator";
import { HttpRequestHeaders } from "./HttpRequestHeaders";

type HttpRequestProps = {
  monitor: Monitor
  isFreeInterval: boolean
  onChangeHttpRequestField: (fieldName: keyof MonitorHttpRequest, event: BaseInputOnChangeEvent) => void
  onBlurHttpRequestField: (fieldName: keyof MonitorHttpRequest, event: BaseInputOnChangeEvent) => void
  onChangeHttpRequestHeaderField: (index: number, fieldName: keyof MonitorHttpHeader, event: BaseInputOnChangeEvent) => void
  onBlurHttpRequestHeaderField: (index: number, fieldName: keyof MonitorHttpHeader, event: BaseInputOnChangeEvent) => void
  onAddRequestHeader: () => void
  onRemoveRequestHeader: (index: number) => void
  errorsRequest: Errors<MonitorHttpRequest>
  errorsRequestHeader: { [name: string]: string | undefined }[]
  enableDefaultMethod: boolean
  onShow: () => void
}

export const HttpRequest: React.FC<HttpRequestProps> = (props) => {

  const { t } = useTranslation();

  const httpRequest = props.monitor.httpRequest;
  const [show, setShow] = useState(!!httpRequest);

  const activateShow = useCallback(() => {
    setShow(true);
    props.onShow();
  }, [props.onShow]);

  return <div className="d-f fb-d-c">
    <div className="f-w-600">
      {t("Method")}
    </div>
    <div className="d-f fb-w-w m-t-1">
      {["HEAD or GET", ...Object.keys(MonitorHttpMethods).map(m => m.toUpperCase())].map((m, i) =>
        <div key={i} className="m-r-0.5 p-b-0.5">
          <SelectionButton
            onChange={e => {
              props.onChangeHttpRequestField("method", new BaseInputOnChangeEvent(i === 0 ? "" : m, e.dirty, e.touched))
            }}
            checked={(i === 0 && !httpRequest?.method ) || m === httpRequest?.method}
            label={m}
            disabled={props.isFreeInterval || (i==0 && !props.enableDefaultMethod)} />
        </div>
      )}
    </div>
    <div
      onClick={() => show ? setShow(false) : activateShow()}
      className={`d-f a-i-c p-b-0.5 m-t-1.5 m-t-2.5-M c-p ${ !show ? "b-sb-s b-w-1px b-c-lightestGray" : "" }`}>
      <div className="fb-a f-s-1 f-w-600">{t("Request")}</div>
      <div
        className="d-f fb-n m-l-1 t-c-g f-s-1 j-c-s">
        <div className="d-f t-c-g">{show ? <ChevronDown /> : <ChevronRight />}</div>
      </div>
    </div>
    {show && httpRequest ?
      <>
        <div className="d-f fb-d-c a-i-s w-100%">
          <HttpRequestHeaders
            disabled={props.isFreeInterval}
            headers={httpRequest.headers}
            onChangeField={props.onChangeHttpRequestHeaderField}
            onBlurField={props.onBlurHttpRequestHeaderField}
            onAddHttpHeader={props.onAddRequestHeader}
            onRemoveHttpHeader={props.onRemoveRequestHeader}
            errors={props.errorsRequestHeader}
          />

          <div className={`${props.isFreeInterval ? "t-c-lightGray" : "t-c-nb"} m-t-2`}>
            {t("Body")}
          </div>
          <div className="min-h-6 w-100% m-t-0.5">
            <TextArea
              disabled={props.isFreeInterval || httpRequest.method === "HEAD"}
              value={httpRequest.method === "HEAD" ? "" : httpRequest.body}
              error={props.errorsRequest.fields.body}
              onBlur={e => props.onBlurHttpRequestField("body", e )}
              onChange={e => props.onChangeHttpRequestField("body", e )} />
          </div>
        </div>
      </>
    : null}

  </div>
}
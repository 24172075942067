import { Alert } from "../model";

// Immutable class
export class AlertSelector {

  public readonly requirePhoneAlertCredits: boolean;

  constructor(
    public readonly alert: Alert,
    public readonly expanded: boolean = false,
    public readonly selected: boolean = false,
    public readonly deleting: boolean = false,
    public readonly showingRecipients: boolean = false) {
    this.requirePhoneAlertCredits = alert.recipients.findIndex(r => r.type === "sms" || r.type === "call") > -1;
  }

  delete(): AlertSelector {
    return new AlertSelector(this.alert, this.expanded, this.selected, true);
  }

  select(): AlertSelector {
    return new AlertSelector(this.alert, this.expanded, !this.deleting ? true : this.selected, this.deleting);
  }
  
  unselect(): AlertSelector {
    return new AlertSelector(this.alert, this.expanded, !this.deleting ? false : this.selected, this.deleting);
  }

  toggle(): AlertSelector {
    return new AlertSelector(this.alert, this.expanded, !this.deleting ? !this.selected : this.selected, this.deleting);
  }

  expand(): AlertSelector {
    return new AlertSelector(this.alert, true, this.selected, this.deleting);
  }

  updateFromRemote(alert: Alert): AlertSelector {
    return new AlertSelector(alert, this.expanded);
  }

  showRecipients(): AlertSelector {
    return new AlertSelector(this.alert, true, this.selected, this.deleting, true);
  }

  hideRecipients(): AlertSelector {
    return new AlertSelector(this.alert, true, this.selected, this.deleting, false);
  }

  public get blocked(): boolean {
    return this.deleting;
  }

  public get canDelete(): boolean {
    return !this.blocked;
  }
}
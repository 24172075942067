import React, { useState, useEffect, useMemo } from 'react';
import { Tabs, TabsOption } from '../ui';
import { SettingsTeam } from './SettingsTeam';
import { SettingsMembers } from './SettingsMembers';
import { SettingsSlug } from './SettingsSlug';
import { Route, useRouteMatch, useHistory, Switch, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SettingsApi } from './SettingsApi';
import { useRecoilValue } from 'recoil';
import { teamState } from '../atoms';


export const Settings: React.FC = () => {

  const match = useRouteMatch();

  const team = useRecoilValue(teamState);
  const { t } = useTranslation();
  const tabsOptions = useMemo<TabsOption[]>(() =>
    [
      {
        id: "team",
        title:  team?.isWorkspace ? t("Workspace") : t("Team"),
        link: `/${team?.slug}/settings/team`
      },
      {
        id: "members",
        title: team?.isWorkspace ? t("Team & Members") : t("Members"),
        link: `/${team?.slug}/settings/members`
      },
      {
        id: "api",
        title: t("API"),
        link: `/${team?.slug}/settings/api`
      },
      {
        id: "slug",
        title: t("Slug"),
        link: `/${team?.slug}/settings/slug`
      }
    ]
  , [t, team]);

  const history = useHistory();
  const [selectedTabOptionIndex, setSelectedTabOptionIndex] = useState(-1);

  useEffect(() => {
    const pathParts = history.location.pathname.split('/');
    let tabId = pathParts[pathParts.length - 1];
    if (tabId.length === 0) {
      tabId = pathParts[pathParts.length - 2];
    }
    const index = tabsOptions.findIndex(t => t.id === tabId);
    setSelectedTabOptionIndex(index);
  }, [history.location, tabsOptions]);

  return (
    <div className="d-f w-100% max-w-66 fb-d-c m-h-a p-h-1 p-h-2-M t-c-nb p-b-2 p-b-3-M">
      <div className="f-w-300 f-s-1.5 f-s-2-M m-t-1.5">
        {t("Settings")}
      </div>
      <div>
        <Tabs
          options={ tabsOptions }
          selected={ selectedTabOptionIndex } />
      </div>
      <div className="d-f j-c-c m-h-a w-100%">
        <Switch>
          <Route path={`${match.path}`} exact><Redirect to={`${match.path}/team`} /></Route>
          <Route path={`${match.path}/team`} exact component={SettingsTeam} />
          <Route path={`${match.path}/members`} exact component={SettingsMembers} />
          <Route path={`${match.path}/api`} exact component={SettingsApi} />
          <Route path={`${match.path}/slug`} exact component={SettingsSlug} />
        </Switch>

      </div>
    </div>
  );
}

import React from 'react';
import styles from './spinner.module.css'; 

type LoadingProps = {
  type?: "destructive" | "primary" | "normal"
}

export const Spinner: React.FC<LoadingProps> = (props) => {

  const type = props.type ? props.type : "normal";

  let typeClass: string;
  switch (type) {
    case "destructive":
      typeClass = "bg-c-r";
      break;
    case "primary":
      typeClass = "bg-c-g";
      break;
    case "normal":
    default:
      typeClass = "bg-c-nb";
  }

  return (
    /* Spinner by Tobias Ahlin https://tobiasahlin.com/spinkit/ */
    <div>
      <div className={`${styles.spinner}`}>
        <div className={`${styles.bounce1} ${typeClass}`}></div>
        <div className={`${styles.bounce2} ${typeClass}`}></div>
        <div className={`${styles.bounce3} ${typeClass}`}></div>
      </div>
    </div>
  )
}
import React, { useState, useEffect } from 'react';
import logo from '../logo.png';
import { useLocation } from 'react-router-dom';
import { useWindowSize } from '../hooks';
import { Breakpoints } from "../Consts";
import { MenuIcon } from "../images";
import { IconType } from '../ui/types';
import { SidebarOption } from './SidebarOption';
import { useTranslation } from 'react-i18next';
import { isOwnerOrBillerState, teamState } from '../atoms';
import { useRecoilValue } from 'recoil';
import { Transition } from 'react-transition-group';

const isLarge = (width: number) => width >= Breakpoints.Large;

export const Sidebar: React.FC = () => {

  const { t } = useTranslation();
  const location = useLocation();
  const windowSize = useWindowSize();
  const [ opened, setOpened ] = useState(isLarge(windowSize.width));
  const team = useRecoilValue(teamState);
  const isOwnerOrBiller = useRecoilValue(isOwnerOrBillerState);

  // Remove the "findDOMNode is deprecated in StrictMode" warning using ReactTransitionGroup.
  // https://github.com/reactjs/react-transition-group/issues/668
  const nodeRef = React.useRef(null);

  useEffect(() => {
    setOpened(isLarge(windowSize.width));
  }, [windowSize, location])

  const options = (collapsed: boolean): React.ReactElement => {
    return (
      <div className="fb-a of-y-a">
        <SidebarOption
          title={t("Dashboard")}
          collapsed={collapsed}
          pathname={`/${team?.slug}`}
          iconType={IconType.Dashboard} />

        <SidebarOption
          title={t("Monitors")}
          collapsed={collapsed}
          pathname={`/${team?.slug}/monitors`}
          iconType={IconType.Monitor} />

        <SidebarOption
          title={t("Groups")}
          collapsed={collapsed}
          pathname={`/${team?.slug}/groups`}
          iconType={IconType.Group} />

        <SidebarOption
          title={t("Pages")}
          collapsed={collapsed}
          pathname={`/${team?.slug}/pages`}
          iconType={IconType.Page} />

        <SidebarOption
          title={t("Alerts")}
          collapsed={collapsed}
          pathname={`/${team?.slug}/alerts`}
          iconType={IconType.Alert} />

        <hr className="b-c-lightestGray b-w-1px b-s-s w-100%"></hr>

        <SidebarOption
          title={t("Preferences")}
          collapsed={collapsed}
          pathname={`/${team?.slug}/preferences`}
          iconType={IconType.Preferences} />

        <SidebarOption
          title={t("Settings")}
          collapsed={collapsed}
          pathname={`/${team?.slug}/settings`}
          iconType={IconType.Settings} />

        {isOwnerOrBiller ?
          <SidebarOption
            title={t("Billing")}
            collapsed={collapsed}
            pathname={`/${team?.slug}/billing`}
            iconType={IconType.Billing} />
          : null
        }
      </div>
    );
  }

  return (
    <>
      {/* Menu Button for phone */}
      <div className="d-f d-n-M a-i-c j-c-c h-4 w-3.5 b-c-lightestGray b-sb-s b-sr-s b-w-1px">
        <div className="c-p" onClick={() => setOpened(!opened)}>
          <MenuIcon />
        </div>
      </div>

      {/* Sidebar for Tablet when is not opened */}
      <div className="d-n d-f-M fb-d-c w-3.5 z-99 pos-f pos-l-0 pos-t-0 pos-b-0 bg-c-nw b-c-lightestGray b-sr-s b-w-1px">
        <div className="fb-n d-f a-i-c h-4 m-b-1.5">
          <div className="p-h-1 c-p" onClick={() => setOpened(!opened)}>
            <MenuIcon />
          </div>
        </div>

        
        { options(true) }
      </div>

      {/* Background for tablet when is opened */}
      {opened ?
        <div className="z-100 pos-f pos-l-0 pos-r-0 pos-t-0 pos-b-0 o-0_5 bg-c-backPopup d-n-L" onClick={() => setOpened(false)}>
        </div>
      : null}

      {/* Fix sidebar for Desktop and diplayed on Tablet and Phone when is opened */}
      <Transition in={opened} timeout={0} nodeRef={nodeRef}>
        {(state) => (
          <div
            className="z-100 pos-f pos-t-0 pos-b-0 w-16 w-14-L"
            style={{
              transition: `left ${300}ms`,
              ...{
                entering: { left: "-16rem" },
                entered: { left: "0" },
                exiting: { left: "0" },
                exited: { left: "-16rem" },
                unmounted: {}
              }[state]
            }}>
            <div className="h-100% bg-c-nw b-c-lightestGray b-sr-s b-w-1px d-f fb-d-c">
              <div className="fb-n d-f a-i-c h-4 p-h-2 d-f m-b-1.5">
                <div className="fb-n d-f a-i-c j-c-c c-p d-n-L w-1.5" onClick={() => setOpened(!opened)}>
                  <MenuIcon />
                </div>
                <a className="fb-a d-f db-ai-c p-h-1 p-h-0-L" href={`/${team?.slug}`}>
                  <img className="max-h-2 h-100% max-w-100%" src={logo}  alt="Statsignal"/>
                </a>
              </div>

              { options(false) }
            </div>
          </div>
        )}
      </Transition>
    </>
  );
}

import React, { useState, useCallback, useMemo } from 'react';
import { CreateAlertReq, Alert, AlertRecipient } from '../model';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { apiBaseUrl } from "../Consts";
import { Errors } from '../validator/Validator';
import { AlertForm } from '../alert-form';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { teamState, userState } from '../atoms';
import { useHandleRequestError } from '../hooks';
import { Breadcrumbs, Loading } from '../ui';

type AlertNewProps = {
  onCancel?: () => void
  onSave?: (alert: Alert) => void
}

export const AlertNew: React.FC<AlertNewProps> = (props) => {

  const { t } = useTranslation();
  const team = useRecoilValue(teamState);
  const user = useRecoilValue(userState);
  const { handleRequestErrorAvoidingPopup } = useHandleRequestError();
  const [ isSaving, setIsSaving ] = useState(false);

  const [ errorsOnServer, setErrorsOnServer ] = useState<Errors<Alert>>()
  const alert = useMemo(() => {
    const alert = new Alert();
    alert.up = true;
    alert.degraded = true;
    alert.disrupted = true;
    alert.down = true;
    alert.maintenance = true;
    const recipient = new AlertRecipient();
    recipient.type = "email";
    recipient.target = user?.email;
    alert.recipients = [recipient];
    return alert;
  }, [user?.email]);

  const history = useHistory();

  const onSave = useCallback((alert: Alert) => {
    if (team) {
      const request = new CreateAlertReq();
      request.name = alert.name;
      request.up = alert.up;
      request.degraded = alert.degraded;
      request.disrupted = alert.disrupted;
      request.down = alert.down;
      request.maintenance = alert.maintenance;
      request.recipients = alert.recipients;
      setIsSaving(true);
      
      axios.post(`${apiBaseUrl}/${team.uuid}/alerts`, request)
        .then(response => {
          if (props.onSave) {
            props.onSave(response.data.alert as Alert);
          } else {
            history.push(`/${team.slug}/alerts?order=lastCreated&descending=true`)
          }
        })
        .catch(err => {
          handleRequestErrorAvoidingPopup(err);
          setErrorsOnServer(err.response.data);
        })
        .finally(() => setIsSaving(false));
    }
  }, [team, props, history, handleRequestErrorAvoidingPopup]);

  const onCancel = useCallback(() => {
    if (props.onCancel) {
      props.onCancel()
    } else if (history.length > 2) {
      history.goBack();
    } else {
      history.push(`/${team?.slug}/alerts`)
    }
  }, [history, team, props])

  const componentForm = useCallback(() =>
    <AlertForm
      errorsOnServer={errorsOnServer}
      onSave={onSave}
      onCancel={onCancel}
      alert={alert}
      isSaving={isSaving}
    ></AlertForm>
  , [errorsOnServer, alert, isSaving, onCancel, onSave]);

  if (!team ) {
    return <Loading />
  }

  return (
    !props.onSave ?
      <div className="d-f fb-d-c m-h-a w-100% max-w-44.5-M max-w-49-L p-h-1 p-h-2-M t-c-nb">
        <div className="m-t-1.5">
          <Breadcrumbs
            items={[
              { title: t("Alerts"), link: `/${team.slug}/alerts`},
              { title: t("New") },
            ]}
          />
        </div>
        <div className="f-w-300 f-s-1.5 f-s-2-M m-v-1 m-v-1.5-M">{t("New Alert")}</div>
        {componentForm()}
      </div>
    : componentForm()
  )
}

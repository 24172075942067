import { useState, useCallback } from "react";
import { scrollToInputError } from "../Helper";

export interface Errors<T> {
  base?: string | string[];
  fields: Record<keyof T, string | string[] | undefined>;
  extras: { [name: string]: string | string[] | undefined };
}

export class Validations<T> {
  readonly fieldValidations: Record<keyof T, (value: any, base?: T) => string | string[] | undefined>;
  private _baseValidation: (value: any) => string | string[] | undefined;
  readonly extraValidations: { [name: string]: (value: any) => string | string[] | undefined };
  readonly base: T;

  constructor(base: T) {
    this.base = base;

    const fieldValidations: any = { };
    Object.keys(this.base as any).forEach(key => {
      // eslint-disable-next-line
      fieldValidations[key as string] = (value: any, base?: T) => undefined;
    });

    this.fieldValidations = fieldValidations as Record<keyof T, (value: any, base?: T) => string | string[] | undefined>;
    // eslint-disable-next-line
    this._baseValidation = (value: any) => undefined;
    this.extraValidations = {};
  }

  addField(name: keyof T, func: (value: any, base?: T) => string | string[] | undefined) {
    this.fieldValidations[name] = func;
  }

  setBase(func: (value: any) => string | string[] | undefined) {
    this._baseValidation = func;
  }

  addExtra(name: string, func: (value: any) => string | string[] | undefined) {
    this.extraValidations[name] = func;
  }

  get baseValidation(): (value: any) => string | string[] | undefined {
    return this._baseValidation;
  }
}

interface Validator<T> {
  validateBase: (value: T) => boolean;
  validateField: (name: keyof T, value: any, base?: T) => boolean;
  validateExtra: (name: string, value: any) => boolean;
  validateAll: (value: T, extraValues?: { [name: string]: any }) => boolean;
  resetErrorBase: () => void;
  resetErrorField: (name: keyof T, error?: string | string[]) => void;
  resetErrorExtra: (name: string) => void;
  resetErrorAll: (errors?: Errors<T>) => void;
  resetErrorAllFromServer: (errors: any) => void
  errors: Errors<T>;
}

export function useValidator<T>(v: Validations<T>, scrollOnFields: boolean = true): Validator<T> {

  const errorFields: any = { };

  Object.keys(v.base as any).forEach(key => {
    errorFields[key as (keyof T)] = undefined;
  });
  
  const [ errors, setErrors ] = useState<Errors<T>>({
    fields: errorFields as Record<keyof T, string | string[] | undefined>,
    extras: {}
  });

  const validateBase = useCallback((base: T): boolean => {
    const result = v.baseValidation(base);
    setErrors((errors) => { return { ...errors, base:  result }; })

    return !result;
  }, [v]);

  const validateField = useCallback((name: keyof T, value: any, base?: T): boolean => {
    const result = v.fieldValidations[name](value, base);
    
    setErrors(errors => {
      const _errors = { ...errors };
      (_errors.fields as Record<keyof T, string | string[] | undefined>)[name] = result;
      return _errors;
    });

    return !result;
  }, [v]);

  const validateExtra = useCallback((name: string, value: any): boolean => {
    const result = v.extraValidations[name](value);
    setErrors(errors => {
      const _errors = { ...errors };
      errors.extras[name] = result;
      return _errors;
    })

    return !result;
  }, [v]);

  const validateAll = useCallback((base: T, extraValues?: { [name: string]: any }): boolean => {
    let valid = validateBase(base);

    Object.keys(v.base as any).forEach(fieldName => {
      const _fieldName = fieldName as keyof T;
      valid = validateField(_fieldName, base[_fieldName], base) && valid;
    })

    if (extraValues) {
      Object.keys(extraValues).forEach(fieldName => {
        valid = validateExtra(fieldName, extraValues[fieldName]) && valid;
      });
    }

    if (scrollOnFields && !valid) {
      setTimeout(() => scrollToInputError());
    }

    return valid;
  }, [validateBase, v.base, scrollOnFields, validateField, validateExtra]);

  const resetErrorBase = useCallback((error?: string | string[]) => {
    setErrors(errors => { return { ...errors, base: error }; });
  }, []);

  const resetErrorField = useCallback((name: keyof T, error?: string | string[]) => {
    setErrors(errors => {
      const _errors = { ...errors };
      (_errors.fields as Record<keyof T, string | string[] | undefined>)[name] = error;
      return _errors;
    });
  }, []);

  const resetErrorExtra = useCallback((name: string, error?: string | string[]) => {
    setErrors(errors => {
      const _errors = { ...errors };
      errors.extras[name] = error;
      return _errors
    });
  }, []);

  const resetErrorAll = useCallback((errors?: Errors<T>) => {
    if (errors) {
      setErrors(errors);
    } else {
      const errorFields: any = { };

      Object.keys(v.base as any).forEach(key => {
        errorFields[key as (keyof T)] = undefined;
      });

      const _errors = {
        fields: errorFields as Record<keyof T, string | string[] | undefined>,
        extras: {}
      };
      setErrors(_errors);
    }

    if (scrollOnFields) {
      setTimeout(() => scrollToInputError());
    }
  }, [scrollOnFields, v.base]);

  const resetErrorAllFromServer = useCallback((errors: any) => {
    const errorFields: any = { };

    Object.keys(v.base as any).forEach(key => {
      errorFields[key as (keyof T)] = errors[key];
    });

    const _errors = {
      fields: errorFields as Record<keyof T, string | string[] | undefined>,
      extras: {} as any,
      base: errors["_base"]
    };

    // Any error that is not _base and doesn't exist in T
    Object.keys(errors).forEach(key => {
      if (!(key in _errors.fields)) {
        _errors.extras[key] = errors[key];
      }
    })

    resetErrorAll(_errors);
  }, [resetErrorAll, v.base]);

  return {
    validateBase,
    validateField,
    validateExtra,
    validateAll,
    resetErrorBase,
    resetErrorField,
    resetErrorExtra,
    resetErrorAll,
    resetErrorAllFromServer,
    errors
  };
}
import React, { useCallback, useEffect, useState } from 'react';
import { apiBaseUrl, MonitorStatusType } from '../Consts';
import { GetMonitorEventsRequest, MonitorEvent, MonitorEventWrap, MonitorStatusCount, Paginator } from '../model';
import { Loading, Status } from '../ui';
import axios from 'axios';
import { DateTime } from 'luxon';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { lastMonitorsUpdatedState, teamState } from '../atoms';
import { useForceUpdate, useHandleRequestError } from '../hooks';
import { Link, useHistory } from 'react-router-dom';
import { statusToColor } from '../Helper';
import { useInterval } from '../hooks/UseInterval';
import { GroupNameWithColor } from '../ui/groupNameWithColor';
import { Empty } from '../empty';
import { usePrevious } from '../hooks/UsePrevious';
import { MonitorUrl } from '../monitor-url';


export const Dashboard = () => {

  const { t } = useTranslation();
  const team = useRecoilValue(teamState);
  const [monitorStatuses, setMonitorStatuses] = useState<MonitorStatusCount[]>();
  const [monitorEventsPaginator, setMonitorEventsPaginator] = useState<Paginator>();
  const lastMonitorsUpdated = useRecoilValue(lastMonitorsUpdatedState);
  const prevLastMonitorsUpdated = usePrevious(lastMonitorsUpdated);
  const [eventWraps, setEventWraps] = useState<MonitorEventWrap[]>();
  const { handleRequestError } = useHandleRequestError();
  const history = useHistory();

  useInterval(useForceUpdate(), 10000);

  const fetch = useCallback(() => {
    if (team) {
      axios.get(`${apiBaseUrl}/${team.uuid}/summary`)
        .then(response => {
          setEventWraps(MonitorEventWrap.wrapMonitorEvents(response.data.monitorEvents));
          setMonitorStatuses(response.data.monitorStatuses);
          setMonitorEventsPaginator(response.data.monitorEventsPaginator);
        })
        .catch(error => handleRequestError(error));
    }

  }, [team, handleRequestError]);

  const fetchMoreEvents = useCallback(() => {
    if (team && monitorEventsPaginator) {
      const request = new GetMonitorEventsRequest();
      request.recordOffset = monitorEventsPaginator.recordOffset + monitorEventsPaginator.pageSize;
      request.pageSize = monitorEventsPaginator.pageSize;
      axios.get(`${apiBaseUrl}/${team.uuid}/monitor-events`, { params: request })
        .then(response => {
          const newEventWraps = MonitorEventWrap.wrapMonitorEvents(response.data.monitorEvents);
          setEventWraps([...(eventWraps as MonitorEventWrap[]), ...newEventWraps]);
          setMonitorEventsPaginator(response.data.paginator);
        })
        .catch(err => handleRequestError(err));
    }
  }, [team, monitorEventsPaginator, eventWraps, handleRequestError]);

  useEffect(() => {
    if (team && eventWraps && lastMonitorsUpdated && prevLastMonitorsUpdated && lastMonitorsUpdated !== prevLastMonitorsUpdated) {
      let isStatusUpdated = eventWraps.length === 0;
      const isDeleted = lastMonitorsUpdated.findIndex(m => m.monitor.status === "deleted") > -1;
      let lastEventWrapCreatedAt: DateTime | undefined;
      if (!isDeleted && !isStatusUpdated) {
        lastEventWrapCreatedAt = DateTime.fromISO(eventWraps[0].event.createdAt);
        isStatusUpdated = lastMonitorsUpdated.findIndex(mse => DateTime.fromISO(mse.monitor.statusSince) > (lastEventWrapCreatedAt as DateTime)) > -1;
      }

      if (isDeleted || isStatusUpdated) {
        axios.get(`${apiBaseUrl}/${team.uuid}/summary`)
          .then(response => {
            setMonitorStatuses(response.data.monitorStatuses);

            const _eventWraps = MonitorEventWrap.wrapMonitorEvents(response.data.monitorEvents);
            if (response.data.monitorStatuses.length === 0) {
              setEventWraps([]);
              setMonitorEventsPaginator(response.data.monitorEventsPaginator);
            } else if (!isDeleted && lastEventWrapCreatedAt) {
              let newEventWraps = _eventWraps.filter(ew => DateTime.fromISO(ew.event.createdAt) > (lastEventWrapCreatedAt as DateTime))
              newEventWraps = [...newEventWraps, ...eventWraps];

              if (monitorEventsPaginator) {
                const limitLength = monitorEventsPaginator.recordOffset + monitorEventsPaginator.pageSize;
                if (newEventWraps.length > limitLength) {
                  newEventWraps.splice(
                    limitLength,
                    newEventWraps.length - limitLength);
                }
              }

              setEventWraps(newEventWraps);

              setMonitorEventsPaginator((monitorEventsPaginator) =>
                monitorEventsPaginator ?
                  {
                    ...monitorEventsPaginator, recordsCount: monitorEventsPaginator.recordsCount
                  }
                : monitorEventsPaginator
              );
            } else {
              setEventWraps(_eventWraps);
              setMonitorEventsPaginator(response.data.monitorEventsPaginator);
            }
          })
          .catch(error => handleRequestError(error));
      }
    }
  }, [eventWraps, handleRequestError, lastMonitorsUpdated, monitorEventsPaginator, prevLastMonitorsUpdated, team]);

  useEffect(() => fetch(), [team, fetch]);

  if (!team || !monitorStatuses || !monitorEventsPaginator || !eventWraps) {

    return <Loading />

  } else if (monitorStatuses.length === 0 && eventWraps.length === 0) {
    return (
      <div className="d-f fb-d-c m-h-a m-b-1 m-b-2-M p-h-1 p-h-2-M w-100% max-w-66-L t-c-nb">
        <div className="">
          <Empty
            description={
              <span>
                <Trans t={t} i18nKey="No monitors<1/>added yet">
                  No monitors<br/>added yet
                </Trans>
              </span>
            }
            actionTitle={t("Start Adding a monitor")}
            onAction={() => history.push(`/${team.slug}/monitors/new`)}
            type={"start"}/>
        </div>
      </div>
    )
  } else {
    return (
      <>
        <div className="d-f fb-d-c m-h-a m-b-1 m-b-2-M p-h-1 p-h-2-M w-100% max-w-66-L t-c-nb of-h">
          <div className="m-t-2.5 f-w-300 f-s-2">
            {t("Monitors' Status")}
          </div> 
          <div className="d-f fb-w-w m-t-1.5 m-r-n2" >
            { monitorStatuses.length > 0 ?
              monitorStatuses.map((ms, i) => {
                const description = `${ms.count} ${ms.status == "requiresPaymentMethod" ? t("Requires Payment Method") : ms.status}`;
                return <div key={i} className="w-100% w-33.3%-M w-25%-L p-b-1.5 p-r-2">
                  <Link to={`/${team.slug}/monitors?status=${ms.status}`} className="d-f a-i-c bg-c-w bg-c-nw:h">
                    <div
                      className="w-100% d-f j-c-sb a-i-c b-s-s b-w-1px b-r-4px p-v-1.5 p-h-1.5 t-c-b"
                      style={{color: statusToColor(ms.status)}}>
                        <div className="w-3 fb-n"><Status animate={true} status={ms.status as MonitorStatusType} /></div>
                        <div className="fb-a of-h t-o-e ws-n" title={description} style={{color: statusToColor(ms.status)}}>
                          {description}
                        </div>
                    </div>
                  </Link>
                </div>
              })
            :
              <div className="w-100% d-f fb-w-w m-t-1">
                {t("No monitors")}.&nbsp;&nbsp;
                <Link to={`/${team.slug}/monitors/new`}>
                  {t("Add a monitor")}
                </Link>
              </div>
            }
          </div>
          <div className="m-t-2.5 f-w-300 f-s-2">
            {t("Latest Events")}
          </div>
          <div className="m-v-1.5">
            { eventWraps.length > 0 ?
              eventWraps.map((eventWrap,i) => {
                const monitorEvent = eventWrap.event as MonitorEvent;
                return <Link
                  to={`/${team.slug}/monitors/${monitorEvent.monitor.uuid}`}
                  key={`${eventWrap.event.createdAt}${i}`}
                  className="w-100% p-v-0.75 d-f fb-w-w fb-w-nw-L bg-c-nw:h a-i-s b-sb-s b-c-lightestGray b-wb-0.1 p-h-1">
                  <div className="w-100% w-50%-L d-f">
                    <div className="fb-n w-3"><Status animate={false} status={eventWrap.event.status as MonitorStatusType} /></div>
                    <div className="fb-a w-100%">
                      <div className="h-2 d-f a-i-c" style={{color: statusToColor(eventWrap.event.status)}}>{ eventWrap.description }</div>
                      <div className="ws-n of-h t-o-e t-c-b">{ monitorEvent.monitor.name }</div>
                      <div>
                        <div className="ws-n of-h t-o-e max-w-100% m-t-0.5">
                          <MonitorUrl noLink={true} monitor={monitorEvent.monitor} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-100% w-50%-L d-f j-c-e-L fb-w-w p-t-0.25 p-l-3 p-l-2-L">
                    {monitorEvent.monitor.groups ?
                      monitorEvent.monitor.groups.map((g, i) =>
                        <div key={i} className="max-w-10">
                          <object>
                            <GroupNameWithColor
                              key={i}
                              group={g}
                              textSize={"small"}
                              margin={true}
                              link={`/${team.slug}/monitors?groupUuid=${g.uuid}`}/>
                          </object>
                        </div>
                      )
                    : null}
                  </div>
                </Link>
              })
            :
              <div className="w-100% d-f fb-w-w m-t-1">
                {t("No Events")}
              </div>
            }
            {monitorEventsPaginator.recordsCount > (monitorEventsPaginator.recordOffset + monitorEventsPaginator.pageSize)  ?
              <div onClick={fetchMoreEvents} className="t-dl-u t-c-g c-p t-a-c m-t-2">
                {t("View More")}
              </div>
            : null}
          </div>
        </div>
      </>
    );
  }
}
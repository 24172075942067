import React from 'react';
import { Link } from 'react-router-dom';

export interface TabsOption {
  id: string
  title: string
  disabled?: boolean
  link?: string
}

type TabsProps = {
  options: TabsOption[],
  selected: number,
  onSelect?: (TabsoptionIndex: number) => void
}

export const Tabs: React.FC<TabsProps> = (props) => {

  return (
    <React.Fragment>
      <div className="w-100% m-t-2 d-f fb-d-r fb-w-w b-sb-s b-c-lightestGray b-w-1px f-s-1">
        {props.options.map((t, i) =>
          t.link ?
            <Link
              key={i}
              to={t.link}
              className={`m-h-1 b-sb-s ${ props.selected === i ? 'b-c-g' : 'b-c-w'} t-c-b m-t-1 p-b-0.5 fb-n b-w-3px c-p`}>
              { t.title }
            </Link>
          :
            <div
              key={i}
              onClick={() => props.onSelect ? props.onSelect(i) : null}
              className={`m-h-1 b-sb-s ${ props.selected === i ? 'b-c-g' : 'b-c-w'} m-t-1 p-b-0.5 fb-n b-w-3px c-p`}>
              { t.title }
            </div>
        )}
      </div>
    </React.Fragment>
  );
}

import React, { useCallback, useEffect, useState } from 'react';
import { IconGitHub, IconGoogle } from '../images';
import logo from '../logo.png';
import { BaseInputOnChangeEvent, Button, Loading, Modal, Spinner, TextInput } from '../ui';
import { Trans, useTranslation } from 'react-i18next';
import { CreateSessionRequest } from '../model';
import axios from 'axios';
import { apiBaseUrl, authBaseUrl } from '../Consts';
import { useHandleRequestError } from '../hooks';
import { useValidator, Validations } from '../validator';
import { isFieldEmailNotMatching, isFieldStringEmpty } from '../Validations';
import { Link, useHistory } from 'react-router-dom';
import { useQuery } from '../hooks/UseQuery';
import { ReCaptchaV2, ReCaptchaV3 } from 'react-recaptcha-x';
import { TRefreshToken } from 'react-recaptcha-x/dist/reCaptchaV3/component/TRefreshToken';
import { hasValidationError } from '../Helper';

const v = new Validations(new CreateSessionRequest());
v.addField("email", (v: any) => isFieldStringEmpty(v) || isFieldEmailNotMatching(v));
v.addField("password", (v: any) => isFieldStringEmpty(v));

export const SignIn: React.FC = () => {

  const { t } = useTranslation();
  const query = useQuery();
  const history = useHistory();

  const { handleRequestError } = useHandleRequestError();
  const [ session, setSession ] = useState(new CreateSessionRequest());
  const { errors, validateField, validateAll, resetErrorBase, resetErrorAllFromServer } = useValidator(v);
  const [ isSaving, setIsSaving ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ invitationEmail, setInvitationEmail ] = useState<string>();
  const [ tryReCaptchaV2, setTryReCaptchaV2 ] = useState(false);
  const [ policyTitle, setPolicyTitle ] = useState<string>();
  const [ policyUrl, setPolicyUrl ] = useState<string>();

  const onChangeField = useCallback(
    (fieldName: keyof CreateSessionRequest, event: BaseInputOnChangeEvent) => {
      if (errors.base) {
        resetErrorBase();
      }
      const value = event.value;
      if (event.dirtyAndTouched) {
        validateField(fieldName, event.value);
      }
      const _session = { ...session } as CreateSessionRequest;
      _session[fieldName] = value as never;

      setSession(_session);

    }, [errors.base, resetErrorBase, session, validateField])

  const onBlurField = useCallback(
    (fieldName: keyof CreateSessionRequest, event: BaseInputOnChangeEvent) => {
      if (event.dirty) {
        validateField(fieldName, event.value);
      }
    }, [validateField])

  const save = useCallback((session: CreateSessionRequest) => {
    if (invitationEmail) {
      session.email = invitationEmail;
    }
    if (validateAll(session)) {
      setIsSaving(true);
      axios.post(`${apiBaseUrl}/session`, session)
        .then(() => window.location.href = "/")
        .catch(err => {
          if (err.response &&
              err.response.status as number === 400 &&
              err.response.data &&
              err.response.data.tryReCaptchaV2) {
            setTryReCaptchaV2(true);
          } else {
            setSession({...session, password: ""} as CreateSessionRequest)
            setTryReCaptchaV2(false);
            if (!hasValidationError(err)) {
              handleRequestError(err);
            }
            resetErrorAllFromServer(err.response.data);
          }
        })
        .finally(() => setIsSaving(false));
    }
  }, [invitationEmail, validateAll, resetErrorAllFromServer, handleRequestError]);

  const sigInDescription = (
    <div className="d-f j-c-c j-c-s-L f-s-1 f-w-600 p-t-0 p-t-4.5-L">
      {t("No account yet?")} <Link className="m-l-1" to="/sign-up">{t("Create account")}</Link>
    </div>
  )

  useEffect(() => {
    if (query.get("invitation")) {
      axios.get(`${apiBaseUrl}/invitation/${query.get("invitation")}`)
      .then((res) => {
        if (res.data.status !== "sign_in") {
          history.replace("/sign-in");
        } else {
          setInvitationEmail(res.data.email);
        }
      })
      .catch((err) => handleRequestError(err))
      .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [handleRequestError, history, query])

  const reCaptchaV3Callback = useCallback((token: string | void, refreshToken: TRefreshToken | void) => {
    let timeout: ReturnType<typeof setTimeout>;
    if (token && !tryReCaptchaV2) {
      setSession({...session, reCaptchaToken: token} as CreateSessionRequest)
      if (refreshToken) {
        timeout = setTimeout(() => refreshToken(), 1000 * 110);
      }
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, [session, tryReCaptchaV2]);

  const reCaptchaV2Callback = useCallback((token: string | false | Error) => {
    if (token) {
      save({...session, reCaptchaToken: token, reCaptchaV2: true} as CreateSessionRequest);
    }
  }, [save, session]);

  return (
    <>
      {loading || (!session.reCaptchaToken && !tryReCaptchaV2) ?
        <Loading />
      :
        <div className="min-h-100vh d-f fb-d-c">
          <div className="w-100% h-6 bg-c-nw h-4-L d-f">
            <div className="w-100% w-60%-L d-f j-c-e">
              <div className="max-w-60-L w-100% d-f p-t-1.5 p-t-3-M p-l-3-L p-t-1-L p-l-1.5-L j-c-c j-c-s-L">
                <a href="https://www.statsignal.dev">
                  <img className="h-3" src={logo} alt="Statsignal" />
                </a>
              </div>
            </div>
            <div className="w-40% bg-c-white d-n d-f-L j-c-s"></div>
          </div>
          <div className="d-f w-100% fb-a fb-d-c fb-d-r-L">
            <div className="fb-n w-100% w-60%-L bg-c-nw-M d-f a-i-s j-c-e a-i-c-L">
              <div className="max-w-60-L w-100% d-f fb-d-c p-h-1.5 p-h-3-L a-i-c a-i-s-L">
                <div className="d-f f-s-5-L f-s-3-M f-s-1.5 f-w-b j-c-c j-c-s-L p-t-2 p-t-4-M p-t-0-L p-b-0 p-b-3-M p-b-0-L">
                  {t("Sign In")}
                </div>
                <div className="d-f-L d-n">
                  {sigInDescription}
                </div>
              </div>
              <div className="d-f d-n-L"></div>
            </div>
            <div className="d-f fb-a fb-d-c w-100% w-40%-L bg-c-white a-i-c j-c-c  p-v-1 p-v-2-M p-v-0-L">
              <div className="d-f fb-d-c w-100% max-w-20 t-c-nb a-i-c a-i-s-L">
                <div className="p-t-0 p-t-0-L f-w-600">
                  {t("Continue with")}
                </div>
                <div className="d-f m-t-1">
                  <a href={`${authBaseUrl}/oauth/github/login`} className="m-r-1.5">
                    <IconGitHub size="large" />
                  </a>
                  <a href={`${authBaseUrl}/oauth/google/login`}>
                    <IconGoogle size="large"/>
                  </a>
                </div>
                <div className="d-f j-c-c j-c-s-L m-t-2.5-M m-t-2 f-w-600">
                  {query.get("invitation") ?
                    t("Sign in with your Email Address to accept or decline the invitation")
                  :
                    t("Sign in with your Email Address")
                  }
                </div>
                <form
                  className="d-f fb-d-c w-100% m-t-2.5-M m-t-2"
                  onSubmit={(e) => { e.preventDefault(); save({...session} as CreateSessionRequest); }}>
                  <div>
                    {invitationEmail ?
                      <div className="f-s-1 t-c-nb m-b-1">{invitationEmail}</div>
                    :
                      <TextInput
                        autoFocus={true}
                        placeholder={t("Email Address")}
                        value={session.email}
                        onChange={e => onChangeField("email", e)}
                        onBlur={e => onBlurField("email", e)}
                        error={errors.fields.email}
                        type="text"
                        material={true}
                        color="black" />
                    }
                  </div>
                  <div className="m-t-1">
                    <TextInput
                      placeholder={t("Password")}
                      value={session.password}
                      onChange={e => onChangeField("password", e)}
                      onBlur={e => onBlurField("password", e)}
                      error={errors.fields.password}
                      type="password"
                      material={true}
                      color="black" />
                  </div>
                  <div className="d-f j-c-c m-t-1 f-w-400">
                    <Link to="/forgot-password">{t("Forgot password?")}</Link>
                  </div>
                  <div className="w-100% m-t-2 d-f fb-d-c a-i-c bg-c-nw">
                    {isSaving ?
                      <Spinner type={"primary"} />
                    : tryReCaptchaV2 ?
                      <ReCaptchaV2 callback={reCaptchaV2Callback} />
                    :
                      <>
                        {errors.base ?
                          <div className="text-red t-c-r m-b-2">
                            {errors.base}
                          </div>
                        : null}
                        <Button type="primary" submit={true} fullWidth="always">
                          {t("Sign In")}
                        </Button>
                        <div className="m-t-1 t-c-gray m-t-2 f-s-0.9">
                          <Trans>
                            This site is protected by reCAPTCHA and the Google
                            <span onClick={() => {
                              setPolicyUrl("https://policies.google.com/privacy");
                              setPolicyTitle(t("Privacy Policy"));
                            }} className="c-p t-c-g"> Privacy Policy </span>
                            and
                            <span onClick={() => {
                              setPolicyUrl("https://policies.google.com/terms");
                              setPolicyTitle(t("Terms of Service"));
                            }} className="c-p t-c-g"> Terms of Service </span>apply.
                          </Trans>
                        </div>
                      </>
                    }
                  </div>
                </form>
              </div>
            </div>
            <div className="fb-n d-b d-n-L p-v-2 bg-c-nw m-v-0">
              {sigInDescription}
            </div>
          </div>
        </div>
      }

      {!tryReCaptchaV2 ?
        <ReCaptchaV3 action="submit" callback={reCaptchaV3Callback} />
      : null}

      <Modal
        closeWhenClickOutside={true}
        open={policyUrl !== undefined}
        onClose={ () => { setPolicyUrl(undefined); setPolicyTitle(undefined) } }
        noPadding={true}>
        <iframe
          className="d-f w-100% b-sb-s b-w-1px b-st-n b-sl-n b-sr-n b-c-w policies-content"
          title={policyTitle}
          src={policyUrl} />
      </Modal>
    </>
  )
}
import axios from 'axios';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState } from '../atoms';
import { apiBaseUrl } from '../Consts';
import { useHandleRequestError } from '../hooks';
import { Spinner } from '../ui';

export const EmailConfirmation: React.FC = () => {

  const user = useRecoilValue(userState);
  const { handleRequestError } = useHandleRequestError();

  const [ sendingEmail, setSendingEmail ] = useState(false);
  const [ emailSent, setEmailSent ] = useState(false);

  const match = useRouteMatch();
  const show = useMemo(() =>
    user &&
    user.requiresConfirmation &&
    Math.floor(DateTime.now().diff(DateTime.fromISO(user.createdAt)).as("days")) > 0, [user])

  const resendEmail = useCallback(() => {
    setSendingEmail(true);
    let timeout: ReturnType<typeof setTimeout>;
    axios.put(`${apiBaseUrl}/session/user/change-email/resend-confirmation`)
      .then(() => {
        setEmailSent(true);
        timeout = setTimeout(() => setEmailSent(false), 15000);
      })
      .catch(err => handleRequestError(err))
      .finally(() => setSendingEmail(false));

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, [handleRequestError]);

  if (!show) {
    return null;
  }

  return (
    <div className="p-v-1.5 p-h-2 bg-c-nw b-sb-s b-w-1px b-c-lightestGray">
      <div className="d-f j-c-c t-a-c">
        Your email address &quot;{user?.email}&quot; has been not confirmed. Please go to your inbox to confirm it.
      </div>
      <div className="d-f j-c-c m-t-0.5 f-w-300">
        {sendingEmail ?
          <Spinner />
        : emailSent ?
          <>Email sent, please check your inbox</>
        : <>
            <span onClick={resendEmail} className="t-c-g c-p">Resend</span>&nbsp;confirmation email
            {match.path !== "/profile" ?
              <>
                &nbsp;or&nbsp;
                <Link to="/profile#change-email-address">change</Link>&nbsp;email address
              </>
            : null}
          </>
        }
      </div>
    </div>
  )
}
import React, { useEffect, useCallback, useState } from 'react';
import axios from 'axios';
import { apiBaseUrl } from '../Consts';
import { BaseInputOnBlurEvent, BaseInputOnChangeEvent, Checkbox, CheckMark } from '../ui';
import { useTranslation } from 'react-i18next';

type ColorsProps = {
  color?: string
  error?: string | string[]
  onChange?: (e: BaseInputOnChangeEvent) => void
  onBlur?: (e: BaseInputOnBlurEvent) => void
}

export const Colors: React.FC<ColorsProps> = (props) => {

  const { t } = useTranslation();
  const [colors, setColors] = useState<string[]>()

  const [ dirty, setDirty ] = useState(false);
  const [ touched, setTouched ] = useState(false);

  const fetch = useCallback(() => {
    axios.get(`${apiBaseUrl}/colors`)
    .then(response => {
      setColors(response.data.colors);
    })
    .catch(error => console.log(error.data));
  }, []);

  const onChange = useCallback((color: string | undefined) => {
    const dirty = true;
    setDirty(dirty);
    if (props.onChange) {
      props.onChange(new BaseInputOnChangeEvent(color || "", dirty, touched))
    }
  }, [props, touched])

  const onBlur = useCallback(() => {
    const touched = true;
    setTouched(touched);
    if (props.onBlur) {
      props.onBlur(new BaseInputOnBlurEvent(props.color || "", dirty, touched))
    }
  }, [dirty, props])


  const onKeydown = useCallback((eventKey: string, color: string) => {
    if (eventKey === "Enter" || eventKey === " ") {
      onChange(color);
    }
  }, [onChange]);

  useEffect(() => fetch(), [fetch]);

  const renderError = (error: string) => {
    return <div className="t-c-r f-s-1 m-t-0.5">{ error }</div>
  }

  const renderErrors = (error?: string | string[]) => {
    if (error) {
      if (typeof error === "string") {
        return renderError(error);
      } else {
        return (error as string[]).map(e => renderError(e));
      }
    } else {
      return null;
    }
  }

  return (
    <div className={`d-f fb-d-c m-t-1 m-t-2-M ${props.error ? "input-error" : ""}`}>
      <div className="f-s-1 f-s-1.25-M">{t("Select Color")}</div>
      <div className={`m-t-0.5 t-a-c b-s-s-M b-w-1px-M b-c-lightestGray-M p-h-2-M p-t-2-M p-v-1 ${props.error ? 'b-c-r-M' : null}`}>
        <div className="d-f fb-w-w a-i-c j-c-c">
          <div className="p-0.25 p-0.75-M p-1-L" title={t("No Color")}>
            <Checkbox
              large={true}
              label={t("No Color")}
              onChange={() => onChange(undefined)}
              checked={props.color ? false : true} />
          </div>
          {colors?.map((color, i) =>
            <div
              key={i}
              onBlur={() => onBlur()}
              tabIndex={0}
              onKeyDown={(e) => onKeydown(e.key, color)}
              onClick={() => onChange(color)}
              className="p-0.25 p-0.75-M p-1-L c-p" title={color}>
              <div className="w-2 t-c-w w-2-M h-2 h-2-M b-r-4px d-f j-c-c a-i-c" style={{backgroundColor: color}}>
                {props.color === color ?
                  <CheckMark />
                : null}
              </div>
            </div>
          )}
        </div>
      </div>
      { renderErrors(props.error) }
    </div>
  )
}
import React, { useState, useCallback, useRef, useLayoutEffect, useMemo, useEffect } from 'react';
import { CreateCheckoutSessionForPaymentReq, Product } from '../model';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Loading, Modal, SecondaryButton } from '../ui';
import { useRecoilValue } from 'recoil';
import { productsState, teamState } from '../atoms';
import { useHandleRequestError, useWindowSize } from '../hooks';
import { ChevronLeft } from '../images/ChevronLeft';
import { ChevronRight } from '../images/ChevronRight';
import { PhoneAlertCreditsProduct } from './PhoneAlertCreditsProduct';
import axios from 'axios';
import { apiBaseUrl } from '../Consts';
import { isElementVisible, scrollToBeVisible } from '../Helper';

type PhoneAlertCreditsBuyProps = {
  balance: number,
  onClose: () => void
  onSave: () => void
}

export const PhoneAlertCreditsBuy: React.FC<PhoneAlertCreditsBuyProps> = (props) => {

  const { t } = useTranslation();
  const team = useRecoilValue(teamState);
  const [ isLoading, setIsLoading ] = useState(false);
  const productsRef = useRef<HTMLDivElement[]>([]);

  const { handleRequestError } = useHandleRequestError();
  const { productId } = useParams<any>();

  const windowSize = useWindowSize();

  const productsContainerRef = useRef<HTMLDivElement>(null);
  const allProducts = useRecoilValue(productsState);
  const products = useMemo(() =>
    allProducts?.filter(p => p.productType === "phone_alert"), [allProducts]);

  const product = useMemo<Product | undefined>(() => {
    if (products && products.length > 0) {
      return productId && products.some(p => p.id === parseInt(productId)) ?
        products.find(p => p.id === parseInt(productId)) as Product :
        products[1]
    } else {
      return undefined;
    }
  }, [productId, products]);

  const [ showLeftArrow, setShowLeftArrow ] = useState(false);
  const [ showRightArrow, setShowRightArrow ] = useState(true);

  const scrollToRight = useCallback(() => {
    if (productsContainerRef.current) {
      const ref = productsContainerRef.current;
      ref.scrollLeft = ref.scrollLeft + (ref.getBoundingClientRect().width * 0.8);
    }
  }, []);

  const scrollToLeft = useCallback(() => {
    if (productsContainerRef.current) {
      const ref = productsContainerRef.current;
      ref.scrollLeft = ref.scrollLeft - (ref.getBoundingClientRect().width * 0.8);
    }
  }, []);

  const onScroll = useCallback((e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = e.currentTarget;
    setShowLeftArrow(target.scrollLeft > 0);
    setShowRightArrow(target.scrollLeft + target.getBoundingClientRect().width < e.currentTarget.scrollWidth);
  }, []);

  const buy = useCallback(() => {
    if (team && product) {
      setIsLoading(true);
      const req = new CreateCheckoutSessionForPaymentReq();
      req.productPriceId = product.prices[0].id;

      axios.post(`${apiBaseUrl}/${team?.uuid}/phone-alert-credits/buy`, req)
        .then(response => window.location.href = response.data.nextUrl)
        .catch(err => {
          setIsLoading(false);
          handleRequestError(err)
        });
    }
  }, [handleRequestError, props, team, product])

  useLayoutEffect(() => {
    if (productsContainerRef.current) {
      setShowRightArrow(productsContainerRef.current.scrollWidth > productsContainerRef.current.clientWidth);
    }
  }, [windowSize]);

  useEffect(() => {
    if (products) {
      productsRef.current = productsRef.current.slice(0, products.length);
    }
 }, [products]);

 useEffect(() => {
  if (productsContainerRef.current && products && product) {
    const productIndex = products.findIndex(p => p.id === product.id);
    const productRef = productsRef.current[productIndex];
    if (!isElementVisible(productRef, productsContainerRef.current)) {
      scrollToBeVisible(productRef, productsContainerRef.current);
    }
  }
 }, [products, product, productsRef.current, productsContainerRef.current])


  return (
    <Modal
      open={true}
      onClose={props.onClose}
      title={t(`Add Balance for Phone Alerts`)}
      noPadding={true}>
      {isLoading ?
        <Loading />
      : !isLoading && team && product ?
        <form onSubmit={e => { e.preventDefault() }} className="d-f fb-d-c p-h-1 p-h-2-M">
          <div className="d-f m-t-2">
            <div className="d-f fb-a j-c-e f-w-300 m-r-0.5">{t("Phone alerts available")}:</div>
            <div className="d-f fb-n f-w-700">{props.balance}</div>
          </div>
          <div className="d-f m-t-2 f-s-1.5">
            {t("Phone alert credits to buy")}
          </div>
          <div className="d-f m-t-1.5">
            {t("There is no expiration date on the balance you add. So you will be able to send messages until your balance is depleted.")}
          </div>
          <div className="m-t-2 d-f">
            <div className="fb-n d-f w-1.5 a-i-c p-b-1.5">
              { showLeftArrow ?
                <div onClick={scrollToLeft} className="h-100% c-p d-f a-i-c">
                  <ChevronLeft />
                </div>
              : null
              }
            </div>

            {products ?
              <div
                onScroll={onScroll}
                className="fb-a d-f of-x-a p-b-1.5"
                ref={productsContainerRef}
                style={{scrollBehavior: 'smooth'}}>
                  {products.map((p, i) =>
                    <PhoneAlertCreditsProduct
                      ref={e => productsRef.current[i] = e as HTMLDivElement}
                      key={i}
                      product={p}
                      isFirst={i===0}
                      selected={product?.id === p.id} />
                  )}
              </div>
            : null}

            <div className="fb-n d-f a-i-c j-c-e w-1.5 p-b-1.5">
              { showRightArrow ?
                <div onClick={scrollToRight} className="h-100% c-p d-f a-i-c">
                  <ChevronRight />
                </div>
              : null
              }
            </div>
          </div>

          <div className="d-f fb-d-cr fb-d-r-M fb-a h-4-M a-i-c j-c-e p-v-2 p-h-2 p-h-2-M w-100%">
            <div onClick={props.onClose} className="d-f j-c-e p-r-2-M m-t-1 m-t-0-M">
              <SecondaryButton type={"primary"} onClick={props.onClose} />
            </div>
            <div className="d-f j-c-e w-100% w-initial-M">
              <Button type="primary" onClick={buy} fullWidth="mobile">
                {t("Buy Phone Alert Credits") }
              </Button>
            </div>
          </div>
        </form>
      : null
      }
    </Modal>
  );
}

import React from 'react';

type ResponseTimeLegendProps = {
  monitorType: string
}

export const ResponseTimeLegend: React.FC<ResponseTimeLegendProps> = (props) => {
  return (  
    <div className="d-f w-100% f-s-0.75 f-w-300 fb-w-w a-i-s">
      <div className="d-f fb-a m-b-0.5">
        <div className="bg-c-rt2 h-1 w-1"></div>
        <div className="t-c-rt2 p-h-0.5">Connection</div>
      </div>
      {props.monitorType === "http" || props.monitorType === "https" ?
        <>
          <div className="d-f fb-a m-b-0.5">
            <div className="bg-c-rt3 h-1 w-1"></div>
            <div className="t-c-rt3 p-h-0.5">TLS Handshake</div>
          </div>
          <div className="d-f fb-a m-b-0.5">
            <div className="bg-c-rt4 h-1 w-1"></div>
            <div className="t-c-rt4 p-h-0.5">Headers</div>
          </div>
        </>
      : null}
    </div>
  )}
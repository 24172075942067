import React from "react";
import { getCheckBoxZise } from "../Helper";

type BillingIconProps = {
  color?: string
  size?: string
}

export const BillingIcon: React.FC<BillingIconProps> = (props) => {
  const color = props.color
  const size = getCheckBoxZise(props.size);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="M19.3333 18H4.66667V15.6H19.3333V18ZM19.3333 13.2H4.66667V10.8H19.3333V13.2ZM19.3333 8.4H4.66667V6H19.3333V8.4ZM1 24L2.83333 22.2L4.66667 24L6.5 22.2L8.33333 24L10.1667 22.2L12 24L13.8333 22.2L15.6667 24L17.5 22.2L19.3333 24L21.1667 22.2L23 24V0L21.1667 1.8L19.3333 0L17.5 1.8L15.6667 0L13.8333 1.8L12 0L10.1667 1.8L8.33333 0L6.5 1.8L4.66667 0L2.83333 1.8L1 0V24Z"
      />
    </svg>
  )
}

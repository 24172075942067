import { atom, selector } from "recoil";
import { TimeZone } from "../model";
import { Settings } from "luxon";

const _userTimeZoneState = atom<TimeZone | undefined>({
  key: '_userTimeZoneState',
  default: undefined,
});

export const userTimeZoneState = selector<TimeZone | undefined>({
  key: "userTimeZoneState",
  get: ({get}) => get(_userTimeZoneState),
  set: ({set}, timeZone) => {
    set(_userTimeZoneState, timeZone);
    if (timeZone) {
      Settings.defaultZone = (timeZone as TimeZone).systemName;
    }
  }
})
import React from 'react';

export const IconCall = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path
      d="M20 16H22.6667C22.6667 12.32 19.68 9.33333 16 9.33333V12C18.2133 12 20 13.7867 20 16ZM25.3333 16H28C28 9.37333 22.6267 4 16 4V6.66667C21.16 6.66667 25.3333 10.84 25.3333 16ZM26.6667 20.6667C25 20.6667 23.4 20.4 21.9067 19.9067C21.7733 19.8667 21.6267 19.84 21.4933 19.84C21.1467 19.84 20.8133 19.9733 20.5467 20.2267L17.6133 23.16C13.84 21.24 10.7467 18.16 8.82667 14.3733L11.76 11.4267C12.1333 11.08 12.24 10.56 12.0933 10.0933C11.6 8.6 11.3333 7 11.3333 5.33333C11.3333 4.6 10.7333 4 10 4H5.33333C4.6 4 4 4.6 4 5.33333C4 17.8533 14.1467 28 26.6667 28C27.4 28 28 27.4 28 26.6667V22C28 21.2667 27.4 20.6667 26.6667 20.6667ZM6.70667 6.66667H8.70667C8.8 7.84 9 9 9.30667 10.1067L7.70667 11.72C7.17333 10.1067 6.82667 8.42667 6.70667 6.66667V6.66667ZM25.3333 25.2933C23.5733 25.1733 21.8667 24.8267 20.2667 24.28L21.8667 22.68C23 23 24.16 23.2 25.3333 23.28V25.2933Z"
      fill="black"
    />
    </svg>
  )
}
import React, { useMemo } from 'react';
import { StatusColors, MonitorStatusType } from '../../Consts';
import { Monitor } from '../../model';
import { statusToColor } from '../../Helper';
import { Link } from 'react-router-dom';
import { Loading } from '../loading/Loading';
import { useRecoilValue } from 'recoil';
import { teamState } from '../../atoms';
import { DateTime } from 'luxon';

export type MonthProps = {
  monitor: Monitor
  regionSlug?: string
  year: number
  month: number
  days: Day[]
}

export class Day {
  constructor(
    public isActive: boolean,
    public date: DateTime,
    public status: MonitorStatusType[]) {}
}

export const Month: React.FC<MonthProps> = (props) => {
  const team = useRecoilValue(teamState);

  const date = useMemo(() => DateTime.fromObject({year: props.year, month: props.month}) , [props]);

  if (!team || !props.monitor) {
    return <Loading />
  } else {
    return (
      <React.Fragment>
        <div className="w-7.5 h-7 j-c-c m-0.5">
          <div className="f-s-0.75 t-c-nb p-t-0.5 p-h-0.25 t-a-r-M">{ date.toFormat("MMM yyyy") }</div>
          <div className="d-f fb-a fb-w-w p-h-0.25 p-t-0.25 a-i-c">
            {[...Array(42)].map((_, i) =>
              {
                const calendarDay = (i + 1) - date.weekday;
                const indexDay = calendarDay - props.days[0].date.day;
                if (i >= date.weekday && calendarDay <= date.endOf("month").day) {
                  if (indexDay > -1 && indexDay < props.days.length) {

                    const pathDate = props.days[indexDay].date.hasSame(DateTime.now(), "day") ?
                      "today" : props.days[indexDay].date.toFormat("yyyy-MM-dd");

                    const to = `/${team.slug}/monitors/${props.monitor.uuid}/${pathDate}` + (
                      props.regionSlug ? `/${props.regionSlug}` : ''
                    );

                    return <Link
                      to={to}
                      key={i}
                      className={props.days[indexDay].isActive ?
                        "w-1 h-1 b-r-0.5"
                        : "w-0.5 h-0.5 b-r-0.25 m-0.25"}
                      style={{
                        backgroundColor: (props.days[indexDay].status.length === 1 ?
                          statusToColor(props.days[indexDay].status[0]) :
                          StatusColors.Activity)
                      }}></Link>
                  } else {
                    return <div key={i} className="w-0.5 h-0.5 bg-c-w b-s-s b-w-1px b-c-lightestGray b-r-0.25 m-0.25"></div>  
                  }
                } else {
                  return <div key={i} className="w-0.5 h-0.5 bg-c-w b-r-0.25 m-0.25"></div>
                }
              }
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
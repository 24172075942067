import React from 'react';
import { Loading } from '../ui';
import { SettingsTeamName } from './SettingsTeamName';
import { SettingsTeamDelete } from './SettingsTeamDelete';
import { useTranslation } from 'react-i18next';
import { isOwnerState, teamState } from '../atoms';
import { useRecoilValue } from 'recoil';

export const SettingsTeam: React.FC = () => {

  const { t } = useTranslation();
  const team = useRecoilValue(teamState);
  const isOwner = useRecoilValue(isOwnerState);

  if (!team) {
    return <Loading />
  } return (
    <div className="d-f fb-d-c w-100% max-w-40.5 max-w-45-L m-t-1 m-t-3-M m-b-1-M">
      <SettingsTeamName />

      {isOwner ?
        <div className="d-f fb-d-c m-t-2 m-t-5-M">
          <div className="h-2 h-3-M f-s-1 f-s-1.25-M t-c-r">
            {t("Danger Zone")}
          </div>
          <SettingsTeamDelete />
        </div>
      : null
      }
    </div>
  );
}

import React, { useEffect, useCallback, useState, useMemo } from 'react';
import axios from 'axios';
import { Page, Paginator as ModelPaginator } from '../model';
import { apiBaseUrl, DefaultPageSize, statBaseUrl } from "../Consts";
import { Empty } from '../empty';
import { Loading, MultiSelectForStatus, MultiSelectState, MenuInRow, Modal, Paginator, SearchResource, SortResource, CheckboxWithImage } from '../ui';
import { Link, useHistory } from 'react-router-dom';
import { RemoveButton } from '../images';
import { PageEdit } from '../page-edit';
import { Status } from '../status';
import { useQuery } from '../hooks/UseQuery';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { isOwnerOrMemberState, lastMonitorsUpdatedState, teamState } from '../atoms';
import { useAdjustPage, useHandleRequestError } from '../hooks';
import { PageSelectors } from './PagesSelector';
import { NewTabIcon } from '../images/NewTabIcon';

export const Pages: React.FC = () => {

  const protocol = window.location.protocol;

  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const team = useRecoilValue(teamState);
  const isOwnerOrMember = useRecoilValue(isOwnerOrMemberState);
  const lastMonitorsUpdated = useRecoilValue(lastMonitorsUpdatedState);
  const baseUrl = useMemo(() => team ? `/${team.slug}/pages` : undefined, [team]);

  const [ editingSelector, setEditingSelector ] = useState<Page>();
  const [ removingSelector, setRemovingSelector ] = useState<Page>();
  const [ selectors, setSelectors ] = useState<PageSelectors>();
  const [ paginator, setPaginator ] = useState<ModelPaginator>();
  const { handleRequestError } = useHandleRequestError()

  const [ isSelectingForRemove, setIsSelectingForRemove ] = useState<boolean>(false);

  const queryText = useMemo(() => query.get("text") ?? undefined, [query]);
  const queryOrder = useMemo(() => query.get("order") ?? undefined, [query]);
  const queryDescending = useMemo(() => query.get("descending") ? query.get("descending") === "true" : false, [query]);
  const [updatingQuery, setUpdatingQuery] = useState(false);
  const { adjustPage, shouldFetchAndReset } = useAdjustPage(baseUrl);

  const fetch = useCallback(() => {
    if (team) {
      axios.get(`${apiBaseUrl}/${team.uuid}/pages`, {params: {
        text: query.get("text"),
        order: query.get("order"),
        descending: query.get("descending"),
        recordOffset: query.get("page") ?
          (parseInt(query.get("page") as string) - 1) * DefaultPageSize : 0
      }})
        .then(response => {
          setSelectors(new PageSelectors(response.data.pages as Page[]));
          setPaginator(response.data.paginator);
          adjustPage(response.data.paginator as ModelPaginator);
        })
        .catch(err => handleRequestError(err))
        .finally(() => setUpdatingQuery(false));
    }

  }, [team, query, adjustPage, handleRequestError]);

  const onUpdate = useCallback((page: Page) => {
    if (selectors) {
      setSelectors(selectors.updateFromRemote([page]));
    }
    setEditingSelector(undefined);
  }, [selectors]);


  const execRemove = useCallback(() => {
    if (team && removingSelector) {
      axios.delete(`${apiBaseUrl}/${team.uuid}/pages/${removingSelector.uuid}`,
        {
          params: {
            text: query.get("text"),
            order: query.get("order"),
            descending: query.get("descending"),
            recordOffset: query.get("page") ?
              (parseInt(query.get("page") as string) - 1) * DefaultPageSize : 0
          }
        })
        .then(response => {
          setSelectors(new PageSelectors(response.data.pages as Page[]));
          setPaginator(response.data.paginator);
          adjustPage(response.data.paginator as ModelPaginator);
        })
        .catch(err => handleRequestError(err));
        setRemovingSelector(undefined);
      }
  }, [team, removingSelector, query, adjustPage, handleRequestError]);

  const removePages = useCallback(() => {
    if (team && selectors) {
      const uuids = selectors.list.filter(ps => ps.selected).map(ps => ps.page.uuid);
      axios.delete(`${apiBaseUrl}/${team.uuid}/pages`, {
          params: {
            uuids: uuids,
            text: query.get("text"),
            order: query.get("order"),
            descending: query.get("descending"),
            recordOffset: query.get("page") ?
              (parseInt(query.get("page") as string) - 1) * DefaultPageSize : 0
          }
        })
        .then(response => {
          setSelectors(new PageSelectors(response.data.pages as Page[]));
          setPaginator(response.data.paginator);
          adjustPage(response.data.paginator as ModelPaginator);
        })
        .catch(err => handleRequestError(err));
        setIsSelectingForRemove(false)
    }
  }, [team, selectors, query, adjustPage, handleRequestError]);

  const changeQuery = useCallback((queryText?: string, queryOrder?: string, queryDescending?: boolean) => {
    if (baseUrl) {
      setUpdatingQuery(true);
      const _query: string[] = []
      if (queryText) {
        _query.push(`text=${encodeURIComponent(queryText)}`);
      } else {
        if (queryOrder) {
          _query.push(`order=${queryOrder}`);
        }
        if (queryDescending) {
          _query.push(`descending=${queryDescending.toString()}`);
        }
      }
      history.push(`${baseUrl}${_query.length > 0 ? "?"+_query.join("&") : ""}`);
    }
  }, [baseUrl, history]);

  const onCancelEdit = useCallback(() => setEditingSelector(undefined), []);

  useEffect(() =>  {
    if (shouldFetchAndReset()) {
      fetch();
    }
  }, [fetch, shouldFetchAndReset]);

  useEffect(() => {
    if (lastMonitorsUpdated) {
      setSelectors(selectors => selectors?.updateMonitorsFromRemote(lastMonitorsUpdated.map(mse => mse.monitor)));
    }
  }, [lastMonitorsUpdated]);

  if (!selectors || !team || !paginator) {

    return <Loading />

  } else if (selectors.length === 0 && !queryText && !updatingQuery) {

    return (
      <Empty
        description={
          <span>
            <Trans t={t} i18nKey="No pages <1/> added yet">
              No pages<br/>added yet
            </Trans>
          </span>}
        actionTitle={t("Add a page")}
        onAction={() => history.push(`/${team.slug}/pages/new`)} />
    )
  } else {
    return (
      <React.Fragment>
        <div className="d-f fb-d-c m-h-a m-b-1 m-b-2-M p-h-0.5 p-h-2-M w-100% max-w-66-L t-c-nb">
        <div className="d-f p-l-0.5 p-v-1.5 m-t-0.5 fb-d-cr fb-d-r-L a-i-c-L">
            <div className="d-n d-f-L f-s-2 f-w-300 fb-n">
              {t("Pages")}
            </div>
            <div className="fb-a w-100% p-h-2-L m-t-2 m-t-0-L">
              <SearchResource
                initValue={queryText}
                onChange={(value) => value !== queryText ? changeQuery(value) : null} />
            </div>
            <div className="d-f a-i-c fb-n">
              <div className="d-n-L f-s-1.5 f-w-300 fb-a">
                {t("Pages")}
              </div>
              <div className="fb-n">
                <SortResource
                  options={{
                    "name": t("Name"),
                    "lastCreated": t("Last created"),
                    "lastModified": t("Last modified"),
                  }}
                  desc={queryDescending}
                  order={queryOrder ?? "name"}
                  disabled={queryText !== undefined}
                  onChange={(order: string, desc: boolean) => { changeQuery(undefined, order, desc) }} />
              </div>
            </div>
          </div>
          {selectors.length === 0 ?
            <div className="d-f h-5 a-i-c f-s-1 p-l-0.5">
              {t("No result. Try your search again with more specific keywords.")}
            </div>
          :
            <>
              <div className="d-f h-5 a-i-c b-sb-s b-c-lightestGray b-wb-0.1 f-w-b">
                <div className="d-f j-c-c w-3">
                  {isOwnerOrMember ?
                    <MultiSelectForStatus
                      circle={false}
                      onChange={() => setSelectors(selectors.changeMultiSelectState())}
                      state={selectors.multiSelectState} />
                    : null
                  }
                </div>
                <div className="d-b fb-a p-l-0.25 t-c-b">
                  {selectors.multiSelectState === MultiSelectState.None ?
                    t("Page")
                  :
                  <div className="d-f">
                    <div onClick={() => setIsSelectingForRemove(true)} title={t("Delete")} className="d-f c-p t-c-nb">
                      <RemoveButton />
                    </div>
                  </div>
                  }
                </div>
                <div className="d-n d-f-M w-20-M w-25-L t-c-b">{t("Items")}</div>
                <div className="d-f w-2"></div>
              </div>

              { selectors?.list.map((ps, i) => {
                  const pageUrl = ps.page.customDomain ?
                    `${protocol}//${ps.page.customDomain}`
                  :
                    `${statBaseUrl}/${ps.page.slug}`;

                  return <React.Fragment key={i}>
                    <div className="d-f a-i-s b-sb-s b-c-lightestGray b-wb-0.1 p-v-0.75">
                      <div className="d-f fb-a a-i-s fb-d-c fb-d-r-M">
                        <div className="d-f w-100% fb-d-c fb-a p-l-0.5">
                          <div className="d-f fb-n a-i-c">
                            <div className="d-f fb-n w-3 a-i-c">
                              <CheckboxWithImage
                                background={ps.page.faviconUrl}
                                size= {"large"}
                                color={"#4a4a4a"}
                                disabled={!isOwnerOrMember}
                                type={"page"}
                                checked={ps.selected}
                                onChange={isOwnerOrMember ? () => setSelectors(selectors.toggle(ps)) : undefined}
                              />
                            </div>
                            <div className="d-f fb-a fb-d-c a-i-s m-r-1">
                              <div className="ws-n of-h t-o-e max-w-100%">
                                <Link to={`/${team.slug}/monitors?pageUuid=${ps.page.uuid}`} className="t-c-nb">
                                  { ps.page.name }
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="d-f m-r-1">
                            <a className="d-f max-w-100% a-i-c" href={pageUrl} rel="noreferrer" target="_blank">
                              <div className="m-l-3 of-h">
                                <span className="ws-n t-o-e of-h t-c-nb f-w-300">{pageUrl}</span>
                              </div>
                              <div className="d-f p-l-0.25 a-i-c"><NewTabIcon /></div>
                            </a>
                          </div>
                          { !ps.expanded && ps.page.statuses?.length > 0 ?
                            <div onClick={() => setSelectors(selectors.expand(ps))} className="d-n-M m-l-3 m-t-0.5 f-s-0.85 t-c-g c-p">
                              {ps.page.statuses[0].type === "monitor" ?
                                t("Show Monitors")
                              :
                                t("Show Groups")
                              }
                            </div>
                          : null}
                        </div>
                        <div className={`fb-n fb-w-w ${ps.expanded ? 'd-f' : 'd-n'} d-f-M w-20-M w-25-L m-t-0.5 m-t-0.25-M m-l-3.5 m-l-0-M`}>
                          { ps.page.statuses?.length > 0 ?
                            ps.page.statuses?.map((s, i) =>
                              <div key={i} className="d-f fb-n fb-d-r p-r-1.5 max-w-10 p-v-0.2">
                                <div className="d-f fb-n a-i-c m-r-0.5">
                                    <Status status={s.status} type={s.type} size={"mini"} />
                                </div>
                                { s.type === "monitor" ?
                                  <Link to={`/${team.slug}/monitors/${s.uuid}`}
                                    className="fb-a f-s-0.75 ws-n of-h t-o-e t-c-nb" title={s.name}>
                                      { s.name }
                                  </Link>
                                :
                                  <Link to={`/${team.slug}/monitors?groupUuid=${s.uuid}`}
                                    className="fb-a f-s-0.75 ws-n of-h t-o-e t-c-nb" title={s.name}>
                                      { s.name }
                                  </Link>
                                }
                              </div>
                            )
                          : <span className="t-c-gray f-s-0.75">{t("No Items")}</span>
                          }
                        </div>
                      </div>
                      <div className="d-f fb-n w-2" title={t("Options")}>
                        <MenuInRow items={[
                          {title: t("Monitors"), link: `/${team.slug}/monitors?pageUuid=${ps.page.uuid}`},
                          { separator: true, },
                          {title: t("Edit"), onClick: () => setEditingSelector(ps.page), visible: isOwnerOrMember},
                          {title: t("Delete"), onClick: () => setRemovingSelector(ps.page), visible: isOwnerOrMember},
                        ]} />
                      </div>
                    </div>
                  </React.Fragment>
                }
              )}
            </>
          }
        </div>
        <div className="d-f j-c-c">
          <Paginator
            recordOffset={paginator.recordOffset}
            recordsCount={paginator.recordsCount}
            pageSize={paginator.pageSize}
            url={`${history.location.pathname}${history.location.search}`} />
        </div>
 
        { editingSelector ?
          <PageEdit
            pageUuid={editingSelector.uuid}
            onUpdate={onUpdate}
            onCancel={onCancelEdit} />
          : null
        }

        { removingSelector ?
          <Modal
            open={removingSelector !== undefined}
            onClose={ () => setRemovingSelector(undefined) }
            title={t("Delete Page")}
            description={ removingSelector.name }
            secondaryActions={ [ { title: t("Cancel"), onAction: () => setRemovingSelector(undefined) } ] }
            primaryAction={ { title: t("Delete Page"), onAction: execRemove, type: "destructive" } }>
            <div className="p-2">
              <div className="f-s-1.5 f-w-300 t-c-b">
                {t("You can't reverse this action!")}
              </div>
              <div className="f-s-1 f-w-300 m-t-0.5">
                {t("The stats for this Page will be archived.")}
              </div>
            </div>
          </Modal>
          : null
        }

        { isSelectingForRemove ?
          <Modal
            open={isSelectingForRemove}
            onClose={ () => setIsSelectingForRemove(false) }
            title={t("Delete Pages")}
            secondaryActions={ [ { title: t("Cancel"), onAction: () => setIsSelectingForRemove(false) } ] }
            primaryAction={ { title: t("Delete Pages"), onAction: removePages, type: "destructive" } }>
            <div className="p-2">
              <div className="f-s-1.5 f-w-300 t-c-b">
                {t("You can't reverse this action!")}
              </div>
              <div className="f-s-1 f-w-300 m-t-0.5">
                {t("Removing pages won't remove their monitors or groups.")}
              </div>
            </div>
          </Modal>
          : null
        }
      </React.Fragment>
    );
  }
}
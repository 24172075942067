import React, { useState, useCallback, useEffect } from 'react';
import { Tabs, TabsOption } from '../ui';
import { BillingInformationComponent as InformationComponent } from './Information';
import { useRouteMatch, Route, Switch, useHistory, Redirect } from 'react-router-dom';
import { Invoices } from './Invoices';
import { PhoneAlertCredits } from './PhoneAlertsCredits';

const tabsOptions: TabsOption[] = [
  { id: "invoices", title: "Invoices" },
  { id: "information", title: "Payment Method & Billing Information" },
  { id: "phone-alert-credits", title: "Phone Alert Credits" }
]

export const Billing = () => {

  const match = useRouteMatch();

  const history = useHistory();
  const [selectedTabOptionIndex, setSelectedTabOptionIndex] = useState(-1);

  const selectedTabOption = useCallback((index: number) => {
    history.push(`${match.path}/${tabsOptions[index].id}`);
  }, [history, match.path]);

  useEffect(() => {
    const pathParts = history.location.pathname.split('/');
    let tabId = pathParts[pathParts.length - 1];
    if (tabId.length === 0) {
      tabId = pathParts[pathParts.length - 2];
    }
    const index = tabsOptions.findIndex(t => t.id === tabId);
    setSelectedTabOptionIndex(index);
  }, [history.location]);

  return (
    <div className="d-f w-100% max-w-66 fb-d-c m-h-a p-h-1 p-h-2-M t-c-nb p-b-1 p-b-2-M">
      <div className="f-w-300 f-s-1.5 f-s-2-M m-t-1.5">
        Billing
      </div>
      <div>
        <Tabs
          options={ tabsOptions }
          selected={ selectedTabOptionIndex }
          onSelect={ selectedTabOption } />
      </div>
      <div className="d-f j-c-c m-h-a w-100%">
        <Switch>
          <Route path={`${match.path}`} exact><Redirect to={`${match.path}/invoices`} /></Route>
          <Route path={`${match.path}/invoices`} exact component={Invoices} />
          <Route path={`${match.path}/information`} exact component={InformationComponent} />
          <Route path={`${match.path}/phone-alert-credits`} exact component={PhoneAlertCredits} />
          <Route path={`${match.path}/phone-alert-credits/buy`} exact component={PhoneAlertCredits} />
          <Route path={`${match.path}/phone-alert-credits/buy/:productId`} exact component={PhoneAlertCredits} />
          <Route path={`${match.path}/phone-alert-credits/checkout/:checkoutId`} exact component={PhoneAlertCredits} />
        </Switch>
      </div>
    </div>
  );
}

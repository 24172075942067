import React, { useCallback, useState } from 'react';
import { BaseInputOnChangeEvent, BaseInputOnBlurEvent } from '../event/BaseInputEvent';

type SelectionButtonProps = {
  checked?: boolean
  label: string
  onChange?: (event: BaseInputOnChangeEvent) => void
  onBlur?: (event: BaseInputOnBlurEvent) => void
  disabled?: boolean
}

export const SelectionButton: React.FC<SelectionButtonProps> = (props) => {

  const [ dirty, setDirty ] = useState(false);
  const [ touched, setTouched ] = useState(false);

  const onChange = useCallback(() => {
    const checked = !props.checked;
    const dirty = true;
    setDirty(dirty);
    if (props.onChange) {
      props.onChange(new BaseInputOnChangeEvent(checked, dirty, touched))
    }
  }, [props, touched]);

  const onBlur = useCallback(() => {
    setTouched(true);
    if (props.onBlur) {
      props.onBlur(new BaseInputOnBlurEvent(props.checked || false, dirty, true));
    }
  }, [props, dirty]);

  const classes: string = (
    props.checked ?
      props.disabled ? "bg-c-nw t-c-lightGray b-c-lightestGray" : "c-p bg-c-g t-c-w b-c-g"
    :
      props.disabled ? "bg-c-nw b-c-lightestGray t-c-lightGray" : "c-p bg-c-w b-c-nb t-c-nb"
  );

  return (
    <div
      tabIndex={0}
      onClick={props.disabled ? undefined : onChange}
      onBlur={onBlur}
      className={`ws-n t-a-c f-s-0.75 b-r-0.25 b-s-s b-w-1px p-v-0.25 p-h-0.75 h-1.75 ${classes}`}>
        {props.label}
    </div>
  );
}

import React, { useState, useCallback } from 'react';
import { BaseInputOnChangeEvent, BaseInputOnBlurEvent } from '../event/BaseInputEvent';

type Alignment = 'left' | 'center' | 'right';

type TextAreaProps = {
  label?: string
  value?: string
  autoFocus?: boolean
  placeholder?: string
  align?: Alignment
  error?: string | string[]
  disabled?: boolean
  onChange?: (event: BaseInputOnChangeEvent) => void
  onBlur?: (event: BaseInputOnBlurEvent) => void
}
export const TextArea: React.FC<TextAreaProps> = (props) => {

  const [ dirty, setDirty ] = useState(false);
  const [ touched, setTouched ] = useState(false);

  let alignClass: string;

  switch (props.align) {
    case "center":
      alignClass = "t-a-c";
      break;
    case "right":
      alignClass = "t-a-r";
      break;
    case "left":
    default:
      alignClass = "t-a-l"
  }

  const errorClass = props.error ? 'b-c-r' : null;

  const onChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDirty(true);
    if (props.onChange) {
      props.onChange(new BaseInputOnChangeEvent(event.currentTarget.value, true, touched));
    }
  }, [props, touched]);

  const onBlur = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTouched(true);
    if (props.onBlur) {
      props.onBlur(new BaseInputOnBlurEvent(event.currentTarget.value, dirty, true));
    }
  }, [props, dirty]);

  const renderError = (error: string) => {
    return <div className="t-c-r f-s-1 m-t-0.5">{ error }</div>
  }

  const renderErrors = (error?: string | string[]) => {
    if (error) {
      if (typeof error === "string") {
        return renderError(error);
      } else {
        return (error as string[]).map(e => renderError(e));
      }
    } else {
      return null;
    }
  }

  return (
    <div className={`${props.error ? "input-error" : ""}`}>
      {props.label ?
        <div className="f-s-1 f-s-1.25-M">{ props.label }</div>
        : null
      }
      <textarea
        autoFocus={props.autoFocus}
        value={props.value || ''}
        placeholder={props.placeholder}
        onChange={ onChange }
        onBlur={ onBlur }
        disabled={props.disabled}
        className={`f-f-r min-w-100% max-w-100% m-t-0.5 p-1 f-s-1 b-w-1px b-s-s ${alignClass} ${errorClass} ${props.disabled ? "t-c-lightGray b-c-lightestGray" : "b-c-gray"}`} />
      { renderErrors(props.error) }
    </div>
  );
}

import React from "react";
import { getCheckBoxZise } from "../Helper";


type AlertIconProps = {
  color?: string
  size?: string
}

export const AlertIcon: React.FC<AlertIconProps> = (props) => {
  const color = props.color ? props.color : "#4A4A4A"
  const size = getCheckBoxZise(props.size ? props.size : "small");

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M10.8 15.6h2.4V18h-2.4v-2.4zm0-9.6h2.4v7.2h-2.4V6zm1.188-6C5.364 0 0 5.376 0 12s5.364 12 11.988 12C18.624 24 24 18.624 24 12S18.624 0 11.988 0zM12 21.6A9.597 9.597 0 012.4 12c0-5.304 4.296-9.6 9.6-9.6 5.304 0 9.6 4.296 9.6 9.6 0 5.304-4.296 9.6-9.6 9.6z"
      ></path>
    </svg>
  );
}
import React, { useState, useCallback, useMemo } from 'react';
import { CreateApiTokenReq, ApiToken, Paginator } from '../model';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { apiBaseUrl } from "../Consts";
import { Errors } from '../validator/Validator';
import { SettingsApiTokenForm } from './SettingsApiTokenForm';
import { useTranslation } from 'react-i18next';
import { Modal } from '../ui';
import { useRecoilValue } from 'recoil';
import { teamState } from '../atoms';
import { useHandleRequestError } from '../hooks';

type SettingsApiTokenNewProps = {
  onCancel: () => void
  onSave: (apiTokens: ApiToken[], paginator: Paginator) => void
  disallowSetMonitors?: boolean
}

export const SettingsApiTokenNew: React.FC<SettingsApiTokenNewProps> = (props) => {

  const { t } = useTranslation();
  const team = useRecoilValue(teamState);
  const { handleRequestError } = useHandleRequestError();
  const [ isSaving, setIsSaving ] = useState(false);

  const [ errorsOnServer, setErrorsOnServer ] = useState<Errors<ApiToken>>();
  const apiToken = useMemo(() => new ApiToken(), []);

  const history = useHistory();

  const onSave = useCallback((apiToken: ApiToken) => {
    if (team) {
      const request = new CreateApiTokenReq();
      request.name = apiToken.name;
      request.allowWrite = apiToken.allowWrite;
      setIsSaving(true)

      axios.post(`${apiBaseUrl}/${team.uuid}/api-tokens`, request)
        .then(response => props.onSave(
          response.data.apiTokens,
          response.data.paginator))
        .catch(err => {
          handleRequestError(err);
          setErrorsOnServer(err.response.data);
        })
      .finally(() => setIsSaving(false));
    }
  }, [team, props, handleRequestError]);

  const onCancel = useCallback(() => {
    if (props.onCancel) {
      props.onCancel()
    } else if (history.length > 2) {
      history.goBack();
    } else {
      history.push(`/${team?.slug}/api-tokens`)
    }
  }, [history, team, props])

  return (
    <Modal
      open={true}
      onClose={props.onCancel}
      title={t("New API Token")}
      noPadding={true}>
      <div className="p-h-1 p-h-2-M">
        <SettingsApiTokenForm
          errorsOnServer={errorsOnServer}
          onSave={onSave}
          onCancel={onCancel}
          apiToken={apiToken}
          isSaving={isSaving} />
      </div>
    </Modal>
  );
}

import React, { ReactNode } from 'react';
import { AffixType } from './TextInputAffixType';

export type TextInputAffixButtonProps = {
  onClick?: () => void
  type?: AffixType
  submit?: boolean,
  children?: ReactNode
}

export const TextInputAffixButton: React.FC<TextInputAffixButtonProps> = (props) => {

  let typeClass: string;
  switch (props.type) {
    case "destructive":
      typeClass = "t-c-r";
      break;
    case "primary":
      typeClass = "t-c-g";
      break;
    case "normal":
    default:
      typeClass = "t-c-b";
  }

  return (
    <button
      type={props.submit ? "submit" : "button"}
      onClick={props.onClick}
      className={`d-f c-p p-h-1 bg-c-lightestGray b-w-0px h-100% d-f a-i-c ${typeClass}`}>
      {props.children}
    </button>
  );
}
import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IconType } from '../ui/types';
import { Icon } from '../ui';
import { SidebarOptionColors } from '../Consts';

type SidebarOptionProps = {
  title: string,
  collapsed?: boolean,
  pathname: string,
  iconType: IconType
}

export const SidebarOption: React.FC<SidebarOptionProps> = (p) => {

  const location = useLocation();
  const selected = useMemo(() => {
    const pathnameSplitted = location.pathname.split("/");
    if (pathnameSplitted.length <= 2) {
      return location.pathname === p.pathname;
    } else {
      return pathnameSplitted.splice(0, 3).join("/") === p.pathname;
    }
  }, [location.pathname, p.pathname]);
  const horizontalPadding = p.collapsed ?
    {paddingLeft: "1rem", paddingRight: "1rem"} :
    {paddingLeft: "2rem", paddingRight: "2rem"}

  return (
    <Link to={p.pathname} className="h-4.5 d-f a-i-c" style={horizontalPadding}>
      <div className={`${p.collapsed ? 'fb-a' : 'fb-n'} d-f a-i-c j-c-c w-1.5`}>
        <Icon type={p.iconType} color={selected ? SidebarOptionColors.Disabled : SidebarOptionColors.Enabled} />
      </div>
      { !p.collapsed ?
        <div className={`fb-a p-l-1 d-f db-ai-c ${selected ? 't-c-nb' : 't-c-g'}`}>
          {p.title}
        </div>
      :
        null
      }
    </Link>
  )
}
import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import { TextInput, BaseInputOnChangeEvent, TextInputAffixButton, Spinner } from '../ui';
import { UpdateTeamNameReq } from '../model';
import { useValidator, Validations } from '../validator';
import { isFieldStringEmpty } from '../Validations';
import { apiBaseUrl } from "../Consts";
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isOwnerState, teamState } from '../atoms';
import { useHandleRequestError } from '../hooks';

const v = new Validations(new UpdateTeamNameReq());
v.addField("name", (v: any) => isFieldStringEmpty(v));

export const SettingsTeamName: React.FC = () => {

  const { t } = useTranslation();
  const [team, setTeam] = useRecoilState(teamState);
  const isOwner = useRecoilValue(isOwnerState);
  const { handleRequestError } = useHandleRequestError();

  const [ updateTeamNameReq, setUpdateTeamNameReq ] = useState<UpdateTeamNameReq>();
  const [ isUpdatingTeamName, setIsUpdatingTeamName ] = useState<boolean>();

  const { errors, validateField, resetErrorField } = useValidator(v);

  const onChangeName = useCallback((event: BaseInputOnChangeEvent) => {
    validateField("name", event.value);
    setUpdateTeamNameReq({ ...updateTeamNameReq, name: event.value as string });
  }, [validateField, updateTeamNameReq])

  const saveName = useCallback(() => {
    if (team  && team.name.trim() !== updateTeamNameReq?.name.trim() && validateField("name", updateTeamNameReq?.name)) {
      setIsUpdatingTeamName(true);
      axios
        .put(`${apiBaseUrl}/${team.uuid}/team/name`, updateTeamNameReq)
        .catch(err => {
          handleRequestError(err);
          resetErrorField("name", err.response.data.name)
        })
        .finally(() => setIsUpdatingTeamName(false));
    }
  }, [updateTeamNameReq, resetErrorField, team, validateField, handleRequestError]);

  const discardName = useCallback(() => {
    setUpdateTeamNameReq({ name: team?.name as string });
    resetErrorField("name");
  }, [setUpdateTeamNameReq, team, resetErrorField]);

  useEffect(() => {
    if (team) {
      setUpdateTeamNameReq({ name: team.name });
    }
  }, [team]);

  if (!team) {
    return null;
  } return (
    <form onSubmit={(e) => { e.preventDefault(); saveName(); }}>
      <TextInput
        label={t("Name")}
        value={updateTeamNameReq?.name}
        onChange={e => onChangeName(e)}
        disabled={!isOwner || isUpdatingTeamName}
        error={errors.fields.name}
        maxLength={50}
        type="text"
        suffix={
          isUpdatingTeamName ?
            <div className="h-100% d-f a-i-c">
              <Spinner type={"primary"} />
            </div>
          : team.name.trim() !== updateTeamNameReq?.name.trim() ?
            <div className="d-f h-100%">
              <TextInputAffixButton onClick={discardName}>
                {t("Cancel")}
              </TextInputAffixButton>
              <TextInputAffixButton submit={true} type="primary">
                {t("Save")}
              </TextInputAffixButton>
            </div>
          : null
        }
        />
    </form>
  );
}

import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { MonitorHttpHeader } from '../model';
import { TextInput, BaseInputOnChangeEvent } from '../ui';
import { X } from '../images';
import { useTranslation } from 'react-i18next';

type HttpRequestHeadersProps = {
  disabled?: boolean
  headers: MonitorHttpHeader[]
  onChangeField: (index: number, fieldName: keyof MonitorHttpHeader, event: BaseInputOnChangeEvent) => void
  onBlurField: (index: number, fieldName: keyof MonitorHttpHeader, event: BaseInputOnChangeEvent) => void
  onAddHttpHeader: () => void
  onRemoveHttpHeader: (index: number) => void
  errors: { [name: string]: string | undefined }[]
}

export const HttpRequestHeaders: React.FC<HttpRequestHeadersProps> = (props) => {

  const { t } = useTranslation();

  const [focusOnNew, setFocusOnNew] = useState(false);

  const onAdd = useCallback(() => {
    props.onAddHttpHeader();
    setFocusOnNew(true);
    setTimeout(() => setFocusOnNew(false));
  }, [props]);

  return (
    <div className="w-100% m-t-1 m-t-1.5-M">
      <div className={`${props.disabled ? "t-c-lightGray" : ""}`}>
        {t("Headers")}
      </div>
      <div className="d-f fb-d-c m-t-0.5 w-100% max-h-12 max-h-16-M of-a">
        {props.headers && props.headers.map((h, i) =>
          <HttpHeader
            disabled={props.disabled}
            key={i}
            index={i}
            header={h}
            onChangeField={props.onChangeField}
            onBlurField={props.onBlurField}
            onRemoveHttpHeader={props.onRemoveHttpHeader}
            hideRemove={props.headers.length === 1}
            focusOnNew={i === props.headers.length - 1 && focusOnNew}
            errors={props.errors.length > i ? props.errors[i] : {}} />
        )}
      </div>
      <div className="d-f f-s-1 m-t-1 j-c-e j-c-s">
        {props.headers && props.headers.length <= 16 ?
          <div onClick={props.disabled ? undefined : onAdd} className={`${props.disabled ? "t-c-lightGray" : "t-c-g c-p"}`}>
            + {t("Add")}
          </div>
        : <div>
            {t("Reached the maximun number of headers 16")}
          </div>
        }
        </div>
    </div>
  );
}

type HttpHeaderProps = {
  disabled?: boolean
  index: number
  header: MonitorHttpHeader
  onChangeField: (index: number, fieldName: keyof MonitorHttpHeader, event: BaseInputOnChangeEvent) => void
  onBlurField: (index: number, fieldName: keyof MonitorHttpHeader, event: BaseInputOnChangeEvent) => void
  onRemoveHttpHeader: (index: number) => void
  hideRemove?: boolean
  errors: { [name: string]: string | undefined }
  focusOnNew: boolean
}

export const HttpHeader: React.FC<HttpHeaderProps> = (props) => {

  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    if (props.focusOnNew && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, props.focusOnNew]);

  return (

    <div className="d-f a-i-s m-v-0.5">
      <div className="fb-n w-14">
        <TextInput
          disabled={props.disabled}
          ref={inputRef}
          value={props.header.name || ""}
          placeholder={t("Enter Name")}
          onChange={e => props.onChangeField(props.index, "name", e)}
          onBlur={e => props.onBlurField(props.index, "name", e)}
          maxLength={1000}
          error={props.errors.name}
          type="text" />
      </div>
      <div className="fb-a p-l-1">
        <TextInput
          disabled={props.disabled}
          value={props.header.value || ""}
          placeholder={t("Enter Value")}
          onChange={e => props.onChangeField(props.index, "value", e)}
          onBlur={e => props.onBlurField(props.index, "value", e)}
          maxLength={3000}
          error={props.errors.value}
          type="text" />
      </div>
      {!props.hideRemove ?
        <div  className="p-l-0.5 m-t-0.75">
          <div className={`${props.disabled ? "t-c-lightGray" : "t-c-g c-p"}`} onClick={props.disabled ? undefined : () => props.onRemoveHttpHeader(props.index)}><X /></div>
        </div>
      : null}
    </div>
  )

}
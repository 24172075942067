import { MonitorStatusType, SelectorStatusType, StatusType } from "../Consts";
import { Monitor } from "../model";

// Immutable class
export class MonitorSelector {

  constructor(
    public readonly monitor: Monitor,
    public readonly selected: boolean = false,
    public readonly lastMonitorRemote: Monitor = monitor,
    public readonly status: StatusType = monitor.status as StatusType) {
  }

  pause(): MonitorSelector {
    switch (this.status) {
      case MonitorStatusType.Up:
      case MonitorStatusType.Degraded:
      case MonitorStatusType.Disrupted:
      case MonitorStatusType.Maintenance:
      case MonitorStatusType.Down:
        return new MonitorSelector(this.monitor, this.selected, this.lastMonitorRemote, SelectorStatusType.Pausing);
      default:
        throw new Error(`Can't pause the Monitor ${this.monitor.name} with status ${this.status}`)
    }
  }

  delete(): MonitorSelector {
    switch (this.status) {
      case MonitorStatusType.Up:
      case MonitorStatusType.Degraded:
      case MonitorStatusType.Disrupted:
      case MonitorStatusType.Paused:
      case MonitorStatusType.Maintenance:
      case MonitorStatusType.Down:
      case MonitorStatusType.RequiresPaymentMethod:
        return new MonitorSelector(this.monitor, this.selected, this.lastMonitorRemote, SelectorStatusType.Deleting);
      default:
        throw new Error(`Can't delete the Monitor ${this.monitor.name} with status ${this.status}`)
    }
  }

  maintain(): MonitorSelector {
    switch (this.status) {
      case MonitorStatusType.Up:
      case MonitorStatusType.Degraded:
      case MonitorStatusType.Disrupted:
      case MonitorStatusType.Paused:
      case MonitorStatusType.Down:
        return new MonitorSelector(this.monitor, this.selected, this.lastMonitorRemote, SelectorStatusType.Maintaining);
      default:
        throw new Error(`Can't maintain the Monitor ${this.monitor.name} with status ${this.status}`)
    }
  }

  resume(): MonitorSelector {
    switch (this.status) {
      case MonitorStatusType.Maintenance:
      case MonitorStatusType.Paused:
        return new MonitorSelector(this.monitor, this.selected, this.lastMonitorRemote, MonitorStatusType.Starting);
      default:
        throw new Error(`Can't maintain the Monitor ${this.monitor.name} with status ${this.status}`)
    }
  }

  select(): MonitorSelector {
    return new MonitorSelector(this.monitor, true, this.lastMonitorRemote, this.status);
  }
  
  unselect(): MonitorSelector {
    return new MonitorSelector(this.monitor, false, this.lastMonitorRemote, this.status);
  }

  toggle(): MonitorSelector {
    return new MonitorSelector(this.monitor, !this.selected, this.lastMonitorRemote, this.status);
  }

  updateFromRemote(monitor: Monitor, serverEvent: boolean = false): MonitorSelector {
    if (serverEvent) {
      monitor = {...monitor,
        groups: this.monitor.groups,
        regions: this.monitor.regions,
        interval: this.monitor.interval
      }
    }

    let thisUpdatedAt = new Date(this.monitor.updatedAt);
    let lastRemoteUpdatedAt = new Date(this.lastMonitorRemote.updatedAt);
    let paramUpdatedAt = new Date(monitor.updatedAt);

    const thisRtUpdatedAt = new Date(this.monitor.rtUpdatedAt);
    const lastRemoteRtUpdatedAt = new Date(this.lastMonitorRemote.rtUpdatedAt);
    const paramRtUpdatedAt = new Date(monitor.rtUpdatedAt);

    if (thisUpdatedAt < thisRtUpdatedAt) {
      thisUpdatedAt = thisRtUpdatedAt;
    }
    if (lastRemoteUpdatedAt < lastRemoteRtUpdatedAt) {
      lastRemoteUpdatedAt = lastRemoteRtUpdatedAt;
    }
    if (paramUpdatedAt < paramRtUpdatedAt) {
      paramUpdatedAt = paramRtUpdatedAt;
    }

    if (this.status === SelectorStatusType.Deleting ||
      (this.status === SelectorStatusType.Maintaining && monitor.status !== MonitorStatusType.Maintenance) ||
      (this.status === SelectorStatusType.Pausing && monitor.status !== MonitorStatusType.Paused)) {

      // Make Monitor Param as lastMonitorRemote
      if (paramUpdatedAt > thisUpdatedAt && paramUpdatedAt > lastRemoteUpdatedAt) {
        return new MonitorSelector(this.monitor, this.selected, monitor, this.status);
      }
    } else {
      // Make last Monitor Remote as the current one
      if (lastRemoteUpdatedAt > thisUpdatedAt && lastRemoteUpdatedAt > paramUpdatedAt) {
        return new MonitorSelector(this.lastMonitorRemote, this.selected);

      // Make Monitor Param as the current one
      } else if (paramUpdatedAt > thisUpdatedAt) {
        return new MonitorSelector(monitor, this.selected);
      }
    }

    // Keep everything
    return new MonitorSelector(this.monitor, this.selected, this.lastMonitorRemote, this.status);
  }

  public get blocked(): boolean {
    switch (this.status) {
      case SelectorStatusType.Pausing:
      case SelectorStatusType.Maintaining:
      case SelectorStatusType.Deleting:
      case MonitorStatusType.Starting:
        return true;
      default:
        return false;
    }
  }

  public get isStarting(): boolean {
    return this.status === MonitorStatusType.Starting;
  }

  public get isPaused(): boolean {
    return this.status === MonitorStatusType.Paused;
  }

  public get isMaintained(): boolean {
    return this.status === MonitorStatusType.Maintenance;
  }

  public get isDegraded(): boolean {
    return this.status === MonitorStatusType.Degraded;
  }

  public get isDisrupted(): boolean {
    return this.status === MonitorStatusType.Disrupted;
  }

  public get isUp(): boolean {
    return this.status === MonitorStatusType.Up;
  }

  public get isDown(): boolean {
    return this.status === MonitorStatusType.Down;
  }

  public get canPause(): boolean {
    return !this.isPaused && !this.blocked;
  }

  public get canMaintain(): boolean {
    return !this.isMaintained && !this.blocked;
  }

  public get canResume(): boolean {
    return (this.isMaintained || this.isPaused) && !this.blocked;
  }

  public get canDelete(): boolean {
    return !this.blocked;
  }
}
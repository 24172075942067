import React, { useCallback, useMemo, useState } from 'react';
import { CreatePageReq, Page } from '../model';
import axios from 'axios';
import { apiBaseUrl } from "../Consts";
import { PageForm } from '../page-form';
import { useHistory } from 'react-router-dom';
import { Errors } from '../validator/Validator';
import { serialize } from 'object-to-formdata';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { messageState, teamState } from '../atoms';
import { useHandleRequestError } from '../hooks';
import { Breadcrumbs, Loading } from '../ui';

export const PageNew: React.FC = () => {

  const { t } = useTranslation();
  const history = useHistory();
  const team = useRecoilValue(teamState);
  const { handleRequestErrorAvoidingPopup } = useHandleRequestError();
  const [ errorsOnServer, setErrorsOnServer ] = useState<Errors<Page>>()
  const page = useMemo(() => new Page(), []);
  const [ isSaving, setIsSaving ] = useState(false);
  const setMessage = useSetRecoilState(messageState);

  // eslint-disable-next-line
  const save = useCallback((page: Page, logoFile?: File, removeLogo?: boolean, faviconFile?: File, removeFavicon?: boolean) => {
    if (team) {
      const req = new CreatePageReq();
      req.name = page.name;
      req.title = page.title;
      req.slug = page.slug;
      req.customDomain = page.customDomain;
      req.description = page.description;
      req.useGroups = page.useGroups;
      req.statuses = page.statuses.map(s => s.uuid);
      req.googleAnalyticsId = page.googleAnalyticsId;
      setIsSaving(true)

      const data = serialize(req);
      if (logoFile) {
        data.append("logoFile", logoFile);
      }
      if (faviconFile) {
        data.append("faviconFile", faviconFile);
      }

      axios.post(`${apiBaseUrl}/${team.uuid}/pages`, data)
        .then((response) => {
          
          if (response.data.checkoutUrl) {
            window.location.href = response.data.checkoutUrl;
            return
          } else {
            history.push(`/${team.slug}/pages?order=lastCreated&descending=true`) 
          }
          if (response.data.checkoutError) {
            setMessage({
              type: "error",
              text: response.data.checkoutError
            })
          }
        })
        .catch(err => {
          handleRequestErrorAvoidingPopup(err);
          setErrorsOnServer(err.response.data);
        })
      .finally(() => setIsSaving(false));
    }
  }, [team, history, handleRequestErrorAvoidingPopup]);

  const cancel = useCallback(() => {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.push(`/${team?.slug}/pages`)
    }
  }, [history, team]);

  if (!team ) {
    return <Loading />

  }
  return (
    <div className="d-f fb-d-c m-h-a w-100% max-w-44.5-M max-w-49-L p-h-1 p-h-2-M t-c-nb">
      <div className="m-t-1.5">
        <Breadcrumbs
          items={[
            { title: t("Pages"), link: `/${team.slug}/pages`},
            { title: t("New") },
          ]}
        />
      </div>
      <div className="f-s-1.5 f-s-2-M f-w-300 m-v-1 m-v-1.5-M">{t("New Page")}</div>
      <PageForm
        errorsOnServer={errorsOnServer}
        page={page}
        onCancel={cancel}
        onSave={save}
        isSaving={isSaving}  />
    </div>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';

type BreadcrumbsProps = {
  items: BreadcrumbsItem[]
}

export class BreadcrumbsItem {
  constructor(
    public readonly title: string,
    public readonly link?: string) { }
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = (props) => {

  return (
    <div className="d-f a-i-c fb-w-w max-w-100%">
      {props.items.map((item, i) =>
        <React.Fragment key={i}>
          {item.link ?
            <Link
              key={i}
              to={item.link}
              className="f-s-0.75 f-s-1-M fb-n c-p t-c-g ws-n of-h t-o-e max-w-12">
              {item.title}
            </Link>
          :
            <div className="f-s-0.75 f-s-1-M fb-n t-c-nb ws-n of-h t-o-e max-w-12">
              {item.title}
            </div>
          }
          { props.items.length - 1 > i ?
            <div className="t-c-lightestGray fb-n d-f a-i-c">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 6L8.59003 7.41L13.17 12L8.59003 16.59L10 18L16 12L10 6Z" fill="currentColor"/>
              </svg>
            </div>
          :
            null
          }
        </React.Fragment>
      )}
    </div>
  );
}

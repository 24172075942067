import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import { apiBaseUrl } from '../Consts';
import { useHandleRequestError } from '../hooks';
import { Loading } from '../ui';


export const Invitation: React.FC = () => {

  const { t } = useTranslation();
  const history = useHistory();
  const { handleRequestError } = useHandleRequestError();
  const { invitationCode } = (useParams() as any);
  const [ invalid, setInvalid ] = useState(false);
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    axios.get(`${apiBaseUrl}/invitation/${invitationCode}`)
      .then((res) => {
        setLoading(false)
        switch (res.data.status) {
          case "invalid":
            setInvalid(true);
            timeout = setTimeout(() => history.replace("/"), 5000);
            break;
          case "sign_up":
            window.location.href = `/sign-up?invitation=${invitationCode}`;
            break;
          case "sign_in":
            window.location.href = `/sign-in?invitation=${invitationCode}`;
            break;
          case "home":
            window.location.href = "/";
            break;
        }
      })
      .catch((err) => {
        setLoading(false);
        handleRequestError(err)
      })
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, [handleRequestError, history, invitationCode])

  if (loading) {
    return <Loading />
  }

  return (
    invalid ?
      <div className="d-f fb-d-c a-i-c bg-c-nw h-100vh">
        <div className="t-a-c m-t-2.5 m-t-3.5-M f-w-b f-s-3-M f-s-2">
          <Trans t={t} i18nKey="This invitation link is not valid. <1/><1/> Maybe the invitation already was taken or was deleted.">
            This invitation link is not valid.<br/><br/>
            Maybe the invitation already was taken or was deleted.
          </Trans>
        </div>
        <div className="t-a-c m-t-2.5 m-t-3-M f-s-2-M f-s-1.5">
          {t("You are being redirected...")}
        </div>
        <div className="t-a-c m-v-2.5 m-v-3-M f-s-1.5-M f-s-1">
          <Trans t={t} i18nKey="If you are not redirected <1> click here </1> to continue">
            If you are not redirected <Link to="/" className="t-c-g">click here</Link> to continue
          </Trans>
        </div>
      </div>
    : null
  )
}
import React, { useCallback, useEffect, useState } from 'react';
import { Loading } from '../ui/loading/Loading';
import { useHistory, useParams } from 'react-router-dom';
import { useHandleRequestError } from '../hooks';
import { apiBaseUrl } from '../Consts';
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { lastCheckoutCompletedState, userState } from '../atoms';
import { CheckoutImage } from '../images/'

export const CompleteCheckout: React.FC = () => {

  const { t } = useTranslation();
  const { handleRequestError } = useHandleRequestError();
  const history = useHistory();
  const { checkoutKey } = useParams() as any;
  const [ isLoading, setIsLoading ] = useState(true);
  const lastCheckoutCompleted = useRecoilValue(lastCheckoutCompletedState);

  const user = useRecoilValue(userState);

  const checkCheckoutSession = useCallback(() => {
    if (user) {
      axios.get(`${apiBaseUrl}/checkout-sessions/${checkoutKey}`)
        .then(response => {
          if (!response.data.exists || response.data.used) {
            history.replace(response.data.nextUrlPath);
          } else {
            setTimeout(() =>  {
              history.replace(response.data.nextUrlPath);
            }, 100000)
          }
        })
        .catch(error => {
          handleRequestError(error);
          history.replace("/");
        }).finally(() => setIsLoading(false));
    }
  }, [user, handleRequestError]);

  useEffect(() => checkCheckoutSession(), [])

  useEffect(() => {
    if (lastCheckoutCompleted && lastCheckoutCompleted.checkoutKey === checkoutKey) {
      history.replace(lastCheckoutCompleted.nextUrlPath);
    }

  }, [lastCheckoutCompleted])



  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <div className="d-f bg-c-nw fb-d-c a-i-c w-100% p-h-1" style={{minHeight: `calc(100vh - 65px)`}}>
        <div className="f-w-300 f-s-1.5 f-s-2-M m-t-1.5">
          {t("The checkout is being completed. Please wait")}
        </div>
        <div className="m-t-2">
          <Loading />
        </div>
        <div className="d-f j-c-c w-100% m-t-4">
          <CheckoutImage />
        </div>
      </div>
    )
  }
}
import React, { useEffect, useState } from "react";
import { statusToColor, statusToHumanText } from "../Helper";
import { Status } from "../ui";
import { MonitorStatusType, responseCodes } from "../Consts";
import { isMonitorRegionEvent, MonitorEventWrap, Region } from "../model";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { regionsState } from "../atoms";
import { ChevronDown, Flag } from "../images";
import { ChevronRight } from "../images/ChevronRight";

type MonitorEventShowProps = {
  eventWrap: MonitorEventWrap
}

export const MonitorEventShow: React.FC<MonitorEventShowProps> = (props) => {
  const color = statusToColor(props.eventWrap.event.status);

  const [, updateState] = React.useState<any>();
  const regions = useRecoilValue(regionsState);
  const [showDetail, setShowDetail] = useState(false);
  
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const { t } = useTranslation();

  useEffect(() => {
    const timeout = setTimeout(() => forceUpdate(), 300000);
    return () => {
      clearTimeout(timeout);
    }
  }, [forceUpdate]);

  const hasMonitorEvent = !isMonitorRegionEvent(props.eventWrap.event) && props.eventWrap.event.detail && (
    props.eventWrap.event.status === MonitorStatusType.Down ||
    props.eventWrap.event.status === MonitorStatusType.Disrupted ||
    props.eventWrap.event.status === MonitorStatusType.Degraded
  );

  return (
    <div className="w-100% b-sb-s b-c-lightestGray b-wb-0.1 p-v-0.5">
      <div className={`d-f fb-a a-i-c ${hasMonitorEvent || isMonitorRegionEvent(props.eventWrap.event) ? "c-p" : ""}`} onClick={() => setShowDetail(v => !v)}>
        <div className="w-3"><Status animate={false} status={props.eventWrap.event.status as MonitorStatusType} /></div>
        <div className="fb-a" style={{color: color}}>
          { props.eventWrap.description }
        </div>
        {isMonitorRegionEvent(props.eventWrap.event) ?
          <div className="h-2 d-f a-i-c" style={{color: color}}>
            {showDetail ? <ChevronDown /> : <ChevronRight />}
          </div>
        : null}
        {hasMonitorEvent ?
          <div className="h-2 d-f a-i-c" style={{color: color}}>
            {showDetail ? <ChevronDown /> : <ChevronRight />}
          </div>
        : null}
      </div>
      <div>
        {!isMonitorRegionEvent(props.eventWrap.event) && props.eventWrap.event.detail?.message ?
          <div className="t-c-gray">
            { props.eventWrap.event.detail.message }
          </div>
        : isMonitorRegionEvent(props.eventWrap.event) ?
          <div
            className={`${showDetail ? "d-f" : "d-n"} m-l-3 m-b-1 m-t-0.5`}
            style={{color: statusToColor(props.eventWrap.event.status)}}>
            {`${props.eventWrap.event.message ?? responseCodes[props.eventWrap.event.responseCode.toString()] ?? "" }`}&nbsp;
            {props.eventWrap.event.responseCode >= 100 && props.eventWrap.event.responseCode <= 599 ? `(${props.eventWrap.event.responseCode})` : ""}
          </div>
        : null}
      </div>
      {showDetail && regions && !isMonitorRegionEvent(props.eventWrap.event) && props.eventWrap.event.detail ?
        <div className="m-l-3 p-v-0.5">
          {props.eventWrap.event.detail.detailRegions?.map(dr => {
            const region = regions.find(r => r.id === dr.regionId) as Region;
            return (
              <div key={dr.regionId} className="d-f p-b-0.5">
                <div className="m-r-1">
                  <Flag country={region.country.code} size={"mini"} />
                </div>
                <div>
                  {region.name}&nbsp;&nbsp;&nbsp;&nbsp;
                  <span style={{color: statusToColor(dr.status)}}>
                    {statusToHumanText(dr.status)} -&nbsp;
                    {`${dr.message ?? responseCodes[dr.responseCode.toString()] ?? "" }`} {dr.responseCode >= 100 && dr.responseCode <= 599 ? `(${dr.responseCode})` : ""}
                  </span>
                </div>
              </div>
            )
          }
          )}
        </div>
      : null}
    </div>
  );
}
import React from "react";

type ResumeButtonProps = {
  size?: number
}

export const ResumeButton: React.FC<ResumeButtonProps> = (props) => {
  const size = props.size || 24

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path fill="#000" d="M8 5v14l11-7L8 5z"></path>
    </svg>
  )
}

import React from "react";

export const AvatarPaused = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="153"
      height="160"
      fill="none"
      viewBox="0 0 153 160"
    >
      <g clipPath="url(#clip0)">
        <path
          fill="#3F3D56"
          d="M59.15 160c32.666 0 59.148-4.678 59.148-10.449s-26.482-10.45-59.149-10.45S0 143.78 0 149.551C0 155.322 26.482 160 59.15 160z"
        ></path>
        <path
          fill="#E2E2E2"
          d="M95.099 115.614c31.926 0 57.807-25.881 57.807-57.807C152.906 25.881 127.025 0 95.099 0 63.173 0 37.292 25.881 37.292 57.807c0 31.926 25.88 57.807 57.807 57.807z"
        ></path>
        <path
          fill="#D0CDE1"
          d="M37.804 62.98s-4.545 12.793-2.525 17.338c2.02 4.545 5.218 8.921 5.218 8.921S39.32 63.822 37.804 62.98z"
        ></path>
        <path
          fill="#000"
          d="M37.804 62.98s-4.545 12.793-2.525 17.338c2.02 4.545 5.218 8.921 5.218 8.921S39.32 63.822 37.804 62.98z"
          opacity="0.1"
        ></path>
        <path
          fill="#2F2E41"
          d="M41.002 92.606s-.337 3.198-.505 3.366c-.168.169.168.505 0 1.01s-.337 1.179 0 1.347c.337.168-1.852 14.981-1.852 14.981s-5.386 7.07-3.198 18.179l.674 11.278s5.218.337 5.218-1.515c0 0-.337-2.188-.337-3.198 0-1.01.842-1.01.337-1.515-.505-.505-.505-.842-.505-.842s.841-.673.673-.841c-.168-.169 1.515-12.12 1.515-12.12s1.852-1.851 1.852-2.861v-1.01s.841-2.189.841-2.357c0-.168 4.545-10.436 4.545-10.436l1.852 7.406 2.02 10.605s1.01 9.594 3.03 13.297c0 0 3.534 12.12 3.534 11.783 0-.336 5.892-1.178 5.723-2.693-.168-1.515-3.535-22.724-3.535-22.724l.842-31.477-22.724.337z"
        ></path>
        <path
          fill="#2F2E41"
          d="M36.457 141.925s-4.545 8.921-1.515 9.258c3.03.337 4.208.337 5.555-1.01.736-.736 2.227-1.724 3.427-2.468a4.43 4.43 0 002.103-4.215c-.089-.823-.396-1.502-1.154-1.565-2.02-.168-4.376-2.02-4.376-2.02l-4.04 2.02zM61.538 148.49s-4.545 8.921-1.515 9.258c3.03.336 4.208.336 5.555-1.01.736-.736 2.227-1.724 3.427-2.468a4.436 4.436 0 002.103-4.215c-.089-.823-.397-1.502-1.154-1.565-2.02-.168-4.376-2.02-4.376-2.02l-4.04 2.02z"
        ></path>
        <path
          fill="#FFB8B8"
          d="M56.734 48.381a7.076 7.076 0 100-14.152 7.076 7.076 0 000 14.152z"
        ></path>
        <path
          fill="#FFB8B8"
          d="M52.186 43.63s-5.054 9.3-5.459 9.3c-.404 0 9.098 3.032 9.098 3.032s2.628-8.895 3.032-9.704l-6.671-2.628z"
        ></path>
        <path
          fill="#D0CDE1"
          d="M59.939 53.807s-10.1-5.555-11.11-5.387c-1.01.169-11.783 9.595-11.614 13.466.168 3.872 1.514 10.268 1.514 10.268s.505 17.843 1.516 18.011c1.01.168-.169 3.198.168 3.198.336 0 23.565 0 23.734-.505.168-.505-4.208-39.051-4.208-39.051z"
        ></path>
        <path
          fill="#FFB8B8"
          d="M65.578 93.784s3.198 9.763.504 9.426c-2.693-.336-3.871-8.416-3.871-8.416l3.367-1.01z"
        ></path>
        <path
          fill="#D0CDE1"
          d="M56.993 53.218s-6.228 1.346-5.218 9.762c1.01 8.417 2.861 16.833 2.861 16.833l6.228 13.634.674 2.525 4.544-1.178-3.366-19.526s-1.178-20.872-2.693-21.546a6.519 6.519 0 00-3.03-.505z"
        ></path>
        <path
          fill="#000"
          d="M53.206 79.56l7.743 13.803-6.524-14.544-1.22.742z"
          opacity="0.1"
        ></path>
        <path
          fill="#2F2E41"
          d="M63.779 39.222l.023-.54 1.076.267a1.203 1.203 0 00-.482-.885L65.54 38a12.362 12.362 0 00-8.268-5.11c-2.478-.36-5.238.16-6.937 2-.824.892-1.342 2.026-1.71 3.184-.68 2.132-.817 4.673.597 6.406 1.438 1.76 3.95 2.106 6.214 2.324.796.076 1.63.148 2.368-.161a5.697 5.697 0 00-.317-2.5 1.666 1.666 0 01-.168-.796c.1-.673.998-.843 1.673-.752.675.09 1.486.23 1.93-.287.305-.355.287-.874.327-1.34.11-1.273 2.517-1.48 2.529-1.746z"
        ></path>
        <path
          fill="#979797"
          d="M107.178 150.989a8.245 8.245 0 10-.002-16.49 8.245 8.245 0 00.002 16.49zM10.354 148.113a8.245 8.245 0 10-.002-16.49 8.245 8.245 0 00.002 16.49z"
        ></path>
        <path
          fill="#979797"
          d="M10.354 134.883a5.944 5.944 0 100-11.887 5.944 5.944 0 000 11.887z"
        ></path>
        <path
          fill="#979797"
          d="M10.354 123.954a4.218 4.218 0 100-8.435 4.218 4.218 0 000 8.435z"
        ></path>
        <path fill="#818181" d="M76.309 24.733H89.347V91.647H76.309z"></path>
        <path fill="#818181" d="M101.426 24.733H114.464V91.647H101.426z"></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H152.906V160H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Group, UpdateGroupReq } from '../model';
import axios from 'axios';
import { Loading, Modal } from '../ui';
import { apiBaseUrl } from "../Consts";
import { GroupForm } from '../group-form';
import { Errors } from '../validator/Validator';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { messageState, teamState } from '../atoms';
import { useHandleRequestError } from '../hooks';

type GroupEditProps = {
  groupUuid?: string,
  onUpdate: (group: Group) => void
  onCancel: () => void
  onFetch?: (group: Group) => void
}

export const GroupEdit: React.FC<GroupEditProps> = (props) => {

  const { t } = useTranslation();
  const team = useRecoilValue(teamState);
  const setMessage = useSetRecoilState(messageState);
  const { handleRequestError, handleRequestErrorAvoidingPopup } = useHandleRequestError()

  const [ group, setGroup ] = useState<Group>();
  const groupUuid = useMemo(() => props.groupUuid, [props.groupUuid])
  const onFetch = useMemo(() => props.onFetch, [props.onFetch])
  const onParentCancel = useMemo(() => props.onCancel, [props.onCancel])
  const [ errorsOnServer, setErrorsOnServer ] = useState<Errors<Group>>()
  const [ isSaving, setIsSaving ] = useState(false);

  const fetch = useCallback(() => {
    if (team && groupUuid) {
      axios.get(`${apiBaseUrl}/${team.uuid}/groups/${groupUuid}`)
        .then(response => {
          const group = response.data.group as Group;
          setGroup(group);
          if (onFetch) {
            onFetch(group);
          }
        })
        .catch(err => {
          onParentCancel();
          handleRequestError(err);
        });
    }
  }, [team, groupUuid, onFetch, handleRequestError, onParentCancel]);


  const onSave = useCallback((group: Group) => {
    if (team) {
      const req = new UpdateGroupReq();
      req.name = group.name;
      req.title = group.title;
      req.description = group.description;
      req.color = group.color;
      req.monitors = group.monitors.map(m => m.uuid);
      setIsSaving(true);

      axios.patch(`${apiBaseUrl}/${team.uuid}/groups/${group.uuid}`, req)
        .then(response => {

          if (response.data.checkoutUrl) {
            window.location.href = response.data.checkoutUrl;
            return
          } else {
            props.onUpdate(response.data.group as Group);
          }
          if (response.data.checkoutError) {
            setMessage({
              type: "error",
              text: response.data.checkoutError
            })
          }
        })
        .catch(err => {
          handleRequestErrorAvoidingPopup(err);
          setErrorsOnServer(err.response.data);
        })
        .finally(() => setIsSaving(false));
    }
  }, [team, props, handleRequestErrorAvoidingPopup]);

  const onCancel = useCallback(() => {
    props.onCancel();
  }, [props])

  useEffect(() => fetch(), [fetch])

  if (groupUuid !== undefined) {
    return (
      <Modal
        open={true}
        onClose={ () => props.onCancel() }
        title={t("Edit Group")}
        description={ group?.name }
        noPadding={true}
        isSaving={isSaving} >
        <div className="p-h-1 p-h-2-M m-v-1 m-v-1.5-M">
          { !group ?
            <Loading />
          :
            <GroupForm
              errorsOnServer={errorsOnServer}
              onSave={onSave}
              onCancel={onCancel}
              group={group}
              isSaving={isSaving} />
          }
        </div>
      </Modal>
    );
  } else {
    return null;
  }
}

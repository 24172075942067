import React from "react";

export const XModal = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <line x1="4.35355" y1="4.64645" x2="27.3536" y2="27.6464" stroke="#4A4A4A"/>
      <line y1="-0.5" x2="32.5269" y2="-0.5" transform="matrix(-0.707107 0.707107 0.707107 0.707107 28 5)" stroke="#4A4A4A"/>
    </svg>
  );
}
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '../../hooks/UseDebounce';
import { SearchIcon, X } from '../../images';

type SearchResourceProps = {
  initValue?: string
  onChange?: (value: string) => void
}

export const SearchResource: React.FC<SearchResourceProps> = (props) => {

  const { t } = useTranslation();
  const [value, setValue] = useState(props.initValue);
  const debouncedValue = useDebounce(value, 500);
  const [focused, setFocused] = useState(false);
  const refInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.onChange && debouncedValue !== props.initValue) {
      props.onChange(debouncedValue);
    }
  }, [debouncedValue, props])

  return (
    <div className={`d-f a-i-c p-v-0.5 b-c-g b-sb-s b-w-0px m-t-1px ${focused || value ? "b-wb-1px" : "m-b-1px"}`}>
      <div
        onClick={() => refInput.current?.focus()}
        className={`d-f a-i-c c-p ${focused ? "t-c-g" : ""}`}>
        <SearchIcon />
      </div>
      <input
        ref={refInput}
        value={value ?? ""}
        placeholder={t("Search")}
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        onChange={(e) =>  setValue(e.currentTarget.value === "" ? undefined : e.currentTarget.value)}
        className="w-100% p-h-0.5 f-s-1" />
      {focused || value ?
        <div
          onClick={() => setValue(undefined)}
          className="d-f a-i-c p-h-0.5 t-c-gray c-p">
          <X />
        </div>
      : null}
    </div>
  );
}

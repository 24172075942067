
import React, { useState, useCallback, useEffect } from 'react';
import { OptionListIcon, ChevronDownIcon } from '../images';
import { Popover } from '../popover/Popover';
import { Menu, MenuItem } from '../menu/Menu';
import { DefaultColor } from '../../Consts';

type MenuInRowProps = {
  items: MenuItem[]
  icon?: React.ReactNode
  color?: string
  title?: string
  onClose?: () => void
}

export const MenuInRow: React.FC<MenuInRowProps> = (props) => {

  const [active, setActive] = useState(false);
  const [items, setItems] = useState<MenuItem[] | undefined>(undefined);

  const toggleActive = useCallback(() => {
    if (active && props.onClose) {
      props.onClose();
    }
    setActive(!active)
  }, [active, props]);

  const activator = (
    <div onClick={toggleActive} className="d-f c-p j-c-c a-i-c f-s-1 t-c-g">
      {props.title ?
        <>
          <div>{props.title}</div>
          <div
            className="m-l-0.5 d-f a-i-c"
            style={{color: props.color ? props.color : DefaultColor}}>
            {props.icon ? props.icon : <ChevronDownIcon  />}
          </div>
        </>
      :
        <div className="d-f a-i-c">
          {props.icon ? props.icon : <OptionListIcon />}
        </div>
      }
      
    </div>
  )

  useEffect(() => {
    setItems(props.items.map(item => {
      return {
        title: item.title,
        link: item.link,
        onClick: () => {
          setActive(false);
          if (item.onClick) {
            item.onClick();
          }
        },
        subItems: item.subItems,
        visible: item.visible,
        separator: item.separator
      }
    }))
  }, [props.items])

  if (items) {
    return (
      <div className="h-1.5">
        <Popover
          active={active}
          activator={activator}
          placement="bottom-end"
          onClose={toggleActive}>
          <Menu items={items} />
        </Popover>
      </div>
    );
  } else {
    return null;
  }
}

import axios from "axios";
import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { Loading, TextInput, BaseInputOnChangeEvent, Spinner, Button } from '../ui';
import { Team, UpdateTeamSlugReq, ValidateTeamSlugReq } from '../model';
import { useValidator, Validations } from '../validator';
import { apiBaseUrl } from '../Consts';
import { isFieldStringEmpty, isFieldSlugNotMatching } from '../Validations';
import { useDebounce } from "../hooks/UseDebounce";
import { useTranslation } from 'react-i18next';
import { isOwnerState, teamState } from "../atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { useHandleRequestError } from "../hooks";

const v = new Validations(new UpdateTeamSlugReq());
v.addField("slug", (v: any) => isFieldStringEmpty(v) || isFieldSlugNotMatching(v));

export const SettingsSlug: React.FC = () => {

  const { t } = useTranslation();
  const [ team, setTeam ] = useRecoilState(teamState);
  const isOwner = useRecoilValue(isOwnerState);
  const { handleRequestErrorAvoidingPopup } = useHandleRequestError();
  const slugPrefix = useMemo(() => `${window.location.protocol}//${window.location.host}/`, [])

  const [ updateTeamSlugReq, setUpdateTeamSlugReq ] = useState<UpdateTeamSlugReq>();
  const [ isUpdatingTeamSlug, setIsUpdatingTeamSlug ] = useState<boolean>();
  const debouncedUpdateTeamSlugReq = useDebounce(updateTeamSlugReq, 500);
  const { errors, validateField, resetErrorField } = useValidator(v);
  const lastRemoteTime = useRef(new Date());

  const onChangeSlug = useCallback((event: BaseInputOnChangeEvent) => {
    validateField("slug", event.value);
    setUpdateTeamSlugReq({ ...updateTeamSlugReq, slug: event.value as string });
  }, [validateField, updateTeamSlugReq])

  const saveSlug = useCallback(() => {
    if (team && team.slug.trim() !== updateTeamSlugReq?.slug.trim() && validateField("slug", updateTeamSlugReq?.slug)) {
      setIsUpdatingTeamSlug(true);
      lastRemoteTime.current = new Date();

      axios
        .put(`${apiBaseUrl}/${team.uuid}/team/slug`, updateTeamSlugReq)
        .then(() => setTeam({...team, slug: updateTeamSlugReq?.slug } as Team))
        .catch(err => {
          handleRequestErrorAvoidingPopup(err);
          if (err.response?.data?.slug) {
            resetErrorField("slug", err.response.data.slug)
          }
        })
        .finally(() => setIsUpdatingTeamSlug(false));
    }
  }, [team, updateTeamSlugReq, validateField, setTeam, handleRequestErrorAvoidingPopup, resetErrorField]);

  const discardSlug = useCallback(() => {
    setUpdateTeamSlugReq({ slug: team?.slug as string });
    resetErrorField("slug");
  }, [setUpdateTeamSlugReq, team, resetErrorField]);

  useEffect(() => {
    if (team) {
      setUpdateTeamSlugReq({ slug: team.slug });
    }
  }, [team]);


  useEffect(
    () => {
      if (team &&
          debouncedUpdateTeamSlugReq &&
          updateTeamSlugReq &&
          debouncedUpdateTeamSlugReq.slug === updateTeamSlugReq.slug &&
          !errors.fields.slug &&
          !isUpdatingTeamSlug) {

        const operationTime = lastRemoteTime.current = new Date();

        const req = new ValidateTeamSlugReq();
        req.slug = debouncedUpdateTeamSlugReq.slug;


        axios.get(`${apiBaseUrl}/${team.uuid}/validate-slug`, {
            params: req,
          })
          .then(() => {
            if (operationTime === lastRemoteTime.current) {
              resetErrorField("slug")
            }
          })
          .catch(err => {
            if (operationTime === lastRemoteTime.current) {
              handleRequestErrorAvoidingPopup(err);
              if (err.response?.data?.slug) {
                resetErrorField("slug", err.response.data.slug);
              }
            }
          });
      }
    },
    [debouncedUpdateTeamSlugReq, resetErrorField, team, handleRequestErrorAvoidingPopup, isUpdatingTeamSlug, updateTeamSlugReq, errors.fields.slug]
  );

  if (!team) {
    return <Loading />
  } return (
    <form
      onSubmit={(e) => { e.preventDefault(); saveSlug(); }}
      className="d-f fb-d-c w-100% max-w-40.5 max-w-45-L m-t-1 m-t-3-M">
      <TextInput
        autoFocus={true}
        label={t("Slug")}
        value={updateTeamSlugReq?.slug}
        onChange={e => onChangeSlug(e)}
        prefix={<div className="d-n d-f-M p-h-1 bg-c-nw t-c-nb h-100% a-i-c ws-n">{slugPrefix}</div>}
        disabled={!isOwner || isUpdatingTeamSlug}
        error={errors.fields.slug}
        maxLength={50}
        type="text"
        />
      <div className="d-n-M m-t-1 t-c-nb a-i-c of-h ws-n t-o-e" title={`${slugPrefix}${updateTeamSlugReq?.slug}`}>
        {slugPrefix}{updateTeamSlugReq?.slug}
      </div>
      <div className="d-f j-c-e m-t-1 m-t-2-M">
        {isUpdatingTeamSlug ?
          <div className="h-100% d-f a-i-c">
            <Spinner type={"primary"} />
          </div>
        : team.slug.trim() !== updateTeamSlugReq?.slug.trim() ?
            <div className="d-f fb-d-cr fb-d-r-M j-c-e a-i-c w-100%">
              <div onClick={discardSlug} className="t-c-g f-s-1.25 c-p p-t-1 p-t-0-M">
                {t("Cancel")}
              </div>
              <div className="m-l-1-M w-100% w-auto-M">
                <Button submit={true} type="primary" fullWidth="mobile">{t("Save")}</Button>
              </div>
            </div>
        : null}
      </div>
    </form>
  );
}
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { AlertRecipient } from '../model';
import { TextInput, BaseInputOnChangeEvent, BaseInputOnSelectEvent, SelectInput, SelectInputItem } from '../ui';
import { IconEmail, IconSlack, IconDiscord, IconWebhook, X } from '../images';
import { useTranslation } from 'react-i18next';
import PhoneNumber from 'awesome-phonenumber';
import { useRecoilValue } from 'recoil';
import { teamState } from '../atoms';

type RecipientsProps = {
  recipients: AlertRecipient[]
  onChangeField: (index: number, fieldName: keyof AlertRecipient, event: BaseInputOnChangeEvent) => void
  onBlurField: (index: number, fieldName: keyof AlertRecipient, event: BaseInputOnChangeEvent) => void
  onAddRecipient: () => void
  onRemoveRecipient: (index: number) => void
  errors: { [name: string]: string | undefined }[]
}

export const Recipients: React.FC<RecipientsProps> = (props) => {

  const { t } = useTranslation();

  const team = useRecoilValue(teamState);

  const [focusOnNew, setFocusOnNew] = useState(false);

  const [showRequireBuyCredits, setShowRequireBuyCredits] = useState(false);

  const onAdd = useCallback(() => {
    props.onAddRecipient();
    setFocusOnNew(true);
    setTimeout(() => setFocusOnNew(false));
  }, [props]);

  useEffect(() => {
    setShowRequireBuyCredits(
      team !== undefined &&
      team.balance <= 0 &&
      props.recipients.findIndex(r => r.type === "call" || r.type === "sms" ) > -1)
    }, [props.recipients, team])

  return (
    <div className="m-t-1 m-t-2-M">
      <div className="f-s-1 f-s-1.25-M">{t("Recipients")}</div>
      <div className="d-f fb-d-c m-t-0.5 w-100% b-s-s-M b-w-1px-M b-c-lightestGray-M p-h-2-M p-v-1.5-M">
        <div className="d-n d-f-M m-b-0.5">
          <div className="fb-n w-8">
            {t("Type")}
          </div>
          <div className="fb-a p-l-1">
            {t("Target")}
          </div>
        </div>

        {props.recipients.map((r, i) =>
          <Recipient
            key={i}
            index={i}
            recipient={r}
            onChangeField={props.onChangeField}
            onBlurField={props.onBlurField}
            onRemoveRecipient={props.onRemoveRecipient}
            hideRemove={props.recipients.length === 1}
            focusOnNew={i === props.recipients.length - 1 && focusOnNew}
            errors={props.errors.length > i ? props.errors[i] : {}} />
        )}

        <div className="d-f f-s-1.25 m-t-1 j-c-e j-c-s">
          <div onClick={onAdd} className='t-c-g c-p'>
            + {t("Add")}
          </div>
        </div>

        {showRequireBuyCredits ?
          <div className="f-s-1 t-c-gray m-t-1">
            {t("Note")}: {t("SMS and Call recipients require to buy Phone Alert Credits. You can save the Alert and buy Phone Alert Credits later.")}
          </div>
        : null}
      </div>
    </div>
  );
}

type RecipientProps = {
  index: number
  recipient: AlertRecipient
  onChangeField: (index: number, fieldName: keyof AlertRecipient, event: BaseInputOnChangeEvent) => void
  onBlurField: (index: number, fieldName: keyof AlertRecipient, event: BaseInputOnChangeEvent) => void
  onRemoveRecipient: (index: number) => void
  hideRemove?: boolean
  errors: { [name: string]: string | undefined }
  focusOnNew: boolean
}

export const Recipient: React.FC<RecipientProps> = (props) => {

  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const recipientTypes = useMemo<SelectInputItem[]>(() => [
    new SelectInputItem(t("Email"), "email"),
    new SelectInputItem(t("SMS"), "sms"),
    new SelectInputItem(t("Call"), "call"),
    new SelectInputItem(t("Slack"), "slack"),
    new SelectInputItem(t("Discord"), "discord"),
    new SelectInputItem(t("Webhook"), "webhook")
  ], [t]);

  const phoneResult = useMemo(() => {
    const type = props.recipient.type;
    if (props.recipient.target && (type === "call" || type === "sms")) {
      return new PhoneNumber(props.recipient.target);
    }
    return undefined;
  }, [props.recipient.target, props.recipient.type])

  const indexType = useMemo(() =>
    recipientTypes.findIndex(rt => rt.data === props.recipient.type)
  , [props.recipient.type, recipientTypes])

  const onSelectType = useCallback((onSelectEvent: BaseInputOnSelectEvent) => {
    const onChangeEvent = new BaseInputOnChangeEvent(
      recipientTypes[onSelectEvent.index].data as string, onSelectEvent.dirty, onSelectEvent.touched);
    props.onChangeField(props.index, "type", onChangeEvent);
    setTimeout(() => inputRef.current?.focus());
  }, [props, recipientTypes]);

  useLayoutEffect(() => {
    if (props.focusOnNew && !props.recipient.uuid && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, props.focusOnNew, props.recipient.uuid]);

  let TargetIcon
  switch (props.recipient.type) {
    case "email" :
      TargetIcon = <IconEmail />
      break;
    case "slack" :
      TargetIcon = <IconSlack />
      break;
    case "discord" :
      TargetIcon = <IconDiscord />
      break;
    case "webhook" :
      TargetIcon = <IconWebhook />
      break;
  }

  return (

    <div className="d-f a-i-s m-v-0.5">
      <div className="fb-n w-8">
        <SelectInput
          options={recipientTypes}
          onSelect={onSelectType}
          indexSelected={indexType}
          onBlur={e => props.onBlurField(props.index, "type", e)}
          placeholder={t(`Select Type`)} />
      </div>
      <div className="fb-a p-l-1">
        <TextInput
          ref={inputRef}
          prefix={
            <div className="p-l-0.5 d-f a-i-c">
              {phoneResult && phoneResult.getRegionCode() ?
                <img
                  alt={phoneResult.getRegionCode()}
                  src={`/flags/${phoneResult.getRegionCode().toLowerCase()}.svg`}
                  className="w-1.5 h-1.5" />
              : <>{TargetIcon}</>
              }
            </div>}
          value={props.recipient.target || ""}
          placeholder={t("Enter Target")}
          onChange={e => props.onChangeField(props.index, "target", e)}
          onBlur={e => props.onBlurField(props.index, "target", e)}
          maxLength={320}
          error={props.errors.target}
          type="text" />
      </div>
      {!props.hideRemove ?
        <div  className="t-c-g p-l-0.5 m-t-0.75">
          <div className="c-p" onClick={() => props.onRemoveRecipient(props.index)}><X /></div>
        </div>
      : null}
    </div>
  )

}
import React from 'react';

type PaymentMethodImageProps = {
  active?: boolean
}

export const PaymentMethodImage: React.FC<PaymentMethodImageProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 720 600"
    >
      <g clipPath="url(#clip0_4864_42177)">
        <path
          fill="#3F3D56"
          d="M425.503 0h-209.22c-32.54 0-58.919 26.5-58.919 59.19V539.47c0 32.689 26.379 59.189 58.919 59.189h209.22c32.54 0 58.919-26.5 58.919-59.189V59.189C484.422 26.5 458.043 0 425.503 0z"
        ></path>
        <path
          fill="#fff"
          d="M478.905 59.193v480.279a53.833 53.833 0 01-15.659 37.916 53.34 53.34 0 01-37.744 15.731H216.285a53.335 53.335 0 01-37.743-15.731 53.834 53.834 0 01-15.66-37.916V59.193a53.835 53.835 0 0115.66-37.916 53.34 53.34 0 0137.743-15.732h27.359v9.494c0 4.298 1.7 8.42 4.725 11.46a16.094 16.094 0 0011.407 4.747h122.235c4.279 0 8.382-1.708 11.407-4.747a16.246 16.246 0 004.726-11.46V5.545h27.358a53.345 53.345 0 0137.744 15.732 53.834 53.834 0 0115.659 37.916z"
        ></path>
        <path
          fill="#3F3D56"
          d="M155.859 121.445a4.005 4.005 0 01-2.836-1.182 4.044 4.044 0 01-1.177-2.849v-8.063c0-1.069.423-2.094 1.176-2.85a4.002 4.002 0 015.675 0 4.042 4.042 0 011.175 2.85v8.063a4.04 4.04 0 01-1.177 2.849 4.005 4.005 0 01-2.836 1.182zM155.859 162.789a4.01 4.01 0 01-2.836-1.182 4.048 4.048 0 01-1.177-2.85v-22.194c0-1.069.423-2.094 1.176-2.851a4.001 4.001 0 015.675 0 4.046 4.046 0 011.175 2.851v22.194a4.044 4.044 0 01-1.177 2.85 4.01 4.01 0 01-2.836 1.182zM155.859 196.026a4.005 4.005 0 01-2.836-1.182 4.047 4.047 0 01-1.177-2.849V169.8c0-1.069.423-2.094 1.176-2.85a4.002 4.002 0 015.675 0 4.042 4.042 0 011.175 2.85v22.195a4.043 4.043 0 01-1.177 2.849 4.005 4.005 0 01-2.836 1.182zM484.924 177.885a4.01 4.01 0 01-2.836-1.182 4.044 4.044 0 01-1.177-2.85v-54.155c0-1.069.423-2.094 1.175-2.85a4.006 4.006 0 012.838-1.181c1.064 0 2.085.425 2.838 1.181a4.042 4.042 0 011.175 2.85v54.155a4.044 4.044 0 01-1.177 2.85 4.01 4.01 0 01-2.836 1.182z"
        ></path>
        <path
          fill="#fff"
          d="M329.514 18.49h-31.101a2.505 2.505 0 01-1.774-.738 2.526 2.526 0 010-3.563 2.505 2.505 0 011.774-.739h31.101c.665 0 1.303.266 1.773.739a2.527 2.527 0 010 3.563 2.5 2.5 0 01-1.773.738zM345.566 17.986a2.01 2.01 0 002.006-2.016 2.01 2.01 0 00-2.006-2.016 2.011 2.011 0 00-2.007 2.016c0 1.113.898 2.016 2.007 2.016z"
        ></path>
        <path
          fill="#E6E6E6"
          d="M231.69 29.301h-27.087a5.002 5.002 0 01-3.547-1.476 5.05 5.05 0 01-1.469-3.563 5.05 5.05 0 011.469-3.563 5.002 5.002 0 013.547-1.476h27.087c1.331 0 2.607.53 3.547 1.476a5.05 5.05 0 010 7.126 5.002 5.002 0 01-3.547 1.476zM334.437 240.568H307.35a5.004 5.004 0 01-3.547-1.476 5.05 5.05 0 01-1.469-3.563 5.05 5.05 0 011.469-3.564 5.004 5.004 0 013.547-1.476h27.087c1.331 0 2.607.531 3.547 1.476a5.051 5.051 0 010 7.127 5.003 5.003 0 01-3.547 1.476zM409.265 28.797c2.493 0 4.515-2.03 4.515-4.535 0-2.505-2.022-4.535-4.515-4.535s-4.515 2.03-4.515 4.535c0 2.505 2.022 4.535 4.515 4.535zM423.311 28.797c2.493 0 4.514-2.03 4.514-4.535 0-2.505-2.021-4.535-4.514-4.535-2.494 0-4.515 2.03-4.515 4.535 0 2.505 2.021 4.535 4.515 4.535zM437.356 28.797c2.493 0 4.515-2.03 4.515-4.535 0-2.505-2.022-4.535-4.515-4.535s-4.515 2.03-4.515 4.535c0 2.505 2.022 4.535 4.515 4.535zM407.476 311.117H220.138a18.172 18.172 0 01-12.859-5.359 18.34 18.34 0 01-5.334-12.917v-18.879a18.342 18.342 0 015.334-12.918 18.176 18.176 0 0112.859-5.359h187.338a18.172 18.172 0 0112.858 5.359 18.343 18.343 0 015.335 12.918v18.879a18.34 18.34 0 01-5.335 12.917 18.168 18.168 0 01-12.858 5.359zm-187.338-53.416a16.167 16.167 0 00-11.44 4.768 16.318 16.318 0 00-4.747 11.493v18.879a16.314 16.314 0 004.747 11.492 16.163 16.163 0 0011.44 4.768h187.338a16.163 16.163 0 0011.44-4.768 16.309 16.309 0 004.746-11.492v-18.879a16.311 16.311 0 00-4.746-11.493 16.167 16.167 0 00-11.44-4.768H220.138zM334.437 380.658H307.35a5.004 5.004 0 01-3.547-1.476 5.048 5.048 0 01-1.469-3.563 5.05 5.05 0 011.469-3.563 5.004 5.004 0 013.547-1.476h27.087c1.331 0 2.607.531 3.547 1.476a5.05 5.05 0 010 7.126 5.003 5.003 0 01-3.547 1.476z"
        ></path>
        <path
          fill={props.active ? "#168D13" : "#979797"}
          d="M340.66 526.796h-39.533a7.294 7.294 0 01-5.182-2.151 7.376 7.376 0 010-10.407 7.313 7.313 0 015.182-2.151h39.533c1.942 0 3.804.775 5.177 2.154a7.37 7.37 0 012.144 5.2 7.371 7.371 0 01-2.144 5.201 7.305 7.305 0 01-5.177 2.154z"
        ></path>
        <path
          fill="#E6E6E6"
          d="M407.476 451.208H220.138a18.176 18.176 0 01-12.859-5.359 18.342 18.342 0 01-5.334-12.918v-18.879a18.34 18.34 0 015.334-12.917 18.172 18.172 0 0112.859-5.359h187.338a18.168 18.168 0 0112.858 5.359 18.34 18.34 0 015.335 12.917v18.879a18.343 18.343 0 01-5.335 12.918 18.172 18.172 0 01-12.858 5.359zm-187.338-53.416a16.163 16.163 0 00-11.44 4.768 16.314 16.314 0 00-4.747 11.492v18.879a16.318 16.318 0 004.747 11.493 16.167 16.167 0 0011.44 4.768h187.338a16.167 16.167 0 0011.44-4.768 16.311 16.311 0 004.746-11.493v-18.879a16.309 16.309 0 00-4.746-11.492 16.163 16.163 0 00-11.44-4.768H220.138zM441.87 195.018H199.586v2.016H441.87v-2.016z"
        ></path>
        <path
          fill={props.active ? "#FFB6B6" : "#E6E6E6"}
          d="M435.57 275.006a6.534 6.534 0 002.454 4.253 6.485 6.485 0 004.712 1.329 6.33 6.33 0 001.008-.217l13.478 18.325 11.663-3.599-21.166-23.098a6.508 6.508 0 00-2.69-3.215 6.459 6.459 0 00-4.084-.896 6.188 6.188 0 00-4.132 2.351 6.26 6.26 0 00-1.243 4.767zM605.18 403.66a6.544 6.544 0 01-1.928-4.519 6.545 6.545 0 011.755-4.589c.239-.248.498-.477.775-.682l-6.573-21.807 9.842-7.244 5.788 30.851a6.528 6.528 0 01.959 4.088 6.523 6.523 0 01-1.725 3.826 6.203 6.203 0 01-4.345 1.923 6.185 6.185 0 01-4.427-1.727l-.121-.12zM549.401 586.999l-9.933-.001-4.726-38.49 14.661.001-.002 38.49z"
        ></path>
        <path
          fill="#2F2E41"
          d="M549.75 597.409l-30.545-.002v-.388a11.97 11.97 0 013.482-8.445 11.862 11.862 0 018.407-3.498h.001l5.579-4.253 10.41 4.254h2.667l-.001 12.332z"
        ></path>
        <path
          fill={props.active ? "#FFB6B6" : "#E6E6E6"}
          d="M592.54 586.999l-9.933-.001-4.725-38.49 14.66.001-.002 38.49z"
        ></path>
        <path
          fill="#2F2E41"
          d="M592.89 597.409l-30.546-.002v-.388c0-3.167 1.253-6.205 3.483-8.445a11.858 11.858 0 018.406-3.498h.001l5.58-4.253 10.41 4.254h2.666v12.332zM519.974 380.112s-11.307 15.835-3.145 43.192c8.161 27.357 3.58 51.815 5.87 56.72 2.291 4.904 6.903 9.561 3.092 13.28-3.81 3.718-5.817 6.742-2.807 7.749 3.01 1.008 13.042 71.557 13.042 71.557l17.055 1.008s6.179-32.772-.422-59.219c0 0 1.449-19.2-1.573-24.839-3.021-5.64-2.429-.553-2.223-7.632a62.356 62.356 0 012.212-15.141c1.003-4.032 1.584-44.663 1.584-44.663l22.172 50.189c.666 1.858.916 3.84.732 5.806-.41 2.777-.414 6.928.089 8.38.504 1.453 1.064 1.758.784 4.629-.28 2.871-.28 79.005-.28 79.005h19.062l9.029-76.134a24.934 24.934 0 01-4.013-6.048c-2.007-4.031-2.085-4.253-1.042-7.166 1.042-2.912 1.042-8.586 1.042-10.284 0-1.699 1.042-78.796-12.02-90.136-13.061-11.339-68.24-.253-68.24-.253z"
        ></path>
        <path
          fill="#3F3D56"
          d="M578.51 259.084l-19.042-4.957-8.889-12.316-21.11 2.607-4.493 16.799-15.028 8.764s8.306 88.258 6.33 93.104c-1.976 4.847-9.06 4.447-3.01 10.694 6.05 6.247 6.852 3.731 5.448 10.532s-11.638 13.999 0 13.927c11.638-.071 81.865-1.079 79.859-9.142-2.007-8.062-1.975-6.015-5-11.07-3.026-5.055-5.062-2.23-4.044-9.69 1.018-7.459.015-12.498.015-16.53 0-4.031-3.362-40.665-3.362-40.665l-7.674-52.057z"
        ></path>
        <path
          fill="#3F3D56"
          d="M519.097 268.288s-18.84-2.149-22.853 6.922c-4.013 9.07-14.127 27.958-14.127 27.958s-7.884-8.287-8.648-12.043c-.648-3.188-1.004 2.138-6.3-3.628-2.373-2.584-.367-2.584-3.377-3.592l-6.019-2.015-10.033 9.07s-.196 10.959 5.017 11.086c5.212.127-2.007-2.015 5.016 5.04 3.626 3.642.3 3.039 6.343 6.746 6.043 3.707 5.361 3.692 7.207 5.715 1.846 2.024 11.752 3.497 11.752 3.497l44.364-24.662-8.342-30.094zM568.609 262.897s8.079-11.891 21.888 3.558c13.808 15.449 24.133 85.974 23.485 88.505-.648 2.532-4.289 1.08.04 5.333 4.328 4.254 7.338 1.23 4.328 4.254-3.009 3.023-9.291 5.869-4.144 6.966 5.148 1.097-14.875 7.135-14.875 7.135s-2.918-1.071-3.041-8.133c-.069-3.966 1.937-5.982-1.072-6.99-3.01-1.008.691-7.738.374-8.901-.316-1.163-19.378-52.564-19.378-52.564l-7.605-39.163z"
        ></path>
        <path
          fill={props.active ? "#FFB6B6" : "#E6E6E6"}
          d="M536.855 239.703c12.775 0 23.131-10.403 23.131-23.236s-10.356-23.237-23.131-23.237c-12.774 0-23.13 10.404-23.13 23.237 0 12.833 10.356 23.236 23.13 23.236z"
        ></path>
        <path
          fill="#2F2E41"
          d="M552.078 234.521c-5.468-4.294 2.006-23.181-7.023-19.149 0 0-11.036-7.055-13.253-2.419-2.567 5.368-11.698 2.933-17.306 1.038a13.73 13.73 0 00.933-3.215 25.664 25.664 0 01-3.28 2.207 6.656 6.656 0 01-2.007-1.653 4.107 4.107 0 01-.511-4.646c.933-1.441 2.859-1.885 3.993-3.165a1.383 1.383 0 00.188-1.266 3.634 3.634 0 01.554-3.03 3.603 3.603 0 012.676-1.505c.213.006.424-.04.615-.135a14.09 14.09 0 002.618-2.076c-1.318.064-2.64.027-3.953-.111a20.22 20.22 0 01-.812-.101 8.901 8.901 0 015.487-5.009 25.988 25.988 0 017.946-1.118l4.655-.151a46.296 46.296 0 019.822.342 23.935 23.935 0 019.64 4.1 24.05 24.05 0 016.984 7.833c3.401 6.198 7.083 26.174-7.966 33.229z"
        ></path>
        <path
          fill={props.active ? "#168D13" : "#979797"}
          d="M493.053 343.937l-119.235 42.35a11.755 11.755 0 01-9.008-.484 11.834 11.834 0 01-6.037-6.735l-20.483-58.199a11.899 11.899 0 01.482-9.049 11.819 11.819 0 016.703-6.065l119.236-42.35a11.755 11.755 0 019.008.484 11.842 11.842 0 016.037 6.734l20.483 58.2a11.908 11.908 0 01-.482 9.049 11.82 11.82 0 01-6.704 6.065z"
        ></path>
        <path
          fill="#fff"
          d="M366.377 319.268c-7.904 5.224-18.033 1.293-21.576-4.434l.565-.323c6.729-4.213 16.721-1.866 21.011 4.757zM465.419 334.09a6.431 6.431 0 01-5.177-.093c-3.393-1.694-4.986-6.716-6.265-10.751-.372-1.174-.724-2.283-1.082-3.189l-.005-.014a7.257 7.257 0 01.302-5.488 7.201 7.201 0 014.057-3.688l16.038-5.696a6.441 6.441 0 015.173.093c3.392 1.691 4.985 6.711 6.266 10.745.373 1.176.726 2.288 1.085 3.195.627 1.8.52 3.775-.296 5.497a7.21 7.21 0 01-4.061 3.694l-16.035 5.695zM367.142 350.118a1.69 1.69 0 01-1.722-.288 2.135 2.135 0 01-.694-1.967 1.713 1.713 0 011.194-1.324l6.104-2.168a1.693 1.693 0 011.756.278 2.13 2.13 0 01.692 1.967 1.706 1.706 0 01-1.194 1.322l-6.104 2.168-.032.012zM363.9 364.031a2.989 2.989 0 01-2.898-1.088 3.78 3.78 0 01-.54-3.659 3.021 3.021 0 012.524-1.894l22.737-7.665a3.002 3.002 0 012.959 1.082 3.778 3.778 0 01.538 3.658 3.03 3.03 0 01-2.524 1.892l-22.737 7.665-.059.009zM484.216 283.292l-141.062 50.103.238.677 141.062-50.103-.238-.677z"
        ></path>
        <path
          fill={props.active ? "#168D13" : "#979797"}
          d="M461.786 322.203c2.216 0 4.013-1.805 4.013-4.031a4.022 4.022 0 00-4.013-4.031 4.021 4.021 0 00-4.013 4.031c0 2.226 1.796 4.031 4.013 4.031zM474.828 323.211a3.017 3.017 0 003.01-3.023 3.017 3.017 0 00-3.01-3.024 3.017 3.017 0 00-3.01 3.024 3.017 3.017 0 003.01 3.023z"
        ></path>
        <path
          fill={props.active ? "#635BFF" : "#424770"}
          fillRule="evenodd"
          d="M259.712 141.769c0 9.362-7.444 14.744-18.279 14.744a35.973 35.973 0 01-14.206-2.973v-12.447c4.374 2.399 9.892 4.183 14.206 4.183 2.919 0 5.006-.786 5.006-3.195 0-6.218-19.784-3.92-19.784-18.353 0-9.262 7.023-14.805 17.597-14.805a34.083 34.083 0 0112.912 2.409v12.285a28.941 28.941 0 00-12.912-3.346c-2.759 0-4.424.786-4.424 2.822 0 5.866 19.834 3.094 19.884 18.676zM274.971 109.81h9.892v11.147h-9.892v18.564c0 7.74 8.227 5.332 9.892 4.656v10.613c-1.725.947-4.845 1.723-9.119 1.723a13.023 13.023 0 01-5.2-.905 13.08 13.08 0 01-4.42-2.897 13.186 13.186 0 01-3.864-9.632l.05-41.896 12.711-2.722-.05 11.349zM311.881 109.81v12.034c-1.616-.575-6.822-1.311-9.892 2.721v31.011h-13.012V109.81h11.246l.833 3.87c3.07-5.644 9.159-4.495 10.825-3.87zM315.061 95.166l13.062-2.772v10.613l-13.062 2.832V95.166z"
          clipRule="evenodd"
        ></path>
        <path
          fill={props.active ? "#635BFF" : "#424770"}
          d="M328.123 109.81h-13.062v45.766h13.062V109.81z"
        ></path>
        <path
          fill={props.active ? "#635BFF" : "#424770"}
          fillRule="evenodd"
          d="M355.301 108.923a14.693 14.693 0 00-10.203 4.082l-.672-3.245h-11.457v60.984l13.012-2.771.05-14.806a14.625 14.625 0 009.22 3.296c9.32 0 17.808-7.528 17.808-24.108-.05-15.168-8.648-23.432-17.758-23.432zm-3.12 36.041a7.784 7.784 0 01-6.15-2.46l-.05-19.411a7.743 7.743 0 016.2-2.56c4.735 0 8.016 5.332 8.016 12.185 0 7.015-3.23 12.246-8.016 12.246zM414.081 132.879c0-13.394-6.45-23.956-18.79-23.956-12.391 0-19.895 10.562-19.895 23.846 0 15.742 8.859 23.694 21.56 23.694a28.923 28.923 0 0014.417-3.396v-10.462a27.561 27.561 0 01-12.752 2.873c-5.046 0-9.531-1.774-10.102-7.942h25.462c0-.686.1-3.407.1-4.657zm-25.713-4.968c0-5.916 3.592-8.375 6.872-8.375 3.171 0 6.562 2.459 6.562 8.375h-13.434z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#CCC"
          d="M0 598.801a1.201 1.201 0 00.736 1.109c.146.06.301.09.458.09h717.612c.317 0 .62-.126.844-.351a1.202 1.202 0 00-.844-2.048H1.194a1.197 1.197 0 00-1.104.74c-.06.146-.09.302-.09.46z"
        ></path>
        <path
          fill="#F2F2F2"
          d="M249.462 572.812a2.8 2.8 0 01-1.636-.436 2.837 2.837 0 01-.406-4.468l.193-.771-.076-.185a7.584 7.584 0 00-2.799-3.379 7.546 7.546 0 00-4.194-1.257 7.544 7.544 0 00-4.185 1.288 7.592 7.592 0 00-2.774 3.4c-2.282 5.521-5.187 11.052-5.903 16.889a22.579 22.579 0 00.393 7.731 90.48 90.48 0 01-8.16-37.43 87.151 87.151 0 01.537-9.704 90.72 90.72 0 011.234-7.867 91.353 91.353 0 0117.997-38.639 24.215 24.215 0 0010.044-10.468 18.568 18.568 0 001.675-5.058c-.489.065-1.843-7.417-1.475-7.876-.681-1.038-1.901-1.555-2.645-2.568-3.701-5.042-8.801-4.162-11.463 2.689-5.687 2.884-5.742 7.666-2.253 12.265 2.22 2.926 2.525 6.885 4.473 10.018-.2.257-.409.507-.609.765a91.854 91.854 0 00-9.533 15.172 38.302 38.302 0 00-2.267-17.717c-2.169-5.258-6.236-9.686-9.818-14.232-4.301-5.46-13.122-3.077-13.88 3.844l-.022.201a26.24 26.24 0 011.562.96 3.848 3.848 0 01.49 5.992 3.805 3.805 0 01-2.03.992l-.078.012c.19 1.926.525 3.835 1.002 5.71-4.594 17.849 5.325 24.35 19.488 24.642.312.161.617.322.93.475a93.992 93.992 0 00-5.019 23.724 89.203 89.203 0 00.065 14.343l-.025-.17a23.493 23.493 0 00-7.976-13.553c-6.138-5.065-14.81-6.93-21.432-11.002a4.367 4.367 0 00-4.72-.011 4.39 4.39 0 00-1.622 1.855 4.431 4.431 0 00-.374 2.442l.027.177a25.51 25.51 0 012.878 1.393 26.24 26.24 0 011.562.96 3.841 3.841 0 011.608 4.022 3.84 3.84 0 01-1.118 1.97 3.814 3.814 0 01-2.03.993l-.078.012-.16.024a38.228 38.228 0 006.998 11.008c2.873 15.582 15.211 17.06 28.409 12.523h.008a93.994 93.994 0 006.261 18.36h22.365c.081-.249.153-.507.225-.757-2.07.131-4.149.007-6.189-.37 1.66-2.045 3.319-4.107 4.978-6.152.037-.039.072-.079.105-.121.841-1.047 1.691-2.086 2.533-3.133v-.001a37.553 37.553 0 00-1.091-9.526z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_4864_42177">
          <path fill="#fff" d="M0 0H720V600H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
import React, { useEffect, useState } from "react";
import { Breakpoints } from "../../Consts";

type Type = "normal" | "destructive" | "primary";

type SecondaryButtonProps = {
  children?: string,
  fullWidth?: "always" | "mobile",
  disabled?: boolean,
  type?: Type,
  submit?: boolean,
  onClick?: () => void
}
export const SecondaryButton: React.FC<SecondaryButtonProps> = (props) => {

  function getWindowSize() {
    const {innerWidth} = window;
    return {innerWidth};
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const title = props.children ? props.children : "Cancel"
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const type = props.type ? props.type : "normal";

  let typeClass: string;
  switch (type) {
    case "destructive":
      typeClass = "t-c-r";
      break;
    case "primary":
      typeClass = "t-c-g";
      break;
    case "normal":
    default:
      typeClass = "t-c-gray";
  }

  let fullWidthClass: string;
  switch (props.fullWidth) {
    case "always":
      fullWidthClass = "w-100%";
      break;
    case "mobile":
      fullWidthClass = windowSize.innerWidth < Breakpoints.Medium ? "w-100%" : "";
      break;
    default:
      fullWidthClass = "";
  }

  return (
    <button
      type={props.submit ? "submit" : "button"}
      disabled={props.disabled}
      onClick={() => props.onClick ? props.onClick() : null}
      className={`ws-n c-p t-a-c f-s-1.25 bg-c-w ${typeClass} ${fullWidthClass}`}>
      {title}
    </button>
  );
}
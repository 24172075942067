import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { messageState } from '../atoms';
import { X } from '../images';

export const MessagePopup: React.FC = () => {

  const containerRef = useRef<HTMLDivElement>(null);
  const [message, setMessage] = useRecoilState(messageState);
  const closeTimeout = useRef<ReturnType<typeof setTimeout>>();

  const close = useCallback(() => {
    setMessage(undefined);
  }, [setMessage]);

  const clickOutside = useCallback((event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      close();
    }
  }, [close]);

  useEffect(() => {
    if (closeTimeout.current) {
      clearTimeout(closeTimeout.current);
    }
    if (message && message.timeout) {
      closeTimeout.current = setTimeout(() => setMessage(undefined), message.timeout);
    }

    return () => {
      if (closeTimeout.current) {
        clearTimeout(closeTimeout.current);
      }
    }
  }, [message, setMessage])

  useLayoutEffect(() => {
    if (message) {
      document.addEventListener('click', clickOutside);

      return () => {
        document.removeEventListener('click', clickOutside);
      };
    }
  }, [clickOutside, message]);

  let classNames = "";
  switch (message?.type) {
    case "error":
      classNames = "bg-c-lr b-c-r t-c-r";
      break;
    case "success":
      classNames = "bg-c-lg b-c-g t-c-g";
      break;
    case "info":
      classNames = "bg-c-lb b-c-blue t-c-blue";
      break;
    case "warning":
      classNames = "bg-c-ly b-c-yellow t-c-yellow";
      break;
  }

  return (
    message ?
      <div
        ref={containerRef}
        className="pos-f pos-b-2 z-9999 w-100% max-w-30 m-h-a p-h-2 pos-l-0 pos-r-0">
        <div className={`w-100% b-w-1px b-s-s p-h-1.5 p-v-1 t-a-l f-s-0.85 d-f a-i-c ${classNames}`}>
          <div className="fb-a d-f a-i-c">
            {message.text}
          </div>
          <div className="d-f a-i-c c-p" onClick={close}>
            <X />
          </div>
        </div>
      </div>
    : null
  );

}
import React from "react";
import { getCheckBoxZise } from "../Helper";


type DashboardIconProps = {
  color?: string
  size?: string
}

export const DashboardIcon: React.FC<DashboardIconProps> = (props) => {
  const color = props.color
  const size = getCheckBoxZise(props.size);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path
      fill={color }
      d="M0 7.2H5.14286V24H0V7.2ZM9.6 0H14.4V24H9.6V0ZM19.2 13.7143H24V24H19.2V13.7143Z"/>
    </svg>
  );
}
import React, { useState, useCallback } from 'react';
import { Popover } from '../ui';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { teamState } from '../atoms';

export const NewMenu = () => {

  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const team = useRecoilValue(teamState);

  const toggleActive = useCallback(() => setActive(!active), [active]);

  const activator = (
    <div className="f-w-b d-f a-i-c p-h-0.5 t-c-g c-p m-r-0.5" onClick={toggleActive}>
      <div className="d-f a-i-c">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#168D13"/>
        </svg>

      </div>
      <div className="d-f a-i-c m-l-0.75 f-w-400 f-s-1.25 t-c-g c-p">{t("New")}</div>
    </div>
  )

  return (
    <Popover
      active={active}
      activator={activator}
      placement="bottom-start"
      onClose={toggleActive}>
      <div className="m-t-2px bg-c-w d-f p-v-0.5 fb-d-c b-r-2px b-w-1px b-s-s b-c-lightestGray f-s-1">
        <Link to={`/${team?.slug}/monitors/new`} onClick={toggleActive} className="p-h-1 p-v-0.5 t-c-b">
          {t("Monitor")}
        </Link>
        <Link to={`/${team?.slug}/groups/new`} onClick={toggleActive} className="p-h-1 p-v-0.5 t-c-b">
          {t("Group")}
        </Link>
        <Link to={`/${team?.slug}/pages/new`} onClick={toggleActive} className="p-h-1 p-v-0.5 t-c-b">
          {t("Page")}
        </Link>
        <Link to={`/${team?.slug}/alerts/new`} onClick={toggleActive} className="p-h-1 p-v-0.5 t-c-b">
          {t("Alert")}
        </Link>
      </div>
    </Popover>
  );
}

import React from "react";
import { getCheckBoxZise } from "../Helper";


type PageIconProps = {
  color?: string
  size?: string
}

export const PageIcon: React.FC<PageIconProps> = (props) => {
  const color = props.color ? props.color : "#4A4A4A"
  const size = getCheckBoxZise(props.size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path fill={color} d="M0 0H24V10H0z"></path>
      <path fill={color} d="M0 14H10V24H0z"></path>
      <path fill={color} d="M14 14H24V24H14z"></path>
    </svg>
  );
}
import React from 'react';

export const CheckoutImage = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="500"
    height="271"
    fill="none"
    viewBox="0 0 500 271"
    >
      <g clipPath="url(#clip0_4840_41762)">
        <path d="M271.325 143.216V143.804C271.324 149.204 269.178 154.382 265.36 158.199C261.541 162.017 256.362 164.161 250.963 164.16H21.1311C15.7325 164.159 10.5553 162.015 6.73797 158.197C2.92066 154.38 0.775889 149.203 0.775375 143.804V143.216H271.325Z" fill="#F2F2F2"/>
        <path d="M48.8449 46.3144C32.2359 50.4166 17.326 37.6385 14.7226 25.7841L15.879 25.5774C29.8781 22.483 45.505 32.4897 48.8449 46.3144Z" fill="#168D13"/>
        <path d="M450.315 265.85C445.512 263.776 444.42 256.219 448.441 252.87C452.461 249.522 459.696 251.963 460.867 257.062L456.386 225.008C455.356 217.639 454.531 209.401 458.985 203.44C463.155 197.858 471.302 196.069 477.843 198.472C484.383 200.875 489.217 206.869 491.268 213.529C493.319 220.188 492.835 227.425 491.044 234.159C489.253 240.893 486.211 247.218 483.134 253.47C479.981 259.876 476.157 266.818 469.418 269.176C462.98 271.429 450.315 265.85 450.315 265.85Z" fill="#F2F2F2"/>
        <path d="M471.366 197.864C474.31 207.054 475.712 216.669 475.515 226.317C475.33 235.923 473.604 245.436 470.404 254.495C468.583 259.634 466.275 264.588 463.512 269.288C463.392 269.509 463.363 269.768 463.431 270.011C463.499 270.253 463.659 270.459 463.876 270.586C464.093 270.713 464.351 270.75 464.595 270.691C464.839 270.631 465.051 270.479 465.185 270.266C470.146 261.789 473.67 252.551 475.617 242.924C477.592 233.252 477.987 223.324 476.788 213.525C476.113 208.034 474.923 202.618 473.235 197.349C472.854 196.167 470.982 196.672 471.366 197.864V197.864Z" fill="white"/>
        <path d="M447.467 254.05L462.806 270.169C463.668 271.075 465.037 269.703 464.176 268.799L448.838 252.68C447.976 251.774 446.607 253.146 447.467 254.05V254.05Z" fill="white"/>
        <path d="M434.437 193.189C433.891 193.19 433.363 192.993 432.95 192.636C423.478 186.468 415.048 178.835 407.974 170.02C407.541 169.609 407.286 169.045 407.264 168.449C407.241 167.853 407.453 167.272 407.853 166.829L412.503 161.695L413.46 162.563L408.811 167.697C408.634 167.892 408.542 168.149 408.555 168.412C408.568 168.675 408.685 168.922 408.88 169.098L408.948 169.17C415.952 177.906 424.304 185.469 433.689 191.575L433.774 191.64C433.969 191.816 434.226 191.908 434.489 191.895C434.752 191.882 434.998 191.765 435.175 191.57L444.247 181.552C444.415 181.366 444.507 181.122 444.503 180.871C444.499 180.62 444.4 180.379 444.226 180.198C442.124 179.053 440.073 177.804 438.128 176.486L438.853 175.417C440.787 176.727 442.827 177.968 444.919 179.104L445.044 179.193C445.267 179.395 445.447 179.638 445.576 179.909C445.704 180.18 445.778 180.474 445.793 180.773C445.808 181.073 445.763 181.372 445.662 181.655C445.561 181.937 445.406 182.197 445.204 182.419L436.133 192.438C435.932 192.661 435.689 192.842 435.418 192.97C435.146 193.098 434.852 193.172 434.553 193.186C434.514 193.188 434.476 193.189 434.437 193.189Z" fill="#3F3D56"/>
        <path d="M424.437 176.323C427.286 178.901 430.212 180.316 430.974 179.476C431.074 179.334 431.136 179.169 431.156 178.996C431.177 178.824 431.154 178.649 431.09 178.488C430.781 177.537 430.287 176.656 429.637 175.897C427.104 174.095 424.643 172.15 422.253 170.064C421.504 169.818 420.929 169.825 420.651 170.135C420.611 170.179 420.579 170.229 420.554 170.283C420.076 171.259 421.736 173.875 424.437 176.323Z" fill="#168D13"/>
        <path d="M407.266 155.554C407.29 156.142 407.545 156.697 407.976 157.098C411.804 161.828 416.01 166.237 420.554 170.283C423.88 173.25 427.399 175.991 431.09 178.488C431.704 178.907 432.324 179.314 432.951 179.715C433.362 180.075 433.89 180.273 434.437 180.271C434.476 180.271 434.514 180.264 434.553 180.264C434.853 180.25 435.148 180.177 435.42 180.048C435.691 179.92 435.935 179.738 436.136 179.515L445.206 169.502C445.612 169.052 445.824 168.459 445.794 167.853C445.763 167.247 445.494 166.679 445.044 166.271L444.922 166.181C435.019 160.832 426.555 153.171 420.25 143.848L420.154 143.732C419.704 143.325 419.111 143.114 418.505 143.144C417.899 143.175 417.331 143.444 416.923 143.894L407.853 153.907C407.652 154.129 407.496 154.389 407.395 154.672C407.294 154.955 407.25 155.254 407.266 155.554V155.554ZM408.558 155.49C408.551 155.36 408.569 155.231 408.613 155.109C408.656 154.987 408.723 154.875 408.81 154.779L417.88 144.759C417.967 144.662 418.074 144.583 418.193 144.528C418.311 144.473 418.44 144.441 418.571 144.436C418.808 144.422 419.043 144.496 419.23 144.643C425.628 154.081 434.2 161.844 444.224 167.279C444.398 167.459 444.498 167.699 444.503 167.949C444.508 168.2 444.417 168.443 444.25 168.629L435.173 178.649C435.087 178.747 434.982 178.826 434.864 178.881C434.746 178.937 434.619 178.968 434.488 178.972C434.226 178.985 433.968 178.894 433.771 178.72L433.687 178.656C432.318 177.777 430.968 176.858 429.637 175.897C427.104 174.095 424.643 172.15 422.253 170.064C417.426 165.853 412.973 161.232 408.945 156.252L408.881 156.181C408.687 156.001 408.571 155.753 408.558 155.49V155.49Z" fill="#3F3D56"/>
        <path d="M430.974 166.558C431.733 165.72 430.038 162.948 427.187 160.367C424.337 157.786 421.411 156.373 420.652 157.212C419.893 158.05 421.588 160.822 424.438 163.403C427.289 165.984 430.215 167.396 430.974 166.558Z" fill="#168D13"/>
        <path d="M373.979 262.636L366.059 262.636L362.291 232.087L373.98 232.088L373.979 262.636Z" fill="#FFB8B8"/>
        <path d="M375.999 270.313L350.462 270.312V269.989C350.462 268.684 350.719 267.391 351.218 266.186C351.718 264.98 352.45 263.884 353.373 262.961C354.296 262.038 355.392 261.306 356.598 260.806C357.804 260.307 359.096 260.05 360.402 260.05H360.402L375.999 260.05L375.999 270.313Z" fill="#2F2E41"/>
        <path d="M397.235 262.636L389.316 262.636L385.548 232.087L397.237 232.088L397.235 262.636Z" fill="#FFB8B8"/>
        <path d="M399.255 270.313L373.718 270.312V269.989C373.718 268.684 373.975 267.391 374.475 266.186C374.974 264.98 375.706 263.884 376.629 262.961C377.552 262.038 378.648 261.306 379.854 260.806C381.06 260.307 382.353 260.05 383.658 260.05H383.659L399.256 260.05L399.255 270.313Z" fill="#2F2E41"/>
        <path d="M402.551 139.178L400.66 251.387C400.654 251.742 400.574 252.092 400.427 252.416C400.279 252.739 400.067 253.028 399.802 253.266C399.538 253.503 399.227 253.684 398.89 253.796C398.553 253.907 398.196 253.949 397.842 253.916L388.574 253.074C387.966 253.019 387.398 252.75 386.969 252.316C386.54 251.881 386.279 251.309 386.232 250.7L380.806 180.708C380.793 180.544 380.718 180.39 380.596 180.279C380.474 180.169 380.314 180.109 380.149 180.112C379.984 180.115 379.827 180.181 379.709 180.297C379.592 180.412 379.523 180.569 379.516 180.733L376.804 252.328C376.779 252.996 376.496 253.628 376.015 254.092C375.533 254.555 374.891 254.814 374.222 254.814H364.283C363.625 254.814 362.992 254.563 362.512 254.112C362.033 253.662 361.744 253.045 361.704 252.388L354.746 138.532L396.091 128.196L402.551 139.178Z" fill="#2F2E41"/>
        <path d="M372.562 33.816C381.325 33.816 388.429 26.7122 388.429 17.9492C388.429 9.18629 381.325 2.08252 372.562 2.08252C363.799 2.08252 356.695 9.18629 356.695 17.9492C356.695 26.7122 363.799 33.816 372.562 33.816Z" fill="#FFB8B8"/>
        <path d="M353.33 142.516C353.055 142.251 352.836 141.933 352.684 141.582C352.533 141.231 352.453 140.854 352.449 140.472L351.378 63.9564C351.314 60.0641 352.485 56.2516 354.723 53.0665C356.961 49.8813 360.151 47.4875 363.835 46.2289C365.321 39.4849 373.233 38.6402 377.845 38.7411C378.996 38.7659 380.124 39.0679 381.134 39.6215C382.144 40.1751 383.005 40.964 383.645 41.9213L386.951 46.8806L394.238 51.1522C397.611 53.1207 400.271 56.1094 401.835 59.6874C403.399 63.2654 403.786 67.2478 402.94 71.06L401.818 76.1631C397.868 94.2252 398.243 112.965 402.915 130.855L405.173 139.462C405.29 139.91 405.299 140.38 405.198 140.833C405.097 141.285 404.889 141.707 404.592 142.062C404.295 142.418 403.918 142.698 403.491 142.878C403.064 143.058 402.6 143.134 402.138 143.098L399.516 142.896C398.835 142.844 398.194 142.555 397.706 142.078C397.218 141.601 396.913 140.967 396.846 140.287L396.412 135.948C396.404 135.869 396.367 135.795 396.307 135.741C396.248 135.687 396.171 135.657 396.091 135.657C396.011 135.657 395.933 135.687 395.874 135.741C395.815 135.795 395.777 135.869 395.769 135.948L395.349 140.149C395.278 140.859 394.949 141.518 394.424 142C393.899 142.483 393.214 142.756 392.501 142.767L355.4 143.338C355.385 143.338 355.37 143.338 355.354 143.338C354.598 143.34 353.871 143.045 353.33 142.516Z" fill="#3F3D56"/>
        <path d="M373.803 27.0951C373.868 29.3381 375.425 30.6357 377.337 31.8105C379.249 32.9854 381.484 33.4948 383.673 33.9861C383.967 34.1423 384.297 34.2201 384.63 34.2119C384.962 34.2037 385.287 34.1099 385.574 33.9395C385.86 33.7691 386.097 33.5278 386.262 33.239C386.428 32.9502 386.516 32.6236 386.519 32.2906L388.527 24.6527C389.238 22.2128 389.754 19.7208 390.073 17.1996C390.557 12.3487 389.407 7.13022 386.021 3.62284C382.635 0.115457 376.763 -1.13048 372.706 1.57189C367.891 -4.578 345.881 9.00942 351.31 13.2859C352.276 14.0467 353.753 13.084 354.979 12.9907C356.205 12.8974 357.579 13.1131 358.357 14.0645C359.375 15.3076 358.932 17.1592 359.351 18.7099C359.524 19.2555 359.804 19.7614 360.174 20.1984C360.544 20.6353 360.997 20.9948 361.506 21.2559C362.016 21.517 362.572 21.6747 363.143 21.7199C363.713 21.765 364.287 21.6968 364.832 21.519C365.845 21.0195 366.799 20.4065 367.674 19.6917C368.614 19.0653 369.803 18.6025 370.861 18.9975C372.257 19.5183 372.752 21.2266 372.878 22.7105C373.091 24.1906 373.4 25.6553 373.803 27.0951Z" fill="#2F2E41"/>
        <path opacity="0.2" d="M370.098 69.6194L367.514 101.274L353.947 134.221L373.974 101.92L370.098 69.6194Z" fill="black"/>
        <path d="M350.775 146.49C351.516 145.895 352.117 145.146 352.536 144.293C352.956 143.441 353.183 142.508 353.203 141.558C353.222 140.609 353.034 139.667 352.65 138.798C352.266 137.929 351.696 137.155 350.98 136.531L359.623 115.125L347.864 117.499L341.46 137.463C340.49 138.734 340.021 140.317 340.14 141.912C340.26 143.506 340.96 145.001 342.108 146.114C343.256 147.227 344.773 147.88 346.37 147.949C347.968 148.018 349.535 147.499 350.775 146.49V146.49Z" fill="#FFB8B8"/>
        <path d="M350.146 137.049L343.49 134.65C342.804 134.399 342.237 133.901 341.9 133.253C341.564 132.605 341.482 131.854 341.671 131.149L352.651 90.9545L354.326 54.6276C354.376 53.5312 354.644 52.4557 355.114 51.4637C355.584 50.4716 356.246 49.5829 357.062 48.8492C357.879 48.1155 358.833 47.5514 359.869 47.1897C360.906 46.828 362.003 46.6759 363.099 46.7423C364.195 46.8088 365.266 47.0924 366.251 47.5766C367.236 48.0608 368.115 48.7361 368.837 49.563C369.559 50.39 370.109 51.3522 370.455 52.3937C370.802 53.4352 370.938 54.5352 370.856 55.6298L367.866 95.4069L353.875 135.278C353.663 135.88 353.258 136.396 352.724 136.746C352.19 137.096 351.555 137.261 350.918 137.215C350.655 137.195 350.395 137.139 350.146 137.049V137.049Z" fill="#3F3D56"/>
        <path d="M418.743 148.589C419.197 147.755 419.463 146.832 419.522 145.884C419.581 144.936 419.431 143.987 419.083 143.103C418.735 142.219 418.198 141.423 417.509 140.769C416.82 140.116 415.996 139.621 415.095 139.32L414.823 116.237L404.887 122.958L406.669 143.848C406.264 145.395 406.44 147.037 407.165 148.462C407.889 149.888 409.112 150.998 410.6 151.582C412.088 152.166 413.739 152.184 415.24 151.633C416.741 151.081 417.987 149.998 418.743 148.589L418.743 148.589Z" fill="#FFB8B8"/>
        <path d="M414.525 140.12L407.458 140.471C406.729 140.503 406.013 140.262 405.453 139.793C404.893 139.325 404.528 138.664 404.431 137.94L399.078 96.6185L386.626 62.4511C386.25 61.4198 386.083 60.3242 386.135 59.2277C386.186 58.1313 386.455 57.0561 386.926 56.0645C387.396 55.0729 388.059 54.1848 388.876 53.4517C389.693 52.7187 390.648 52.1554 391.685 51.7946C392.721 51.4338 393.819 51.2826 394.915 51.3499C396.01 51.4173 397.082 51.7017 398.066 52.1867C399.051 52.6717 399.929 53.3476 400.65 54.1752C401.371 55.0027 401.921 55.9653 402.266 57.0071L414.833 94.8653L417.283 137.048C417.32 137.686 417.145 138.318 416.787 138.847C416.429 139.376 415.907 139.772 415.302 139.975C415.051 140.059 414.789 140.107 414.525 140.12V140.12Z" fill="#3F3D56"/>
        <path d="M499.354 270.641H253.223C253.052 270.641 252.888 270.573 252.767 270.452C252.645 270.331 252.577 270.166 252.577 269.995C252.577 269.824 252.645 269.659 252.767 269.538C252.888 269.417 253.052 269.349 253.223 269.349H499.354C499.525 269.349 499.69 269.417 499.811 269.538C499.932 269.659 500 269.824 500 269.995C500 270.166 499.932 270.331 499.811 270.452C499.69 270.573 499.525 270.641 499.354 270.641Z" fill="#3F3D56"/>
        <path d="M208.803 131.168C205.093 131.168 202.142 130.065 200.032 127.888C195.264 122.969 195.562 113.486 195.802 105.867C195.872 103.651 195.938 101.557 195.871 99.8028L195.871 99.7764C195.888 96.3497 197.256 93.068 199.679 90.6444C202.101 88.2207 205.382 86.8507 208.809 86.8322H239.569C243.274 86.8322 246.222 87.934 248.331 90.1076C253.099 95.0231 252.804 104.503 252.566 112.121C252.497 114.342 252.432 116.44 252.5 118.197C252.488 121.629 251.121 124.917 248.697 127.346C246.273 129.775 242.988 131.149 239.556 131.168H208.803ZM197.163 99.7672C197.23 101.563 197.164 103.674 197.094 105.908C196.861 113.283 196.572 122.462 200.96 126.989C202.843 128.932 205.409 129.876 208.803 129.876H239.554C242.64 129.86 245.595 128.626 247.777 126.444C249.959 124.262 251.192 121.306 251.208 118.22C251.139 116.445 251.205 114.325 251.275 112.08C251.505 104.707 251.791 95.5296 247.403 91.0072C245.521 89.0673 242.958 88.1241 239.569 88.1241H208.81C205.727 88.1414 202.776 89.3735 200.595 91.5531C198.415 93.7327 197.181 96.6842 197.163 99.7672V99.7672Z" fill="#3F3D56"/>
        <path d="M42.049 47.5033C36.6553 47.4706 31.3789 45.9252 26.8199 43.0427C20.1409 38.8925 15.0979 32.2457 13.6589 25.6969L13.5147 25.0405L15.3196 24.7175C29.5274 21.5818 45.5974 31.6867 49.04 45.9302L49.191 46.5548L48.5672 46.709C46.4346 47.2368 44.2459 47.5035 42.049 47.5033ZM15.0771 26.0736C16.6274 32.081 21.3324 38.1115 27.5018 41.9453C33.4668 45.7628 40.6955 47.077 47.6225 45.6034C44.0206 32.3334 28.9382 23.0214 15.586 25.9821L15.0771 26.0736Z" fill="#3F3D56"/>
        <path d="M24.127 120.917C19.9004 120.917 16.4666 119.026 14.4507 115.576C13.3207 113.569 12.7268 111.304 12.7261 109.001C12.7253 106.697 13.3179 104.432 14.4466 102.424C16.4948 98.9152 20.0104 97.0116 24.3523 97.0781H65.3377C69.665 97.0229 73.1832 98.918 75.2327 102.431C76.3615 104.439 76.9543 106.703 76.9541 109.006C76.9539 111.309 76.3607 113.574 75.2315 115.581C73.1829 119.086 69.6656 120.958 65.328 120.916H24.3418C24.27 120.917 24.1984 120.917 24.127 120.917ZM24.151 98.3686C20.3817 98.3686 17.337 100.035 15.5624 103.076C14.5456 104.885 14.0118 106.925 14.0125 109C14.0132 111.076 14.5483 113.116 15.5663 114.924C17.3707 118.013 20.4778 119.684 24.3322 119.624H65.3378C69.1961 119.685 72.3128 118.014 74.116 114.929C75.1332 113.12 75.6676 111.081 75.6677 109.006C75.6679 106.931 75.1337 104.891 74.1167 103.082C72.3124 99.9893 69.195 98.3238 65.3469 98.3701H24.3388C24.2762 98.3692 24.2132 98.3686 24.151 98.3686H24.151Z" fill="#3F3D56"/>
        <path d="M219.321 102.194H206.401C205.544 102.194 204.723 101.854 204.117 101.248C203.511 100.643 203.171 99.8211 203.171 98.9644C203.171 98.1078 203.511 97.2862 204.117 96.6804C204.723 96.0747 205.544 95.7344 206.401 95.7344H219.321C220.178 95.7344 220.999 96.0747 221.605 96.6804C222.211 97.2862 222.551 98.1078 222.551 98.9644C222.551 99.8211 222.211 100.643 221.605 101.248C220.999 101.854 220.178 102.194 219.321 102.194Z" fill="#168D13"/>
        <path d="M219.321 112.531H206.401C205.544 112.531 204.723 112.19 204.117 111.585C203.511 110.979 203.171 110.157 203.171 109.301C203.171 108.444 203.511 107.622 204.117 107.017C204.723 106.411 205.544 106.071 206.401 106.071H219.321C220.178 106.071 220.999 106.411 221.605 107.017C222.211 107.622 222.551 108.444 222.551 109.301C222.551 110.157 222.211 110.979 221.605 111.585C220.999 112.19 220.178 112.531 219.321 112.531Z" fill="#168D13"/>
        <path d="M219.321 122.867H206.401C205.544 122.867 204.723 122.527 204.117 121.921C203.511 121.315 203.171 120.493 203.171 119.637C203.171 118.78 203.511 117.959 204.117 117.353C204.723 116.747 205.544 116.407 206.401 116.407H219.321C220.178 116.407 220.999 116.747 221.605 117.353C222.211 117.959 222.551 118.78 222.551 119.637C222.551 120.493 222.211 121.315 221.605 121.921C220.999 122.527 220.178 122.867 219.321 122.867Z" fill="#168D13"/>
        <path d="M241.931 102.194H229.011C228.155 102.194 227.333 101.854 226.727 101.248C226.121 100.643 225.781 99.8211 225.781 98.9644C225.781 98.1078 226.121 97.2862 226.727 96.6804C227.333 96.0747 228.155 95.7344 229.011 95.7344H241.931C242.788 95.7344 243.61 96.0747 244.215 96.6804C244.821 97.2862 245.162 98.1078 245.162 98.9644C245.162 99.8211 244.821 100.643 244.215 101.248C243.61 101.854 242.788 102.194 241.931 102.194Z" fill="#168D13"/>
        <path d="M241.931 112.531H229.011C228.155 112.531 227.333 112.19 226.727 111.585C226.121 110.979 225.781 110.157 225.781 109.301C225.781 108.444 226.121 107.622 226.727 107.017C227.333 106.411 228.155 106.071 229.011 106.071H241.931C242.788 106.071 243.61 106.411 244.215 107.017C244.821 107.622 245.162 108.444 245.162 109.301C245.162 110.157 244.821 110.979 244.215 111.585C243.61 112.19 242.788 112.531 241.931 112.531Z" fill="#168D13"/>
        <path d="M241.931 122.867H229.011C228.155 122.867 227.333 122.527 226.727 121.921C226.121 121.315 225.781 120.493 225.781 119.637C225.781 118.78 226.121 117.959 226.727 117.353C227.333 116.747 228.155 116.407 229.011 116.407H241.931C242.788 116.407 243.61 116.747 244.215 117.353C244.821 117.959 245.162 118.78 245.162 119.637C245.162 120.493 244.821 121.315 244.215 121.921C243.61 122.527 242.788 122.867 241.931 122.867Z" fill="#168D13"/>
        <path d="M271.325 56.2894H0.775345V57.5815H271.325V56.2894Z" fill="#3F3D56"/>
        <path d="M250.007 164.535H21.3184C15.6664 164.528 10.2477 162.28 6.25107 158.284C2.25447 154.287 0.00638453 148.868 0 143.216V32.1022C0.00638453 26.4502 2.25447 21.0315 6.25107 17.0349C10.2477 13.0383 15.6664 10.7902 21.3184 10.7838H250.007C255.659 10.7902 261.077 13.0383 265.074 17.0349C269.071 21.0315 271.319 26.4502 271.325 32.1022V143.216C271.319 148.868 269.071 154.287 265.074 158.284C261.077 162.28 255.659 164.528 250.007 164.535V164.535ZM21.3184 12.0758C16.0089 12.0819 10.9186 14.1937 7.16427 17.9481C3.4099 21.7025 1.29806 26.7927 1.29202 32.1022V143.216C1.29806 148.526 3.40991 153.616 7.16428 157.37C10.9186 161.125 16.0089 163.237 21.3184 163.243H250.007C255.316 163.237 260.406 161.125 264.161 157.37C267.915 153.616 270.027 148.526 270.033 143.216V32.1022C270.027 26.7927 267.915 21.7025 264.161 17.9481C260.406 14.1937 255.316 12.0819 250.007 12.0758H21.3184Z" fill="#3F3D56"/>
        <path d="M471.376 255.844C471.254 260.403 470.489 264.922 469.105 269.268C469.074 269.369 469.04 269.468 469.007 269.569H460.532C460.541 269.478 460.55 269.378 460.559 269.268C461.124 262.779 457.896 223.754 454 217C454.341 217.548 472.034 235.225 471.376 255.844Z" fill="#168D13"/>
        <path d="M470.74 269.268C470.669 269.369 470.596 269.47 470.52 269.569H464.162C464.21 269.483 464.266 269.382 464.331 269.268C465.381 267.374 468.49 261.713 471.376 255.844C474.476 249.537 477.322 242.991 477.082 240.621C477.157 241.155 479.303 257.446 470.74 269.268Z" fill="#168D13"/>
      </g>
      <defs>
        <clipPath id="clip0_4840_41762">
      <rect width="500" height="270.733" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}
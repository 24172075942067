
export class BaseInputEvent {
  constructor(
    readonly dirty: boolean,
    readonly touched: boolean) {
  }

  get dirtyAndTouched(): boolean {
    return this.dirty && this.touched;
  }
}


export class BaseInputOnChangeEvent extends BaseInputEvent {
  constructor(
    readonly value: string | number | boolean,
    readonly dirty: boolean,
    readonly touched: boolean) {
    super(dirty, touched);
  }
}

export class BaseInputOnAddEvent extends BaseInputEvent {
  constructor(
    readonly value: string | number | boolean,
    readonly dirty: boolean,
    readonly touched: boolean) {
    super(dirty, touched);
  }
}

export class BaseInputOnRemoveEvent extends BaseInputEvent {
  constructor(
    readonly index: number,
    readonly dirty: boolean,
    readonly touched: boolean) {
    super(dirty, touched);
  }
}

export class BaseInputOnSelectEvent extends BaseInputEvent {
  constructor(
    readonly index: number,
    readonly dirty: boolean,
    readonly touched: boolean) {
    super(dirty, touched);
  }
}

export class BaseInputOnBlurEvent extends BaseInputEvent {
  constructor(
    readonly value: string | number | boolean,
    readonly dirty: boolean,
    readonly touched: boolean) {
    super(dirty, touched);
  }
}

export class BaseInputOnFocusEvent extends BaseInputEvent {
  constructor(
    readonly value: string | number | boolean,
    readonly dirty: boolean,
    readonly touched: boolean) {
    super(dirty, touched);
  }
}
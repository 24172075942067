import React from "react";

type ChevronLeftProps = {
  active?: boolean
}

export const ChevronLeft: React.FC<ChevronLeftProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={props.active ? (props.active === true ? "currentColor" : "#E6E6E6") : "currentColor" }
        d="M14 6l1.41 1.41L10.83 12l4.58 4.59L14 18l-6-6 6-6z"
      ></path>
    </svg>
  );
}
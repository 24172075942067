import React from "react";
import { sizeFlag } from "../Helper";

type FlagCAProps = {
  size?: string
}

export const FlagCA: React.FC<FlagCAProps> = (props) => {
  const size = sizeFlag(props.size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size.width}
      height={size.height}
      fill="none"
      viewBox="0 0 24 18"
    >
      <path fill="#fff" d="M5.63 0h12.737v16H5.63V0z"></path>
      <path
        fill="#D52B1E"
        d="M0 0h6v16H0V0zm18 0h6v16h-5.996l-.003-16zM7.799 7.731l-.47.15 2.17 1.797c.165.46-.057.594-.2.838l2.352-.282-.056 2.235.49-.013-.109-2.218 2.359.265c-.146-.29-.275-.444-.143-.906l2.167-1.703-.378-.131c-.311-.226.133-1.085.199-1.629 0 0-1.262.41-1.345.194l-.325-.584-1.15 1.194c-.125.028-.178-.02-.208-.12l.53-2.493-.841.447c-.07.031-.14.006-.186-.072l-.811-1.531-.835 1.59c-.063.057-.126.063-.179.025l-.802-.425.484 2.472c-.04.1-.132.125-.238.072L9.169 5.725c-.142.219-.238.572-.43.653-.19.075-.828-.15-1.256-.237.146.497.603 1.322.315 1.593v-.003z"
      ></path>
    </svg>
  )
}
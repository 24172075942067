import React, { useEffect, useState } from "react";
import { Flag } from "../images";
import { Monitor, AverageRt, MonitorRegionAverageRtByDay, Region } from "../model";
import { camelToDasherizedCase, formatResponseTime } from "../Helper";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from "recoil";
import { regionsState, teamState } from "../atoms";

type ResponseTimeRegionsProps = {
  day: string,
  monitor: Monitor,
  monitorAverageRt: AverageRt[],
  monitorRegionAverageRtByDay: MonitorRegionAverageRtByDay[]
}

export const ResponseTime: React.FC<ResponseTimeRegionsProps> = (props) => {

  const { t } = useTranslation();
  const team = useRecoilValue(teamState);

  const regions = useRecoilValue(regionsState);
  const [ slower, setSlower ] = useState(0);
  const [ monitorAverageRtByDay, setMonitorAverageRtByDay ] = useState<number[]>()

  useEffect(() => {
    let slower = 0;

    const monitorAverageRtByDay = [0,0,0,0,0,0,0,0,0,0];
    for (const martHour of props.monitorAverageRt) {
      for (let i = 0; i < martHour.rt.length; i++) {
        monitorAverageRtByDay[i] += martHour.rt[i];
      }
    }

    if (props.monitorAverageRt.length > 0) {
      for (let i = 0; i < monitorAverageRtByDay.length; i++) {
        monitorAverageRtByDay[i] = monitorAverageRtByDay[i] / props.monitorAverageRt.length;
      }
    }

    const monitorAverageRt = monitorAverageRtByDay.reduce((t,v) => t + v);

    const responseTimeDays = [monitorAverageRt, ...props.monitorRegionAverageRtByDay.map(mr => mr.rt.reduce((t,v) => t + v))];

    for (const rtd of responseTimeDays) {
      if (rtd > slower) {
        slower = rtd;
      }
    }
    setSlower(slower);
    setMonitorAverageRtByDay(monitorAverageRtByDay);
  }, [props.monitorAverageRt, props.monitorRegionAverageRtByDay]);

  if (!team || !regions || !monitorAverageRtByDay) {
    return null;
  } else {

    return (
      <div className="d-f fb-d-c m-t-2">
        {props.monitorRegionAverageRtByDay.length > 1 ?
          <div className="d-f fb-d-c fb-d-r-M m-b-2 t-c-nb">
            <div className="d-f a-i-c fb-n">
              <div className="d-n d-f-M j-c-e w-5 f-s-0.75 m-r-0.5">{t("Average")}</div>
              <div className="d-f w-1.5 m-r-0.5 m-b-0.5 m-b-0-M">
              </div>
              <div className="d-f d-n-M w-5 f-s-0.75 m-b-0.5 m-r-0.5">{t("Average")}</div>
            </div>
            <div className="d-f fb-a w-100% j-c-c j-c-s-M p-l-2 p-l-0-M">
              {renderChart(slower, monitorAverageRtByDay, props.monitor.type)}
            </div>
          </div>
        : null}
        {props.monitorRegionAverageRtByDay.map((mr, i) => {
          const region = regions.find(r => r.id === mr.regionId) as Region;
          const urlRegionName = camelToDasherizedCase(region.systemName);

          return (
            <Link
              to={`/${team.slug}/monitors/${props.monitor.uuid}/${props.day}/${urlRegionName}`}
              key={i}
              className="d-f fb-d-c fb-d-r-M m-b-1 t-c-nb">
              <div className="d-f a-i-s fb-n">
                <div className="d-n d-f-M j-c-e w-5 f-s-0.75 m-r-0.5">{ region.name }</div>
                <div className="w-1.5 m-r-0.5 m-b-0.5 m-b-0-M d-f">
                  <Flag country={region.country.code} size={"mini"} />
                </div>
                <div className="d-f d-n-M w-5 f-s-0.75 m-b-0.5 m-r-0.5">{ region.name }</div>
              </div>
              <div className="d-f fb-a w-100% j-c-c j-c-s-M p-l-2 p-l-0-M">
                {renderChart(slower, mr.rt, props.monitor.type)}
              </div>
            </Link>
        )})}
      </div>
    );
  }
}

function renderChart(
  slower: number,
  rtDay: number[],
  monitorType: string) {

// Rt [10]float64
// [0] dns_start
// [1] dns_done
// [2] connect_start
// [3] connect_done
// [4] tls_handshake_start
// [5] tls_handshake_done
// [6] got_connection
// [7] wrote_headers
// [8] wrote_request
// [9] ttfb

  let connectStartDay = 0;
  let connectDoneDay = 0;
  let tlsHandshakeStartDay = 0;
  let tlsHandshakeDoneDay = 0;
  let gotConnectionDay = 0;
  let wroteHeadersDay = 0;
  let wroteRequestDay = 0;
  let ttfbDay = 0;
  
  connectStartDay += rtDay[2];
  connectDoneDay += rtDay[3];
  if (monitorType === "http" || monitorType === "https") {
    tlsHandshakeStartDay += rtDay[4];
    tlsHandshakeDoneDay += rtDay[5];
    gotConnectionDay += rtDay[6];
    wroteHeadersDay += rtDay[7];
    wroteRequestDay += rtDay[8];
    ttfbDay += rtDay[9];
  }

  const connection = Math.round(connectStartDay + connectDoneDay);
  const tlsHandshake = Math.round(tlsHandshakeStartDay + tlsHandshakeDoneDay);
  const response = Math.round(gotConnectionDay + wroteHeadersDay + wroteRequestDay + ttfbDay);
  const responseTimeTotal = connection + tlsHandshake + response

  if (responseTimeTotal === 0.0) {
    return (
      <div className="w-100% f-s-0.75 f-w-400 p-l-0.5-M" style={{color: "#000"}}>
        No Data
      </div>
    )
  } else {

    let barSize = ((responseTimeTotal / slower));
    if (barSize < 0.01) {
      barSize = 0.01;
    }
    return (
      <div className="d-f j-c-s w-100%">
        <div className="d-f min-w-4" style={{width: `calc((100% - 6rem) * ${barSize})`}}>
          <div
            className="bg-c-rt2 d-f h-1 j-c-c t-c-w f-s-0.75 f-w-400 min-w-1 of-h"
            style={{flex: `${connection}` }} title={formatResponseTime(connection)}>{ formatResponseTime(connection) }</div>
          {monitorType === "http" || monitorType === "https" ?
            <>
              <div
                className="bg-c-rt3 d-f h-1 j-c-c t-c-w f-s-0.75 f-w-400 min-w-1 of-h"
                style={{flex: `${tlsHandshake}` }} title={formatResponseTime(tlsHandshake)}>{ formatResponseTime(tlsHandshake) }</div>
              <div
                className="bg-c-rt4 d-f h-1 j-c-c t-c-w f-s-0.75 f-w-400 min-w-1 of-h"
                style={{flex: `${response}` }} title={formatResponseTime(response)}>{ formatResponseTime(response) }</div>
            </>
          : null}
        </div>
        <div className="w-6 f-s-0.75 f-w-400 p-l-0.5" style={{color: "#000"}}>{ formatResponseTime(responseTimeTotal) }</div>
      </div>
    );
  }

}
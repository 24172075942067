import axios from 'axios';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { lastPhoneAlertLogState, teamState } from '../atoms';
import { apiBaseUrl } from '../Consts';
import { useHandleRequestError } from '../hooks';
import { IconPhoneAlert, IconBuyCar } from '../images';
import { NewTabIcon } from '../images/NewTabIcon';
import { Paginator as ModelPaginator, PhoneAlertLog } from '../model';
import { Loading, Paginator } from '../ui';
import { PhoneAlertCreditsBuy } from './PhoneAlertCreditsBuy';

type LogSelector = {
  data: PhoneAlertLog,
  showingRecipients: boolean
}

export const PhoneAlertCredits: React.FC = () => {


  const { t } = useTranslation();
  const [ team ] = useRecoilState(teamState);
  const [ logs, setLogs ] = useState<LogSelector[]>();
  const [ logsPaginator, setLogsPaginator ] = useState<ModelPaginator>();
  const [ balance, setBalance ] = useState<number>();
  const { handleRequestError } = useHandleRequestError();
  const lastPhoneAlertLog = useRecoilValue(lastPhoneAlertLogState);
  const history = useHistory();

  const location = useLocation();

  const [ isBuying, setIsBuying ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const fetch = useCallback((recordOffset = 0) => {
    if (team) {
      setLoading(true);
      axios.get(`${apiBaseUrl}/${team.uuid}/phone-alert-credits`, {
          params: { recordOffset: recordOffset }
        })
        .then(response => {
          setLogs(response.data.logs ?
            (response.data.logs as PhoneAlertLog[]).map(l => ({data: l, showingRecipients: false}))
            : []);
          setLogsPaginator(response.data.logsPaginator);
          setBalance(response.data.balance)
        })
        .catch(err => handleRequestError(err))
        .finally(() => setLoading(false))
    }
  }, [handleRequestError, team]);


  const onSave = useCallback(() => fetch(), [fetch]);

  const showRecipients = useCallback((i: number) => {
    if (logs) {
      const log = {...logs[i], showingRecipients: true} as LogSelector;
      logs.splice(i, 1, log);
      setLogs([...logs]);
    }
  }, [logs]);

  const openBuy = useCallback(() =>
    history.push(`/${team?.slug}/billing/phone-alert-credits/buy`)
  , [history, team]);

  const closeBuy = useCallback(() =>
    history.push(`/${team?.slug}/billing/phone-alert-credits`)
  , [history, team]);

  useEffect(() => {
    if (location.pathname.split("/").some(p => p === "buy")) {
      setIsBuying(true);
    } else {
      setIsBuying(false);
    }
  }, [location.pathname])

  useEffect(() => fetch(), [fetch]);

  useEffect(() => {
    if (lastPhoneAlertLog) {
      fetch();
    }
  }, [lastPhoneAlertLog])


  return (
    <>
      <div className="d-f fb-d-c w-100% p-h-1">
        {loading ?
          <Loading />
        :
          <>
            <div
              onClick={openBuy}
              className="d-f d-n-L j-c-e m-t-1 c-p t-c-g">
              {t("Add phone alert credits")}
            </div>
            <div className="d-f m-t-2 m-t-4-L">
              <div className="d-f fb-a">
                <div className="fb-a fb-n-L m-r-1.5 f-s-1.25 f-s-2-L">{t("Phone alerts available")}:</div>
                <div className="d-f f-w-b f-s-1.5 f-s-2-L">{balance}</div>
              </div>
              <div onClick={openBuy} className="d-n d-f-L m-t-1 c-p t-c-g">
                {t("Add phone alert credits")}
              </div>
            </div>
            {!logs || logs.length === 0 ?
              <div className="m-v-2">
                {t("There are no records of Phone alerts.")}
              </div>
            :
              <>
                <div className="d-f f-s-1.25 f-s-2-L m-t-3">
                  {t("Phone Alerts History")}
                </div>
                <div className="d-f m-t-2 b-sb-s b-w-1px b-c-lightestGray ">
                  <div className="fb-a f-w-600">{t("Description")}</div>
                  <div className="d-n d-b-L w-9 f-w-600">{t("Date")}</div>
                  <div className="d-n d-b-L w-4 f-w-600 t-a-r">{t("Qty")}</div>
                  <div className="d-n d-b-L w-6 f-w-600 t-a-r">{t("Balance")}</div>
                </div>
                <div className="d-f fb-d-c">
                  {logs.map((log, i) =>
                    <div key={i} className="d-f fb-d-c p-v-0.5 b-sb-s b-w-1px b-c-lightestGray">
                      <div className="d-c d-f-L">
                        <div className="d-f fb-a a-i-c p-v-0.5">
                          <div className="w-1.5 m-r-0.25 m-h-1-L d-f a-i-c">
                            {log.data.isCredit ?
                              <IconBuyCar />
                            :
                              <IconPhoneAlert />
                            }
                          </div>
                          <div className="fb-a p-r-2-L a-i-c">
                            <div className="d-f">
                              <div className="x-truncate fb-a p-r-1" title={log.data.description}>
                                {log.data.description}
                              </div>
                              <div className="d-f a-i-c fb-n">
                                {log.data.isCredit && log.data.invoice.stripeUrl && team ?
                                  <>
                                    <div>
                                      <a  href={log.data.invoice.stripeUrl} target="_blank" rel="noreferrer" className="t-c-g c-p">
                                        {t("Show invoice")}
                                      </a>
                                    </div>
                                    <div className="d-f p-l-0.25 a-i-c"><NewTabIcon /></div>
                                  </>
                                : !log.showingRecipients && log.data.recipients && log.data.recipients.length > 0 ?
                                    <div className="cursor-pointer t-u t-c-g c-p" onClick={() => showRecipients(i)}>
                                      {t("Show detail")}
                                    </div>
                                : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-f m-t-0.75 m-t-0-L a-i-s a-i-c-L p-l-0 p-l-1.75-M p-l-0-L">
                          <div className="w-9-L">
                            {DateTime.fromISO(log.data.createdAt).toFormat('dd LLL yyyy HH:mm')}
                          </div>
                          <div className="d-f d-n-L p-h-0.25 p-h-0.5-M">|</div>
                          <div className="w-4-L t-a-c t-a-r-L">
                            <span className="d-i d-n-L p-r-0.5 p-r-0-L">{t("Qty")}:</span>
                            {!log.data.isCredit ? " -" : null}{log.data.quantity}
                          </div>
                          <div className="d-f d-n-L p-h-0.5">|</div>
                          <div className="w-6-L t-a-r">
                            <span className="d-i d-n-L p-r-0.5 p-r-0-L">{t("Balance")}:</span>
                            {log.data.balance}
                          </div>
                        </div>
                      </div>
                      <div className="d-f p-l-3.5 w-100%">
                        {log.data.recipients && log.showingRecipients ?
                          <>
                            <div className="d-f fb-a m-t-1 p-r-1 f-s-0.9">
                              {t("Recipients:")} {log.data.recipients.map(r => r).join(", ")}
                            </div>
                            <div className="d-n d-f-L fb-n w-19"></div>
                          </>
                        : null}
                      </div>
                    </div>
                  )}

                  {team && logs && logsPaginator ?
                    <div className="d-f j-c-c m-t-2">
                      <Paginator
                        recordOffset={logsPaginator.recordOffset}
                        recordsCount={logsPaginator.recordsCount}
                        pageSize={logsPaginator.pageSize}
                        onSelect={(page) => fetch((page - 1) * logsPaginator.pageSize)} />
                    </div>
                  : null}
                </div>
              </>
            }
          </>
        }
      </div>

      { isBuying && balance !== undefined ?
        <PhoneAlertCreditsBuy
          balance={balance}
          onSave={onSave}
          onClose={closeBuy} />
        : null
      }
    </>
  );
}

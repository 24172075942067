import React from "react";

export const CompletedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        fill="#E6E6E6"
      />
      <path
        d="M15.9998 29.3332C23.3636 29.3332 29.3332 23.3636 29.3332 15.9998C29.3332 8.63604 23.3636 2.6665 15.9998 2.6665C8.63604 2.6665 2.6665 8.63604 2.6665 15.9998C2.6665 23.3636 8.63604 29.3332 15.9998 29.3332Z"
        fill="#168D13"
      />
      <path
        d="M14.8671 20.8218C14.8433 20.8218 14.8194 20.8212 14.7955 20.8201C14.5847 20.8099 14.3785 20.7542 14.1912 20.6569C14.0038 20.5596 13.8397 20.4229 13.7102 20.2562L11.6649 17.6265C11.4268 17.3196 11.3201 16.9308 11.3683 16.5453C11.4164 16.1598 11.6155 15.8092 11.9219 15.5704L11.9955 15.5131C12.3024 15.2749 12.6912 15.1683 13.0767 15.2164C13.4622 15.2646 13.8129 15.4637 14.0518 15.77C14.167 15.9183 14.313 16.0398 14.4796 16.1264C14.6462 16.213 14.8296 16.2625 15.0171 16.2717C15.2046 16.2808 15.3919 16.2493 15.5662 16.1794C15.7404 16.1094 15.8975 16.0026 16.0266 15.8663L20.1796 11.4825C20.3119 11.3428 20.4705 11.2305 20.6462 11.1521C20.8219 11.0737 21.0114 11.0306 21.2038 11.0254C21.3961 11.0202 21.5876 11.053 21.7673 11.1218C21.947 11.1906 22.1114 11.2942 22.2511 11.4265L22.3189 11.4908C22.601 11.758 22.7654 12.1264 22.7758 12.5149C22.7863 12.9033 22.6421 13.28 22.3748 13.5621L15.9305 20.3642C15.7937 20.5088 15.6288 20.624 15.446 20.7026C15.2632 20.7813 15.0662 20.8219 14.8671 20.8218V20.8218Z"
        fill="white"
      />
    </svg>
  );
}
import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { teamState } from '../atoms';
import { apiBaseUrl } from '../Consts';
import { useHandleRequestError } from '../hooks';
import { Invoice, InvoiceItem } from '../model';
import logo from '../logo.png';
import { formatAmount } from '../Helper';
import { DateTime } from 'luxon';
import { ChevronLeft } from '../images/ChevronLeft';
import { NewTabIcon } from '../images/NewTabIcon';

export const InvoiceDetails: React.FC = () => {

  const { t } = useTranslation();
  const team = useRecoilValue(teamState);

  const { invoiceUuid } = useParams<any>();
  const { handleRequestError } = useHandleRequestError();
  const [ invoice, setInvoice ] = useState<Invoice>();
  const isCurrent = useMemo(() => invoiceUuid === "current", [invoiceUuid]);

  const fetch = useCallback(() => {
    if (team) {
      axios.get(`${apiBaseUrl}/${team.uuid}/invoices/${invoiceUuid}`)
        .then(response => {
          setInvoice(response.data.invoice as Invoice);
        })
        .catch(err => handleRequestError(err));
    }
  }, [handleRequestError, invoiceUuid, team]);
  useEffect(() => fetch(), [fetch]);

  const startTime = (ii: InvoiceItem): string => {
    return DateTime.fromISO(ii.startsAt).toUTC().toFormat("MM-dd HH:mm");
  }

  const stopTime = (ii: InvoiceItem): string => {
    return (ii.endsAt ? DateTime.fromISO(ii.endsAt) : DateTime.fromISO(ii.updatedAt)).toUTC().toFormat("MM-dd HH:mm");
  }

  return (
    <>
      <div className="d-f fb-d-c w-100% p-h-1 max-w-66 m-h-a p-b-3">
        {invoice && team ?
          <div className="d-f fb-d-c m-t-1 m-t-2-M">
            <div>
              <Link to={`/${team.slug}/billing/invoices`} className="d-f a-i-c">
                <ChevronLeft /> <div className="m-l-0.5">{t("Invoices")}</div>
              </Link>
            </div>
            <div className="m-t-1 m-t-2-M">
              <a href="/">
                <img className="h-2.5" src={logo} alt="Statsignal" />
              </a>
            </div>
            {isCurrent ?
              <div className="d-f m-t-2 fb-d-c fb-d-r-M">
                <div className="d-f a-i-c">
                  {t("Accrued Charges")}
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  1 {DateTime.fromISO(invoice.startAt).toFormat("MMMM")} - {t("Today")}
                  <span className="d-n d-f-M">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                </div>
                <div className="d-f a-i-c m-t-0.5 m-0-M">
                  {formatAmount(invoice.subTotal)}
                </div>
              </div>
            :
              <div className="m-t-2 d-f">
                {DateTime.fromISO(invoice.updatedAt).toFormat("MMMM d")}
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <a href={invoice.stripeUrl} target="_blank" rel="noreferrer" className="t-c-g t-dl-u c-p d-f">
                  {t("Invoice")}&nbsp;{invoice.number}
                  <div className="d-f p-l-0.25 a-i-c"><NewTabIcon /></div>
                </a>
              </div>
            }
            <div className="d-f fb-d-c m-t-4 m-t-6-M">
              <div className="f-s-1 f-w-b">{t("Charges")}</div>
              <div className="m-t-2 p-b-0.75 d-f b-sb-s b-w-1px b-c-lightestGray">
                <div className="fb-a f-w-600 m-t-1">{t("Description")}</div>
                {invoice.invoiceItems.find(ii => ii.unitType === "hour") ?
                  <>
                    <div className="d-n d-f-M fb-n w-7-M w-8-L t-a-l f-w-600 m-t-1">{t("Start (UTC)")}</div>
                    <div className="d-n d-f-M fb-n w-7-M w-8-L t-a-l f-w-600 m-t-1">{t("End (UTC)")}</div>
                    <div className="d-n d-f-M fb-n w-3 t-a-c f-w-600 m-t-1">{t("Hours")}</div>
                  </>
                : null}
                <div className="w-7 w-5-M w-7-L t-a-r f-w-600 m-t-1">{t("Price")}</div>
                <div className="w-7 w-5.5-M w-7-L t-a-r f-w-600 m-t-1">{t("Amount")}</div>
              </div>
              <div className="fb-a">
                {invoice.invoiceItems.map((ii, i) =>
                <React.Fragment key={i}>
                  <div className="d-f p-b-1-M b-sb-s-M b-w-1px-M b-c-lightestGray-M">
                    <div className="fb-a m-t-1 ws-n t-o-e of-h m-r-1 m-r-2-L" title={ii.description}>
                      {ii.description}
                    </div>
                    {ii.unitType === "hour" ?
                      <>
                        <div className="d-n d-f-M fb-n w-7-M w-8-L t-a-l m-t-1">{startTime(ii)}</div>
                        <div className="d-n d-f-M fb-n w-7-M w-8-L t-a-l m-t-1">
                          {stopTime(ii)}
                        </div>
                        <div className="d-n d-f-M fb-n w-2 w-3 j-c-c m-t-1">{ii.quantity}</div>
                      </>
                    : null}
                    <div className="fb-n w-4 w-5-M w-7-L t-a-r m-t-1">{formatAmount(ii.unitPrice)}</div>
                    <div className="fb-n w-7 w-5.5-M w-7-L t-a-r m-t-1">{formatAmount(ii.total)}</div>
                  </div>
                  {ii.unitType === "hour" ?
                    <div className="d-f d-n-M d-f b-sb-s b-w-1px b-c-lightestGray p-b-1">
                      <div className="fb-n w-9 t-a-l m-t-1 f-s-0.85">
                        <span className="f-w-b">{t("Start (UTC)")}:&nbsp;&nbsp;</span>{startTime(ii)}
                      </div>
                      <div className="fb-n w-9 t-a-l m-t-1 f-s-0.85">
                        <span className="f-w-b">{t("End (UTC)")}:&nbsp;&nbsp;</span>{stopTime(ii)}
                      </div>
                      <div className="fb-n w-3 t-a-c m-t-1 f-s-0.85">
                        <span className="f-w-b">{t("Hours")}:&nbsp;&nbsp;</span>{ii.quantity}
                      </div>
                    </div>
                  : null}
              </React.Fragment>
                )}
              </div>
              {isCurrent ?
                <div className="d-f m-t-1 ">
                  <div className="fb-a m-t-1"></div>
                  <div className="w-8 t-a-r f-w-b">{t("Total Estimated")}</div>
                  <div className="w-7 t-a-r f-w-b">{formatAmount(invoice.subTotal)}</div>
                </div>
              :
                <>
                  <div className="d-f m-t-1 ">
                    <div className="fb-a m-t-1"></div>
                    <div className="w-8 t-a-r">{t("Sub Total")}</div>
                    <div className="w-7 t-a-r f-w-b">{formatAmount(invoice.subTotal, 2)}</div>
                  </div>
                  <div className="d-f m-t-1 ">
                    <div className="fb-a m-t-1"></div>
                    <div className="w-8 t-a-r">{t("Tax")}</div>
                    <div className="w-7 t-a-r f-w-b">{formatAmount(invoice.tax, 2)}</div>
                  </div>
                  <div className="d-f m-t-1 ">
                    <div className="fb-a m-t-1"></div>
                    <div className="w-8 t-a-r">{t("Total")}</div>
                    <div className="w-7 t-a-r f-w-b">{formatAmount(invoice.total, 2)}</div>
                  </div>
                </>
              }
            </div>
          </div>
        : null}
      </div>
    </>
  );
}
